import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { titlename } from '../../../ENUM/titlename';
import { AssetService } from '../../../services/Assets/asset.service';
import { CustomDateService } from '../../../services/Common/customDateService';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-resources-default',
  templateUrl: './resources-default.component.html',
  styleUrls: ['./resources-default.component.scss']
})
export class ResourcesDefaultComponent implements OnInit {
  searchText = new FormControl('');
  titleName = "Channel List";
  tokenDatasource: Array<any>
  displayedColumns: string[] = ['channelLogo', 'channelCode', 'channelName'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  submitted = false;
  searchShow: boolean = true;
  token: any;
  hideButton: boolean = true;
  titleEnum: typeof titlename;
  FreeText = new FormControl('');
  channelList: { id: string; name: any; }[];
  orderStatusList: { id: string; name: any; }[];
  shipmentServiceLevelList: { id: string; name: any; }[];
  paymentMethodList: { id: string; name: any; }[];
  itemConditionList: any;
  PermissionsEnum: typeof PermissionsEnum;
  productResponse$: Observable<any>;
  productData: any;
  sortByParams: any = '';
  featureIds: typeof HelpFeatureIdsEnum;
  displayNoItemsTemplate: boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  constructor(
    private formbulider: FormBuilder,
    private customMessageService: CustomMessageService,
    public dialog: MatDialog,
    private customDateService: CustomDateService,
    private assetService: AssetService
  ) {


  }

  ngOnInit() {
    this.featureIds = HelpFeatureIdsEnum;
    this.PermissionsEnum = PermissionsEnum;
    this.titleEnum = titlename;
    this.getResources();
    this.setDefaultNoItemsFound();
  }
  getResources() {
    this.assetService.getResources().subscribe(
      data => {
        if (data.data['data'].length) {
          this.dataSource.data = data.data['data'];
          this.token = data.data["paging"].continuationToken;
          if (this.token) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }
          this.tokenDatasource = data.data['data'];
        }
        else {
          this.hideButton = true;
          this.dataSource.data = [];
          this.customMessageService.ShowCustomMessage(messages.noRecordFound, true);
          this.displayNoItemsTemplate = true;
        }
      }
    );
  }
  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.tokenDatasouce(this.token)
    }
  }
  tokenDatasouce(token) {

    if (token !== null || token !== undefined) {

      var requestParameter = "?continuationToken=" + encodeURIComponent(token);

      this.assetService.getResources(requestParameter).subscribe(data => {
        if (data.data) {
          for (let i = 0; i < data.data["data"].length; i++) {
            this.tokenDatasource.push(data.data["data"][i]);
          }
          this.token = data.data["paging"].continuationToken;
          this.hideButton = true;
          if (data.data["paging"].continuationToken) {
            this.hideButton = false;
          }
          this.dataSource.data = this.tokenDatasource;
        }
      });
    }
  }

  customDate(date) {
    return this.customDateService.customDateFormat(date);
  }
  customDateAndTime(date) {
    return this.customDateService.customDateAndTimeFormat(date);
  }
  downloadZipFile(url) {
    window.open(url, "_blank")
  }

  //===================================
  // openHistory(id,name)
  // {
  //   this.getHistoryService.openHistoryDialog(PIMEntityType.Channel,id,name);  
  // }
  // openChat(id,name)
  // {
  //   this.chatDialogService.openChatDialog(PIMEntityType.Channel,id,name);  
  // }
  // openHelpDialog(featureId)
  // {
  //   this.helpGuideService.openHelpGuideDialog(featureId)
  // }

  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle="Downloads";
    this.noItemsFoundDesc=messages.downloadPendingDesc;
  }
}

