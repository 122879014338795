export enum PIMEntityType
    {
        Product = 1,
        Category=2,
        Brand=3,
        NotificationEventSettings=4,
        ProductTag=5,
        VariantType=6,
        User=7,
        Asset=8,
        AssetCategory=9,
        Channel=10
    }

    export enum ActivityType 
    {
        Create = 1,
        Update,
        Delete
    }