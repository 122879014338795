export const maximumLength = {
    mobileNumber :10,
    pinCode:6,
    length100:100,
    length50:50,
    length25:25,
    length15:15,
    length250:250,
    length150:150,
    length60:60,
    length200:200,
    postalCode:6,
    mobileNumberWithCode:12,
    length9:9,
    category:50
}