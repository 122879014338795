import { Injectable } from '@angular/core';
import { HttpHelperService } from '../Common/http-helper.service';
import { UserModel } from 'src/app/shared/models/authorization/user-model';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
  _url = "user";
  constructor(private httpHelper: HttpHelperService) { }

  createUser(user: UserModel) {
    user.id=0;
    user.profileImageUri="";
    user.statusId=2;
    user.statusName="";
    user.organizationId=0;
    //user.lastLogin="2019-08-13T08:18:50.517Z";
    return this.httpHelper.postData(user, this._url);
  }
}
