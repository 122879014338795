import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataService } from './data.service';
import { RouteGuardsService } from './route-guards.service';
import { StorageService } from './storage.service';
import { UrlSessionStorageService } from './url-session-storage.service';
import { ApplicationService } from './application.service';
import { UserStatusService } from './User/user-status.service';
import { CreateUserService } from './User/create-user.service';
import { LoaderInterceptor } from './loader/loading-interceptor';
import { LoaderService } from './loader/loader.service';
import { ServerErrorInterceptor } from './error-handler-global/server-error.interceptor';
import { ErrorsHandler } from './error-handler-global/errors-handler';
import { CommonService } from './Common/common.service';
import { HttpHelperService } from './Common/http-helper.service';
import { SessionStorageService } from './Common/session-storage.service';
import { AuthAccessService } from './Authorization/auth-access.service';
import { AuthCallBack } from './Authorization/auth-callback.service';
import { AuthInterceptor } from './Authorization/auth-interceptor';
import { AuthService } from './Authorization/auth.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { CookieCheckGuardService } from './guards/cookie-check-guard.service';
import { RegisterGuardService } from './guards/register-guard.service';
import { CustomMessageService } from './custommessageservice';
import { UserInfoService } from './common_services/user-info.service';

@NgModule({
  declarations: [],
  providers: [DataService, RouteGuardsService, StorageService, UrlSessionStorageService, ApplicationService,
    CreateUserService, LoaderInterceptor, LoaderService, ServerErrorInterceptor, ErrorsHandler, CommonService, HttpHelperService,
    SessionStorageService, AuthAccessService, AuthCallBack, AuthInterceptor, AuthService, AuthGuardService,
    CookieCheckGuardService, RegisterGuardService, UrlSessionStorageService, CustomMessageService,
     UserStatusService, UserInfoService,DatePipe],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }
