import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output,EventEmitter, ViewChildren, Inject, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'mat-range-datepicker';
import { PermissionsEnum } from 'src/app/modules/pim/constants/permissions';
import { DeleteDialogComponent } from 'src/app/modules/pim/dialogs/delete/delete.dialog.component';
import { ImportDialogComponent } from 'src/app/modules/pim/dialogs/import-files/import.dialog.component';
import { HelpFeatureIdsEnum } from 'src/app/modules/pim/ENUM/helpFeatureIds';
import { PIMEntityType } from 'src/app/modules/pim/ENUM/PIMEntityTypeEnum';
import { ProductStatusEnum } from 'src/app/modules/pim/ENUM/productstatusenum';
import { titlename } from 'src/app/modules/pim/ENUM/titlename';
import { CategoryModel } from 'src/app/modules/pim/models/Category/category-model.model';
import { ProductStatus } from 'src/app/modules/pim/models/product/product-status';
import { SearchproductModel } from 'src/app/modules/pim/models/product/searchproductmodel';
import { RouterLinksEnum } from 'src/app/modules/pim/shared/router-links/router-links.enum';
import { CategoriesServiceService } from 'src/app/modules/pim/services/categories-service.service';
import { CommentService } from 'src/app/modules/pim/services/comment/comment.service';
import { CommonService } from 'src/app/modules/pim/services/Common/common.service';
import MY_FORMATS from 'src/app/modules/pim/services/Common/customDateService';
import { HttpHelperService } from 'src/app/modules/pim/services/Common/http-helper.service';
import { HelpGuideService } from 'src/app/modules/pim/services/helpGuide/helpGuideService';
import { ProductListService } from 'src/app/modules/pim/services/Product/product-list.service';
import { ProductService } from 'src/app/modules/pim/services/Product/product.service';
import { ProductbrandService } from 'src/app/modules/pim/services/Product/productbrand.service';
import { ProductstatusService } from 'src/app/modules/pim/services/productstatus.service';
import { ChatDialogService } from 'src/app/modules/pim/shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from 'src/app/modules/pim/shared/constants/global';

import { HistoryDialogService } from 'src/app/modules/pim/shared/histotyDialogOpenService/historyDialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { StickySearchDirective } from 'src/app/modules/pim/shared/directives/sticky-search.directive';
import { messages } from 'src/app/shared/Constants/messageResource';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { CustomMessageService } from 'src/app/core/custommessageservice';
;

@Component({
  selector: 'app-desktop-product-list-default',
  templateUrl: './desktop-product-list-default.component.html',
  styleUrls: ['./desktop-product-list-default.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue:MY_FORMATS },
  ],
})
export class DesktopProductListDefaultComponent implements OnInit {
  @Output() currentDevice:EventEmitter<object>= new EventEmitter(); 
  blob: Blob;
  @Input() hideTopPart:boolean  //added to inject product into category
  @Input() injectedCategoryId: any; //added to inject product into category
  @Input() injectedSearchText: any;
  public show: boolean = false;
  public buttonName: any = 'Show';
  myControl = new FormControl;
  getallproductInfo: SearchproductModel[] = [];
  productstatusinfo: ProductStatus[] = [];
  productbrandinfo: any;
  SerachForm: any;
  isFormSubmit: boolean = false;
  dataSourceArray = [];
  jsonOrgData: string;
  orgId: any;
  organizationData: Array<any>;
  category: CategoryModel[] = [];
  categorys$: Observable<any>;
  statusValues;
  productstatus
  variantId: any;
  brandId: any;
  selectedIndex;
  prList = {};
  checkParentStatus: boolean = false;
  isExampanded: boolean[] = [];
  PermissionsEnum;
  RouterLinksEnum: any;
  CatId;
  selectedBrands = [];
  filteredOptions: Observable<string[]>;
  options: any;
  response: any;
  searchText = '';
  allCategories;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  brandIdName = [];
  newArr: Array<SearchproductModel>;
  autoFieldsFromSearchForm = {
    sku: 'product',
    keyword: 'keyword',
    productCategory: 'category',
    createdOn: 'createdon',
    updatedOn: 'updatedon',
    maximumSellerAllowedPrice: 'priceto',
    minimumSellerAllowedPrice: 'pricefrom',
    // status: [],
    productStatus: 'status',
    productBrand: 'brands',
    createdByName: 'createdby',
    updatedByName: 'updatedby',
    // sellingChannels: '',
    includeSubcategories: 'has:subcategories',
    ShowOnlyVariationGroups:'has:variationgroups',
    CreateDateRangeStart: 'createafter',
    CreateDateRangeEnd: 'createbefore',
    UpdateDateRangeStart: 'updateafter',
    UpdateDateRangeEnd: 'updatebefore'
  };
  token: any;
  hideButton: boolean = true;
  count: number = 0;
  allDeleteObservable: Observable<any>[] = [];
  activeItemId: any;
  titlename: string;
  titleEnum: typeof titlename;
  hideDeleteButton: boolean = false;
  categoryId: any;
  approvalTypeEnum: { id: number; name: string; }[];
  featureIds: typeof HelpFeatureIdsEnum;
  maxDate = new Date();
  isBaseOrganization:boolean = true;
  baseOrganizationId:any;
  showkinatorImportIcon: boolean = false;
  displayTotal:number=0;
  queryParamStatuses:string;
  @ViewChild (StickySearchDirective,{static:true}) stickySearchDirective : StickySearchDirective;
  queryString: any = {};
  apiInProgress:boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  checkRequestParameters:string;
  constructor(private productListService: ProductListService,private productstatusService: ProductstatusService,
    private productbrandService: ProductbrandService,private formbulider: FormBuilder,private route: ActivatedRoute,
    private router: Router,private categoryService: CategoriesServiceService,private fb: FormBuilder,
    public httpClient: HttpClient,private CustomMessage: CustomMessageService,public dialog: MatDialog,
    public productService: ProductService,private historyDialogService:HistoryDialogService,private httpHelper:HttpHelperService,
    private chatDialogService:ChatDialogService,private commentService:CommentService,
    private helpGuideService:HelpGuideService, private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,private deviceDetect:DeviceDetect) {

      const state = history.state;
      if(state){
        this.variantId = state.id;
      }
      this.getAllproductbrand();
  }
  columnsToDisplay = ['id', 'image', 'name', 'sku', 'mrp','approvalStatus', 'status', 'actions',];
  expandedElement: SearchproductModel | null;
  displayedColumns = ['id', 'image', 'name', 'sku', 'mrp','approvalStatus', 'status','actions'];
  public dataSource = new MatTableDataSource<SearchproductModel>();
  selection = new SelectionModel<any>(true, []);
  numberPattern = "^[0-9]{0,200}[.]?[0-9]{0,200}$";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  subscriptionRef: Subscription;
  ngOnInit() {
    this.setDefaultNoItemsFound();
    this.subscriptionRef = this.productService.productImportNotification.subscribe(() => {
      this.getAllProducts();   
    });
    
    this.route.queryParamMap.subscribe((param)=>{
      this.queryParamStatuses=param.get('statuses');
    })
    this.featureIds = HelpFeatureIdsEnum; 
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.productList;
    this.document.body.scrollTop = 0;
    this.RouterLinksEnum = RouterLinksEnum;
    this.options = [];
    this.approvalTypeEnum = [{ id: 1, name: 'Not Initiated' },
    { id: 2, name: 'Pending For Approval' },
    { id: 3, name: 'Approved' },
    { id: 4, name: 'Rejected' },
    ];
    this.statusValues = { 1: "Draft", 2: "Completed", 3: "Active", 4: "Inactive", 5: "Discontinued" };
    this.PermissionsEnum = PermissionsEnum;
    this.SerachForm = this.formbulider.group({
      sku: [],
      productCategory: [],
      createdOn: [],
      updatedOn: [],
      maximumSellerAllowedPrice: [, [Validators.pattern(this.numberPattern)]],
      minimumSellerAllowedPrice: [, [Validators.pattern(this.numberPattern)]],
      status: [],
      productStatus: [],
      productBrand: [],
      createdByName: [],
      updatedByName: [],
      sellingChannels: [],
      includeSubcategories: [],
      ShowOnlyVariationGroups:[],
      CreateDateRangeStart: [],
      CreateDateRangeEnd: [],
      UpdateDateRangeStart: [],
      UpdateDateRangeEnd: [],
      keyword: [],
      approvalStatus:[],
      freeText:[],
      categoryId:[]
    })
    this.organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = this.organizationData[0].id;
    this.baseOrganizationId=environment.baseOrganizationId
    if(this.orgId==this.baseOrganizationId)
    {
      this.isBaseOrganization=false;
    }
    this.getProductStatus();
    this.dataSource.paginator = this.paginator;
    this.categorys$ = this.getCategories(this.orgId);
    this.categorys$.subscribe(data => {
      this.category = data;
      this.allCategories = data;
      this.response = data;
      for (let i = 0; i < this.response.length; i++) {
        this.options.push(this.response[i].fullHierarchicalName);
      }
    });
    let brandControler = this.SerachForm.get(['productCategory']) as FormControl;
    this.categorys$ = brandControler.valueChanges.pipe(
      startWith(''),
      map(brand => brand ? this.filterBrands(brand) : this.category.slice())
    );
    this.categoryId = this.route.snapshot.params['categoryId'] ? this.route.snapshot.params['categoryId'] : null;
    this.variantId = this.route.snapshot.params['variantId'] ? this.route.snapshot.params['variantId'] : null;
    this.brandId = this.route.snapshot.params['brandId'] ? this.route.snapshot.params['brandId'] : null;
    //added to inject product into category ---------start
    if(this.injectedCategoryId){
      this.categoryId=this.injectedCategoryId
    }
    //-----------------------------------------------end
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(null),
        map(val => (val !== null && val.length >= 1) ? this.filter(val) : this.options)
      );

      if(this.injectedSearchText){
        this.searchText=this.injectedSearchText
        this.doSplitSerchAndFormSubmit();
      }
      if(this.injectedCategoryId){
        if(this.hideTopPart){
          this.doSplitSerchAndFormSubmit();
        }
      }
      else{
        if(!this.hideTopPart){
          this.getAllProducts();
        }
      }

    this.myControl.valueChanges.subscribe(uname => {
        if(uname.length===0){
          this.SerachForm.get("productCategory").setValue('');
          this.SerachForm.get('categoryId').setValue('');
        }
      }); 
    this.getAdminDomin();
  }
  filter(val: string): string[] {
    return this.options.filter(option => option.toLowerCase().includes(val.toLocaleLowerCase()));
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  productStatusUpdate(event, productstatus, id, index, variantIndex) {
    let productStatusID = productstatus.toString();
    let varintProductId = variantIndex;
    let toggleElement = event;
    if (productstatus == ProductStatusEnum.Active) {
      productstatus = ProductStatusEnum.Inactive;
    }
    else {
      productstatus = ProductStatusEnum.Active;
    }
    var productStatusDetail = {
      organizationId: this.orgId,
      productId: id,
      status: productstatus
    };
    this.productService.postProductStatus(productStatusDetail).subscribe(res => {
      if (res.data) {
        if(variantIndex || variantIndex == 0)
        {
          for (let i = 0; i < this.dataSource.data.length; i++) 
          {
            if (this.dataSource.data[i].isVariantGroup == true) 
            {
              for (let j = 0; j < this.dataSource.data[i].productVariants.length; j++) 
              {
                if (this.dataSource.data[i].productVariants[j].id == id)
                {
                  this.dataSource.data[i].productVariants[j].status = productstatus;
                }
              }
            }
          }
        }
        else{
          this.dataSource.data[index].status = productstatus;
        }
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
      }
    }, err => {
        if(varintProductId || variantIndex == 0)
        {
          for (let i = 0; i < this.dataSource.data.length; i++) 
          {
            if (this.dataSource.data[i].isVariantGroup == true) 
            {
              for (let j = 0; j < this.dataSource.data[i].productVariants.length; j++) 
              {
                if (this.dataSource.data[i].productVariants[j].id == id)
                {
                  this.dataSource.data[i].productVariants[j].status = productStatusID;
                  toggleElement.source.checked = !toggleElement.source.checked;
                  this.table.renderRows();
                  return false;
                }
              }
            }
          }  
        }
        else{
          this.dataSource.data[index]['status'] = productStatusID;
        }
      
      toggleElement.source.checked = !toggleElement.source.checked;
      this.table.renderRows();
    })
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.checkParentStatus == false) {
      this.prList = {};
    } else {
      this.dataSource.data.forEach(row => {
        let productItem = row as any;
        this.prList[productItem.id] = true;
        if (productItem.productVariants) {
          productItem.productVariants.forEach(element => {
            this.prList[element.id] = true;
          });
        }
      });
    }
  }
  // manual implementation using prlist because onchange on All checkbox checked nested checkbox in not checked
  getSelectedRowData() {
    this.count = 0;
    let selectedRow = [];
    this.dataSource.data.forEach(row => {
      let productItem = row as any;
      if (productItem.isVariantGroup === true) {
        productItem.productVariants.forEach(variantProduct => {
          if (this.prList[variantProduct.id] === true) {
            selectedRow.push(variantProduct);
          }
        });
      }
      else if (this.prList[productItem.id] === true) {
        selectedRow.push(row);
      }
    });
    return selectedRow;
  }
  setSelectedCategory(category) {
    this.SerachForm.get("productCategory").setValue(category);
    let categoryId = this.getCatId(category);
    this.SerachForm.get('categoryId').setValue(category && categoryId ? categoryId : '')

  }
  private filterBrands(value) {
    const filterValue = value.toLowerCase();
    return this.category.filter(brand => brand.name.toLowerCase().indexOf(filterValue) === 0);
  }
  getCategories(orgId: number): Observable<any> {
    let queryString = "organizationId=" + orgId;
    return this.categoryService.getAllCategories(queryString).pipe(
        map(res => res.data.data)
      );
  }
  removeSelectedRowsAll() {
    this.activeItemId = [];
    if (this.getSelectedRowData().length == 0) {
      this.CustomMessage.ShowCustomMessage(messages.selectRecord, true);
      return false;
    }
    this.getSelectedRowData().forEach(item => {
      let index: number = this.dataSource.data.findIndex(d => d === item);
      if (item.status != ProductStatusEnum.Draft) {
        this.count++;
        this.prList[item.id] = false;
        this.activeItemId.push(item.id);
      }
    });
    if (this.count !== 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.onlyDraftProductDelete, isError: true, buttonName: 'Ok'}
      });
      return false;
    }
    else if (this.count === 0) {
      this.deleteProducts();
    }
  }

  deleteProducts() {
    let arr = [];
    arr = this.getSelectedRowData();
    for (let i = 0; i < this.activeItemId.length; i++) {
      arr.pop();
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: 1 }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.deleteAllSelectedProducts(arr);
        forkJoin(this.allDeleteObservable).subscribe(results => {
          if (results.length === arr.length) {
            if(result)
            {
              this.getAllProducts();
              this.CustomMessage.ShowCustomMessage(messages.selectedProductsDeleted, true);
            }
          }
          else {
            this.CustomMessage.ShowCustomMessage(messages.notAllProductsDeleted, false);
          }
        });
      }
    })
  }
  deleteAllSelectedProducts(arr) {
    this.allDeleteObservable = [];
    for (let i = 0; i < arr.length;) {
      let requestparameter = arr[i].id + "?organizationId=" + arr[i].organizationId;
      let delProduct$ = this.productListService.removeData(requestparameter);
      this.allDeleteObservable.push(delProduct$);
      i++;
    }
  }
  removeSelectedRows(item) {
    if (item.status != ProductStatusEnum.Draft) {
      // this.CustomMessage.showValidationMessage(`Product '${item.name}' is ${ProductStatusEnum[item.status].toLowerCase()} and cannot be deleted`, false);
      let message = `Product '${item.name}' is ${ProductStatusEnum[item.status].toLowerCase()} and cannot be deleted`;
      this.dialog.open(ConfirmationDialogComponent, {
        data: { message: message, isError: true, buttonName: 'Ok'}
      });
      return false;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok',name: item.name}
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: item.id, name: item.name }
    // });
    if (item.status == ProductStatusEnum.Draft) {
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {

          let id = item.id;
          let organizationId = item.organizationId;
          let requestparameter = id + "?organizationId=" + organizationId;
          let index: number = this.dataSource.data.findIndex(d => d === item);

          this.productListService.removeData(requestparameter).subscribe((res) => {
            // for variants
            if (item.isVariant) {
              let itemId = item.id;
              let parentData = JSON.parse(JSON.stringify(this.dataSource.data));
              parentData.forEach((obj, i) => {
                if (obj['productVariants'].length > 0) {

                  let childObj = obj['productVariants'].find(x => x.id === itemId);
                  let indexing = obj['productVariants'].indexOf(childObj);
                  if (indexing > -1) {

                    parentData[i]['productVariants'].splice(indexing, 1);
                    this.dataSource.data.splice(i, 1);
                    this.dataSource.data = parentData;
                    this.displayTotal = this.getDisplayTotal();
                    this.table.renderRows();
                    this.dataSource = new MatTableDataSource(parentData);
                    this.changeExpand(i);
                    this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
                    if(this.dataSource.data.length == 0)
                    {
                      this.hideDeleteButton = false;
                    }
                  }
                }
              });
            } // end of for variants
            else {
              this.dataSource.data.splice(index, 1);
              this.displayTotal = this.getDisplayTotal();
              this.dataSource = new MatTableDataSource(this.dataSource.data);
              this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
              if(this.dataSource.data.length == 0)
              {
                this.hideDeleteButton = false;
              }
            } // end of else 
          },
            err => {
              this.CustomMessage.ShowCustomMessage(err.error.StatusMessage || "Something went wrong", false);
            })
        }
      });
    }
  }
  onFormSubmit(f: NgForm | FormGroup) {
    if (f.invalid) {
      return false;
    }
    this.show = false;
    this.isFormSubmit = true;
    const searchdata = this.SerachForm.value;

    let requestparameter = this.getSearchParameter();
    
    this.advanceSearch(requestparameter);
  }
  createSearchText() {
    this.searchText = '';
    let searchData = this.SerachForm.value;
    if (searchData.includeSubcategories == false) {
      searchData.includeSubcategories = null;
    }
    if(searchData.ShowOnlyVariationGroups==false){
      searchData.ShowOnlyVariationGroups=null;
    }
    let brandFromId = [];
    brandFromId = this.getBrandsFromIds();
    for (let data in searchData) {
      if (searchData[data] !== null && searchData[data] !== undefined && searchData[data] !== "") {
        let addData = false;
        if (typeof searchData[data] == "object") {
          if (searchData[data] !== null && searchData[data].length > 0) {
            addData = true;
            this.searchText += `${this.autoFieldsFromSearchForm[data]}:`;
          }
          if (this.autoFieldsFromSearchForm[data] == "brands" || this.autoFieldsFromSearchForm[data] == "status") {
            let tempArr = [];
            tempArr = searchData[data];
            tempArr.forEach((value, index) => {
              if (this.autoFieldsFromSearchForm[data] == "brands") {
                this.searchText += `${brandFromId[value]}`;
              } else {
                this.searchText += `${this.statusValues[+value]}`;
              }
              if (index !== tempArr.length - 1)
                this.searchText += ',';
            });
          } else {
            addData = true;
          }
        } else if (typeof searchData[data] === "boolean") {
          addData = true;
        } else {   
          addData = true;
          this.searchText += `${this.autoFieldsFromSearchForm[data]}:${searchData[data]}`;
        }
        if (addData == true) {
          this.searchText += ';';
        }
      }
    }
    this.searchText = this.searchText.replace(/(^;)|(;$)/g, "");
  }
  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  getBrandsFromIds() {
    let BrandNames = [];
    let searchData = this.SerachForm.value;
    let brandIds = searchData.productBrand;
    let brandOnId = [];
    if (brandIds !== null) {
      brandIds.forEach(brandId => {
        brandOnId[brandId] = '-1';
      });
      this.productbrandinfo.forEach(brand => {
        if (brandOnId[brand.id]) {
          brandOnId[brand.id] = brand.name;
        }
      });
    }
    return brandOnId;
  }
  checkBoxListAll() {
    this.dataSource = null;
  }
  changeIcon(index) {
    this.selectedIndex != index;
  }
  onenterapplyFilter(event) {
    this.hideButton = true;
    if (this.variantId) {
      var requestparameter = "?organizationId=" + this.orgId + "&VariantTypeId=" + event;
      this.productListService.getData(requestparameter).subscribe(res => {
        this.token = res.data["paging"].continuationToken;
        if (res.data["paging"].continuationToken) {
          this.hideButton = false;
        }
        if (res.data["data"] == null || res.data["data"] == "") {
          this.setDefaultNoItemsFound();
          this.dataSource.data = null;
          this.displayTotal = this.getDisplayTotal();
          this.apiInProgress = false;
          this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
          return false;
        }
        this.dataSource.data = res.data["data"];
        this.hideDeleteButton = true;
        this.apiInProgress = false;
        this.displayTotal = this.getDisplayTotal();
      })
    }
    else {
      var requestparameter = "?organizationId=" + this.orgId + "&fullText=" + event;
      this.productListService.getData(requestparameter).subscribe(res => {
        this.token = res.data["paging"].continuationToken;
        if (res.data["paging"].continuationToken) {
          this.hideButton = false;
        }
        if (res.data["data"] == null || res.data["data"] == "") {
          this.dataSource.data = null;
          this.displayTotal = this.getDisplayTotal();
          this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
          this.hideButton = true;
          this.apiInProgress = false;
          return false;
        }
        this.dataSource.data = res.data["data"];
        this.apiInProgress = false;
        this.displayTotal = this.getDisplayTotal();
      })
    }
  }
  doSplitSerchAndFormSubmit() {
    var requestparameter = "?organizationId=" + this.orgId;
    if(this.injectedCategoryId && !this.injectedSearchText){
      requestparameter += "&CategoryId=" + this.injectedCategoryId;
    }
    if(this.injectedSearchText){
      requestparameter += "&fullText=" + this.commonService.filterSpecialCharacters(this.searchText);
    }
    else{
      requestparameter += "&fullText=" + this.commonService.filterSpecialCharacters(this.searchText);
    }
    
    this.advanceSearch(requestparameter);
  }
  getIdsFromBrand(brands) {
    let tempBrands = [];
    tempBrands = brands;
    let idPerBrand = [];
    let brandIds = [];
    tempBrands.forEach(brandName => {
      brandName = brandName.trim();
      idPerBrand[brandName] = "-1";
    });
    this.productbrandinfo.forEach(brand => {
      if (idPerBrand[brand.name.toLowerCase()] == "-1") {
        brandIds.push(brand.id);
      }
    });
    return brandIds;
  }

  getSearchParameter() {
    var requestparameter = "?organizationId=" + this.orgId;
    this.checkRequestParameters=requestparameter;
    if (this.SerachForm.get('sku').value != null && this.SerachForm.get('sku').value) {
      requestparameter += "&NameOrSKU=" + this.SerachForm.get('sku').value;
    }
    if (this.SerachForm.get('CreateDateRangeStart').value != null && this.SerachForm.get('CreateDateRangeStart').value) {
      if(this.isIsoDate(this.SerachForm.get('CreateDateRangeStart').value)){
        requestparameter += "&CreateDateRangeStart=" + this.SerachForm.get('CreateDateRangeStart').value;
      }else{
        requestparameter += "&CreateDateRangeStart=" + this.SerachForm.get('CreateDateRangeStart').value.toISOString();
      } 
      // requestparameter += "&CreateDateRangeStart=" + this.SerachForm.get('CreateDateRangeStart').value.toISOString();
    }
    if (this.SerachForm.get('CreateDateRangeEnd').value != null && this.SerachForm.get('CreateDateRangeEnd').value) {
      if(this.isIsoDate(this.SerachForm.get('CreateDateRangeEnd').value)){
        requestparameter += "&CreateDateRangeEnd=" + this.SerachForm.get('CreateDateRangeEnd').value;
      }else{
        requestparameter += "&CreateDateRangeEnd=" + this.SerachForm.get('CreateDateRangeEnd').value.toISOString();
      }

      // requestparameter += "&CreateDateRangeEnd=" + this.SerachForm.get('CreateDateRangeEnd').value.toISOString();
    }
    if (this.SerachForm.get('UpdateDateRangeStart').value != null && this.SerachForm.get('UpdateDateRangeStart').value) {
      if(this.isIsoDate(this.SerachForm.get('UpdateDateRangeStart').value)){
        requestparameter += "&UpdateDateRangeStart=" + this.SerachForm.get('UpdateDateRangeStart').value;
      }else{
        requestparameter += "&UpdateDateRangeStart=" + this.SerachForm.get('UpdateDateRangeStart').value.toISOString();
      }

      // requestparameter += "&UpdateDateRangeStart=" + this.SerachForm.get('UpdateDateRangeStart').value.toISOString();
    }
    if (this.SerachForm.get('UpdateDateRangeEnd').value != null && this.SerachForm.get('UpdateDateRangeEnd').value) {
      if(this.isIsoDate(this.SerachForm.get('UpdateDateRangeEnd').value)){
        requestparameter += "&UpdateDateRangeEnd=" + this.SerachForm.get('UpdateDateRangeEnd').value;
      }else{
        requestparameter += "&UpdateDateRangeEnd=" + this.SerachForm.get('UpdateDateRangeEnd').value.toISOString();
      }

      // requestparameter += "&UpdateDateRangeEnd=" + this.SerachForm.get('UpdateDateRangeEnd').value.toISOString();
    }
    if (this.SerachForm.get('minimumSellerAllowedPrice').value != null && this.SerachForm.get('minimumSellerAllowedPrice').value) {
      requestparameter += "&MinimumPrice=" + this.SerachForm.get('minimumSellerAllowedPrice').value.toString();
    }
    if (this.SerachForm.get('maximumSellerAllowedPrice').value != null && this.SerachForm.get('maximumSellerAllowedPrice').value) {
      requestparameter += "&MaximumPrice=" + this.SerachForm.get('maximumSellerAllowedPrice').value.toString();
    }
    if (this.SerachForm.get('createdByName').value != null && this.SerachForm.get('createdByName').value) {
      requestparameter += "&CreatedBy=" + this.SerachForm.get('createdByName').value.toString();
    }
    if (this.SerachForm.get('updatedByName').value != null && this.SerachForm.get('updatedByName').value) {
      requestparameter += "&UpdatedBy=" + this.SerachForm.get('updatedByName').value.toString();
    }
    if (this.SerachForm.get('includeSubcategories').value == true) {
      requestparameter += "&includeSubcategories=" + this.SerachForm.get('includeSubcategories').value.toString();
    }
    if (this.SerachForm.get('ShowOnlyVariationGroups').value == true) {
      requestparameter += "&ShowOnlyVariationGroups=" + this.SerachForm.get('ShowOnlyVariationGroups').value.toString();
    }
    if (this.SerachForm.get('approvalStatus').value) {
      requestparameter += "&ApprovalStatus=" + this.SerachForm.get('approvalStatus').value.toString();
    }

    var brandIdFound = false; // TODO:001 picking value from input // Temp Change
    if (this.SerachForm.get('productBrand').value != null && this.SerachForm.get('productBrand').value != "" &&
      this.SerachForm.get('productBrand').value != "") {
      requestparameter += "&brandIds=" + this.SerachForm.get('productBrand').value.toString().replace(/,/g, '&brandIds=');
      brandIdFound = true;
    }

    if (brandIdFound == false) // TODO:001 then pick the value from Query string // temp Change
    {
      if (this.brandId) {
        requestparameter += "&brandIds=" + this.brandId;
      }
    }
    if (this.SerachForm.get('productStatus').value != null) {
      if(this.SerachForm.get('productStatus').value.length){
        requestparameter += "&Statuses=" + this.SerachForm.get('productStatus').value.toString().replace(/,/g, '&Statuses=');
      }
    }
    let category = this.SerachForm.get("productCategory").value;
    if (category) {
      let categoryId = this.SerachForm.get('categoryId').value;
      if (categoryId) {
        requestparameter +=  "&CategoryId=" + categoryId;
      }
    }
    if (this.SerachForm.get('keyword').value != null && this.SerachForm.get('keyword').value) {
      requestparameter += "&fullText=" + this.commonService.filterSpecialCharacters(this.SerachForm.get('keyword').value.toString());
    }
    if(this.searchText)
    {
      requestparameter += "&fullText=" + this.commonService.filterSpecialCharacters(this.searchText);
    }
    return requestparameter;
  }

  advanceSearch(requestparameter) {
    
    this.setSearchNotFoundVariables();
    if(requestparameter===this.checkRequestParameters||requestparameter===this.checkRequestParameters+"&fullText="){
      this.setDefaultNoItemsFound();
    }
    this.productListService.getData(requestparameter).subscribe(res => {
      if (res.data["data"] == null || res.data["data"] == "") {
        this.hideDeleteButton = false;
        this.dataSource.data = [];
        this.displayTotal = this.getDisplayTotal();
        this.apiInProgress = false;
        this.CustomMessage.ShowCustomMessage(messages.noProductFound, true);   
        this.hideButton = true;      
        return false;
      }
      this.hideButton = true;
      this.token = res.data["paging"].continuationToken;
      if (res.data["paging"].continuationToken) {
        this.hideButton = false;
      }
      this.dataSource.data = res.data["data"]; 
      this.displayTotal = this.getDisplayTotal();   
      this.newArr = res.data.data;
      this.hideDeleteButton = true;
      this.apiInProgress = false;
      this.setQueryString();
    });
  }
  toggle() {
    this.show = !this.show;
  }
  resetForm() {
    this.isFormSubmit = false;
    this.searchText = '';
    this.SerachForm.reset();
    this.setDefaultNoItemsFound();
    // this.router.navigateByUrl('admin/products');
    this.queryString = {};
    this.router.navigate( [RouterLinksEnum.ADMIN],  { queryParams: { query: decodeURI(JSON.stringify(this.queryString)) } })

  }
  clearFilter() {
    this.resetForm();
    return false;
  }
  public getAllproductbrand() {
    let requestParameter = "?pageSize=-1";
    this.productbrandService.getproductBrandsActive(requestParameter).subscribe(res => {
      this.productbrandinfo = res;
      this.sortBrandData();
    })
  }

  sortBrandData(){
    this.productbrandinfo.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
  public getproductbrandById() {
    this.selectedBrands.push(this.brandId);
    let requestparameter = this.getSearchParameter();
    this.advanceSearch(requestparameter);
  }
  public getProductStatus() {
    this.productstatusService.getproductStatus().subscribe(data => {
      this.productstatusinfo = data.data;

    })
  }
  public getAllProducts() {
    this.apiInProgress = true;
    this.setSearchValues();
    if (this.categoryId) {
      this.searchCategoryById(this.categoryId);
    }
    else if (this.variantId) {
      this.serachVariantById(this.variantId);
    }
    else if (this.brandId) {
      this.getproductbrandById();
    }
    else {
      let requestparameter = this.getSearchParameter();
      if(this.queryParamStatuses && this.queryParamStatuses!="0"){ 
        requestparameter+="&statuses="+this.queryParamStatuses
      }
      this.productListService.getData(requestparameter)
        .subscribe(res => {
          this.apiInProgress = false;
          this.dataSource.data = res.data["data"];
          
          this.displayTotal = this.getDisplayTotal()
          if(this.dataSource.data.length == 0)
          {
            this.hideDeleteButton = false;
          }
          else{
            this.hideDeleteButton = true;
          }
          this.token = res.data["paging"].continuationToken;
          this.hideButton = true;
          if (res.data["paging"].continuationToken) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }
          for (let i = 0; i < this.dataSource.data.length; i++)
            this.isExampanded[i] = false;
          this.getallproductInfo = res.data;
          this.getallproductInfo['productStatus'] = []
          this.getallproductInfo['productStatus'].push(this.queryParamStatuses)
          this.newArr = res.data.data;
          this.setSearchValues();
        })
    }
  }
  getCatId(categoryName: string) {
    for (let i = 0; i < this.category.length; i++) {
      if (this.category[i].fullHierarchicalName.toLowerCase() === categoryName.toLowerCase()) {
        this.CatId = this.category[i].id;
        break;
      }
    }
    return this.CatId;
  }
  public redirectToUpdate = (id, name) => {
    this.router.navigate([ RouterLinksEnum.ADMIN_SLASH + id +RouterLinksEnum.ADMIN_EDIT], { state: { productName: name } });
  }
  public redirectToDelete = (id) => {
    var requestparameter = "?organizationId=" + this.orgId + "&" + id;
    this.productListService.removeData(requestparameter).subscribe(res => {
      this.dataSource.data = res.data["data"];
      this.displayTotal = this.getDisplayTotal();
    })
  }
  public redirectToCopy = (id) => {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN+RouterLinksEnum.ADMIN_NEW, { state: { copyId: id } });
  }
  getCommaSeparated(arrayObjects: string[]): string {
    return Array.prototype.map.call(arrayObjects, s => s).toString();
  }
  redirecttoReadOnly(id, name) {
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.MANAGE_PRODUCTS + id], { state: { productName: name } });
  }
  changeExpand(i) {
    let expnadInfo = this.isExampanded[i];
    for (let j = 0; j < this.isExampanded.length; j++) {
      this.isExampanded[j] = false;
    }
    this.isExampanded[i] = !expnadInfo;
  }
  searchCategoryById(id) {
    let requestParameter = id + "?organizationId=" + this.orgId
    this.categoryService.getCategoriesById(requestParameter).subscribe(res => {
      if (res) {
        this.apiInProgress = false;
        this.myControl.setValue(res.data.name);
        let requestparameter =  this.getSearchParameter();
        this.advanceSearch(requestparameter);
      } else{
        this.apiInProgress = false;
      }
    })
  }
  serachVariantById(id) {
    this.onenterapplyFilter(id);
  }
  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }
  callApiAgain(token) {
    if (token !== null || token !== undefined) {
      let requestParameter = this.getSearchParameter();
      if(requestParameter)
      {
        requestParameter += "&continuationToken=" + encodeURIComponent(token)
      }
      else
      {
        requestParameter = "?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token);
      }
      this.productListService.getData(requestParameter)
        .subscribe(res => {
          if (res.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              this.newArr.push(res.data.data[i]);
            }
            this.token = res.data["paging"].continuationToken;
            this.hideButton = true;
            if (res.data["paging"].continuationToken) {
              this.hideButton = false;
            }
            this.dataSource.data = this.newArr;
            this.displayTotal = this.getDisplayTotal();
          }
        });
    }
  }
  openDialogBox(importFrom) {
    const dialogPlugin = this.dialog.open(ImportDialogComponent, {
      data: { id: 1 , importFrom:importFrom}
    });
    dialogPlugin.afterClosed().subscribe(result => {
      if (result === 1) {
       
      }
    });
}
getWrapedText(text,maxLength)
{
  if(text.length <= maxLength)
  {
    return text;
  }
  else
  {
    return text.substring(0,maxLength-3) + "...";
  }
}
openHistory(id,name)
{
  this.historyDialogService.openHistoryDialog(PIMEntityType.Product,id,name);  
}
DownloadProfilePic(url,title,extension) { ;
  this.httpHelper.downloadData(url).subscribe(res=>{
     var downloadURL = window.URL.createObjectURL(res);
     var link = document.createElement('a');
     link.href = downloadURL;
     link.download = title+"."+extension;
     link.click();})
 } 
 openChat(id,name)
 {
   this.chatDialogService.openChatDialog(PIMEntityType.Product,id,name);  
 }

 openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }
  export()
  {
    let requestParameter = this.getSearchParameter()
    this.downloadProduct(requestParameter);
  }
  downloadProduct(requestparameter)
  {
    this.productService.getProductReport(requestparameter).subscribe(res => {
      this.blob = new Blob([res], {type: res.type});
      var downloadURL = window.URL.createObjectURL(res);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "ProductReport.xlsx";
      link.click();
    },error => {
      alert("Something went wrong");
    });
  }
  addProductsFromCatalogue(){
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.MANAGE_PRODUCTS + RouterLinksEnum.CATALOUGE)
  }
  getAdminDomin() {
    if (sessionStorage.getItem("SessionStorge_UserModel") !== null) {
      let userInfo = JSON.parse(sessionStorage.getItem('SessionStorge_UserModel'));
      if (userInfo.email) {
        let splitInfo = userInfo.email.split("@");
        if (environment.admin_domain.indexOf(splitInfo[1]) > -1) {
          this.showkinatorImportIcon = true; // this icon will be used for import products from kinator
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptionRef.unsubscribe();
  }
  getDisplayTotal() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  checkToggleDisabled(status){
    return status == ProductStatusEnum.Discontinued ?  true :  false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentDevice.emit(this.deviceDetect.currentDevice());
  }
  redirectToAddProduct(){
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS_NO_SLASH+RouterLinksEnum.ADMIN_NEW)
  }

  setQueryFreeText(freeText){
    this.queryString = {};
      
    this.SerachForm.reset();
    this.SerachForm.get('freeText').setValue(freeText);
    let stickySearch = "&" + 'freeText';
    // this.queryString = this.stickySearchDirective.first.setRouteParamenter(stickySearch,this.customersSearchForm);
    this.queryString = { freeText : freeText}
    this.router.navigate( [RouterLinksEnum.ADMIN],  { queryParams: { query: decodeURI(JSON.stringify(this.queryString)) } }  );
  }


  setQueryString(){
    
    if(this.searchText){
      this.SerachForm.get('freeText').setValue(this.searchText);
    }

    let formControlKeys = Object.keys(this.SerachForm.value)

    for(let i=0;i<formControlKeys.length;i++)
    {
      if(formControlKeys[i] == 'productBrand' && this.SerachForm.get(formControlKeys[i]).value && this.SerachForm.get(formControlKeys[i]).value.length == 0){
        continue;
      }
      if(formControlKeys[i] == '"productCategory"' && this.SerachForm.get(formControlKeys[i]).value && this.SerachForm.get(formControlKeys[i]).value.length == 0){
        continue;
      }
      if(this.SerachForm.get(formControlKeys[i]).value || (JSON.stringify(this.queryString) && JSON.stringify(this.queryString).includes(formControlKeys[i])))
      {
        let stickySearch = "&"+formControlKeys[i]
        this.queryString = this.stickySearchDirective && this.stickySearchDirective.setRouteParamenter(stickySearch,this.SerachForm);
        this.queryString && Object.keys && Object.keys.length && Object.keys(this.queryString).forEach(key => {
          if (!this.queryString[key]) {
            delete this.queryString[key];
          }
        });

        // if(Object.keys(this.queryString).length > 1){
        //   delete this.queryString['freeText']
        // }

      }  
    }

    this.router.navigate( [RouterLinksEnum.ADMIN],  { queryParams: { query: decodeURI(JSON.stringify(this.queryString)) } })

  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
  }

  setSearchValues(){
    this.stickySearchDirective && this.stickySearchDirective.getRouteParamenter(this.SerachForm);let formControlKeys = Object.keys(this.SerachForm.value)
    for(let i=0;i<formControlKeys.length;i++){
      if(this.SerachForm.get(formControlKeys[i]).value)
      {
        if(this.SerachForm.get('freeText').value){
          this.searchText = this.SerachForm.get('freeText').value
        }
        
        // this.onFormSubmit(this.SerachForm);
        break;
      }  
    }
  }
  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle="Products";
    this.noItemsFoundDesc=messages.noResultFoundDesc;
  }
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle="Products";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }
}

