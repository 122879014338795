import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants/global';
import { HttpClient } from '@angular/common/http';
import { BaseApplicationModel } from 'src/app/shared/models/authorization/application-model';
import { AuthService } from '../Authorization/auth.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  //private _getStatusesUrl = 'Product/statuses';
  private _getAuthActivitiesUrl = 'auth-activities';
  private _getAllAplication = 'applications';
  private applicationApiResponse$: BehaviorSubject<[]> = new BehaviorSubject([]);

  //-----------------Refactored Urls - 6/18/2021 --------------------------
  private _getStatusesUrl = 'Products/statuses';
  newOrderPlacedId$ = new Subject<any>();
  stopOrderBell$ = new Subject<any>();
  constructor(
    private httpHelper: HttpHelperService,private authService: AuthService) {

  }

  getStatuses(queryString = ""): Observable<any> {

    return this.httpHelper.getData(this._getStatusesUrl + "?" + queryString)
      .pipe(
        take(1),
        map(res => res.data)
      );

  }

  getAuthActivities(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getAuthActivitiesUrl + "?" + queryString);
  }

  getApplication() : Observable<any>{
    return this.httpHelper.getData(this._getAllAplication);
  }

  getOrganizationData(userName) : any{
    let organizationData = localStorage.getItem(AppConstants.ORGANIZATION_INFO);
    if(organizationData){
      let jsonOrgData = JSON.parse(decodeURIComponent(organizationData));
      if(jsonOrgData.userName)
      {
        // if(userName.toLowerCase() == jsonOrgData.userName.toLowerCase()){
        if(userName == jsonOrgData.userName.toLowerCase()){
          return jsonOrgData;
        }else{
          localStorage.removeItem(AppConstants.ORGANIZATION_INFO);
        }
      }
      else
      {
        return organizationData;
      }
    }
    return null;
  }
  getAllWarehouse(queryString = ""): Observable<any> {
    let _urlWarehouse = "Warehouse";
    return this.httpHelper.getData(_urlWarehouse + "?" + queryString) as Observable<any>;
  }
  generateInvoice(data) : Observable<any>
  {
    let url="generate-invoice"
    return this.httpHelper.putInvoiceData(data,url);
  }

  removeLocalStorageInOutItems(){
    var arr = [];
    for (var i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).substring(0,6).toLowerCase() == 'inout_') {
            arr.push(localStorage.key(i));
        }
    }
    for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
    }
  }
  getOrganizationFromUrl(quertString = '') : Observable<any>
  {
      let url = "organizationsFromUrl";
      return this.httpHelper.orgFromUrlData(url + quertString)
  }

  getOrganizationById() : Observable<any>{
    let url = "Public/organization";
    return this.httpHelper.orgFromId(url);
  }
  
  getUserOrganizationApplications(quertString = '') : Observable<BaseApplicationModel>
  {
      let url = "userOrganizationApplications";
      return this.httpHelper.orgFromUrlData(url + quertString)
  }
  deleteOrganization()
  {
     let url="deleteOrganization";
     return this.httpHelper.delete(url);
  }
  getOrganizationsList(): Promise<any> {
    let url="organizations";
    return this.httpHelper.getData(url).toPromise();
  }

     // send to api
     orderQuantity(unitWeightInGrams,presentationQuantity){
      let OrderQuantity = unitWeightInGrams/1000 * presentationQuantity;
      return OrderQuantity; 
    }
  
    // show in UI (ORDERS)
    presentationQuantity(orderQuantity,unitWeightInGrams){
      let presentationQuantity = orderQuantity * 1000 / unitWeightInGrams;
      return presentationQuantity;
    }
  
    presentationSellingPrice(sellingPrice,unitWeightInGrams){
       let presentationSellingPrice = sellingPrice * unitWeightInGrams / 1000;
       return presentationSellingPrice;
    }

    sendApplicationsApiRes(res) {
      this.applicationApiResponse$.next(res);
    }
  
    get applicationsApiRes() {
      return this.applicationApiResponse$.asObservable();
    }

    filterSpecialCharacters(text: string){
        return text && text.replace(/[^a-zA-Z0-9 ]/g, ' ');
    }

    getWrapedText(text, maxLength) {
      if (text) {
        if (text.length <= maxLength) {
          return text;
        }
        else {
          return text.substring(0, maxLength - 3) + "...";
        }
      }
    }

    private getAdminAccessInfo(queryString = ''): Observable<any> {
      let url = "admin/canaccess";
      return this.httpHelper.getCommonData(url + queryString)
    }
  
    async canAccessAdmin(): Promise<boolean> {
      // if (!this.authService.isLoggedIn())
      //   return Promise.resolve(false);
    
      let result = await this.getAdminAccessInfo().toPromise();
      localStorage.setItem(AppConstants.CAN_ACCESS_ADMIN, JSON.stringify(result.data));
      return Promise.resolve(result.data);  
    }

    sideBarOpenCloseState(element){
    if (element && element.elementRef && element.elementRef.nativeElement.classList.contains('mdc-drawer--open')) {
      element.elementRef.nativeElement.classList.remove('mdc-drawer--open');
    } else {
      element && element.elementRef && element.elementRef.nativeElement.classList.contains('mdc-drawer--closing') && element.elementRef.nativeElement.classList.remove('mdc-drawer--closing');
      element && element.elementRef && element.elementRef.nativeElement.classList.add('mdc-drawer--open');
    }
  }
  
    Guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

  //subject used to update orderlist page on receiving new order placed notification from signalR

  sendNewOrderId(id) {
    this.newOrderPlacedId$.next(id);
  }

  get newOrderPlacedId() {
    return this.newOrderPlacedId$.asObservable();
  }

  //subject used to stop  playing bell on acceptance/rejection of an order

  stopPlayingOrderBell(){
    this.stopOrderBell$.next();
  }

  get orderBellPlayStatus() {
    return this.stopOrderBell$.asObservable();
  }
  getEstoreOrg(queryString = ""): Observable<any> {
    let url = "Public/organization/search"
    return this.httpHelper.getEstoreOrg(url  + queryString);
  }
  getUersStoreList(queryString = ""){
    let  url = "organization/userOrganizationApplications";
    return this.httpHelper.getEstoreOrg(url  + queryString);
  }
}
