import { Injectable } from '@angular/core';
import { CategoryModel } from '../models/Category/category-model.model';

@Injectable({
  providedIn: 'root'
})
export class SearchPreserveService {
  searchText: string;
  data: any;

  constructor() { }

  storeSearchData(text:string,tableData:CategoryModel[]){
    this.clearData();
    this.searchText=text;
    this.data=tableData;
  }

  getSearchText(){
    return this.searchText;
  }

  getTableData(){

    return this.data;
  }

  clearData(){
    this.searchText=null;
    this.data=null;
  }
}
