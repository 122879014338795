import { Injectable, OnInit } from '@angular/core';
import { HttpHelperService } from './Common/http-helper.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './Authorization/auth.service';
import { promise } from 'protractor';
import { map } from 'rxjs/operators';
import { ApplicationModel } from '../shared/models/authorization/application-model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  _url = "Authorization/applications";
  _applications: ApplicationModel[];

  constructor(private _authService: AuthService, private _httpHelper: HttpHelperService) {

  }

  getApplications(): Promise<any> {
    return this._httpHelper.getData(this._url).toPromise().then(data => {
      this._applications = data.data;
      return data.data;
    });
  }

  getApplicationsObservable(): Observable<ApplicationModel[]> {

    return this._httpHelper.getData(this._url)
      .pipe(
        map(res => Object.values(res['data']))
      );

  }
}
