import { Injectable } from '@angular/core';

import { SessionStorageService } from './Common/session-storage.service';
import { Router } from '@angular/router';
import { AuthService } from './Authorization/auth.service';
import { UrlSessionStorageService } from './url-session-storage.service';
import { AppConstants } from '../constants/global';
import { UserStatusEnum } from '../shared/enum/user-status';
import { UserModel } from '../shared/models/authorization/user-model';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardsService {
  UserMOdel: UserModel;
  returnUrl: any;
  hasAuthError:boolean = false;
  hasRegisterError:boolean = false;
  hasCookieError:boolean=false;
  hasUrlError:boolean=false;

  constructor(private _sessionStorageService: SessionStorageService, private urlStorage: UrlSessionStorageService, private router: Router, private authService: AuthService) { }

  async checkIfRegisterd(): Promise<boolean> {
    if(this.CheckIfErrors()){
      return true;
    }
    else{
    const res = await this._sessionStorageService.getloggedinUserinfo();
    this.UserMOdel = res;
    if (this.UserMOdel.statusId == UserStatusEnum.New) {
      this.hasRegisterError=true;
      return false;
    }
    else if (this.UserMOdel.statusId == UserStatusEnum.Active) {
      return true;
    }
    // return false;
  }
  }

  checkIfAuthenticated(): boolean {
    //this function should be called before checing first guard condition
    this.ClearAllErrors();
   if(this.CheckIfErrors()){
     return true;
   }
   else{
    if (this.authService.isLoggedIn()) {
      return true;
    }
    else {
      this.hasAuthError=true;
      return false;
    }
  }
  }

  checkOrgCookieExists(): boolean {
    let organizationData = [];
    let jsonOrgData = localStorage.getItem(AppConstants.ORGANIZATION_INFO);
    if(this.CheckIfErrors()){
      return true;
    }
    else{
    if(!jsonOrgData){
      this.hasCookieError=true;
      return false;
    }
    jsonOrgData = decodeURIComponent(jsonOrgData);
    organizationData.push(JSON.parse(jsonOrgData));
    if (organizationData.length > 0) {
      return true;
    }
    else {
      this.hasCookieError=true;
      return false;
    }}
  }

  checkIfUrlExist(): boolean {
    if(this.CheckIfErrors()){
      return true;
    }
    else{
    let returnUrl = this.urlStorage.getUrlFromSession();
    if (returnUrl!==null) {
      return true;
    }
    else {
      this.hasUrlError=true;
      return false;
    }
  }
  }

  CheckIfErrors(){
    return this.hasAuthError || this.hasRegisterError || this.hasCookieError||this.hasUrlError;
  }

    //this function should be called before checing first guard condition
  ClearAllErrors(){
    this.hasAuthError = false;
    this.hasRegisterError = false;
    this.hasCookieError = false;
    this.hasUrlError = false;
  }
}
