import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ORGANIZATION_INFO } from '../../shared/constants/global';
import { DataService } from '../../services/data.service';
import { AssetService } from '../../services/Assets/asset.service';
import { AssetCategoryService } from '../../services/asset-category.service';
import { RouterLinksEnum } from '../../shared/router-links/router-links.enum';
import { CustomMessageService } from 'src/app/core/custommessageservice';


@Component({
  selector: 'app-add-folder.dialog',
  templateUrl: '../../dialogs/add-folder/add-folder.dialog.html',
  styleUrls: ['../../dialogs/add-folder/add-folder.dialog.css']
})
export class AddFolderDialogComponent {
  searchText
  public assetCategory = new MatTableDataSource();
  public assetList = new MatTableDataSource();

  assetListColumns = ['icon','name','updatedOn', 'updatedBy','size' ];
  assetCategoryColumn = ['icon','category','updatedOn', 'updatedBy','size' ]
  //public dataSource = new MatTableDataSource<Comment>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
 
  folder = new FormControl();
  orgId: any;
  buttonName: string;
  title: string;

  constructor(public dialogRef: MatDialogRef<AddFolderDialogComponent>,private router:Router,  private _assetCategoryService: AssetCategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService, private assetService: AssetService, private CustomMessage: CustomMessageService) {
      let organizationData = [];
      let jsonOrgData = localStorage.getItem(ORGANIZATION_INFO);
      if (jsonOrgData) {
        jsonOrgData = decodeURIComponent(jsonOrgData);
        organizationData.push(JSON.parse(jsonOrgData));
        this.orgId = organizationData[0].id;
      }
      if(this.data.folderName){
        this.folder.setValue(this.data.folderName)
      }
      if(this.data.action==='add'){
        this.buttonName="Save";
        this.title="New Folder"
      }
      else{
        this.buttonName="Update"
        this.title="Rename Folder";
      }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

add(){
  if (this.data.action === "add") {
    this.addFolder();
  }
  else if (this.data.action === "update") {
    this.updateCategory();
  }
}

  addFolder() {
    let pId=""
    if(this.data.pid){
      pId=this.data.pid
    }
    let name=this.folder.value;
    let obj={
      name:name,
      parentCategoryId:pId,
      _Paxcom_OrganizationId: this.orgId,
    }
    this._assetCategoryService.postCategory(obj).subscribe(res => {
      if (res) {
        // let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(this.getWrapedText(res.statusMessage,30,'res'), true);
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>{
          if(this.data.url){
          this.router.navigate([this.data.url])
          }
          else{
            this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET_LIST])
          }
        })
        this.closeDialog();
      }
    });
  }

  getWrapedText(text, maxLength,type) {
    if(type=='res'){
    let folderName = text.slice(text.indexOf("Folder") + 7, text.indexOf("has been"));
    if (folderName.length < 30) {
      return text
    } else {
      return `Folder ${folderName.substring(0, maxLength - 3) + '...'} has been added successfully.`
    }
  } else {
    if ( text && text.length <= maxLength) {
      return text;
    }
    else {
      return  text && text.substring(0, maxLength - 3) + "...";
    }
  }
  }
  updateCategory() {
    let pid="";
    let name=this.folder.value;
    if(this.data.pid){
      pid=this.data.pid;
    }
    let obj={
      name:name,
      id:this.data.folderId,
      parentCategoryId:pid,
      _Paxcom_OrganizationId: this.orgId,
    }    
    this._assetCategoryService.putCategory(obj).subscribe(res => {
      if (res) {
        this.CustomMessage.ShowCustomMessage(this.getWrapedText(res.statusMessage,30,'res'), true);
        this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() =>{
        if(this.data.url){
          this.router.navigate([this.data.url])
          }
          else{
            this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST])
          }
      }) 
      this.closeDialog();      
    }});
  }  
  closeDialog():void{
    this.dialogRef.close();
  }

  
}
