import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(inputDate: any, _format = 'yyyy-MM-dd', _delimiter = '-'): any {

    if (inputDate) {
      var inputDate = inputDate.split('T');
      if (inputDate) {
        var _date = inputDate[0];
        return _date;
      }
    }
    return null;

  }

}
