import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipeService } from './currency-pipe-service';

@Pipe({
  name: 'currencySymbol'
})
export class currencySymbolPipe implements PipeTransform {
 constructor(private currencyService:CurrencyPipeService){}
  transform(
    value:number,
    code?:string
  ): string {
    let currencyValue = this.currencyService.fetchCurrencySymbol(value,'narrow',code);
    return currencyValue;
  }
 
}