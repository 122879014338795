import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete/delete.dialog.component';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  selectedImage;
  swatChImagesData: any = [];
  noSwatchImage: boolean = false;
  assetSwatchImages: any = [];
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  if(this.data.length)
  {
    var flags = []
    let distinctAsset = []
    for(let i=0;i<this.data.length;i++)
    {
      if( flags[this.data[i].fileDetails.mediumThumbnailUrl]) continue;
      flags[this.data[i].fileDetails.mediumThumbnailUrl] = true;
      distinctAsset.push(this.data[i]);
    }
    for(let j=0;j<distinctAsset.length;j++)
    {
      if(distinctAsset[j].isSwatchOnlyImage)
      {
        this.assetSwatchImages.push(distinctAsset[j]);
      }
    }
    for(let j=0;j<distinctAsset.length;j++)
    {
      if(distinctAsset[j].isSwatchOnlyImage)
      {
        this.noSwatchImage == false;
        return
      }
      else
      {
        this.noSwatchImage == true;
      }
    }
  }
  else
  {
    this.noSwatchImage = true;
  }
  }

  selectImage(image) {
    this.selectedImage = image;
  }
  onSubmit(){
    if(this.selectedImage)
    {
      this.dialogRef.close(this.selectedImage);
    }
    else
    {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { id: 1,selectImageValidationTrue:true }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
        }
      })
      
    }
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
