export const environment = {
  production: false,
  apiBaseUrl: 'https://devocapigateway.azurewebsites.net/',
  identityauthorityUrl: 'https://devocidentityapi.azurewebsites.net',
  organisationUrl:'https://devocorgweb.z29.web.core.windows.net/redirect',
  // apiBaseUrl: 'http://localhost:5109/',
  // identityauthorityUrl: 'http://host.docker.internal:5105',
  //organisationUrl: 'http://org.app.localhost:5104/redirect', //'http://org.app.localhost:5104?autoLogin=true',
  SMALL_WIDTH_BREAKPOINT: 1240,
  APP_TITLE: "Orders",
  httpsOnlyAccess: false,
  authStorage: localStorage,
  CreateProductURL: 'http://pim.app.localhost:5204/InOut/',
  shopOrganizationInfo : 'InOut_eStore',
  applicationsToDisplayInHeader: [2,3,5,8],
  WMSAppToDisplayInHeader: [5], // warehouse application id
  organizationUrlIdentifier : 'organizations/api/v1/',
  ordersUrlIdentifier:'orders/api/v1/',
  shopUrlIdentifier : 'eStore/api/v1/',
  //  omnichannelHostname: 'http://localhost:1200',
  omnichannelHostname: 'https://onpaxcom.ai',
  pimUrlIdentifier: 'Products/api/v1/',
  admin_domain : ["paxcom.net","paymentus.com","paxcel.net"],
  applicationsToDisplayInApplicationsMenuList: [1,4,1002],
  baseOrganizationId:10675,
  cdnImageUrl:'https://devocstorage.blob.core.windows.net/content',
  googleMapApiKey:'AIzaSyDvyp6ykG8cnrzbBEFxIMvS-8Go7_rODHY',
  currencySymbol:'INR',
  assetsBaseUrl:'https://devocstorage.blob.core.windows.net/content/',
  paxcomHostname: 'https://paxcom.ai',
  pimApiBaseUrl :  'products/api/v1/',
  orderApiBaseUrl : 'orders/api/v1/',
  shopApiBaseUrl : 'eStore/api/v1/',
  warehouseApiBaseUrl : 'inventory/api/v1/',
  signalRNotificationUrl:'https://devocnotificationapi.azurewebsites.net/',
  cafeBusinessId:16,
  paxcomContactUsUrl: "https://paxcom.ai/contact-us/",
  paytmGatewayUrl: 'https://securegw-stage.paytm.in',
  showGalptoLogoOnLoad:true,

};