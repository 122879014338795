import {MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {Component, Inject, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, NgForm, FormGroup, Validators, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { HelpGuideService } from 'src/app/core/help-guide/help-guide.service';

@Component({
  selector: 'app-helpGuide.dialog',
  templateUrl: './helpGuide.dialog.html',
  styleUrls: ['./helpGuide.dialog.css']
})
export class HelpGuideComponent implements OnInit {
  historyData: any = [];
  PIMEntityTypeEnum: { id: number; name: string; }[];
  onloadShowHistory:boolean = true;
  onSelectionShowHistory: boolean = false;
  selectedIndex: any;
  showHistory: boolean = false;
  PIMActivityTypeEnum: { id: number; name: string; }[];
  noHistory: boolean = false;
  PIMActivityTypeValue: { id: number; name: string; }[];
  allCommentInfo: any = [];
  commentForm: any;
  isFormSubmit: boolean = false;
  addedAllUser = [];
  allSelectedUser = [];
  allUser: { id: number; name: string; }[];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allUserList: { id: number; name: string; }[];
  userCSVsCtrl = new FormControl();
  // filteredUser: Observable<string[]>;
  filteredUser: any = [];
  selectedUserList: string[] = [];
  allFruits: string[] = [];

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  helpGuideInfo: any;
  innerHtml: any;
  constructor(public dialogRef: MatDialogRef<HelpGuideComponent>,
    private customMessageService: CustomMessageService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,private helpGuideService:HelpGuideService) { 
                dialogRef.disableClose = true;
                
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
      setTimeout(()=>{this.getHelpGuide()},1000);  
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  getHelpGuide()
  {
    this.helpGuideService.getHelpGuide().subscribe(res=>{
      this.helpGuideInfo = res.data.data;
      this.innerHtml = this.helpGuideInfo[0].html;
    })
  }
}
