import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class DeviceDetect {
    constructor() { }
    currentDevice() {
        let screenWidth = window.innerWidth;
        let phoneScreen: boolean;
        let tabletScreen: boolean;
        let desktopScreen: boolean;
        if (screenWidth < 767) {
            phoneScreen = true;
            tabletScreen = false;
            desktopScreen = false;
        } else if (screenWidth >= 768 && screenWidth < 1000) {
            tabletScreen = true;
            phoneScreen = false;
            desktopScreen = false;
        }
        else {
            phoneScreen = false;
            desktopScreen = true;
            tabletScreen = false;
        }

        return {
            phoneScreen: phoneScreen,
            desktopScreen: desktopScreen,
            tabletScreen: tabletScreen,
        }
    }
}
