import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsandconditionsComponent } from './shared/termsAndConditions/termsandconditions.component';
import { PrivacypolicyComponent } from './shared/privacyPolicy/privacypolicy.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { AdminLoginGuardService } from './core/guards/adminLogin-guard.service';
import { RouteConstants } from './constants/RouteConstant';
import { CanAccessAdmin } from './core/guards/can-access-admin';
import { MasterGuard } from './core/guards/mainGuard/masterGuard';
import { NoOrganizationFoundComponent } from './no-organization-found/no-organization-found.component';
import { OrderDetailsComponent } from './OrderDetails/order-details/order-details.component';





const routes: Routes = [
  // {
  //   path: 'admin',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
  //   pathMatch: 'full',
  //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  // },
  
  
  {
    path: 'barcode/:barcodeId/print/:templateSize', canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/warehouse/barcode/barcode.module').then(m => m.BarcodeModule)
  },
  {
    path: "auth-callback",
    loadChildren: './auth-callback/auth-callback.module#AuthCallbackModule'
  },
  {
    path: 'onBoard',
    loadChildren:()=> import('./modules/onboard/onboard.module').then(m =>m.OnboardModule)
  },
  // {
  //   path: 'glapto-shop',
  //   loadChildren:()=> import('./modules/mobile-shop-onboard/mobile-shop-onboard.module').then(m =>m.MobileShopOnboardModule)
  // },
  {
    path: 'glapto',
    loadChildren:()=> import('./modules/glapto/glapto.module').then(m =>m.GlaptoModule)
  },
  {
    path: 'admin/warehouse',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
    loadChildren:()=> import('./modules/warehouse/warehouse.module').then(m =>m.WarehouseModule)
  },
  {
    path: 'admin/orders',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
    loadChildren:()=> import('./modules/order/order.module').then(m =>m.OrderModule)
  },
  {
    path: 'admin/products',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
    loadChildren:()=> import('./modules/pim/pim.module').then(m =>m.PIMModule)
  },
  {
    path: 'admin/e-store',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
    loadChildren:()=> import('./modules/e-store-admin/e-store-admin.module').then(m =>m.EStoreAdminModule)
  },
  {
    path: RouteConstants.Login,canActivate: [AdminLoginGuardService],
    loadChildren:()=> import('./public/public.module').then(m =>m.PublicModule)
  },
  {path : 'admin' , redirectTo : 'admin/warehouse' , pathMatch : 'full',data: {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard]},
  {
    path: "redirect",
    loadChildren: './redirect/redirect.module#RedirectModule'
  },  
  {
    path: "term-and-conditions",
    component:TermsandconditionsComponent
  },
  {
    path: "privacy-policy",
    component:PrivacypolicyComponent
  },
  {
    path:"no-organization-found",
    component:NoOrganizationFoundComponent
  },
  {
    path: 'order/invoice/:id',
    loadChildren:()=> import('./invoice/invoice.module').then(m =>m.InvoiceModule)
  },
  {
    path: 'myorders/:id',
    component: OrderDetailsComponent
  },
  {
    path: '',
    loadChildren:()=> import('./modules/shop/shop.module').then(m =>m.ShopModule)
  },
  
]
@NgModule({
  imports: [RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],providers:[
    MasterGuard
  ]
})
export class AppRoutingModule { }
