import { Injectable, Inject } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AppConstants } from '../../constants/global';
import { BaseCountryModel } from '../../models/Country/countryModel';
import { BaseStateModel } from '../../models/State/stateModel';
import { GetSetSessionStorageService } from './get-set-session-storage.service';
import { UpdateMiniCartService } from './update-cart.service';
import { OrderTotalCalculationService } from './OrderTotalService/order-total-calculation.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private showTopMenu$: Subject<boolean> = new Subject<boolean>();
  private sendThemeInfo$ = new BehaviorSubject<any>('');
  private showHideWishlistWidget$ = new BehaviorSubject<boolean>(true);
  private showHideProductReview$ = new BehaviorSubject<boolean>(true);
  private getUpdatedWishlist$ = new Subject<any>();
  private _getAuthActivitiesUrl = 'auth-activities';
  userName: string;
  private subjectName = new Subject<any>();
  public paymentOrderId: string;
  private deviceSessionInfo$ = new BehaviorSubject<any>('');



  //-----------------Refactored Urls - 6/21/2021 ---------------------------

  constructor(
    private httpHelper: HttpHelperService,
    @Inject(DOCUMENT) private document: Document,
    private getSetSessionStorageService: GetSetSessionStorageService,
    private updateMiniCartService:UpdateMiniCartService,
    private orderTotalCalculationService:OrderTotalCalculationService) {

  }

  getOrderDetails(id){
    let url=`Public/Orders/${id}`;
  return this.httpHelper.getData(url);
  }

  getAuthActivities(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getAuthActivitiesUrl + "?" + queryString);
  }

  getOrgByEStoreUrl(eStoreUrl: string): Observable<any> {
    let Url = "Public/organizationByUrl/" + eStoreUrl;
    return this.httpHelper.getData(Url);
  }
  getOrgInfo(): Observable<any> {
    let orgInfo = this.getSetSessionStorageService.getOrgInfoFromSession();
    if(orgInfo != null)
      return of({data: orgInfo});

    let Url = "Public/organization"
    return this.httpHelper.getData(Url);
  }

  getOrganizationData(userName): any {

    if (sessionStorage.getItem("SessionStorge_UserModel")) {
      let userInfo = JSON.parse(sessionStorage.getItem('SessionStorge_UserModel'));
      this.userName = userInfo.username;
    }
    let organizationData = sessionStorage.getItem(AppConstants.SHOP_SESSION_INFO)



    if (organizationData) {
      let jsonOrgData = JSON.parse(this.decodeURIComponentSafely(organizationData));
      if (userName == this.userName) {
        return jsonOrgData;
      } else {
        localStorage.removeItem(AppConstants.ORGANIZATION_INFO);
      }
    }
    return null;
  }
/**************try-catch block to decode safely. If the string is decodable it will decode, if not, it returns the same string as it is already decoded, %(ampersand) breaks the logic of decodeURIComponent() function,gives an URIError: URI malformed which halts the code execution******************/
  decodeURIComponentSafely(uri) {
    try {
      return decodeURIComponent(uri)
    } catch (e) {
      console.error('URI Component not decodable')
      return uri
    }
  }

  removeLocalStorageInOutItems() {
    var arr = [];
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 6).toLowerCase() == 'inout_') {
        arr.push(localStorage.key(i));
      }
    }
    for (var i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }
  validURL(str) {
    var pattern = new RegExp('^(((ht|f)tp(s?))?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
  loadTheme(cssFile: string) {
    const headEl = this.document.getElementsByTagName('head')[0];
    const newLinkEl = this.document.createElement('link');
    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = cssFile + '.css';
    // newLinkEl.type ='text/html'
    headEl.append(newLinkEl);
  }

  
  loadGoogleTagManager(key: string) {
    //Always add GATags in Header
    const headEl = this.document.getElementsByTagName('head')[0];
    const newGtmScript = this.document.createElement('script');
    newGtmScript.async = true;
    newGtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + key;
    headEl.appendChild(newGtmScript);
  }

  getWidgetConfiguredLayout(queryString:string = '') : Observable<any>{
    let url ="Public/getWidgetConfiguredLayout";
    return this.httpHelper.getData(url + queryString);
  }

  getItemsStockes(queryString:string = '') : Observable<any>{
    let url ="Products/GetItemStocks";
    return this.httpHelper.getData(url + queryString);
  }

  getOrganizationAddress() : Observable<any>
   {
     let url = "organization/address";
     return this.httpHelper.getData(url);
   }

   getSASToken(data) : Observable<any>{
    let url="accessToken/generate"

    return this.httpHelper.postData(data,url);
  }

  getAssetThumbnail(data) : Observable<any>{

    let url="thumbnail/generate"

    // let url="Asset/thumbnail/generate"+queryString
    return this.httpHelper.postData(data,url);
  }

  updateTopMenuState(state: boolean) {
    this.showTopMenu$.next(state);
  }

  get getTopMenuState() {
    return this.showTopMenu$.asObservable();
  }
  getWrapedText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    else {
      return text.substring(0, maxLength - 3) + "...";
    }
  }

  // send to api
  orderQuantity(presentationQuantity:number,unitWeightInGrams:number){
    let OrderQuantity = unitWeightInGrams/1000 * presentationQuantity;
    return OrderQuantity; 
  }

  // show in UI (ORDERS)
  // presentationQuantity(orderQuantity,unitWeightInGrams){
  //   let presentationQuantity = orderQuantity * 1000 / unitWeightInGrams;
  //   return presentationQuantity;
  // }

  presentationSellingPrice(sellingPrice:number,unitWeightInGrams:number){
     let presentationSellingPrice = sellingPrice * unitWeightInGrams / 1000;
     return presentationSellingPrice;
  }

  filterSpecialCharacters(text: string){
    if(text){
      return text.replace(/[^a-zA-Z0-9 ]/g, ' ');
    }
  }

  getCountry(): Observable<BaseCountryModel> {
    let url = "country"
    return this.httpHelper.getData(url) as Observable<BaseCountryModel>;
  }
  getState(id): Observable<BaseStateModel> {
    let url = "state/" + id;
    return this.httpHelper.getData(url) as Observable<BaseStateModel>;
  }

  updateShowHideStatus(state: boolean) {
    this.showHideWishlistWidget$.next(state);
  }

  get widgetShowHideStatus() {
    return this.showHideWishlistWidget$.asObservable();
  }
   
  updateShowHideStatusProductReview(state:boolean){
    this.showHideProductReview$.next(state);
  }
  get showHideStatusProductReview() {
   return this.showHideProductReview$.asObservable();
  }
  sendMyAccountPageTitle(data) { //the component that wants to update something, calls this fn
    this.subjectName.next({ data: data }); //next() will feed the value in Subject
  }

  getMyAccountPageTitle(): Observable<any> { //the receiver component calls this function 
    return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
  private subject = new Subject<any>(); //need to create a subject
  sendProductInfo(data) { //the component that wants to update something, calls this fn
      this.subject.next({ data: data }); //next() will feed the value in Subject
  }

  getProductInfo(): Observable<any> { //the receiver component calls this function 
      return this.subject.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private componentSubject = new Subject<any>(); 
  private cartSubject = new Subject<any>(); 
  private cartItemSubject = new Subject<any>(); 
  public cartInfo = [];
  public themeJson = {};
  public themeInfo = {};
  private themeLoadedSubject = new Subject<any>(); 

  sendComponentInfo(data, themeName: string) { 
      this.componentSubject.next({ data: data, themeName: themeName}); 
  }

  getComponentInfo(): Observable<any> { 
      return this.componentSubject.asObservable(); 
  }

  sendCartInfo(data) { 
    this.cartSubject.next({ data: data}); 
  }

  sendCartItemsInfo(data) {
    this.cartItemSubject.next({ data: data});
  }
  getCartItemsInfo(): Observable<any> {
      return this.cartItemSubject.asObservable();
  }

  getCartInfo(): Observable<any> { 
      return this.cartSubject.asObservable(); 
  }
  setCartInfo(data){
    this.cartInfo = data;
  }

  setComponentJson(data){
    this.themeJson = data;
  }

  setThemeInfo(data) {
    this.themeInfo = data
    this.sendThemeInfo$.next(data);
  }

  get getThemeInfo() {
    return this.sendThemeInfo$.asObservable();
  }

  notifyThemeLoaded(data) { 
    this.themeLoadedSubject.next({ data: data}); 
}

  getThemeLoadedNotification(): Observable<any> { 
      return this.themeLoadedSubject.asObservable(); 
  }

  getUpdatedWishList(){
    this.getUpdatedWishlist$.next();
  }

  get updatedWishlistItems(){
    return this.getUpdatedWishlist$.asObservable();
  }
  checkAlreadyItemsInCart(cartItems,productData){
    if(cartItems.length && productData.length){
      for(let i=0;i<productData.length;i++){
        for(let j=0;j<cartItems.length;j++){
          productData[i]['isItemAlredyInCart'] = cartItems[j].productId == productData[i].id ? true : false;
          productData[i]['presentationQty'] = cartItems[j].presentationQty     
          productData[i]['displayQuantity'] = cartItems[j].presentationQty       
          productData[i]['quantity'] = cartItems[j].quantity       
          if(productData[i]['isItemAlredyInCart'] == true) { break; }
        }
      }
    }
    return productData;
  }
  updateCartInfo(cartInfo) {
    if (cartInfo && cartInfo.orderItems) {
      this.updateMiniCartService.sendUpdate(cartInfo.orderItems);
      this.updateMiniCartService.sendCartSubTotal(this.orderTotalCalculationService.getCartSubTotal(cartInfo));
    } else {
      this.updateMiniCartService.sendUpdate([]);
      this.updateMiniCartService.sendCartSubTotal(0);
    }
  }
  getMaxCartQuantity(maxCartQty){
    let maxAllowedQuanity = 10000000;
    if(!maxCartQty){maxCartQty=maxAllowedQuanity;}
    return maxCartQty
  }

  setPaymentOrderId(orderId: string){
    this.paymentOrderId = orderId;
  }

  getPaymentOrderId()
  {
    return this.paymentOrderId;
  }

  sendOTP(data){
    let url ="Public/otp/send";
    return this.httpHelper.postData(data,url);
  }
  verifyOTP(data){
    let url ="Public/otp/verify";
    return this.httpHelper.postData(data,url);
  }
  placeOrderMenu(data,orderToken){
    let url ="Public/orders/place/"+orderToken;
    return this.httpHelper.postData(data,url);
  }
  getDeviceSession(requestParameter){
    let url ="Public/device-session";
    return this.httpHelper.getData(url+requestParameter);
  }
  


  //need to create a subject
  sendDeviceSessionInfo(data) { //the component that wants to update something, calls this fn
      this.deviceSessionInfo$.next({ data: data }); //next() will feed the value in Subject
  }

  getDeviceSessionInfo(): Observable<any> { //the receiver component calls this function 
      return this.deviceSessionInfo$.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
}
