import { Injectable } from '@angular/core';
import { GlobalUPCTypeEnum } from '../../ENUM/globalUPCTypeEnum';


@Injectable({
  providedIn: 'root'
})
export class EnumHelperService {

  constructor() { }

  getGlobalUPCTypeEnum(){
    return this.enumToArrayObject(GlobalUPCTypeEnum);
  }

  StringIsNumber = (value: any) => isNaN(Number(value)) === false;

  enumToArrayObject(enumData) {
    return Object.keys(enumData)
      .filter(this.StringIsNumber)
      .map(key => ({ id: key, name: enumData[key] }));
  }

}
