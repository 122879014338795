import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;

  constructor() { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
  redirectToPrivacyPolicy()
  {
    let url = "privacy-policy";
    window.open(url,"_blank")
  }
  redirectToTermAndCondition()
  {
    let url = "term-and-conditions";
    window.open(url,"_blank")
  }
  redirectToPaxcomOfficial(){
    let url = environment.paxcomHostname;
    window.open(url,"_blank")
  }
}
