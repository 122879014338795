import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
@Component({
  selector: 'app-channel.dialog',
  templateUrl: './channel.dialog.html',
  styleUrls: ['./channel.dialog.css']
})
export class ChannelComponent implements OnInit {
 assetChannelId : any = "" ;
  channelData: any = [];
  selectedAssetChannelIds : any = [];
  showSwatchImageCheckBox: boolean = true;
  assetSwatchImage: boolean = false;
  swatchHelp = "A swatch image typically shows the key difference between variation products in relation to a product variation attribute. You can define swatches for variation products. For example: For the fabric variation attribute, a highly zoomed detail image of each fabric appears in a row beneath the product's picture."
  constructor(public dialogRef: MatDialogRef<ChannelComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { 
                dialogRef.disableClose = true;
                
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
    if(this.data)
    {
      if(this.data.channelData)
      {
        this.channelData = this.data.channelData
      }
      if(this.data.showSwatchImage)
      {
        this.showSwatchImageCheckBox = true;
      }
      else
      {
        this.showSwatchImageCheckBox = false;
      }
    }
  }
  saveChannel()
  {
    if(this.selectedAssetChannelIds.length)
    {
      let res = 
      {
        selectedAssetChannelIds : this.selectedAssetChannelIds,
        isSwatchOnlyImage : this.assetSwatchImage
      }
      this.dialogRef.close(res);
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  
}
