import { Directive, HostListener, ElementRef, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appReplaceWithName]'
})
export class ReplaceWithNameDirective implements OnInit {
  @Input() stringArray: string[];
  @Output() stringArrayChange = new EventEmitter();
  @HostListener("keydown.enter", ['$event']) onEnter(event: KeyboardEvent) {
    this.rawValue = this.rawValue += '\n• ';
    event.preventDefault();
  }
  @HostListener("change", ['$event']) change(event) {
    this.stringArrayChange.emit(this.rawValue.split("\n• "));
  }

  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void {
    let temp: string = '';
    if(this.stringArray){
    this.stringArray.forEach(item => {
      if (temp)
        temp += "\n";
      temp += '•' + item;
    });
  }

    this.rawValue = temp;
  }

  get rawValue(): string {
    return this.elementRef.nativeElement.value;
  }
  set rawValue(value: string) {
    let inputString = value;
    inputString = inputString.substr(0,1);
    if(inputString=='•'){
      this.elementRef.nativeElement.value = value;
    }
    else{
      this.elementRef.nativeElement.value = '• ' +value;
    }
    
  }

}
