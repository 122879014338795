import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-organization-found',
  templateUrl: './no-organization-found.component.html',
  styleUrls: ['./no-organization-found.component.scss']
})
export class NoOrganizationFoundComponent implements OnInit {
  assetsBaseUrl:string=environment.assetsBaseUrl;
  redirectingUrl:string=environment.omnichannelHostname+"/login";
  constructor() { }

  ngOnInit() {
  }
 
  
}
