import { MdcDrawer, MdcTopAppBar } from '@angular-mdc/web';
import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CurrentApplication } from 'src/app/constants/CurrentApplicationEnum';
import { CurrentApplicationIndexService } from 'src/app/core/Common/current-application-index.service';
import { environment } from 'src/environments/environment';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { DynamicPimThemeLoadService } from '../../../services/Common/dynamic-pim-theme-load.service';
import { CommonService } from 'src/app/core/Common/common.service';
import { MatDialog } from '@angular/material';
import { AddProductDialogComponent } from '../../../dialogs/add-product/add-product.dialog.component';
const SMALL_WIDTH_BREAKPOINT = 1240;
@Component({
  selector: 'app-inout-menu-default',
  templateUrl: './inout-menu-default.component.html',
  styleUrls: ['./inout-menu-default.component.scss']
})
export class InoutMenuDefaultComponent implements OnInit {
  matcher: MediaQueryList;
  PermissionsEnum;

  titlename = "";
  RouterLinksEnum: any;
  disableAddNewProduct: boolean = false;
  addNewProductUrl: string = `${RouterLinksEnum.ADMIN_SLASH}${RouterLinksEnum.MANAGE_PRODUCTS_NEW}`;

  @ViewChild('topAppBar', { static: false }) topAppBar: MdcTopAppBar;
  @ViewChild('appDrawer', { static: false }) appDrawer: MdcDrawer; 

  navigationLinks = [
    { name: 'Dashboard',skipLocationChange:true, route:RouterLinksEnum.ADMIN_SLASH +RouterLinksEnum.PRODUCT_DASHBOARD, icon: 'dashboard', title: 'Dashboard', isButton: true },
    { name: 'Products', route: RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.MANAGE_PRODUCTS_NO_SLASH, icon: 'shopping_cart', title: 'Product list', isButton: true },    
    { name: 'Categories', route: RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_LIST, icon: 'category', title: "Categories", isButton: true },
    { name: 'Channels', route:RouterLinksEnum.ADMIN +RouterLinksEnum.PRODUCT_CHANNELS, icon: 'settings_overscan', isButton: true, title: "Channels" },
    { name: 'Assets', route: RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET, icon: 'web_asset', title: 'Asset Categories', isButton: true },
    { name: 'Brands', route:RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.BRANDS, icon: 'local_offer', title: "Brands", isButton: true },
    { name: 'Variants', route:RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.VARIANTS, icon: 'tune', title: "Variants", isButton: true },
    { name: 'Downloads', route:RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.RESOURCES, icon: 'manage_accounts',title: "Downloads", isButton: true },
    { name: 'Settings', route:RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.PRODUCT_SETTINGS, icon: 'settings',title: "Settings", isButton: true }
   
  ];
  routerUrl: any;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private currentIndexService:CurrentApplicationIndexService,
    private themeService: DynamicPimThemeLoadService,
    private commonService:CommonService,
    public dialog: MatDialog) {
    this.PermissionsEnum = PermissionsEnum;
    // this.themeService.checkDeviceType();
  }
  isScreenSmall(): boolean {
    return this.matcher.matches;
  }
  ngOnInit() {
    this.currentIndexService.sendCurrentIndex(CurrentApplication.Products);
    this.routerUrl = this._router.url;
    this.RouterLinksEnum = RouterLinksEnum;
    this.disableAddNewProduct = this.routerUrl == this.addNewProductUrl;
    if (environment.production) {
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
    }
    this.matcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
      // remove background-focused class --------------
      this._router.events.subscribe(event => {
        
        let mdcList = document.querySelector('mdc-list');
  
        for (let key in mdcList) {
          if (key == 'innerHTML') {
            document.querySelectorAll('.mdc-ripple-upgraded--background-focused').forEach(
              mdcRippleUpgraded => {
                mdcRippleUpgraded.classList.remove('mdc-ripple-upgraded--background-focused');
              }
            );
          }
        }
      });
      // end of remove background-focused class --------------
      
  }


  onDrawerSelect(route?: string) {
    if (route) {
      this.route.data.subscribe(data => { this.titlename = data.titlename; });
    }
    if (this.isScreenSmall()) {
      this.appDrawer.open = false;
    }
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
      this._router.navigate([uri]));
  }

  redirectToNewProduct(){
    if(!this.isDisableAddNewProduct()){
      if(this.isScreenSmall()){
        this._router.navigate([`${RouterLinksEnum.ADMIN_SLASH}${RouterLinksEnum.MANAGE_PRODUCTS_NEW}`]).then(() =>{
          this.appDrawer.open = false;
        });
      }else{
        const dialogRef = this.dialog.open(AddProductDialogComponent,{
          width:'72vw',
          height:'84vh'
        })
      }
    }
  }
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.commonService.sideBarOpenCloseState(this.appDrawer);
  }

  isDisableAddNewProduct(){
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.disableAddNewProduct = event.urlAfterRedirects == this.addNewProductUrl;
      }
    });
    return this.disableAddNewProduct;
  }
}
