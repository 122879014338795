import { environment } from "src/environments/environment";

export const ORGANIZATION_INFO = "InOut_Organization";
export const Account_Detail = "/Account/UpdateUserDetails";
export const MAXLENGTHGUPCVALIDATIONMESSAGE="Maximum length allowed for Global UPC values is 25."
export const MAXVALUEFORPRODUCTLENGTHMESSAGE ="The length of product should be less than or equal to 1220 cms."
export const MAXVALUEFORPRODUCTBREADTMESSAGE ="The width of product should be less than or equal to 2350 cms."
export const MAXVALUEFORPRODUCTHEIGHTMESSAGE ="The height of product should be less than or equal to 1400  cms."
export const MAXVALUEFORPRODUCTWEIGHTMESSAGE ="The weight of product should be less than or equal to 500000 grams."
export const MAXIMUMVALUEFORPACKAGELENGTHMESSAGE ="The length of package should be less than or equal to 1220 cms."
export const MAXIMUMVALUEFORPACKAGEBREADTHMESSAGE ="The width of package should be less than or equal to 2350 cms."
export const MAXIMUMVALUEFORPACKAGEHEIGHTMESSAGE ="The height of package should be less than or equal to 1400  cms."
export const MAXIMUMVALUEFORPACKAGEWEIGHTMESSAGE ="The weight of package should be less than or equal to 500000 grams."
export const INVALIDHSNCODEFORMATMESSAGE="HSN code shouldn't have any commas/spaces."
export const NEWBRANDNOTE="*Note: Any brand added will be public by default and visible to all other users of the Glapto platform.";
export const NEWCATEGORYNOTE="*Note: This category will be created as a root level category. To create hierarchical category ";

export const MAXIMUMALLOWEDPRODUCTWEIGHT=500000;
export const MAXIMUMALLOWEDPRODUCTLENGTH=1220;
export const MAXIMUMALLOWEDPRODUCWIDTH=2350;
export const MAXIMUMALLOWEDPRODUCTHEIGHT=1400;
export const MAXIMUMALLOWEDPACKAGEWEIGHT=500000;
export const MAXIMUMALLOWEDPACKAGELENGTH=1220;
export const MAXIMUMALLOWEDPACKAGEWIDTH=2350;
export const MAXIMUMALLOWEDPACKAGEHEIGHT=1400;
export const apiBaseUrl = environment.apiBaseUrl + 'products/api/v1/';


