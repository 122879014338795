import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { userStatusModel } from '../../models/UserStatusModel';
import { ActivitiesModel } from '../../models/Authorization/activities-model.model';
import { CommonService} from '../Common/common.service';
import { PIM_ACTIVITIES } from '../../constants/global';
import { SessionStorageService } from 'src/app/core/Common/session-storage.service';
import { AuthService } from 'src/app/core/Authorization/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAccessService {

    public Email: string = "";
    public OrgId: number;
    UserMOdel: userStatusModel;
    activitiesData: any;  
    SessionStorage_ActMOdel = PIM_ACTIVITIES;

  constructor(private authService: AuthService, public commonService: CommonService, 
    private _sessionStorageService: SessionStorageService) {
  }

    async getUserEmailOrgId() {
       
        return await this.authService.initializeUser().then(async () => {
                await this._sessionStorageService.getloggedinUserinfo().then(res => {
                    this.UserMOdel = res;
                    //console.log(this.UserMOdel.email);
                    //console.log(this.UserMOdel.organizationId);
            });
        });
    }

    async getActivitiesList(): Promise<ActivitiesModel>
    {        
        let pro = await this.getUserEmailOrgId();

        if(sessionStorage[this.SessionStorage_ActMOdel] != null)       
            {
              return Promise.resolve(JSON.parse(sessionStorage[this.SessionStorage_ActMOdel]));
            }
        else {            
            return this.commonService.getAuthActivities("organizationId=" + this.UserMOdel.organizationId + "&userEmail=" + this.UserMOdel.username)
            .toPromise().then(res => {
                    sessionStorage[this.SessionStorage_ActMOdel] = JSON.stringify(res.data);

                        for (var v in JSON.parse(sessionStorage[PIM_ACTIVITIES])) {
                            //console.log(JSON.parse(sessionStorage[PIM_ACTIVITIES])[0].name);
                            this.activitiesData.push(JSON.parse(sessionStorage[PIM_ACTIVITIES])[v].name);
                            console.log(this.activitiesData);

                          return this.activitiesData;
                        }
                });
            } 
    }

    async checkAccessStatus(cons: string) : Promise<boolean> {
        let dfault = false;
        
        let p1 = await this.getActivitiesList(); 
            for (var v in p1)
                {
                    this.activitiesData = p1;
                    //console.log(this.activitiesData[v].name);
                    if (this.activitiesData[v].name == cons){
                        dfault = true;  
                    }
                } 

        console.log(dfault);
        return dfault;
    }


}