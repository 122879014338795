import {Component,OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService} from '../services/Common/common.service';
@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss'],  
})
export class BrandListComponent implements OnInit {

  themeComponentName: string = '';
  themeSubscription: Subscription;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['brandList'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }
}
