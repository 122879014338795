import { Injectable } from '@angular/core';
import { HttpHelperService } from '../Common/http-helper.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {ProductStatus} from '../../models/product/productstatus';
import { ProductPostDTO } from '../../models/product/product-post-dto';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  fileImported$: Subject<void> = new Subject<void>();
   //--------------------------Refactored Urls - 6/22/2021 ------------------------------------

  _urlPostProduct = "Products";
  //_urlProductId = "Product/product/";
  //_urlProductTag = "Product/tags";
  //_urlproductStatus = "Product/changeStatus"
  _urlMeasureUnit = "measureunit";

    //--------------------------Refactored Urls - 6/18/2021 ------------------------------------
    _urlProductId = "Products/";
    _urlproductStatus = "Products/status";
    _urlProductTag = "Products/tags";
  
  constructor(private httpHelper: HttpHelperService) { }


  getproduct(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._urlProductId + queryString)
      .pipe(
        map(data => data.data)
      );
}
  postProductData(payload:ProductPostDTO): Observable<any> {
    return this.httpHelper.postData(payload,this._urlPostProduct);
  }
  postBulkData(file:any,url): Observable<any> {
    //--------------------------Refactored Urls - 6/18/2021 -----------------------------
    return this.httpHelper.postData(file,url);
  }

  putProductData(payload:ProductPostDTO,queryString = ""): Observable<any> {
    return this.httpHelper.putData(payload,this._urlPostProduct + queryString);
  }

  getProductTag(queryString = "") : Observable<any>
  {    //--------------Query string deleted------------Refactored Urls - 6/22/2021 -----------------------------
    return this.httpHelper.getData(this._urlProductTag + queryString)  as Observable<any>;
  }

  postProductStatus(payload : ProductStatus) : Observable<any>
  {
    return this.httpHelper.putData(payload,this._urlproductStatus) as Observable<any>;
  }
  productApproval(payload,productId) : Observable<any>
  {
    //let url = "Product/"+productId+"/approval"

    //--------------------------Refactored Urls - 6/18/2021 -----------------------------
    let url = "Products/"+productId+"/approval"
    return this.httpHelper.putData(payload,url) as Observable<any>;
  }

  getUnitOfMeasurement() : Observable<any>
  {

    return this.httpHelper.getData(this._urlMeasureUnit)  as Observable<any>;
  }
  getProductReport(quertString  = "") : Observable<any>{
    //let url="Product/exportCsv"

    //--------------------------Refactored Urls - 6/18/2021 ------------------------------------
    let url="Products/export"

    //return this.httpClient.get(url + quertString,{ responseType: 'blob' as 'json' })
    return this.httpHelper.downloadExcel(url + quertString);
  }

  checkUniqueUpcInBaseOrganization(queryString = ""):Observable<any>{
    let _urlUpc = "Products/upc"
    return this.httpHelper.getData(_urlUpc + queryString) as Observable<any>;
  }


  // -----------------------Subject for product import------------

  sendProductImportUpdate() {
    this.fileImported$.next();
  }

  get productImportNotification() {
    return this.fileImported$.asObservable();
  }
  
  addProductAsset(file:any,url): Observable<any> {
    return this.httpHelper.postData(file,url);
  }

  addProductAssets(file:any,url): Observable<any> {
    return this.httpHelper.postData(file,url);
  }
}
