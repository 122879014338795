import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HistoryDialogComponent } from 'src/app/shared/dialogs/history/history.dialog.component';
@Injectable({
    providedIn: 'root'
})
export class GetHistoryService {
    allWarehouseList: any = [];
    constructor(public dialog: MatDialog,) {
    }
    openHistoryDialog(entityType,entityId,entityName) {
        const dialogRef = this.dialog.open(HistoryDialogComponent, {
            data: { entityType:entityType,entityId:entityId,entityName:entityName }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
            }
          })   
    }

  
}
