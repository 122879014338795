export enum HelpFeatureIdsEnum
{
    // Create New product
    // Tab 1 Basic Information
        Product_Basic_Information = "Product_Basic_Information",

    // Tab2:Volume & Weight
        Product_Volume_And_Weight = "Product_Volume_And_Weight",

    // Tab3:Shelf Life & Batch
        Product_Shelf_Life_And_Batch = "Product_Shelf_Life_And_Batch",

    // Tab 4 : Images/Videos
        Product_Images_Videos = "Product_Images_Videos",

    // Tab 5 : Enhanced Content
        Product_Enhanced_Content = "Product_Enhanced_Content",

    // Product Advance Search :
        Product_Advance_Search = "Product_Advance_Search",

    // Create New Category :
        Categories_Create_New_Category = "Categories_Create_New_Category",

    // Category List :
        Categories_Category_List = "Categories_Category_List",

    // Create New Channel :
        Channels_Create_New_Channel = "Channels_Create_New_Channel",

    // Channel List :
        Channels_Channel_List = "Channels_Channel_List",

    // Create New Asset:
        Assets_Create_New_Asset = "Assets_Create_New_Asset",

    // Brand Creation :
        Brands_Brand_Creation = "Brands_Brand_Creation",

    // Brand List :
        Brands_Brand_List = "Brands_Brand_List",

    // Create new variant : 
        Variants_Create_new_variant = "Variants_Create_new_variant",

    // Variant List :
        Variants_Variant_List = "Variants_Variant_List",
    
    // Product Dashboard :
        Dashboard_Product_Dashboard = "Dashboard_Product_Dashboard",
}