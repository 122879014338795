import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';


@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private httpHelper: HttpHelperService,) { }

  getHistory(querySyring = ""): Observable<any> {
    let url = 'history';
    return this.httpHelper.getData(url + querySyring);
  }
}
