import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from '../../../core/data.service';
import { HistoryService} from '../../../core/common_services/history.service';
import { CustomDateService } from 'src/app/core/common_services/customDates/customDateService';
@Component({
  selector: 'app-history.dialog',
  templateUrl: '../../dialogs/history/history.dialog.html',
  styleUrls: ['../../dialogs/history/history.dialog.css']
})
export class HistoryDialogComponent implements OnInit {
  historyData: any = [];
  OMSEntityTypeEnum: { id: number; name: string; }[];
  onloadShowHistory:boolean = true;
  onSelectionShowHistory: boolean = false;
  selectedIndex: any;
  showHistory: boolean = false;
  OMSActivityTypeEnum: { id: number; name: string; }[];
  noHistory: boolean = false;
  OMSActivityTypeValue: { id: number; name: string; }[];
  constructor(public dialogRef: MatDialogRef<HistoryDialogComponent>,private historyService:HistoryService,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService,private customDateService:CustomDateService) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
    this.WMSEntityType()
      if(this.data)
      {
        setTimeout(()=>{this.getHistory(this.data.entityId,this.data.entityType)},1000);
        
      }
      
  }
  WMSEntityType()
  {
    this.OMSActivityTypeEnum = 
    [{ id: 1, name: 'is created' },
    { id: 2, name: 'is updated' },
    { id: 3, name: 'is deleted' },
  ],
    this.OMSEntityTypeEnum = 
    [{ id: 1, name: 'Channel' },
    { id: 2, name: 'Order' },
    { id: 3, name: 'Order Fulfillment' },
    { id: 4, name: 'Notification Event Settings' },
    { id: 5, name: 'User' },
    { id: 6, name: 'Return Order' },
    { id: 7, name: 'Shipping Option' },
    ];
    this.OMSActivityTypeValue = 
    [
      { id: 1, name: 'Created' },
    { id: 2, name: 'Updated' },
    { id: 3, name: 'Deleted' },
  ]
}
  
  confirmDelete(): void {
    //this.dataService.deleteIssue(this.data.id);
  }
  showActivityType(entityType,activityType) {
    var text;
    text =  this.OMSEntityTypeEnum[entityType - 1].name +" "+ this.OMSActivityTypeEnum[activityType - 1].name
    return text
    //console.log(matches.join(''));
  }
  getHistory(entityId,entityType)
  {
      let requestParameter = "?entityId="+entityId+"&entityType="+entityType
      this.historyService.getHistory(requestParameter).subscribe(res=>{
        this.historyData = res.data.data;
        if(this.historyData.length > 0)
        {
          this.showHistory = true;
        }
        else
        {
          this.noHistory = true; 
        }
    })
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  getShortText(text) {

    var myStr =text;
    var matches = myStr.match(/\b(\w)/g);
    return matches.join('');
    //console.log(matches.join(''));
  }
  onHistoryListSelection(ind)
  {
    this.selectedIndex = ind;
    this.onSelectionShowHistory = true;
    this.onloadShowHistory = false;
  }
  getWrapedText(text,maxLength)
  {
    if(text.length <= maxLength)
    {
      return text;
    }
    else
    {
      return text.substring(0,maxLength-3) + "...";
    }
  }
  removeLastComma(strng){        
    var n=strng.lastIndexOf(",");
    var a=strng.substring(0,n) 
    return a;
  }
  customDateAndTime(date)
    {
      return this.customDateService.customDateAndTimeFormat(date);
    }
    customDate(date)
    {
      return this.customDateService.customDateFormat(date);
    }
}
