import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { AssetService } from '../../services/Assets/asset.service';
import { AssetCategoryService } from '../../services/asset-category.service';


@Component({
  selector: 'app-add-approval-commnent.dialog',
  templateUrl: '../../dialogs/approval-comment/add-approval-commnent.dialog.html',
  styleUrls: ['../../dialogs/approval-comment/add-approval-commnent.dialog.css']
})
export class AddApprovalCommnetDialogComponent implements OnInit {

  commnet=new FormControl();
  form: FormGroup;
  formSubmit: boolean=false;
  constructor(public dialogRef: MatDialogRef<AddApprovalCommnetDialogComponent>,private _assetCategoryService: AssetCategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formbuilder:FormBuilder,public dataService: DataService, private assetService: AssetService) {
      this.dialogRef.disableClose = true;

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
    this.form=this.formbuilder.group({
      comment:[]
    })

  }

  get comment() {
    return this.form.get('comment');
  }

  confirmDelete(): void {
  }
  closeDialog(): void {
    
    this.dialogRef.close();
  }
  onSave():void
  { 
      this.formSubmit = true;
      if (this.form.invalid) {
        return;
      }
      let comment=this.form.get('comment').value;
      this.dialogRef.close(comment);
  }
}
