
import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CategoriesService } from 'src/app/core/common_services/master/categories/categories.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { validationPattern } from 'src/app/shared/Constants/validationPattern';
import { ProductModel } from 'src/app/shared/models/products/product';
import { BlobStorageService } from '../../../../azure-storage/blob-storage.service';
import { SASTokenService } from '../../../../azure-storage/sastoken.service';
import { PermissionsEnum } from '../../../../constants/permissions';
import { pageSize } from '../../../../constants/variables';
import { BarcodeDialogComponent } from '../../../../dialogs/barcode-dialog/barcode-dialog.component';
import { DeleteDialogComponent } from '../../../../dialogs/delete/delete.dialog.component';
import { GlobalUPCTypeEnum } from '../../../../ENUM/globalUPCTypeEnum';
import { InventoryMethodEnum } from '../../../../ENUM/inventoryMethodEnum';
import { titlename } from '../../../../ENUM/titlename';
import { CategoryModel } from '../../../../models/Category/category-model.model';
import { RouterLinksEnum } from '../../../../shared/router-links/router-links.enum';
import { BrandsService } from '../../../../services/Brands/brands.service';
import { CategoriesServiceService } from '../../../../services/categories-service.service';
import { CommonService } from '../../../../services/Common/common.service';
import { EnumHelperService } from '../../../../services/Common/enumHelperService';
import { ProductListService } from '../../../../services/Product/product-list.service';
import { ProductService } from '../../../../services/Product/product.service';
import { ORGANIZATION_INFO } from '../../../../shared/constants/global';
import {
  CdkDropList,
  CdkDragEnter,
  CdkDropListGroup,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  INVALIDHSNCODEFORMATMESSAGE, MAXVALUEFORPRODUCTLENGTHMESSAGE,
  MAXVALUEFORPRODUCTBREADTMESSAGE, MAXVALUEFORPRODUCTHEIGHTMESSAGE,
  MAXVALUEFORPRODUCTWEIGHTMESSAGE, MAXIMUMVALUEFORPACKAGELENGTHMESSAGE,
  MAXIMUMVALUEFORPACKAGEBREADTHMESSAGE, MAXIMUMVALUEFORPACKAGEHEIGHTMESSAGE,
  MAXIMUMVALUEFORPACKAGEWEIGHTMESSAGE, MAXLENGTHGUPCVALIDATIONMESSAGE,
  MAXIMUMALLOWEDPRODUCTWEIGHT, MAXIMUMALLOWEDPRODUCTLENGTH,
  MAXIMUMALLOWEDPRODUCWIDTH, MAXIMUMALLOWEDPRODUCTHEIGHT,
  MAXIMUMALLOWEDPACKAGEWEIGHT, MAXIMUMALLOWEDPACKAGEHEIGHT,
  MAXIMUMALLOWEDPACKAGELENGTH, MAXIMUMALLOWEDPACKAGEWIDTH
} from '../../../../shared/constants/global';
import { maximumLength } from 'src/app/shared/Constants/maximumLength';
import { AddBrandDialogComponent } from 'src/app/modules/pim/dialogs/add-brand/add-brand.dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { messages } from 'src/app/shared/Constants/messageResource';
import { forkJoin } from 'rxjs';
import { AddCategoryDialogComponent } from 'src/app/modules/pim/dialogs/add-category/add-category.dialog.component';
@Component({
  selector: 'app-create-new-product-mobile-default',
  templateUrl: './create-new-product-mobile-default.component.html',
  styleUrls: ['./create-new-product-mobile-default.component.scss']
})
export class CreateNewProductMobileDefaultComponent implements OnInit {
  @Output() currentDevice: EventEmitter<object> = new EventEmitter();
  productForm: FormGroup;
  imageUploadForm: FormGroup;
  isFormSubmit: boolean = false;
  brandResponse = [];
  allBrandsValues = [];
  isServerSideBrandSearch: boolean;
  isServerSideCategorySearch: boolean;
  categoriesResponse: Array<CategoryModel> = [];
  allCategoriesValues: Array<CategoryModel> = [];
  categoryFormControl = new FormControl('');
  brandFormControl = new FormControl('');
  globalUPCTypes: { id: string; name: string; }[];
  globalUPCTypeEnum: typeof GlobalUPCTypeEnum;
  productId: string;
  copyId: string;
  unitOfMeasureResponse = [];
  pricePerUnit: number = 0;
  showUnitWeightField: boolean = false;
  productAssetFormControls: FormGroup;
  channelData = [];
  selectedAssets = [];
  channelName: string;
  channelId: string;
  uploadStarted: boolean = false;
  disableButtons: boolean = false;
  showImageDetails: boolean = false;
  viewId: string = '';
  orgId: string;
  productInfo: ProductModel;
  titlename: string;
  permissionsEnum: typeof PermissionsEnum;
  allProductStatus: string[] = ["Draft", "Completed", "Active", "Inactive", "Discontinued"];
  toggleStatus: boolean = false;
  buttonName: string = ''
  showProductDetails: boolean = true;
  numberPattern = "^[0-9]{0,200}[.]?[0-9]{0,200}$";
  onlyNumberPattern = "^[0-9]*$";
  maximumLimitForProductWeight = MAXIMUMALLOWEDPRODUCTWEIGHT;
  maximumLimitForProductLength = MAXIMUMALLOWEDPRODUCTLENGTH;
  maximumLimitForProductWidth = MAXIMUMALLOWEDPRODUCWIDTH;
  maximumLimitForProductHeight = MAXIMUMALLOWEDPRODUCTHEIGHT;
  maximumLimitForPackageWeight = MAXIMUMALLOWEDPACKAGEWEIGHT;
  maximumLimitForPackageLength = MAXIMUMALLOWEDPACKAGELENGTH;
  maximumuLimitForPackageWidth = MAXIMUMALLOWEDPACKAGEWIDTH;
  maximumLimitForPackagerHeight = MAXIMUMALLOWEDPACKAGEHEIGHT;
  maxLengthGUPCValidationMessage = MAXLENGTHGUPCVALIDATIONMESSAGE;
  maximumValueforProductLengthMessage = MAXVALUEFORPRODUCTLENGTHMESSAGE;
  maximumValueforProductWidthMessage = MAXVALUEFORPRODUCTBREADTMESSAGE;
  maximumValueforProductHeightMessage = MAXVALUEFORPRODUCTHEIGHTMESSAGE;
  maximumValueforProductWeightMessage = MAXVALUEFORPRODUCTWEIGHTMESSAGE;
  maximumValueforPackageLengthMessage = MAXIMUMVALUEFORPACKAGELENGTHMESSAGE;
  maximumValueforPackageWidthMessage = MAXIMUMVALUEFORPACKAGEBREADTHMESSAGE;
  maximumValueforPackageHeightMessage = MAXIMUMVALUEFORPACKAGEHEIGHTMESSAGE;
  maximumValueforPackageWeightMessage = MAXIMUMVALUEFORPACKAGEWEIGHTMESSAGE;
  invalidHSNCodeFormatMessage = INVALIDHSNCODEFORMATMESSAGE;
  isClonedProduct: boolean = false;
  @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public activeContainer;
  get Brand_MRP() {
    return this.productForm.get('brandMRP');
  }
  get MRP() {
    return this.productForm.get('mrp');
  }
  get Selling_Price() {
    return this.productForm.get('sellingPrice');
  }

  public filesToBeUploadedTempInfo = [];
  public assetsToBeUploadedTempInfo = [];

  constructor(private deviceDetect: DeviceDetect, private formBuilder: FormBuilder, private brandsService: BrandsService,
    private categoriesService: CategoriesService, private categoryService: CategoriesServiceService, private productService: ProductService,
    private customMessageService: CustomMessageService, private enumHelperService: EnumHelperService, public dialog: MatDialog,
    private commonService: CommonService, private sASTokenService: SASTokenService, private blobStorageService: BlobStorageService,
    private router: Router, private route: ActivatedRoute, private productListService: ProductListService) {
    this.target = null;
    this.source = null;
  }

  ngOnInit() {
    this.permissionsEnum = PermissionsEnum;
    this.route.paramMap.subscribe(params => {
      this.productId = params.get('id') ? params.get('id') : null;
      this.viewId = params.get('viewId') ? params.get('viewId') : null;
      this.buttonName = 'Update';
      if (this.productId) { this.titlename = titlename.editProduct }
      if (this.viewId) { this.titlename = titlename.viewProduct }
    })
    if (!this.productId && !this.viewId) { this.titlename = titlename.addProduct; this.buttonName = "Save" }
    this.productFormControls();
    this.onControlsValueChanged();
    this.getChannel();
    this.getAllBrands();
    this.getAllCategories();
    this.getAllUnitOfMeasurement();
    this.globalUPCTypes = this.enumHelperService.getGlobalUPCTypeEnum();
    this.globalUPCTypeEnum = GlobalUPCTypeEnum;
    this.getProductInfo();
    this.markAsDisable();
  }
  markAsDisable() {
    if (this.viewId) {
      this.productForm.disable();
      let globalUPCDetails = this.productForm.get('globalUPCDetails') as FormArray;
      globalUPCDetails.disable();
      this.brandFormControl.disable();
      this.categoryFormControl.disable();
    }
  }
  onControlsValueChanged() {
    this.brandFormControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      !val && this.productForm.get('productBrand').setValue(null);
      if (this.isServerSideBrandSearch) {
        let queryString = `searchText=${val}&pageSize=${pageSize}`;
        this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
          val && (this.brandResponse = res);
          !val && (this.brandResponse = []);
        })
        return;
      }
      val = val && val.toLowerCase();
      if (this.allBrandsValues.length == 0) { return }
      this.brandResponse = this.allBrandsValues.filter(x => {
        return x.name.toLowerCase().includes(val)
      });
    });
    this.categoryFormControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (this.categoryFormControl.invalid) {
        this.productForm.controls['productCategory'].setErrors({ 'incorrect': true });
      } else {
        this.productForm.controls['productCategory'].setErrors(null);
      }
      !val && this.productForm.get('productCategory').setValue(null);
      if (this.isServerSideCategorySearch) {
        let queryString = `searchText=${val}&pageSize=${pageSize}`;
        this.categoryService.getAllCategoriesActive(queryString).subscribe(res => {
          val && (this.categoriesResponse = res);
          !val && (this.categoriesResponse = []);
        })
        return;
      }
      val = val && val.toLowerCase();
      if (this.allCategoriesValues.length == 0) { return }
      this.categoriesResponse = this.allCategoriesValues.filter(x => {
        return x.fullHierarchicalName.toLowerCase().includes(val)
      })
    })
    this.productForm.get('fractionAllowed').valueChanges.subscribe(val => {
      if (val) {
        this.productForm.get('unitWeightInGrams').enable();
        this.productForm.get('unitWeightInGrams').setValidators(Validators.required);
        this.productForm.get('unitWeightInGrams').updateValueAndValidity();
      } else {
        this.productForm.get('unitWeightInGrams').clearValidators();
        this.productForm.get('unitWeightInGrams').updateValueAndValidity();
        this.productForm.get('unitWeightInGrams').setValue('');
        this.productForm.get('unitWeightInGrams').disable();
      }
    });
    this.productForm.get('unitWeightInGrams').valueChanges.subscribe(val => {
      if (val == 0 && val !== '') {
        this.productForm.get('unitWeightInGrams').setValue(1, { emitEvent: false });
      }
      else if (val && val > 999) {
        this.productForm.get('unitWeightInGrams').setValue(999, { emitEvent: false });
      } else if ((val && val < 1)) {
        this.productForm.get('unitWeightInGrams').setValue(1, { emitEvent: false });
      }
      this.calculatePricePerUnit();
    });
    this.productForm.get('sellingPrice').valueChanges.subscribe(val => {
      this.calculatePricePerUnit();
    })
    this.productForm.get('unitOfMeasureShortName').valueChanges.subscribe(val => {
      this.showUnitWeightField = val == 'kg' ? true : false;
      val == 'kg' && !this.productForm.get('unitWeightInGrams').value && this.productForm.get('unitWeightInGrams').disable();
      if (val != 'kg' && !this.showUnitWeightField) {
        this.productForm.get('unitWeightInGrams').setValue('')
        this.productForm.get('fractionAllowed').setValue(false);
      }
    });
  }
  patchProductInfo() {
    if ((this.productId || this.viewId) && this.productInfo) {
      this.selectedAssets = this.productInfo['assets'].length ? this.productInfo['assets'] : [];
      const globalUPCControl = <FormArray>this.productForm.get('globalUPCDetails');
      if (this.productInfo['globalUPCDetails'] && this.productInfo['globalUPCDetails'].length) {
        globalUPCControl.clear();
        this.productInfo['globalUPCDetails'].forEach(y => {
          globalUPCControl.push(this.getGlobalUPCDetailsFormBuilder())
        })
      }
      if (!this.categoryFormControl.value) {
        this.categoryFormControl.setValue(this.productInfo.productCategory.fullHierarchicalName);
      }
      if (!this.brandFormControl.value) {
        this.brandFormControl.setValue(this.productInfo.productBrand.name);
      }
      this.markAsDisable()
      if (this.allProductStatus[this.productInfo.status - 1] == "Active") { this.toggleStatus = true; }
      this.productForm.patchValue(this.productInfo)
    }
  }

  getProductInfo() {
    let orgId = JSON.parse(decodeURI(localStorage.getItem(ORGANIZATION_INFO)))['id'];
    let id = this.productId || this.viewId;
    let requestparameter = id + "?organizationId=" + orgId;
    this.productService.getproduct(requestparameter).subscribe(res => {
      if (res) {
        this.productInfo = res;
        this.isClonedProduct = this.productInfo['isClonedProduct'] ? true : false;
        this.disableFormControlsForClonedProduct();
        this.patchProductInfo()
      }
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentDevice.emit(this.deviceDetect.currentDevice());
  }
  productFormControls() {
    this.productForm = this.formBuilder.group({
      id: [''],
      organizationId: [],
      name: ['', [Validators.required, Validators.maxLength(250)]],
      sku: ['', [Validators.maxLength(50)]],
      productCategory: [null],
      hsnCode: ['', [Validators.maxLength(10), Validators.pattern(validationPattern.hsnCodePattern)]],
      productTaxType: [null],
      description: ['', [Validators.maxLength(2000)]],
      bulletPoints: this.formBuilder.array([]),
      productBrand: [null],
      productCondition: [null],
      productConditionDescription: [null],
      mrp: [0, [Validators.pattern(validationPattern.numberPattern), Validators.max(10000000)]],
      brandMRP: [0, [Validators.required, Validators.pattern(validationPattern.numberPattern), Validators.max(10000000)]],
      shippingFee: [0, [Validators.pattern(validationPattern.numberPattern)]],
      sellingPrice: [0, [Validators.pattern(validationPattern.numberPattern), Validators.max(10000000)]],
      minimumSellerAllowedPrice: [0],
      maximumSellerAllowedPrice: [0],
      maxCartQty: [0],
      minCartQty: [0],
      unitWeightInGrams: [''],
      allowFileUpload: [false],
      fileMandatory: [false],
      fractionAllowed: [false],
      asin: [''],
      globalUPCDetails: this.formBuilder.array([this.getGlobalUPCDetailsFormBuilder()]),
      status: [1, [Validators.required]],
      tags: [[], [Validators.maxLength(100)]],
      returnable: [false],
      allowedReturnPeriodInDays: ['', [Validators.pattern(this.onlyNumberPattern)]],
      productReplacementShippingCondition: [],
      productReplacementConditionDescription: [''],
      tat: [0],
      productDimensions: this.formBuilder.group({
        lengthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductLength)]],
        widthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductWidth)]],
        heightInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductHeight)]],
        weightInGrams: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductWeight)]],
      }),
      packageDimensions: this.formBuilder.group({
        lengthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackageLength)]],
        widthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumuLimitForPackageWidth)]],
        heightInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackagerHeight)]],
        weightInGrams: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackageWeight)]]
      }),
      serializable: [''],
      unitOfMeasureId: [''],
      unitOfMeasureName: [''],
      unitOfMeasureShortName: [''],
      unitOfMeasureShortNameDisp: [''],
      isPerishable: [false],
      shelfLife: [null],
      productShelfLifeUnit: [null],
      season: [null],
      maintainBatch: [false],
      launchDate: [''],
      inventoryMethod: [InventoryMethodEnum["First in First out"]],
      images: this.formBuilder.array([]),
      isVariantGroup: [false],
      variationGroupId: [''],
      isVariant: [false],
      variantTypes: this.formBuilder.array([]),
      productVariants: this.formBuilder.array([]),
      createdById: [''],
      createdByName: [''],
      assets: this.formBuilder.array([]),
      aPlusContents: this.formBuilder.array([]),
      approvalDetails: this.formBuilder.group({
        actionByEmail: [''],
        actionByName: [''],
        approvalDateTime: [''],
        approvalStatus: [''],
        approvalStatusName: [''],
        comments: [''],
        requestedDateTime: [''],
      }),
      channelProductCodes: this.formBuilder.array([]),
      channelVariantPresentations: this.formBuilder.array([]),
      isPublicProduct: [false]
    });
    this.productAssetFormControls = this.formBuilder.group({
      isSwatchOnlyImage: [],
      channelId: [''],
      channelName: [''],
      isPrimary: [''],
      displayOrder: [''],
      id: [''],
      title: [''],
      description: [''],
      fileDetails: this.formBuilder.group({
        fileName: [''],
        extension: [''],
        fileType: [''],
        fileUrl: [''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      }),
      tags: [],
      status: [''],
      type: [''],
      brandId: [''],
      brandName: [''],
      assetCategoryId: [''],
      assetCategoryName: [''],
      createdByEmail: [''],
      createdByName: [''],
      updatedByEmail: [''],
      updatedByName: [''],
      createdOn: [''],
      updatedOn: [''],
    })
  }
  getGlobalUPCDetailsFormBuilder(): FormGroup {
    return this.formBuilder.group({
      upcType: [],
      upcValue: ['', [Validators.maxLength(maximumLength.length25)]]
    });
  }
  submitForm(showImageDetails,showUpdatedInfo: boolean) {
    this.isFormSubmit = true;
    if (this.productForm.invalid) { return }
    let formValue = this.productForm.getRawValue();
    formValue.assets = this.selectedAssets.length ? this.selectedAssets.filter(x => x.isUploaded != false) : []
    this.disableButtons = true;
    this.setDisplayOrder();
    if (this.productId) {
      formValue.id = this.productId;
      if (this.productInfo) {
        if (this.productInfo.bulletPoints.length) { formValue.bulletPoints = this.productInfo.bulletPoints; }
        if (this.productInfo['aPlusContents'].length) { formValue.aPlusContents = this.productInfo['aPlusContents']; }
        if (this.isClonedProduct && this.productInfo['assets'].length) { formValue.assets = this.productInfo['assets'] }
      }
      this.updateProduct(formValue,showUpdatedInfo)
    } else {
      this.createProduct(formValue, showImageDetails)
    }
  }
  createProduct(formValue, showImageDetails) {
    this.productService.postProductData(formValue).subscribe(res => {
      this.customMessageService.ShowCustomMessage(res.statusMessage, true);
      this.productId = res.data.id
      this.showImageDetails = showImageDetails ? true : false;
      this.showProductDetails = showImageDetails ? false : true;
      if (this.showImageDetails == false) { this.router.navigate([RouterLinksEnum.ADMIN]); }
      if (this.filesToBeUploadedTempInfo && this.filesToBeUploadedTempInfo.length > 0) {
        this.startAssetsUploading();
      }
      this.removeChecks()
    }, err => { this.removeChecks() })
  }
  updateProduct(formValue,showUpdatedInfo: boolean) {

    this.productService.putProductData(formValue).subscribe(res => {
      if (res.data && showUpdatedInfo) {
        this.customMessageService.ShowCustomMessage(res.statusMessage, true);
        this.router.navigate([RouterLinksEnum.ADMIN]);
      }
      if (this.filesToBeUploadedTempInfo && this.filesToBeUploadedTempInfo.length > 0) {
        this.startAssetsUploading();
      }
      this.removeChecks()
    }, err => { this.removeChecks() })
  }
  getAllBrands(searchText: string = '') {
    let queryString = `searchText=${searchText}&pageSize=${pageSize}`;
    this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
      this.patchProductInfo();
      this.isServerSideBrandSearch = res.length == pageSize ? true : false;
      !this.isServerSideBrandSearch && (this.brandResponse = res);
      this.allBrandsValues = res;
      // !this.isServerSideBrandSearch && this.brandFormControl.setValidators(this.validOptionValidator(this.brandResponse));
      // !this.isServerSideBrandSearch && this.brandFormControl.updateValueAndValidity();
    });
  }
  selectBrand(brand, event) {
    if (event.isUserInput) {
      let selectedBrand = JSON.parse(JSON.stringify(brand))
      this.isServerSideBrandSearch && (this.brandResponse = []);
      this.productForm.get('productBrand').setValue(selectedBrand);
      this.brandFormControl.setValue(selectedBrand.name)
    }
  }
  getAllCategories(searchText: string = '') {
    let queryString = ""
    if (!searchText) {
      searchText = ""
    }
    queryString = `?searchText=${searchText}&pageSize=${pageSize}&displayAllCategoriesHierarchy=${"true"}`;
    this.categoriesService.getCategoriesForProducts(queryString).subscribe(res => {
      this.patchProductInfo();
      this.isServerSideCategorySearch = res['data'].length == pageSize ? true : false;
      !this.isServerSideCategorySearch && (this.categoriesResponse = res['data']);
      this.allCategoriesValues = res['data'];
      // !this.isServerSideCategorySearch && this.categoryFormControl.setValidators(this.validOptionValidatorForCategory(this.categoriesResponse));
      // !this.isServerSideCategorySearch && this.categoryFormControl.updateValueAndValidity();
    });
  }
  selectCategory(category, event) {
    if (event && event.isUserInput) {
      this.categoryFormControl.setValue(category.fullHierarchicalName);
      this.productForm.get('productCategory').setValue(category);
      this.isServerSideCategorySearch && (this.categoriesResponse = []);
    }
  }
  //****custom validator for brand/category autocomplete valid options*****//
  validOptionValidator(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control.value || '').length) return null;
      let item = options && options.length && options.find(item => control.value == item.name);
      return !item ? { validOptions: { value: control.value } } : null;
    };
  }
  validOptionValidatorForCategory(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control.value || '').length) return null;
      let item = options && options.length && options.find(item => control.value == item.fullHierarchicalName);
      return !item ? { validOptions: { value: control.value } } : null;
    };
  }
  checkUniqueUpc(event) {
    if (event) {
      let queryString = `?upcValue=${event}`
      if (this.productId) {
        queryString += `&productId=${this.productId}`
      }
      this.productService.checkUniqueUpcInBaseOrganization(queryString).subscribe(res => {
        if (res.data && res.data.item2 == true) {
          this.customMessageService.ShowCustomMessage(res.data.item1, false);
        }
      })
    }
  }
  openScanner(i: number) {
    const dialogRef = this.dialog.open(BarcodeDialogComponent, {}
    ).afterClosed().subscribe(result => {
      let globalUpcDetails = this.productForm.get('globalUPCDetails') as FormGroup;
      globalUpcDetails.controls[i].get('upcValue').setValue(result.data);
      //globalUpcDetails.controls[i].get('upcType').setValue(this.globalUPCTypeEnum.EAN);
    })
  }
  deleteGlobalUPCDetailsRow(index, event) {
    let globalUPCTypes = this.productForm.get('globalUPCDetails') as FormArray;
    if (this.productId || this.copyId) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.delete, isError: false, buttonName: 'Ok' }
      });
      // const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //   data: { id: 1 }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          event.preventDefault();
          globalUPCTypes.removeAt(index);
        }
      })
    }
    else {
      event.preventDefault();
      globalUPCTypes.removeAt(index);
    }
  }
  createGlobalUPCDetailsRow() {
    let globalUPCDetailFormArray = this.productForm.get('globalUPCDetails') as FormArray;
    globalUPCDetailFormArray.push(this.getGlobalUPCDetailsFormBuilder());
  }
  getAllUnitOfMeasurement() {
    this.productService.getUnitOfMeasurement().subscribe(res => {
      this.patchProductInfo();
      this.unitOfMeasureResponse = res.data;
      this.unitOfMeasureResponse.forEach(res => {
        if (res.name == "Pieces") {
          this.productForm.get('unitOfMeasureId').setValue(res.id)
          this.productForm.get('unitOfMeasureName').setValue(res.name);
          this.productForm.get('unitOfMeasureShortName').setValue(res.shortName);
        }
      })
    });
  }
  selectMeasureUnit(event) {
    if (event.value) {
      for (let i = 0; i < this.unitOfMeasureResponse.length; i++) {
        if (this.unitOfMeasureResponse[i].id == event.value) {
          this.productForm.get('unitOfMeasureName').setValue(this.unitOfMeasureResponse[i].name);
          this.productForm.get('unitOfMeasureShortName').setValue(this.unitOfMeasureResponse[i].shortName);
        }
      }
    }
  }
  calculatePricePerUnit() {
    let pricePerUnit = (this.productForm.get('sellingPrice').value / 1000) * this.productForm.get('unitWeightInGrams').value;
    this.pricePerUnit = Number(pricePerUnit.toFixed(2));
  }
  checkAssetExtension(result) {
    let showSwatchImage = true;
    if (result.length) {
      for (let i = 0; i < result.length; i++) {
        if (result[i].fileDetails.extension == 'png' || result[i].fileDetails.extension == 'jpg' ||
          result[i].fileDetails.extension == 'PNG' || result[i].fileDetails.extension == 'JPG' || result[i].fileDetails.extension == 'jpeg' ||
          result[i].fileDetails.extension == 'JPEG' || result[i].fileDetails.extension == 'bmp' || result[i].fileDetails.extension == 'BMP' ||
          result[i].fileDetails.extension == 'tiff' || result[i].fileDetails.extension == 'TIFF') {
          showSwatchImage = true;
        }
        else {
          showSwatchImage = false;
          // this.assetSwatchImage = false;
          break;
        }
      }
      return showSwatchImage
    }
  }
  getChannel() {
    let requestParameter = "?pageSize=-1";
    this.commonService.getChannels(requestParameter).subscribe(res => {
      this.channelData = res['data'].data
      this.channelData.forEach(channel => {
        if (channel.code == 'ESTORE') {
          this.channelName = channel.name;
          this.channelId = channel.id;
        }
      })
      this.patchProductInfo();
    })
  }
  // onFileChange(event): void {
  //   const file = event.target.files[0];
  //   this.uploadStarted = true;
  //   this.disableButtons = true;
  //   this.sASTokenService.getSASToken({ fileNameWithExtension: event.target.files[0].name }).subscribe(res => {
  //     this.uploadTokenFile(file, res);
  //   }, err => {
  //     this.removeChecks()
  //   })
  // }
  // uploadTokenFile(file: File, tokenResponse) {
  //   let sasInfo = {
  //     blobName: tokenResponse.data.blobName,
  //     containerName: tokenResponse.data.containerName,
  //     fileUrl: tokenResponse.data.fileUrl,
  //     serviceUrlWithSAS: tokenResponse.data.serviceUrlWithSAS
  //   }
  //   this.blobStorageService.uploadBlobToStorage(file, sasInfo).then(response => {
  //     let requestParameter = {
  //       fileUrl: tokenResponse.data.fileUrl
  //     }
  //     this.sASTokenService.getAssetThumbnail(requestParameter).subscribe(res => {
  //       if (res) {
  //         let fileName = file.name.substring(0, file.name.lastIndexOf("."));
  //         fileName = fileName ? fileName : file.name;
  //         this.addAsset({ fileUrl: tokenResponse.data.fileUrl, fileName: fileName })
  //       }
  //     }, err => {
  //       this.removeChecks()
  //     })
  //   }, err => {
  //     this.removeChecks()
  //   })
  // }
  // addAsset(data) {
  //   let url = "Products/" + this.productId + "/asset"
  //   this.productService.addProductAsset(data, url).subscribe(res => {
  //     if (res.data) {
  //       let assetInfo = res.data;
  //       assetInfo['channelName'] = this.channelName;
  //       assetInfo['channelId'] = this.channelId;
  //       assetInfo['isSwatchOnlyImage'] = false;
  //       assetInfo['isPrimary'] = false;
  //       this.selectedAssets.push(res.data);
  //       assetInfo['displayOrder'] = this.selectedAssets.length - 1;
  //     }
  //     this.removeChecks()
  //   }, err => {
  //     this.removeChecks()
  //   })
  // }




  onFileChange(event): void {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      //Data is added in the same way as received from api to support backward compatibility and previous implementation 
      this.selectedAssets.push(
        { 
          fileDetails:  {
            fileUrl :reader.result
          },
          isUploaded: false
        });
    }
    this.filesToBeUploadedTempInfo.push(file);
  }

  getSaSTokens() {
    let requestArray = [];
    this.filesToBeUploadedTempInfo.forEach(file => {
      requestArray.push(this.sASTokenService.getSASToken({ fileNameWithExtension: file.name }))
    });
    return forkJoin(requestArray);
  }
  uploadBlob(res) {
    let requestArray = [];
    let blobFile;
    res.forEach(item => {
      let sasInfo = {
        blobName: item.data.blobName,
        containerName: item.data.containerName,
        fileUrl: item.data.fileUrl,
        serviceUrlWithSAS: item.data.serviceUrlWithSAS
      }

      blobFile = this.filesToBeUploadedTempInfo.find(file => {
        let fileName = file.name.substring(0, file.name.lastIndexOf("."));
        fileName = fileName ? fileName : file.name;
        return fileName == item.data.fileName;
      });

      this.assetsToBeUploadedTempInfo.push({ fileUrl: item.data.fileUrl, fileName: item.data.fileName });

      if (blobFile) {
        requestArray.push(this.blobStorageService.uploadBlobToStorage(blobFile, sasInfo));
      } else {
        //continue;
      }
    });

    forkJoin(requestArray).subscribe(res => {
      this.addAssets();
    });
  }

  addAssets() {
    this.selectedAssets = this.selectedAssets.filter(x => x.isUploaded != false);
    let url = "Products/" + this.productId + "/assets"
    this.productService.addProductAsset(this.assetsToBeUploadedTempInfo, url).subscribe(res => {
      if (res.data) {
        res.data.forEach(item => {
          let assetInfo = item;
          assetInfo['channelName'] = this.channelName;
          assetInfo['channelId'] = this.channelId;
          assetInfo['isSwatchOnlyImage'] = false;
          assetInfo['isPrimary'] = false;
          this.selectedAssets.push(item);
          assetInfo['displayOrder'] = this.selectedAssets.length - 1;
        });
      }
      this.clearTempData();
      this.submitForm(false,false);
      this.removeChecks()
    }, err => {
      this.removeChecks()
    })
  }

  clearTempData(){
    this.filesToBeUploadedTempInfo = [];
    this.assetsToBeUploadedTempInfo = [];
  }

  startAssetsUploading() {
    this.getSaSTokens().subscribe(res => {
      if (res && res.length > 0) {
        this.uploadBlob(res);
      }
    });
  }
  removeChecks() {
    this.uploadStarted = false;
    this.disableButtons = false;
  }
  checkIsBrandLinked() { // we are using this function while uploading image/video
    if (!this.brandFormControl.value) {
      this.customMessageService.ShowCustomMessage("Please link a Brand with the product before uploading any new image/video.", false);
      return
    }
  }
  deleteAsset(index) {
    this.selectedAssets.splice(index, 1);
    this.filesToBeUploadedTempInfo.splice(index,1);
  }
  getWrapedText(text) {
    return this.commonService.getWrapedText(text, 22)
  }
  productStatusUpdate(event) {
    let orgId = JSON.parse(decodeURI(localStorage.getItem(ORGANIZATION_INFO)))['id'];
    let toggleElement = event;
    var productStatusDetail = {
      organizationId: orgId,
      productId: this.viewId,
      status: event.checked ? this.allProductStatus[2] : this.allProductStatus[3]
    };
    this.productService.postProductStatus(productStatusDetail).subscribe(res => {
      if (res.data) {
        this.productForm.get('status').setValue(productStatusDetail.status);
        this.customMessageService.ShowCustomMessage(res.statusMessage, true);
      }
    }, err => {
      if (err) { toggleElement.source.checked = !toggleElement.source.checked; }
    })
  }
  redirectToUpdate() {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH + this.viewId + RouterLinksEnum.ADMIN_EDIT);
  }
  redirectToProductList() {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN);
  }
  redirectToDelete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: 1 }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.productListService.removeData(this.viewId).subscribe(res => {
          this.customMessageService.ShowCustomMessage(res.statusMessage, true);
          this.router.navigate([RouterLinksEnum.ADMIN]);
        })
      }
    })
  }

  dropListDropped() {
    if (!this.target || this.viewId || this.isClonedProduct) return;
    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;
    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );
    this.target = null;
    this.source = null;
    this.activeContainer = null;
    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.selectedAssets, this.sourceIndex, this.targetIndex);
    }
  }
  cdkDropListEntered(e: CdkDragEnter) {
    if (this.viewId || this.isClonedProduct) return;
    const drag = e.item;
    const drop = e.container;
    if (drop === this.placeholder) { return true; }
    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;
    const dragIndex = this.__indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement
    );
    const dropIndex = this.__indexOf(
      dropElement.parentElement.children,
      dropElement
    );
    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      sourceElement.parentElement.removeChild(sourceElement);
    }
    this.targetIndex = dropIndex;
    this.target = drop;
    phElement.style.display = '';
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );
    requestAnimationFrame(() => {
      this.placeholder._dropListRef.enter(
        drag._dragRef,
        drag.element.nativeElement.offsetLeft,
        drag.element.nativeElement.offsetTop
      );
    });
  }
  __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
  }
  setDisplayOrder() {
    if (this.selectedAssets.length) {
      this.selectedAssets.forEach((res, index) => {
        res['displayOrder'] = index + 1;
      })
    }
  }
  addNewBrand() {
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { addBrand: "Brand" }
    // });
    const dialogRef = this.dialog.open(AddBrandDialogComponent, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let obj = {
          name: result.data,
          active: true,
          _Paxcom_OrganizationId: this.orgId
        }
        this.brandsService.postBrand(obj).subscribe(res => {
          if (res && res.data) {
            this.brandResponse =  [...this.brandResponse,{...res.data}];
            this.allBrandsValues = [...this.allBrandsValues,{...res.data}];
            this.productForm.get('productBrand').setValue(res.data);
            this.brandFormControl.setValue(res.data.name);
            this.customMessageService.ShowCustomMessage(res['statusMessage'], true);
          }
        })
      } else {
        !this.isServerSideBrandSearch && (this.brandResponse = this.allBrandsValues);
      }
    });
  }
  addNewCategory() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
        data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result.data) {
            let obj = {
                name: result.data,
                description: '',
                id: '',
                parentCategoryName: '',
                parentCategoryId: '',
                fullHierarchicalName: '',
                childCount: 0,
                active: true,
                _Paxcom_OrganizationId: this.orgId,
                header:'',
                footer:''
            }
            this.categoryService.postCategory(obj).subscribe(res => {
                if (res && res.data) {
                    this.categoriesResponse = [...this.categoriesResponse,{...res.data}];
                    this.allCategoriesValues = [...this.allCategoriesValues,{...res.data}];
                    this.productForm.get('productCategory').setValue(res.data);
                    this.categoryFormControl.setValue(res.data.name);
                    this.customMessageService.ShowCustomMessage(res['statusMessage'], true);
                }
            })
        }else if(result.isOpenAddCategoryBoolean){
          this.getAllCategories('')
       } else {
            !this.isServerSideBrandSearch && (this.categoriesResponse = this.allCategoriesValues);
        }
    });
}
  disableFormControlsForClonedProduct() {
    if (this.isClonedProduct) {
      this.productForm.get('name').disable();
      this.brandFormControl.disable();
      this.categoryFormControl.disable();
      this.productForm.get('brandMRP').disable();
      let globalUPCDetails = this.productForm.get('globalUPCDetails') as FormArray
      globalUPCDetails.disable();
      this.productForm.get('description').disable();
      this.productForm.get('returnable').disable();
    }
  }
}
