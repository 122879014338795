import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { currencySymbolPipe } from './currency-symbol.pipe';
import { SafePipe } from './safe.pipe';




@NgModule({
    declarations: [currencySymbolPipe,SafePipe],
    exports: [currencySymbolPipe,SafePipe],
    imports: [
        CommonModule
    ]
})
export class PipesModule { }