export const messages = {
    delete : 'Are you sure you want to delete?',
    deleteChannel:'Are you sure you want to delete channel?',
    cancel:'Are you sure you want to cancel?',
    remove:'Are you sure you want to remove?',
    removeLogo : 'Are you sure you want to remove this logo?',
    removeDefaultLogo : 'Default channel logo can not be removed.',
    approveProduct : 'Are you sure you want to approve this Product?',
    changeStatus : 'Are you sure you want to change status?',
    unsaved:'Are you sure you want to discard any unsaved changes?',
    selectImage : 'Please select a image',
    invalidBrand : 'Invalid brand information',
    invalidCategory : 'Invalid category information',
    selectRecord : 'Please select atleast one record',
    brandNameRequired : 'Name of the brand',
    orderShipped:'Are you sure you want to mark this order as Shipped?',
    orderDelivered:'Are you sure you want to mark this order as Delivered?',
    orderAccepted:'Are you sure you want to accept this order?',
    orderRejected:'Are you sure you want to reject this order?',
    orderPaid:'Are you sure you want to mark this order as Paid?',
    dateRangeValidation:'Please select the date range to download the report.',
    maxDateRangeValidation:'The maximum date range for report download is 1 year.',
    removeImage:'Are you sure you want to remove this image?',
    removePlugin:'Are you sure you want to uninstall?',
    variantAddMessage:'Please add some variants first',
    removeVariantValidation:'You cannot delete, the only variant type present. There needs to be at least one variant type selected.',

    pluginWarehouseAreaCodeMapping : 'Please enter complete details for location/area code mapping',
    pluginWarehouseDelhiveryNameMapping : 'Please enter complete details for location/delhivery name mapping',
    pluginWarehouseLocationMapping : 'Please enter complete details for location/shiprocket pickup zone id mapping',
    pluginChannelMapping : 'Please enter complete details for channel/shiprocket channel id mapping',
    duplicateWarehouse : 'One or more location were found duplicate in the list',
    duplicateChannel : 'One or more channel were found duplicate in the list',
    sameShippingProvider : 'Selected GDNs has different values for one or more Logistics or their SLAs are you sure you want to create manifest with the selected Items?',
    cancelWaybill : 'This will also remove the GDN from current manifest, are you sure you want to continue?',
    invalidGDNSelect : "For shiprocket please select individual GDN's to proceed",
    cancelPicklist : 'Deleting/Cancelling the picklist will not recreate the allocations and the allocated inventory moved to GDNs will be freed up.',
    acceptOrder : 'Are you sure you want to accept order(s)?',
    rejectOrder : 'Are you sure you want to reject order(s)?',
    cancelOrder : 'Are you sure you want to cancel order(s)?',
    cancelFulfillment : 'Are you sure you want to cancel fulfillment?',
    removeGDNFromLinkedManifest : 'Are you sure you want to remove this GDN from the manifest?',
    cancelManifest : 'Are you sure you want to cancel this manifest?',
    cancelGDN : 'If there is any allocated inventory that won’t be re-allocated automatically, you will have to manually do the allocation if required.',

    max_minCartQuantity:'Max. Cart quantity cannot be less than Min. cart quantity',
    folderNotSameDestination:'The new destination can not be under the same folder.',
    brandNotAdded:'Please link a Brand with the product before uploading any new image/video.',
    onlyDraftProductDelete : 'Draft Product can only be Deleted',
    emptyOrdersList : "Looks like you haven't made your choice yet. Add your favourite items from the menu.",
    addChannel:"Please add a Channel before linking Image/Video with the product.",
    selectAsset:"Please add a Channel before linking Image/Video with the product.",
    mrpValidation:"Selling price cannot be greater than Seller MRP",
    uploadFile:"Please upload file first.",
    noProductFound:"No Products Found",
    selectedProductsDeleted:"Selected product(s) deleted successfully",
    notAllProductsDeleted:"Something went wrong. Not all product(s) are deleted",

    autoCatalogForPosTitle : "Automatically add products to your shop from our catalog of 35,000+ products",
    requiredSellingPrice : "Selling price is missing for one or more products",
    negativeInventoryDisclaimer : 'Orders using auto-catalog flow can only be placed when linked location allows Negative Inventory for POS.',
    SingleOrderNumberSequenceForAllChannelsHelpText: "When selected, all channel order numbers will have a single sequence.",
    resetOrderSequenceTypeHelptext:"Order Sequence will reset based on the setting selected. Default value is Never.",

    selectBatch : 'Please select batch',
    activateLocation : 'Please activate the location to add zones under it',

    //No Result Messages
    searchNotFoundDescType : 'searchNotFound',
    noResultFoundDescType : 'noResultFound',
    notFoundTitle : 'No {} Found in the system.',
    searchNotFoundDesc : 'Search tip: Some term require an exact match. Try filtering or typing a different word or phrase or type the entire term',
    noResultFoundDesc : "Looks like we haven't got any {} right now. Start adding {} now! ",
    noCustomerDesc:"Looks like we haven’t got any customer. Share your store link on your social channels to get customers!",
    noReturnDesc:"Select the order from the order list to create a return!",
    noRecordFound:"No record found",
    noReviewFound:"Ask your customer to drop a review to start approving them!",
    noReturnFoundDesc:"Go to the orders page & select orders to create a return!",
    someThingWentWrong:"Something went wrong",
    imageNotSupported:"Image type is not supported",
    addComment:"Please add comment.",
    noItemsAdded:'No Items added yet.',

    downloadPendingDesc:"Looks like there is no pending download. Generate reports to start downloading",
    publicInvoiceMsg:"Kindly note that it is a system generated document and hence does not require any signature.",
    cameraPermissonDenied : 'Camera access denied, please provide permission first',
    openingCamera : 'Opening Camera',
    noCameraDeviceFound : 'No camera devices were found.',
    NOT_AUTHORISED : "You are not authorized to perform this action!",
    ZERO_SELLING_PRICE_CONFIRMATION:"Are you sure you want to continue selling product {} at 0 price?",
    settingsUpdated: "Settings updated successfully",
    postalCodeRequired: 'Location Zip code(s) are required',
    invalidPostalCodes: 'Zip code(s) {} are invalid',
    noNotificationMsg:"You have currently no Notification. We'll notify you when someting new arrives!",

    //shop msgs
    upiPaymentConfirmationMsg:'Please enter the payment transaction id (optional) after making the UPI payment and click confirm.',
    confirmationMailOrderSuccess:'You will receive a confirmation with order details shortly.',
    OTPSent:'OTP has been sent to {}',
    paymentMethodRequired : 'Payment Method is required.',
    maxCartQty:'Max. cart value for this product is {}.',
    phoneNoRequired:'Customer phone is required.',
    invalidPhoneNo:'Please enter valid phone no.',

    storeSelectionRequired:'Please select store.',
    searchStore : 'Search shops by name or scan the QR code and add to your favorites list.',
    noResultFound : 'Sorry! No result found Please check the spelling or try searching for something else.',
    shopDisabledTitle:'Uh Oh! Looks like you have not enabled the store.',
    shopDisabledBody:'To view the store you will have to enable the store. Please go to eStore settings in the Admin portal to enable the eStore & start accepting online orders.',
    sorryNoResultFound:'Sorry! No result found',
    noResultFoundMessage:'Please check the spelling or try searching for something else.'

}