import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import {  BehaviorSubject,Observable,Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants/global';
import { messages } from 'src/app/shared/Constants/messageResource';
import { environment } from 'src/environments/environment';
import { OrderStatusEnum} from '../../orders/enum/master-data.enum';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  //private _getStatusesUrl = 'Product/statuses';
  private _getAuthActivitiesUrl = 'auth-activities';
  private _getAllAplication = 'applications';
  private themeComponentsInfo = new BehaviorSubject<any>({});
  resetOrderTab$ = new Subject<any>();

  //-----------------Refactored Urls - 6/18/2021 --------------------------
  private _getStatusesUrl = 'Products/statuses';
  bussienssType:number = JSON.parse(sessionStorage.getItem('Shop_info')).businessTypeId;
  cafeBusinessId = environment.cafeBusinessId;
  constructor(
    private httpHelper: HttpHelperService) {

  }

  getStatuses(queryString = ""): Observable<any> {

    return this.httpHelper.getData(this._getStatusesUrl + "?" + queryString)
      .pipe(
        take(1),
        map(res => res.data)
      );

  }

  getAuthActivities(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getAuthActivitiesUrl + "?" + queryString);
  }

  getApplication() : Observable<any>{
    return this.httpHelper.getData(this._getAllAplication);
  }

  getOrganizationData(userName) : any{
    let organizationData = localStorage.getItem(AppConstants.ORGANIZATION_INFO);
    if(organizationData){
      let jsonOrgData = JSON.parse(decodeURIComponent(organizationData));
      if(jsonOrgData.userName)
      {
        // if(userName.toLowerCase() == jsonOrgData.userName.toLowerCase()){
        if(userName == jsonOrgData.userName.toLowerCase()){
          return jsonOrgData;
        }else{
          localStorage.removeItem(AppConstants.ORGANIZATION_INFO);
        }
      }
      else
      {
        return jsonOrgData;
      }
    }
    return null;
  }
  getAllWarehouse(queryString = ""): Observable<any> {
    let _urlWarehouse = "Warehouse";
    return this.httpHelper.getData(_urlWarehouse + "?" + queryString) as Observable<any>;
  }
  generateInvoice(data) : Observable<any>
  {
    let url="generate-invoice"
    return this.httpHelper.putData(data,url);
  }

  removeLocalStorageInOutItems(){
    var arr = [];
    for (var i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).substring(0,6).toLowerCase() == 'inout_') {
            arr.push(localStorage.key(i));
        }
    }
    for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
    }
  }

  getStoreAddress() : Observable<any>{
    let url = "orgAddress"
    return this.httpHelper.getData(url);
  }
  sendComponentData(data){
    this.themeComponentsInfo.next(data);
  }

  get getComponentData(){
   return this.themeComponentsInfo.asObservable();
  }

  changeStatusLabel(labelName) {
    if (labelName.includes('Shipped') && this.cafeBusinessId == this.bussienssType) {
      return labelName.replace('Shipped', 'Prepared')
    } else {
      return labelName
    }
  }
  changeStatusIcon(orderStatus){
    if(orderStatus ==  OrderStatusEnum.Shipped ){
      if(this.cafeBusinessId == this.bussienssType){return 'uil-crockery'}
      else{return 'uil-truck'}
    }
  }

  //reset order tab when navigate from notification tab and user is on order list page
  resetOrderTabonNavigate(){
    this.resetOrderTab$.next();
  }

  get resetTabEvent(){
    return this.resetOrderTab$.asObservable();
  }

}
