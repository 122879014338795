export enum RouterLinksEnum {
	INOUT="InOut",
	CATEGORY = "categories",
    CATEGORY_LIST = "categories",
	CATEGORY_LIST_SLASH = "/categories",
	CATEGORY_LIST_DOUBLE_SLASH = "/categories/",
    CATEGORY_CREATE = "categories/new",
	CATEGORY_CREATE_SLASH = "/categories/new",
    CATEGORY_EDIT = "edit",
	CATEGORY_VIEW = "",
	SUB_CATEGORY="sub-categories",
	SUB_CATEGORY_SLASH="/sub-categories",
	
	CATEGORY_ASSET_SLASH="asset/",
	CATEGORY_ASSET="asset",
	CATEGORY_ASSET_LIST = "asset",
    CATEGORY_ASSET_CREATE = "asset/new",
    CATEGORY_ASSET_EDIT = "edit",
	CATEGORY_ASSET_VIEW = "",
	SUB_CATEGORY_ASSET="sub-asset",
	SUB_CATEGORY_ASSET_SLASH ="/sub-asset",

	PRODUCTS = "products/manage-products",
	PRODUCTS_LIST = "products/manage-products",
	PRODUCTS_CREATE = "products/new",
	PRODUCTS_EDIT = "edit",
	PRODUCTS_COPY = "copy",
    PRODUCTS_VIEW = "view",	

	BRANDS = "brands",
	BRANDS_LIST = "products/brands",
	BRANDS_CREATE = "brands/new",
	BRANDS_EDIT = "edit",
	BRANDS_VIEW = "",

	VARIANTS = "variants",
	VARIANTS_LIST = "variants",
	VARIANTS_CREATE = "variants/new",
	VARIANTS_EDIT = "edit",
    VARIANTS_VIEW = "",

	CATALOUGE_SELECTED_PRODUCTS = "catalogue/selected-products",
	CATALOUGE = "catalogue",

	CATEGORY_URL="/category/",

	MANAGE_PRODUCTS="manage-products/",
	MANAGE_PRODUCTS_NEW="manage-products/new",
	MANAGE_PRODUCTS_NO_SLASH = "manage-products",

	PRODUCT_DASHBOARD="product-dashboard",

	RESOURCES="resources",
	PRODUCT_SETTINGS = "product-settings",
	
    PRODUCT_CHANNELS ='/product-channels',	
	PRODUCT_CHANNELS_WITH_SLASH ='/product-channels/',
    ADMIN_EDIT ="/edit",
	ADMIN_VIEW ="view/",
	ADMIN_NEW ="/new",
	ADMIN = '/admin/products',
	ADMIN_SLASH = '/admin/products/',
	VARIANTS_SLASH = "variants/",
    
}




