import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ORGANIZATION_INFO } from '../../shared/constants/global';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

 // private _getStatusesUrl = 'Product/statuses';
  //--------Refactored URLs 6/22/2021 ---------------
  private _getAuthActivitiesUrl = 'auth-activities';
  private _getAllAplication = 'applications';
  private themeComponentsInfo = new BehaviorSubject<any>({});

  //--------Refactored URLs 6/21/2021 ---------------
  private _getStatusesUrl = 'Products/statuses';
  constructor(
    private httpHelper: HttpHelperService) {

  }

  getStatuses(queryString = ""): Observable<any> {

    return this.httpHelper.getData(this._getStatusesUrl + "?" + queryString)
      .pipe(
        take(1),
        map(res => res.data)
      );

  }

  getOrganizationData(userName) : any{
    let organizationData = localStorage.getItem(ORGANIZATION_INFO);
    if(organizationData){
      let jsonOrgData = JSON.parse(decodeURIComponent(organizationData));
      if(jsonOrgData.userName)
      {
        // if(userName.toLowerCase() == jsonOrgData.userName.toLowerCase()){
        if(userName == jsonOrgData.userName.toLowerCase()){
          return organizationData;
        }else{
          localStorage.removeItem(ORGANIZATION_INFO);
        }
      }
      else
      {
        return organizationData;
      }
    }
    return null;
  }

  getAuthActivities(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getAuthActivitiesUrl + "?" + queryString);
  }

  getApplication() : Observable<any>{
    return this.httpHelper.getData(this._getAllAplication);
  }
  getChannels(querySyring = "") : Observable<any>{
    let url = "Channels"
    return this.httpHelper.getData(url+querySyring);
  }
  getHelpGuide(querySyring = ""): Observable<any> {
    let url = 'HelpGuide';
    return this.httpHelper.getData(url + querySyring);
  }
  getHelpGuideById(id,querySyring = ""): Observable<any> {
    let url = 'HelpGuide/'+id;
    return this.httpHelper.getData(url + querySyring);
  }
  saveHelpGuide(data:any) : Observable<any>
  {
    let url="HelpGuide"
    return this.httpHelper.putData(data,url);
  }
  updateHelpGuide(data) :Observable<any> {
    let url="HelpGuide";
    return this.httpHelper.postData(data,url);

  }
  deletHelpGuideById(id)
  {
    let url="HelpGuide/"+id
     return this.httpHelper.delete(url);
  }
  updateMultipleAsset(data:any) : Observable<any>
  {
    //let url="Asset/bulk-update"

    //--------Refactored URLs 6/21/2021 ---------------
    let url="Assets/bulk-update"
    return this.httpHelper.putData(data,url);
  }


  removeLocalStorageInOutItems(){
    
    var arr = [];
    for (var i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).substring(0,6).toLowerCase() == 'inout_') {
            arr.push(localStorage.key(i));
        }
    }
    for (var i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
    }
  }

  filterSpecialCharacters(text: string){
    if(text){
      return text.replace(/[^a-zA-Z0-9 ]/g, ' ');
    } 
    return '';
  }

  sendComponentData(data){
    this.themeComponentsInfo.next(data);
  }

  get getComponentData(){
   return this.themeComponentsInfo.asObservable();
  }
  
  getWrapedText(text, maxLength) {
    if (text) {
      if (text.length <= maxLength) {
        return text;
      }
      else {
        return text.substring(0, maxLength - 3) + "...";
      }
    }
  }
}
