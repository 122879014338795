import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styles: ['.demo-card{margin-top:50px;width:97%;padding-bottom:15px;justify-content:left;}','.mdc-typography--headline5{padding-left:20px; padding-right:20px;}','.mdc-typography--body2{padding-left:20px; padding-right:20px;}','mdc-typography--headline4{padding-top:15px;padding-bottom:25px;}','p{margin-top:10px; margin-bottom:0px;}','.heading{padding-bottom:15px;}']
})
export class PrivacypolicyComponent implements OnInit {
imageCdn:string = environment.cdnImageUrl;
  constructor() {   
  }

  ngOnInit() {
  }

}
