import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatStepper, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import { CategoriesService } from 'src/app/core/common_services/master/categories/categories.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { CategoryModel } from 'src/app/shared/models/category/category';
import { PIMEntityType } from '../../ENUM/PIMEntityTypeEnum';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { messages } from 'src/app/shared/Constants/messageResource';

@Component({
  selector: 'app-clone-master-categories.dialog',
  templateUrl: './clone-master-categories.dialog.component.html',
  styleUrls: ['./clone-master-categories.dialog.component.scss']
})
export class CloneMatserCategoriesDialogComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;
  masterCategories: Array<CategoryModel> = [];
  showCategories: boolean = true;
  dataSource = new MatTableDataSource<CategoryModel>();
  displayedColumns: string[] = ['select', 'detail'];
  selection = new SelectionModel<any>(true, []);
  tokenDatasource: Array<CategoryModel>
  token: string;
  hideButton: boolean = true;
  searchText = new FormControl('');
  @Input() entityType: PIMEntityType;
  showDiscountsColumn: boolean = true;
  searchInput = new FormControl();
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  noDataFound:boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  @ViewChild('categorySearchInput', { static: true }) categorySearchInput: ElementRef;

  constructor(public dialogRef: MatDialogRef<CloneMatserCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private masterCategoryService: CategoriesService, private customMessageService: CustomMessageService
    , private detectDevice: DeviceDetect) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getCategories();

    this.searchInput.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      this.getCategories(val);
    });
    this.phoneScreen = this.detectDevice.currentDevice().phoneScreen;
    this.tabletScreen = this.detectDevice.currentDevice().tabletScreen;
    this.desktopScreen = this.detectDevice.currentDevice().desktopScreen;
    this.setSearchNotFoundVariables();
  }

  closeDialog(value = 0): void {
    this.dialogRef.close(value);
  }

  validationMessage(message) {
    this.customMessageService.ShowCustomMessage(message, false);
  }

  cloneCategories() {
    if (this.selection.selected.length == 0) {
      this.validationMessage('Please choose atleast 1 product category.')
      return
    }
    let data = [];
    this.selection.selected.forEach(element => {
      data.push(element.id);
    });
    this.masterCategoryService.cloneMasterCategories(data).subscribe(res => {
      this.customMessageService.ShowCustomMessage(res['statusMessage'], true);
      this.closeDialog(1); // this 1 will subscribe after popup closed using of this 1 is if subscribe result == 1 then
                           // category list api calling, other wise every time popup close category list api called
    });   
  }


  getCategories(searchText = '') {
    let queryString = ''
    if (searchText != "") {
      queryString += "?searchText=" + searchText;
    }
    this.masterCategoryService.getMasterCategories(queryString).subscribe(res => {
      if (res['data'].length) {
        this.dataSource.data = res['data'].sort((a, b) => (a.name < b.name ? -1 : 1));
        this.masterCategories = res['data'].sort((a, b) => (a.name < b.name ? -1 : 1));
        if (res['data'].paging) {
          this.token = res['data'].paging.continuationToken;
        }
        this.noDataFound = false;
        this.showHideLoadMoreButton();
        this.tokenDatasource = res['data'].data;
        this.updateSelection();
      }
      else {
        this.hideButton = true;
        this.dataSource.data = [];
        this.noDataFound = true;
      }
    })
  }

  updateSelection(){
    this.dataSource.data.forEach(row => {
      this.selection.selected.forEach(select=>{
        if(row.id==select.id){
          row.isSelected=true;
        }
      })
    })
  }
  showHideLoadMoreButton() {
    if (this.token) {
      this.hideButton = false;
    }
    else {
      this.hideButton = true;
    }
  }
  getMoreData() {
    if (this.token) {
      this.hideButton = false;
      this.tokenDatasouce(this.token)
    }
  }
  tokenDatasouce(token) {
    if (token !== null || token !== undefined) {
      var requestParameter = "?ContinuationToken=" + encodeURIComponent(token);
      this.masterCategoryService.getMasterCategories(requestParameter).subscribe(res => {
        if (res['data']) {
          for (let i = 0; i < res['data'].length; i++) {
            this.tokenDatasource.push(res['data'][i]);
          }
          if (res['data'].paging) {
            this.token = res['data'].paging.continuationToken;
          }
          this.showHideLoadMoreButton();
          this.dataSource.data = this.tokenDatasource;
        }
      });
    }
  }


  filteredCategories(value: string) {  // function will return filtered Categories baesd upon user input
    let filter = value.toLowerCase();
    return this.masterCategories.filter(x => x.fullHierarchicalName.toLowerCase().includes(filter));
  }
  onKey(value) {
    // this.selection.selected.length
    // this.dataSource.data = this.filteredCategories(value);
  }

  onCheckBoxClicked(event,row){
    if (event.checked) {
      row.isSelected = true;
      this.selection.selected.push(row)
    }
    else {
      row.isSelected = false;
      for (let i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].id == row.id) {
          this.selection.selected.splice(i, 1)
          break;
        }
      }
    }
  }
  
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle="Categories";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }

  rowSelected(element)
  {
    if(element.isSelected==false)
    {
      element.isSelected=true;
      this.selection.selected.push(element)
    }
    else
    {
      element.isSelected=false;
      for (let i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i].id == element.id) {
          this.selection.selected.splice(i, 1)
          break;
        }
      }

  }
}
}
