import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollingToErrorsService } from '../../../../../../../../OmniChannel/src/app/core/Common/scrollingToErrors.service';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { BrandsService } from '../../../services/Brands/brands.service';
import { HttpHelperService } from '../../../services/Common/http-helper.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { NEWBRANDNOTE } from '../../../shared/constants/global';

@Component({
  selector: 'app-add-new-brand-default',
  templateUrl: './add-new-brand-default.component.html',
  styleUrls: ['./add-new-brand-default.component.scss']
})
export class AddNewBrandDefaultComponent implements OnInit {
  newBrandNote = NEWBRANDNOTE;
  newBrand: string;
  editBrand: {};
  brandId: number;
  isEditMode: boolean = false;
  orgId:string;  
  orgName:any;
  appLogoUrl:any;  
  jsonOrgData: string;  
  organizationData:Array<any>;
  RouterLinksEnum:any;
  brandForm: FormGroup;
  buttonName: string;
  titlename: string;
  titleEnum: typeof titlename;
  addedAllUser = [];
  allSelectedUser = [];
  allUser: { id: number; name: string; }[];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allUserList: { id: number; name: string; }[];
  userCSVsCtrl = new FormControl();
  // filteredUser: Observable<string[]>;
  filteredUser: any = [];
  selectedUserList: any= [];
  allFruits: string[] = [];
  brandNameMaxLength: number = 50;
  showHelpIcon : boolean = false;
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  isFormSubmit: boolean;
  approvers: any = [];
  allSelectedTags = [];
  brandName: any;
  featureIds: typeof HelpFeatureIdsEnum;
  disableActionsButtonsWhileAPICall: boolean = false;
  saveAndContinue: boolean;
  actionName: string = "Save";
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor( public httpClient: HttpClient, public httpHelper:HttpHelperService, private route: ActivatedRoute,
     private router: Router, public dialog: MatDialog, public dataService: BrandsService,private formBuilder: FormBuilder,
     private CustomMessage: CustomMessageService,private scrollingToErrorsService:ScrollingToErrorsService,
     private deviceDetect : DeviceDetect,
     private chatDialogService:ChatDialogService,
     private helpGuideService:HelpGuideService, 
     private historyDialogService:HistoryDialogService) { 
      this.getCurrentDevice();
      this.userCSVsCtrl.valueChanges.subscribe(val => {
 
        if (val !== null && val !== undefined && val !== '') {
          this.getAllProductTags(val);
        }
      });
     }  

  addNewBrand(formValue) {
    this.dataService.postBrand(formValue).subscribe(res => {
      let message = res['statusMessage'];
      this.CustomMessage.ShowCustomMessage(message, true);      
      this.disableActionsButtonsWhileAPICall =  false;
          if (this.saveAndContinue == false) {
            this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.BRANDS]);
          }
          else
          {
            this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
            this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.BRANDS_CREATE])); 
          }
        //   if (this.buttonName === "saveContinue") {
        //     this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() =>
        //     this.router.navigate(['admin/' + RouterLinksEnum.BRANDS_CREATE]));
        // }
    },err=>{
      this.disableActionsButtonsWhileAPICall =  false;
    })

  }
  
  updateBrand(formValue) {
    this.dataService.editBrand(formValue).subscribe(res => {
      let message = res['statusMessage'];
      this.CustomMessage.ShowCustomMessage(message, true);
      this.disableActionsButtonsWhileAPICall =  false;
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.BRANDS]);
    },err=>{
      this.disableActionsButtonsWhileAPICall =  false;
    })
  }

  cancell() {
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.BRANDS]);
  }

  onFormSubmit(): void {
    if(this.brandForm.invalid){
      this.scrollingToErrorsService.scrollToError();
return;
    }
    const approvarFormArray = <FormArray>this.brandForm.get('approvers'); 
      
    if(this.selectedUserList)
    {
      for(let i=0;i<this.selectedUserList.length;i++)
      {
        approvarFormArray.push(this.formBuilder.control(this.selectedUserList[i]))
      } 
    }
    this.buttonName = document.activeElement.getAttribute("name");
    let brandFormValue = this.brandForm.value;
    this.disableActionsButtonsWhileAPICall = true;
    if(this.brandId)
    {
      //  if (this.buttonName === "update") {
        brandFormValue.id = this.brandId;
        this.updateBrand(brandFormValue);
      // }
    }
    else
    {
      brandFormValue.id = 0;
      // if (this.buttonName === "save") {
      //   this.addNewBrand(brandFormValue);
      // }
      // else if (this.buttonName === "saveContinue") {
      //   this.addNewBrand(brandFormValue);
      // }
      this.addNewBrand(brandFormValue);
    }


  }

  ngOnInit() {  
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum=titlename;  
    this.titlename=this.titleEnum.addBrand;
    this.RouterLinksEnum = RouterLinksEnum;
    this.organizationData=[];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId=this.organizationData[0].id;

    this.brandForm = this.formBuilder.group({
      id:[''],
      name: ['', [Validators.required,Validators.maxLength(this.brandNameMaxLength)]],
      active:[true],
      approvers:this.formBuilder.array([]),
      _Paxcom_OrganizationId:[this.orgId]

    })

    this.brandId = this.route.snapshot.params['id'];
      if(this.brandId){
        this.actionName = "Update";
        this.isEditMode = true;
        this.titlename=this.titleEnum.updateBrand;
        if (this.brandId !== null || this.brandId !== undefined) {
            this.dataService.getBrandbyBrandId(this.brandId+"?organizationId="+this.orgId).subscribe(res => 
              {
                if(res.data)
                {
                  this.brandName = res.data.name;
                  let data=res.data;
                  if(res.data.approvers)
                  {
                    this.selectedUserList = res.data.approvers;
                  }
                    if(res.data.approvers===null){
                      data.approvers=[];
                    }
                    this.brandForm.patchValue(data)

                  
                }
                //this.brandForm.setValue({ brand: res.data.name});
                
              }, err => { this.CustomMessage.ShowCustomMessage(err.error.message, false) });
          }
      }

      else {
        this.isEditMode = false;
        this.showHelpIcon = true;
      }

  }
  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        // this.selectedUserList.push(value.trim());
        // let tag = value.trim();
        // this.allSelectedTags.push(tag);
        // this.brandForm.get("approvers").setValue(this.allSelectedTags);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
      
      this.userCSVsCtrl.setValue(null);
    }
  }

  remove(fruit: string): void {
    const index = this.selectedUserList.indexOf(fruit);

    if (index >= 0) {
      this.selectedUserList.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedUserList.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    //this.brandForm.get('approvers').setValue(JSON.stringify(this.selectedUserList).replace(/[\[\]']+/g,'').replace(/['"]+/g, ''));
     this.userCSVsCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  getAllProductTags(val) {

     this.dataService.getBrandApprovers(val).subscribe(res=>{
      this.filteredUser = res.data
     })


    // this.filteredUser = this.usersListService.getUsersList(val).pipe(
    //   map(res => res.data),
    //   share()  // add share to stop API call mutiple times on load
    // );
    // return this.filteredUser;
  }
  openChat()
  {
    this.chatDialogService.openChatDialog(PIMEntityType.Brand,this.brandId,this.brandName);  
  }
  openHelpDialog(featureId)
    {
      this.helpGuideService.openHelpGuideDialog(featureId)
    }
    openHistory()
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Brand,this.brandId,this.brandName);  
  }
  onSaveAndRedirect()
  {
      this.saveAndContinue = false;   
  }
  onSaveAndContinue()
  {
      this.saveAndContinue = true;   
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }

}

