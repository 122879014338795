import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private _httpHelper:HttpHelperService) { }

  getUersInfo() : Observable<any>
  {
    let url ='whoami';
    return this._httpHelper.getData(url);
  }
}
