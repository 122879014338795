import { Component, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';

@Component({
  template: `
    <mdc-dialog>
      <mdc-dialog-container>
        <mdc-dialog-surface>
          <mdc-dialog-title>{{message}}</mdc-dialog-title>
          <mdc-dialog-actions>
            <button mdcDialogButton mdcDialogAction="Ok" style="">Ok</button>
          
          </mdc-dialog-actions>
        </mdc-dialog-surface>
      </mdc-dialog-container>
    </mdc-dialog>
    `,
})
export class SuccessMessageComponent {
  message: string = '';
  constructor(public dialogRef: MdcDialogRef<SuccessMessageComponent>, @Inject(MDC_DIALOG_DATA) data) {
    this.message = data.message;
  }
}
