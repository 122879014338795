import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-warning.dialog',
  templateUrl: '../../dialogs/warning/warning.dialog.html',
  styleUrls: ['../../dialogs/warning/warning.dialog.css']
})
export class WarningDialogComponent {

  constructor(public dialogRef: MatDialogRef<WarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    //this.dataService.deleteIssue(this.data.id);
  }
    
}
