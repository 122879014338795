export enum OrderStatusEnum {
    Draft = 1,
    Received,
    Accepted,
    Rejected,
    Shipped,
    'Partially Shipped',
    Processing,
    Closed,
    Cancelled,
    Returned,
    'Fulfillment Processing',
    'Fulfillment Rejected',
    'Fulfillment Accepted',
    'Fulfillment Partially Accepted',
    'Fulfillment Partially Rejected',
    'Partially Accepted',
    Delivered,
    'Partially Delivered',
    'Partially Returned',
    'Fulfillment Requested',
    'Fulfillment Partially Requested'
}


export const pendingTabOrderStatus = [OrderStatusEnum.Received,OrderStatusEnum["Fulfillment Requested"],OrderStatusEnum["Fulfillment Partially Requested"],OrderStatusEnum["Fulfillment Partially Accepted"],OrderStatusEnum["Fulfillment Accepted"],OrderStatusEnum["Fulfillment Rejected"],OrderStatusEnum["Fulfillment Partially Rejected"]];

export const processingTabOrderStatus = [OrderStatusEnum.Accepted,OrderStatusEnum.Processing,OrderStatusEnum.Shipped,OrderStatusEnum["Partially Shipped"],OrderStatusEnum["Partially Delivered"]];