import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-add-new-product',
  templateUrl: './add-new-product.component.html',
  styleUrls: ['./add-new-product.component.scss']
})
export class AddNewProductComponent implements OnInit {
  themeComponentName: string = '';
  themeSubscription: Subscription;
  productName: string;
  copyId: string;
  constructor(private commonService: CommonService,private router:Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      this.productName = navigation.extras.state ? navigation.extras.state.productName : null;
      this.copyId = navigation.extras.state ? navigation.extras.state.copyId : null;
    }
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['createProduct'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }
}
