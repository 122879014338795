import { Component, OnInit, ViewChild, ElementRef, HostListener, PLATFORM_ID, Inject, ChangeDetectorRef, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialog, MatTable, MatAutocomplete, MatChipInputEvent, MatTabChangeEvent, MatTableDataSource } from '@angular/material';
import { ORGANIZATION_INFO } from '../../../../shared/constants/global';
import { BrandsService } from '../../../../services/Brands/brands.service';
import { CreateBrand } from '../../../../models/Brands/create-brand.model';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, filter, share, debounceTime } from 'rxjs/operators';
import { CommonService } from '../../../../services/Common/common.service';
import { Status } from '../../../../models/common/statuses';
import { PostImage } from '../../../../models/images/image';
import { ImageService } from '../../../../services/images/image.service';
import { VariantsService } from '../../../../services/Variants/variants.service';
import { Variant, ProductReplacementShippingCondition, ProductDimensions, PackageDimensions } from '../../../../models/product/product-view-model';
import { VariantDTO, CombinationVariant, VariantWithOptions } from '../../../../models/Variants/VariantViewModel';
import { ProductCategory, ProductCondition, Image, ProductImage, VariantType, Option, ProductVariant, Tag, ProductShelfLifeUnit } from '../../../../models/product/product-post-dto';
import { ProductService } from '../../../../services/Product/product.service';
import { CategoriesServiceService } from '../../../../services/categories-service.service';
import { ProductListService } from '../../../../services/Product/product-list.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ImageDialogComponent } from '../../../../dialogs/image-dialog/image-dialog.component';
import { IMAGE_NOT_SUPPORTED, UNIT_REQUIRED } from '../../../../constants/messages';
import { RouterLinksEnum } from '../../../../shared/router-links/router-links.enum';
import { ScrollTop } from '../../../../shared/scroll';
import { DOCUMENT } from '@angular/common';
import { PermissionsEnum } from '../../../../authorization/enum/permissions.enum';
import { DeleteDialogComponent } from '../../../../dialogs/delete/delete.dialog.component';
import { titlename } from '../../../../ENUM/titlename';
import { DefaultDropDownEnum } from '../../../../ENUM/enums';
import { InventoryMethodEnum } from '../../../../ENUM/inventoryMethodEnum';
import { AddAssetDialogComponent } from '../../../../dialogs/addAsset/add-asset.dialog.component';
import { AssetService } from '../../../../services/Assets/asset.service';
import { ApprovalStatusEnum } from '../../../../ENUM/approvalStatusEnum';
import { AddApprovalCommnetDialogComponent } from '../../../../dialogs/approval-comment/add-approval-commnent.dialog.component';
import { HttpHelperService } from '../../../../services/Common/http-helper.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileHandle } from '../../../../shared/directives/dragDrop.directive';
import { BlobStorageService } from '../../../../azure-storage/blob-storage.service';
import { SASTokenService } from '../../../../azure-storage/sastoken.service';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { PIMEntityType } from '../../../../ENUM/PIMEntityTypeEnum';
import { ChatDialogService } from '../../../../shared/chatDialogOpenService/chatDialog';
import { ChannelComponent } from '../../../../shared/selectChannel/channel.dialog.component';
import { HelpFeatureIdsEnum } from '../../../../ENUM/helpFeatureIds';
import { HistoryDialogService } from '../../../../shared/histotyDialogOpenService/historyDialog';
import { SettingService } from '../../../../services/Settings/setting.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import MY_FORMATS from '../../../../services/Common/customDateService';
import { SearchproductModel } from '../../../../models/product/searchproductmodel';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ScrollingToErrorsService } from 'src/app/core/Common/scrollingToErrors.service';
import { EnumHelperService } from '../../../../services/Common/enumHelperService';
import { GlobalUPCTypeEnum } from '../../../../ENUM/globalUPCTypeEnum';
import { ProductStatusEnum } from '../../../../ENUM/productstatusenum';
import { pageSize } from '../../../../constants/variables';
import { BarcodeDialogComponent } from '../../../../dialogs/barcode-dialog/barcode-dialog.component';
import { CategoriesService } from 'src/app/core/common_services/master/categories/categories.service';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { validationPattern } from 'src/app/shared/Constants/validationPattern';
import { maximumLength } from 'src/app/shared/Constants/maximumLength';
import {
  INVALIDHSNCODEFORMATMESSAGE, MAXVALUEFORPRODUCTLENGTHMESSAGE,
  MAXVALUEFORPRODUCTBREADTMESSAGE, MAXVALUEFORPRODUCTHEIGHTMESSAGE,
  MAXVALUEFORPRODUCTWEIGHTMESSAGE, MAXIMUMVALUEFORPACKAGELENGTHMESSAGE,
  MAXIMUMVALUEFORPACKAGEBREADTHMESSAGE, MAXIMUMVALUEFORPACKAGEHEIGHTMESSAGE,
  MAXIMUMVALUEFORPACKAGEWEIGHTMESSAGE, MAXLENGTHGUPCVALIDATIONMESSAGE,
  MAXIMUMALLOWEDPRODUCTWEIGHT, MAXIMUMALLOWEDPRODUCTLENGTH,
  MAXIMUMALLOWEDPRODUCWIDTH, MAXIMUMALLOWEDPRODUCTHEIGHT,
  MAXIMUMALLOWEDPACKAGEWEIGHT, MAXIMUMALLOWEDPACKAGEHEIGHT,
  MAXIMUMALLOWEDPACKAGELENGTH, MAXIMUMALLOWEDPACKAGEWIDTH
} from '../../../../shared/constants/global';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { messages } from 'src/app/shared/Constants/messageResource';
import { AddBrandDialogComponent } from 'src/app/modules/pim/dialogs/add-brand/add-brand.dialog.component';
import { HelpGuideService } from 'src/app/modules/pim/services/helpGuide/helpGuideService';
import { AddCategoryDialogComponent } from 'src/app/modules/pim/dialogs/add-category/add-category.dialog.component';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { AddChannelWiseProductDataDialogComponent } from 'src/app/modules/pim/dialogs/add-channelwise-product-data/add-channelwise-product-data.dialog.component';
@Component({
  selector: 'app-create-new-product-default',
  templateUrl: './create-new-product-default.component.html',
  styleUrls: ['./create-new-product-default.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CreateNewProductDefaultComponent implements OnInit {
  variantAddMessage:string=messages.variantAddMessage;
  showChannelLevelProductTitle: boolean;
  UploadProgress: Observable<number>;
  productVariationHelpText = " It helps users to combine multiple products of different attributes. For e.g. Color ,size,storage.";
  batchHelpText = "If a product maintains batch then this checkbox must be enabled and batch will be mandatory in WMS transactions.";
  serializedHelpText = "If a product is serialized then this checkbox must be enabled and user can not enter more than 1 quantity  in Serial No Field in WMS Transactions and this field will be mandatory.";
  DefaultDropDownEnum: String;
  DefaultDropDwnEnum: typeof DefaultDropDownEnum;
  brandControl = new FormControl('');
  selectedTabIndex: number = 0;
  variantcheck: boolean;
  isPerishable: boolean = false;
  maintainBatch: boolean = false;
  columnsToDisplay = ['channel', 'asset_thumbnail', 'title', 'displayOrder', 'edit_icon'];
  displayedColumnsForAPlusContent: string[] = ['logo', 'imageData', 'version', 'channel', 'download_icon'];
  displayedColumns = ['channel', 'asset_thumbnail', 'title', 'displayOrder', 'edit_icon'];
  dataSource = [];
  finalArrlenght: number;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild('dragTable', { static: true }) dragTable: MatTable<any>;
  public isButtonVisible = true;
  isSwitchOn: boolean = false;
  public message: string;
  public imagePath;
  imgURL: any;
  createForm: FormGroup;
  productForm: FormGroup;
  imageUploadForm: FormGroup;
  brands: CreateBrand[] = [];
  allProductStatus: string[] = ["Draft", "Completed", "Active", "Inactive", "Discontinued"];
  allProductStatusDropdown = [
    { id: 1, value: this.allProductStatus[0] },
    { id: 2, value: this.allProductStatus[1] },
    { id: 3, value: this.allProductStatus[2] },
    { id: 4, value: this.allProductStatus[3] },
    { id: 5, value: this.allProductStatus[4] }
  ];
  selectedProductStatus;
  itemConditions: string[] = ["New", "Refurbished"];
  itemConditoinDropDown: ProductCondition[] = [{ id: '1', name: this.itemConditions[0] }, { id: '2', name: this.itemConditions[1] }];
  statuses$: Observable<Status[]>;
  statuses: Status[];
  postImages: PostImage[];
  imageEdit: boolean = false;
  imageControlEditable: boolean[] = [];
  allVariants: VariantDTO[] = [];
  allActiveVariants: VariantDTO[] = [];
  allVariants$: Observable<VariantDTO[]>;
  variantsMapToId = [];
  allCategories: ProductCategory[] = [];
  productId: string = '-1';
  productShelfLifeUnitDropdown: ProductShelfLifeUnit[] = [
    { id: '1', name: 'Days' },
    { id: '2', name: 'Months' },
  ];
  selectedProductShelfLifeUnit: ProductShelfLifeUnit;
  selectedCategory: ProductCategory;
  selectedBrand;
  returnReplacementConditoinDropdown: ProductReplacementShippingCondition[] = [
    { id: '1', name: "Returnable" },
    { id: '2', name: "Replaceable" },
    { id: '3', name: "Non returnable/replaceable" }
  ];
  itemsProductVariations: FormArray;
  allProducts$: any = new ReplaySubject(1);
  allProducts = [];
  allProductsSearchList: any;
  itemsProductVariations_copy: FormArray;
  disabledImageActions: boolean = false;
  jsonOrgData: string;
  orgId: any;
  selectedVariantTypes = [];
  selectedProductByIndex = [];
  categoryControler = new FormControl('');
  productConditionControler = new FormControl();
  productReplacementShippingConditionControler = new FormControl();
  productShelfLifeUnitControler = new FormControl();
  isBasicFormSubmitted = false;
  isChannelVariantFormSubmitted = false;
  @Input() copyId: string = null;
  viewId: string = null;
  allTags = [];
  addedAllTags = [];
  allSelectedTags = [];
  allTags$;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  tagCtrl = new FormControl();
  statusControler = new FormControl();
  fruits: string[] = [];
  filteredFruits: Observable<string[]>;
  copiedImages = [];
  toggleStatus: boolean = false;
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagauto', { static: false }) matAutocomplete: MatAutocomplete;
  @HostListener('window:scroll')
  numberPattern = "^[0-9]{0,200}[.]?[0-9]{0,200}$";
  onlyNumberPattern = "^[0-9]*$";
  // HSN code pattern which is not allow special characters
  hsnCodePattern = "^[a-zA-Z0-9_-]*$";
  RouterLinksEnum: typeof RouterLinksEnum;
  tab: any;
  scrollTop: ScrollTop;
  titlename: string;
  @Input() productName: any = "";
  name: any;
  // TODO:000 Refactor global variables
  copy_productId: string;
  copy_productName: string;
  copy_searchName: string;
  copy_sku: string;
  copy_status: string;
  PermissionsEnum: typeof PermissionsEnum;
  isbuttonVisible: boolean = true;
  titleEnum: typeof titlename;
  unitOfMeasureResponse: any;
  currentTab: any;
  unitMandatoryMessage: boolean = false;
  isBasicFormSuccess: boolean = false;
  disabledTag: boolean = false;
  brandResponse: any;
  categoriesResponse: any;
  inventoryMethodEnum: { id: number; name: string; }[];
  isReturnable: boolean = false;
  disabledSKU: boolean = false;
  aPlusContentTitle: string;
  aPlusContentChannel: string = "";
  aPlusContentVersion: string;
  aPlusContentDescription: string;
  channelData: any = [];
  assetData: any = [];
  assetTitle: string;
  assetDisplayOrder: string;
  assetImage: any;
  assetId: any;
  public productChannelDataSource = new MatTableDataSource<any>();
  productChannelTableInfo=[];
  channelWisedisplayedColumns :any=['channelName','code','productName','productDescription','productBulletPoints','actions']
  // channelWisedisplayedColumns :any=['channelName','code','actions']
  
  public assetDataSource = new MatTableDataSource<any>();
  public aPlusDataSource = new MatTableDataSource<any>();
  assettableInfo: any = [];
  selectedAssetIndex: any;
  assetListTitle: any = "";
  assetListDisplayOrder: any = "";
  aPlusExtension: any;
  aPlusFileName: any;
  aPlusFileSizeInBytes: any;
  aPlusFileType: any;
  aPlusFileUrl: any;
  aPlusLargeThumbnailUrl: any;
  aPlusMediumThumbnailUrl: any;
  aPlusSmallThumbnailUrl: any;
  aPlusContentImage: any;
  aPlustableInfo: any = [];
  aPlusListTitle: any;
  aPlusListVersion: any;
  aPlusListDescription: any;
  seletedAPlusIndex: any;
  isAPlusImageVisible: boolean = true
  aPlusImage: any;
  aPlusContentChannelName: any;
  assetImageByte: any;
  previewTabIndex: number;
  aPlusListChannel: any = "";
  aPlusListChannelName: any;
  closeStatus: any = false;
  assetSwatchImage: boolean = false;
  assetChannelId: any = "";
  assetChannelName: any;
  assetListChannelId: any = "";
  assetListChannelName: any;
  aPlusListExtension: any;
  aPlusListFileName: any;
  aPlusListFileSizeInBytes: any;
  aPlusListFileType: any;
  aPlusListFileUrl: any;
  aPlusListImage: any;
  aPlusListLargeThumbnailUrl: any;
  aPlusListSmallThumbnailUrl: any;
  aPlusListMediumThumbnailUrl: any;
  assetExtension: any;
  isPrimaryImage: boolean = false; assetMediumThumbnailUrl: any;
  assetLargeThumbnailUrl: any;
  assetFileUrl: any;
  assetSmallThumbnailUrl: any;
  showRequestForApprovalButton: boolean;
  showApprovedButton: boolean = false;
  showRejectedButton: boolean = false;
  userEmail: any;
  approverMatched: boolean = false;
  filesSelected: boolean = false;
  currentFile: any;
  productResponse: any;
  UploadStarted: boolean;
  UploadCompleted: boolean;
  blobName: any;
  containerName: any;
  fileUrl: any;
  serviceUrlWithSAS: any;
  _response: BlobUploadCommonResponse;
  files: FileHandle[];
  productInfo: any = [];
  aPlusFileDowbloadUrl: any;
  aPlusListDownloadUrl: any;
  featureIds: typeof HelpFeatureIdsEnum;
  disableActionsButtonsWhileAPICall: boolean = false;
  variantDetailFormArrayValues: any = [];
  checkDuplicateChannel: boolean = false;
  variantDetailStatus: boolean = false;
  showVariantTabStatus:boolean = false;
  selectedAssetChannelIds: any = [];
  assetModifiedData: any = [];
  element: any;
  expandedElement: SearchproductModel | null;
  selectedAssetRow: any;
  assetTitleName: any;
  addAssetRowInfo: any;
  showSwatchImageCheckBox: any = true;
  swatchHelp = "A swatch image typically shows the key difference between variation products in relation to a product variation attribute. You can define swatches for variation products. For example: For the fabric variation attribute, a highly zoomed detail image of each fabric appears in a row beneath the product's picture."
  tagLimitError: boolean = false;
  hideFieldsForCloneProduct: boolean = true;
  disabelAndHideFieldsForCloneProduct: boolean = true;
  approvalStatus: string;
  checkView:boolean=false;
  allBrandsValues = [];
  allCategoriesValues = [];
  isServerSideBrandSearch: boolean = false;
  isServerSideCategorySearch: boolean = false;

  status: string = "status";
  bulletPointMaxLength: number = 2000;
  value: string = "value";
  bulletPoint: string = "bulletPoint";

  OptionCombinations = [];
  OptionCombinationsString: string[] = [];
  OptionCombinationsStringify: string[] = [];

  variantsArray = [];
  variantWithOptionsArray: VariantWithOptions[] = [];
  hasVariants = false;
  finalArray = [];

  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  addAssetApiCall: boolean = false;
  assetUploadStarted: boolean;
  showUnitWeightField: boolean = false;
  isUnitWeight: FormControl = new FormControl(false);
  unitWeightInGram: FormControl = new FormControl('');
  pricePerUnit: number = 0;
  markFormCancel: boolean = false;
  fileMandatoryHelpText: string = 'When checked, File upload will be mandatory for this product while placing order from shop';
  allowFileHelpText: string = "When checked, Will show an option to upload file while placing order from shop"
  @Output() currentDevice: EventEmitter<object> = new EventEmitter();
  userPhoneNo: string | number;
  showHelpIcon: boolean = false;
  get Product_name() {
    return this.productForm.get('name');
  }
  get Product_sku() {
    return this.productForm.get('sku');
  }
  get Product_description() {
    return this.productForm.get('description');
  }
  get Product_Status() {
    return this.productForm.get('status');
  }
  get Brand_MRP() {
    return this.productForm.get('brandMRP');
  }
  get MRP(){
    return this.productForm.get('mrp');
  }
  get Selling_Price(){
    return this.productForm.get('sellingPrice');
  }

  globalUPCTypes: { id: string; name: string; }[];
  globalUPCTypeEnum: typeof GlobalUPCTypeEnum;
  previousRoute: any = '';
  disableToggle: boolean = false;
  savedBrand: string;
  searchKeyword: any;
  maximumLimitForProductWeight = MAXIMUMALLOWEDPRODUCTWEIGHT;
  maximumLimitForProductLength = MAXIMUMALLOWEDPRODUCTLENGTH;
  maximumLimitForProductWidth = MAXIMUMALLOWEDPRODUCWIDTH;
  maximumLimitForProductHeight = MAXIMUMALLOWEDPRODUCTHEIGHT;
  maximumLimitForPackageWeight = MAXIMUMALLOWEDPACKAGEWEIGHT;
  maximumLimitForPackageLength = MAXIMUMALLOWEDPACKAGELENGTH;
  maximumuLimitForPackageWidth = MAXIMUMALLOWEDPACKAGEWIDTH;
  maximumLimitForPackagerHeight = MAXIMUMALLOWEDPACKAGEHEIGHT;
  maxLengthGUPCValidationMessage = MAXLENGTHGUPCVALIDATIONMESSAGE;
  maximumValueforProductLengthMessage = MAXVALUEFORPRODUCTLENGTHMESSAGE;
  maximumValueforProductWidthMessage = MAXVALUEFORPRODUCTBREADTMESSAGE;
  maximumValueforProductHeightMessage = MAXVALUEFORPRODUCTHEIGHTMESSAGE;
  maximumValueforProductWeightMessage = MAXVALUEFORPRODUCTWEIGHTMESSAGE;
  maximumValueforPackageLengthMessage = MAXIMUMVALUEFORPACKAGELENGTHMESSAGE;
  maximumValueforPackageWidthMessage = MAXIMUMVALUEFORPACKAGEBREADTHMESSAGE;
  maximumValueforPackageHeightMessage = MAXIMUMVALUEFORPACKAGEHEIGHTMESSAGE;
  maximumValueforPackageWeightMessage = MAXIMUMVALUEFORPACKAGEWEIGHTMESSAGE;
  invalidHSNCodeFormatMessage = INVALIDHSNCODEFORMATMESSAGE;
  //the folowi
  formInputAndTabMap = {
    0: ["name", "sku", "hsnCode", "description", "bulletPoints", "mrp", "brandMRP", "shippingFee", "sellingPrice", "minimumSellerAllowedPrice",
      "maximumSellerAllowedPrice", "maxCartQty", "minCartQty", "globalUPCDetails", "status", "tags", "tat"],
    1: [],
    2: ["productDimensions", "packageDimensions"],
    3: ["allowedReturnPeriodInDays"]
  }
  formInputAndTabMapForVariantGroups= {
    0: ["name", "sku", "hsnCode", "description", "bulletPoints", "mrp", "brandMRP", "shippingFee", "sellingPrice", "minimumSellerAllowedPrice",
      "maximumSellerAllowedPrice", "maxCartQty", "minCartQty", "status", "tags", "tat"],
    1: [],
    2: [],
    3: [],
    4:["channelVariantPresentations"]
  }
  isFormSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder, public dialog: MatDialog, private httpHelper: HttpHelperService, private brandsService: BrandsService,
    private commonService: CommonService, private imageService: ImageService, private variantsService: VariantsService, private categoryService: CategoriesServiceService,
    private productListService: ProductListService, private router: Router, private route: ActivatedRoute,
    private scrollingToErrorsService: ScrollingToErrorsService, private productService: ProductService, private chatDialogService: ChatDialogService,
    private customMessageService: CustomMessageService, private assetService: AssetService, private blobStorageService: BlobStorageService,
    private sASTokenService: SASTokenService, private helpGuideService: HelpGuideService, private deviceDetect: DeviceDetect,
    private historyDialogService: HistoryDialogService, private settingService: SettingService, private cdRef: ChangeDetectorRef, private masterCategoryService: CategoriesService,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object, private enumHelperService: EnumHelperService) 
    {
    this.titleEnum = titlename;
    this.route.paramMap.subscribe(params => {

      this.productId = params.get('id') ? params.get('id') : null;
      this.viewId = params.get('viewId') ? params.get('viewId') : null;
      if (this.viewId) {
        this.disabledTag = true;
        this.isPerishable = true;
        this.isBasicFormSuccess = true;
      }
    });
    this.createFullForm();
    this.route.queryParams.subscribe(params => {
      this.tab = params['tab'];
    });
    this.checkView = history.state.checkView;
    if (this.router.url === `${RouterLinksEnum.ADMIN_SLASH}${RouterLinksEnum.MANAGE_PRODUCTS_NEW}`) {
      this.titlename = this.titleEnum.addProduct;
      this.productForm.controls["name"].valueChanges.subscribe(val => this.titlename = this.titleEnum.addProduct + ": " + val);
    }
    if (this.router.url.includes("edit")) {
      this.titlename = this.titleEnum.editProduct + ": " + this.productName;
      this.productForm.controls["name"].valueChanges.subscribe(val => {
        this.titlename = this.titleEnum.editProduct + ": " + val;
        this.productName = val;
      });
    }
  }

  get channelFormArr() {
    return this.productForm.get('channelProductCodes') as FormArray;
  };
  get globalUPCDetailFormArray() {
    return this.productForm.get('globalUPCDetails') as FormArray;
  };
  get channelVariantFormArr() {
    return this.productForm.get('channelVariantPresentations') as FormArray;
  };

  ngOnInit() {
    if (this.productId == null && (this.copyId !== null && this.copyId !== undefined)) {
      this.productId = this.copyId;
    }
    else if ((this.productId === null || this.productId === undefined) && this.viewId !== null) {
      this.productId = this.viewId;
    }
    this.checkScreenSize();
    this.featureIds = HelpFeatureIdsEnum;
    this.getChannel();
    let brand = '';
    let category = '';

    this.inventoryMethodEnum = [{ id: 1, name: 'First in First out' },
    { id: 2, name: 'Last in First out' },
    { id: 3, name: 'First Expired First out' },
    { id: 4, name: 'Manual' },
    ];
    this.DefaultDropDwnEnum = DefaultDropDownEnum;
    this.DefaultDropDownEnum = this.DefaultDropDwnEnum.defaultVal;
    this.titleEnum = titlename;
    this.PermissionsEnum = PermissionsEnum;
    this.document.body.scrollTop = 0;
    this.RouterLinksEnum = RouterLinksEnum;
    this.variantcheck = false;
    this.statusControler.setValue(this.allProductStatusDropdown[0].value);
    this.productConditionControler.setValue(this.itemConditoinDropDown[0].name);
    let orgId = JSON.parse(decodeURI(localStorage.getItem(ORGANIZATION_INFO)))['id'];
    this.statuses$ = this.getStatuses();
    if (orgId) {
      if (this.productId == null || this.productId == undefined)
        this.productForm.get('organizationId').setValue(orgId);
      this.getAllUnitOfMeasurement(orgId);
    }
    this.brandControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      !val && this.productForm.get('productBrand').setValue(null);
      // if ((this.productId || this.copyId) && !this.isServerSideBrandSearch && this.allBrandsValues.length <=1) {
      //   this.getAllBrands(val);
      //   return;
      // }
      if (this.isServerSideBrandSearch) {
        let queryString = `searchText=${val}&pageSize=${pageSize}`;
        this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
          val && (this.brandResponse = res);
          !val && (this.brandResponse = []);
        })
        return;
      }
      val = val && val.toLowerCase();
      this.brandResponse = this.allBrandsValues.filter(x => {
        return x.name.toLowerCase().includes(val)
      });
    });
    this.tagCtrl.valueChanges.subscribe(val => {
      if (val !== null && val !== undefined && val !== '') {
        this.getAllProductTags(val);
      }
    });
    this.productForm.get('returnable').valueChanges.subscribe(val => {
      if (val) {
        this.isReturnable = true;
      }
      else {
        this.isReturnable = false;
      }
    });

    this.categoryControler.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      // if ((this.productId || this.copyId) && !this.isServerSideCategorySearch && this.allCategoriesValues.length <=1) {
      //   this.getAllCategories(val);
      //   return;
      // }
      if (this.categoryControler.invalid) {
        this.productForm.controls['productCategory'].setErrors({ 'incorrect': true });
      } else {
        this.productForm.controls['productCategory'].setErrors(null);
      }
      !val && this.productForm.get('productCategory').setValue(null);
      if (this.isServerSideCategorySearch) {
        let queryString = `searchText=${val}&pageSize=${pageSize}`;
        this.categoryService.getAllCategoriesActive(queryString).subscribe(res => {
          val && (this.categoriesResponse = res);
          !val && (this.categoriesResponse = []);
        })
        return;
      }
      val = val && val.toLowerCase();
      this.categoriesResponse = this.allCategoriesValues.filter(x => {
        return x.fullHierarchicalName.toLowerCase().includes(val)
      })
    })

    let productData: any;
    // bind data with respect to product id start
    this.allVariants$ = this.getAllVariantTypes(orgId);
    this.allVariants$.subscribe(result => {
      this.allVariants = result;
      this.showVariantTabStatus=this.allVariants.length ? true : false
      if (this.productId) {
        this.isBasicFormSuccess = true;
        if ((this.productId === this.viewId) && this.productName) {
          this.titlename = this.titleEnum.product + ": " + this.productName;
        }
        this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
        let organizationData = JSON.parse(this.jsonOrgData);
        this.orgId = organizationData['id'];
        this.userEmail = JSON.parse(decodeURI(sessionStorage.getItem('SessionStorge_UserModel')))['email'];
        this.userPhoneNo = JSON.parse(decodeURI(sessionStorage.getItem('SessionStorge_UserModel')))['phone'];
        let requestparameter = this.productId + "?organizationId=" + this.orgId;
        this.productService.getproduct(requestparameter).subscribe(data => {
          this.checkVariantType(data);
          productData = data;
          this.copyId && (productData.sku = '');
          this.savedBrand = productData.productBrand.name;
          this.getAllCategories('');
          this.getAllBrands('');
          this.productInfo = data;
          this.productResponse = data;
          this.disableToggle = this.productInfo.status == ProductStatusEnum.Discontinued ? true : false;
          this.setApprovalStatusValue(productData);
          if (productData) {
            const channelControl = <FormArray>this.productForm.get('channelProductCodes');
            if (productData.channelProductCodes.length != 0) {
              channelControl.clear();
              productData.channelProductCodes.forEach(channelData => {
                if(!channelData.bulletPoints){
                  channelData.bulletPoints = [this.getBulletPointFormBuilder().value]
                }
                channelControl.push(
                  this.formBuilder.group({
                    channelId: [''],
                    channelName: [''],
                    productCode: [''],
                    productChannelTitle: [''],
                    bulletPoints: this.formBuilder.array([this.getBulletPointFormBuilder()]),
                    description: [""],
                  })
                  )
              })
              
            }
            const globalUPCControl = <FormArray>this.productForm.get('globalUPCDetails');
            globalUPCControl.clear();
            if (productData.globalUPCDetails && productData.globalUPCDetails.length != 0) {

              productData.globalUPCDetails.forEach(y => {
                globalUPCControl.push(
                  this.formBuilder.group({
                    upcType: [],
                    upcValue: [],
                  })
                )
              })
            }
            else {
              globalUPCControl.push(
                this.formBuilder.group({
                  upcType: [],
                  upcValue: [],
                })
              )
            }
            if (productData.assets) {
              this.GetProductImageDisplayData(this.channelData, productData.assets);
              let assetFormControls = <FormArray>this.productForm.get('assets');
              // Resetting the form array because it was containing one empty object
              assetFormControls.clear();
              productData.assets.forEach(y => {
                assetFormControls.push(
                  this.formBuilder.group({
                    isSwatchOnlyImage: [],
                    channelId: [''],
                    channelName: [''],
                    isPrimary: [''],
                    displayOrder: [''],
                    id: [''],
                    title: [''],
                    description: [''],
                    fileDetails: this.formBuilder.group({
                      fileName: [''],
                      extension: [''],
                      fileType: [''],
                      fileUrl: [''],
                      fileSizeInBytes: [''],
                      smallThumbnailUrl: [''],
                      mediumThumbnailUrl: [''],
                      largeThumbnailUrl: [''],
                    }),
                    tags: [],
                    status: [''],
                    type: [''],
                    brandId: [''],
                    brandName: [''],
                    assetCategoryId: [''],
                    assetCategoryName: [''],
                    createdByEmail: [''],
                    createdByName: [''],
                    updatedByEmail: [''],
                    updatedByName: [''],
                    createdOn: [''],
                    updatedOn: [''],
                  })
                )
              })
            }
            if (productData.aPlusContents) {

              for (let i = 0; i < productData.aPlusContents.length; i++) {
                this.aPlustableInfo.push({
                  version: productData.aPlusContents[i].version,
                  aPlusImgUrl: productData.aPlusContents[i].fileDetails.mediumThumbnailUrl,
                  title: productData.aPlusContents[i].title,
                  description: productData.aPlusContents[i].description,
                  channelId: productData.aPlusContents[i].channelId,
                  channelName: productData.aPlusContents[i].channelName,
                  aPlusMediumThumbnailUrl: productData.aPlusContents[i].fileDetails.mediumThumbnailUrl,
                  extension: productData.aPlusContents[i].fileDetails.extension,
                  downloadUrl: productData.aPlusContents[i].fileDetails.fileUrl
                })
              }
              this.aPlusDataSource.data = this.aPlustableInfo;
              let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');

              aPlusFormControls.clear();
              productData.aPlusContents.forEach(y => {
                aPlusFormControls.push(
                  this.formBuilder.group({
                    title: [''],
                    version: [''],
                    description: [''],
                    channelId: [''],
                    channelName: [''],
                    fileDetails: this.formBuilder.group({
                      fileName: [''],
                      extension: [''],
                      fileType: [''],
                      fileUrl: [''],
                      fileSizeInBytes: [''],
                      smallThumbnailUrl: [''],
                      mediumThumbnailUrl: [''],
                      largeThumbnailUrl: [''],
                    })
                  })
                )
              })
            }
          }
          this.statusControler.setValue(this.allProductStatusDropdown[productData.status - 1].value);

          if (this.statusControler.value == "Active") {
            this.toggleStatus = true;
          }
          //made check of null value to add default value in dropdown
          if (productData.productShelfLifeUnit == null) {
            this.productShelfLifeUnitControler.setValue("-1");
          }
          if (!this.productName)
            this.titlename = this.titleEnum.product + ": " + productData.name;
          this.allSelectedTags = productData.tags;
          if (this.allSelectedTags !== null && this.allSelectedTags !== undefined && this.allSelectedTags.length > 0) {
            this.allSelectedTags.forEach(tag => {
              this.addedAllTags.push(tag.name);
            });
          }

          if (this.productId !== null) {
            this.productForm.get("isVariantGroup").disable();
          }
          if (this.copyId !== null && this.copyId !== undefined) {
            productData.status = 1;
            this.copiedImages = productData.images;
          }
          productData = this.setBulletPointInFormArray(productData);
          this.refreshChannelVariantForm(productData, "Onload")
          this.makeFormObject(productData);


          brand = productData.productBrand.name ? productData.productBrand.name : '';
          this.brandControl.setValue(brand);
          category = productData.productCategory.name ? productData.productCategory.name : '';
          if (productData.productCategory.id !== null) {
            this.categoryControler.setValue(productData.productCategory.fullHierarchicalName);
            this.productForm.get('productCategory').patchValue(productData.productCategory);
            this.productForm.patchValue(productData);
            }
          if (this.viewId !== null && this.viewId !== undefined) {
            this.makeAllFieldsDisable();
          }
          if (this.copyId === null) {
            this.isBasicFormSuccess = true;
          }
          if (this.copyId !== null && this.copyId !== undefined) {
            this.productForm.get('id').setValue('');
          }
          this.updateDataSourceFromImages();
          this.checkRequestForApproval(productData);
          // if (productData.productCategory.id !== null)
          //   this.categoryService.getCategoriesById(productData.productCategory.id + "?organizationId=" + this.orgId).subscribe(res => {
          //     this.categoryControler.setValue(res.data.fullHierarchicalName);
          //     this.productForm.get('productCategory').patchValue(res.data);
          //   });
          if (productData.productCondition !== null && productData.productCondition.id !== null && productData.productCondition.id !== '')
            this.productConditionControler.setValue(this.itemConditoinDropDown[+productData.productCondition.id - 1].name);
          if (productData.productReplacementShippingCondition !== null && productData.productReplacementShippingCondition.id !== null && productData.productReplacementShippingCondition.id !== "")
            this.productReplacementShippingConditionControler.setValue(this.returnReplacementConditoinDropdown[+productData.productReplacementShippingCondition.id - 1].name);
          if (productData.productShelfLifeUnit !== null && productData.productShelfLifeUnit.id !== null && productData.productShelfLifeUnit.id !== "")
            this.productShelfLifeUnitControler.setValue(this.productShelfLifeUnitDropdown[+productData.productShelfLifeUnit.id - 1].name);
          if (productData.status !== null) {
            this.statusControler.setValue(this.allProductStatusDropdown[+productData.status - 1].value);

          }
          if (this.tab > 0) {
            this.selectedTabIndex = this.tab;
          }

          // this.getAllBrands(brand)
          // this.getAllCategories(category);
          // this.clonedProduct(productData);
        })
      } else {
        this.getAllBrands(brand)
        this.getAllCategories(category);
      }
    });
    this.getChannelLevelProductTitlesValue();
    this.globalUPCTypes = this.enumHelperService.getGlobalUPCTypeEnum();
    this.globalUPCTypeEnum = GlobalUPCTypeEnum;

    //Getting all Brands and Categories

    if(history.state.dialogProductForm != null){
      let dialogProductFormValue = JSON.parse(history.state.dialogProductForm);
      dialogProductFormValue && dialogProductFormValue.productCategory && dialogProductFormValue.productCategory.fullHierarchicalName && this.categoryControler.setValue(dialogProductFormValue.productCategory.fullHierarchicalName);
      dialogProductFormValue && dialogProductFormValue.productBrand && dialogProductFormValue.productBrand.name && this.brandControl.setValue(dialogProductFormValue.productBrand.name);
      this.productForm.patchValue(dialogProductFormValue);
    }
    if(!this.productId && !this.viewId){ this.showHelpIcon = true}
    
    // end of ngOnInit
  }

  showBulletPointData(bulletPoints,returnFullText=false){
    if(!bulletPoints || bulletPoints.length==0){return ""}
    if(bulletPoints && bulletPoints.length!=0){
      let points='';
      for(let i=0;i<bulletPoints.length;i++){
        if(bulletPoints[i]['name']!=""){
          points+=bulletPoints[i]['name']+"\n";
        }
      }
      return returnFullText ? points: this.getWrapedText(points,100);
    }
  }

  addAllTag(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;
      this.tagLimitError = value.length > 100 ? true : false;
      if (this.tagLimitError) return;
      if ((value || '').trim()) {
        if(this.addedAllTags.includes(value.trim())) return;
        this.addedAllTags.push(value.trim());
        let tag = new Tag();
        tag.id = '';
        tag.name = value.trim();
        this.allSelectedTags.push(tag);
        this.productForm.get("tags").setValue(this.allSelectedTags);
      }
      if (input) {
        input.value = '';
      }
      this.tagCtrl.setValue(null);
   
  }
  removeAllTag(tag: string): void {
    if (this.viewId == null) {
      const index = this.addedAllTags.indexOf(tag);
      if (index >= 0) {
        this.addedAllTags.splice(index, 1);
        this.allSelectedTags.splice(index, 1);
        this.productForm.get("tags").setValue(this.allSelectedTags);
      }
    }
  }
  selected(tag): void {
    if (tag !== null && tag !== undefined) {
      if(this.addedAllTags.includes(tag.name))return;
      this.addedAllTags.push(tag.name);
      this.allSelectedTags.push(tag);
      this.productForm.get("tags").setValue(this.allSelectedTags);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }
  getChannelLevelProductTitlesValue() {
    this.settingService.getOrganizationSettings().subscribe(res => {
      if (res.data) {
        if (res.data.allowChannelLevelProductTitles) {
          this.showChannelLevelProductTitle = true;
        }
        else {
          this.showChannelLevelProductTitle = false;
        }
      }
    });
  }
  getAllUnitOfMeasurement(orgId) {
    this.productService.getUnitOfMeasurement().subscribe(res => {
      this.unitOfMeasureResponse = res.data;
      this.unitOfMeasureResponse.forEach(res => {
        if (res.name == "Pieces") {
          this.productForm.get('unitOfMeasureId').setValue(res.id)
          this.productForm.get('unitOfMeasureName').setValue(res.name);
          this.productForm.get('unitOfMeasureShortName').setValue(res.shortName);
        }
      })
      // get statuses
      this.statuses$.subscribe(value => {
        this.statuses = value;
        //get All Product Tags
        this.getAllProductTags('-').subscribe(res => {
          this.allTags = res;
          // get Variants
          this.allVariants$.subscribe(result => {
            this.allVariants = result;
            this.allActiveVariants = this.allVariants.filter(variant => variant.active);
            this.allVariants.forEach(variant => {
              this.variantsMapToId[variant.id] = variant.name;
            });
          });
        });
      });
    });
  }
  selectMeasureUnit(event) {
    if (event.value) {
      for (let i = 0; i < this.unitOfMeasureResponse.length; i++) {
        if (this.unitOfMeasureResponse[i].id == event.value) {
          this.productForm.get('unitOfMeasureName').setValue(this.unitOfMeasureResponse[i].name);
          this.productForm.get('unitOfMeasureShortName').setValue(this.unitOfMeasureResponse[i].shortName);
        }
      }
    }
    this.unitMandatoryMessage = false;
  }
  onTabClick(event: MatTabChangeEvent) {
    this.currentTab = event.index;

    if (event.index == 2) {
      // Product dimensions
      let lengthInCentimetersValuePD = this.productForm.get('productDimensions').get('lengthInCentimeters').value;
      this.productForm.get('productDimensions').get('lengthInCentimeters').setValue('');
      this.productForm.get('productDimensions').get('lengthInCentimeters').setValue(lengthInCentimetersValuePD);

      let widthInCentimetersValuePD = this.productForm.get('productDimensions').get('widthInCentimeters').value;
      this.productForm.get('productDimensions').get('widthInCentimeters').setValue('');
      this.productForm.get('productDimensions').get('widthInCentimeters').setValue(widthInCentimetersValuePD);

      let heightInCentimetersValuePD = this.productForm.get('productDimensions').get('heightInCentimeters').value;
      this.productForm.get('productDimensions').get('heightInCentimeters').setValue('');
      this.productForm.get('productDimensions').get('heightInCentimeters').setValue(heightInCentimetersValuePD);

      let weightInGramsValuePD = this.productForm.get('productDimensions').get('weightInGrams').value;
      this.productForm.get('productDimensions').get('weightInGrams').setValue('');
      this.productForm.get('productDimensions').get('weightInGrams').setValue(weightInGramsValuePD);
      // Package dimensions
      let lengthInCentimetersValue = this.productForm.get('packageDimensions').get('lengthInCentimeters').value;
      this.productForm.get('packageDimensions').get('lengthInCentimeters').setValue('');
      this.productForm.get('packageDimensions').get('lengthInCentimeters').setValue(lengthInCentimetersValue);

      let widthInCentimetersValue = this.productForm.get('packageDimensions').get('widthInCentimeters').value;
      this.productForm.get('packageDimensions').get('widthInCentimeters').setValue('');
      this.productForm.get('packageDimensions').get('widthInCentimeters').setValue(widthInCentimetersValue);

      let heightInCentimetersValue = this.productForm.get('packageDimensions').get('heightInCentimeters').value;
      this.productForm.get('packageDimensions').get('heightInCentimeters').setValue('');
      this.productForm.get('packageDimensions').get('heightInCentimeters').setValue(heightInCentimetersValue);

      let weightInGramsValue = this.productForm.get('packageDimensions').get('weightInGrams').value;
      this.productForm.get('packageDimensions').get('weightInGrams').setValue('');
      this.productForm.get('packageDimensions').get('weightInGrams').setValue(weightInGramsValue);
    }
    else if (event.index == 3) {
      let shelfLifeValue = this.productForm.get('shelfLife').value;
      this.productForm.get('shelfLife').setValue('');
      this.productForm.get('shelfLife').setValue(shelfLifeValue);
      let productShelfLifeUnitControlerValue = this.productShelfLifeUnitControler.value;
      if (productShelfLifeUnitControlerValue) {
        if (productShelfLifeUnitControlerValue.indexOf("Months") !== -1) {
          this.productShelfLifeUnitControler.setValue('');
          this.productShelfLifeUnitControler.setValue('Months');
        }
        else if (productShelfLifeUnitControlerValue.indexOf("Days") !== -1) {
          this.productShelfLifeUnitControler.setValue('');
          this.productShelfLifeUnitControler.setValue('Days');
        }
      }
      else {
        this.productShelfLifeUnitControler.setValue('');
      }
    }
  }
  RePopulateVariationProduct(variantIdentifierId, itemsProductVariations_copy) {
    itemsProductVariations_copy.value.forEach((itemCopy: any) => {
      if (variantIdentifierId == itemCopy.variantIdentifierId) {
        this.copy_productId = itemCopy.productId;
        this.copy_productName = itemCopy.productName;
        this.copy_searchName = itemCopy.searchName;
        this.copy_sku = itemCopy.sku;
        this.copy_status = itemCopy.status;
      }
    });
  }
  generateVariationProducts(variantTypeValue) {
    if (this.itemsProductVariations !== undefined) {
      this.itemsProductVariations.clear();
    }
    // let variantsArray = this.productForm.get('variantTypes');
    let variantArray = (variantTypeValue);
    if (variantArray.length > 0) {
      var variantOptionsArrayFinal = [];
      variantArray.forEach(function iterateType(item, primaryIndex, array) {
        let primaryOptions = [];
        let primaryTitle = item.name;
        if (primaryTitle) {
          primaryOptions = item.options;
          var variantOptionsArray = [];
          primaryOptions.forEach(function iterateOptions(optionsItem, indexx) {
            let optionsName = '';
            if (optionsItem.name) {
              optionsName = primaryTitle + ':' + optionsItem.name;
              variantOptionsArray.splice(indexx, 1, { OptionValueCode: optionsItem.OptionValueCode, name: optionsName });
            }
          });
          variantOptionsArrayFinal.splice(primaryIndex, 1, variantOptionsArray);
        }
      });
      if (variantOptionsArrayFinal.length > 0) {
        var variationProductsList = this.cartesianProduct(variantOptionsArrayFinal);
        var variationSize = variationProductsList.length;
        for (var z = 0; z < variationSize; z++) {
          let variationProductData = variationProductsList[z];
          let variantIdentifierId = '';
          let variationProductTitle = '';
          variationProductData.forEach(function iterateType(item, index) {
            variantIdentifierId += item.OptionValueCode + '_';
            variationProductTitle += item.name + ' ';
          });
          this.itemsProductVariations = this.productForm.get('productVariants') as FormArray;
          this.itemsProductVariations.removeAt(z);
          this.copy_productId = "";
          this.copy_productName = "";
          this.copy_searchName = "";
          this.copy_sku = "";
          if (this.itemsProductVariations_copy !== undefined && this.itemsProductVariations_copy.value.length > 0) {
            this.RePopulateVariationProduct(variantIdentifierId, this.itemsProductVariations_copy);
          }
          this.itemsProductVariations.insert(z, this.createItemProductVariations(variationProductTitle, variantIdentifierId, this.copy_productId, this.copy_productName, this.copy_searchName, this.copy_sku));
          if (this.viewId || this.productInfo.isClonedProduct) {
            this.itemsProductVariations.controls.forEach(item => {
              item.get('searchName').disable()
            })
          }
        }
      }
    }
  }
  changeProductVariationsData(productObj, index) {
    this.itemsProductVariations = this.productForm.get('productVariants') as FormArray;
    let setproductVariants = {
      "productId": productObj.id,
      "productName": productObj.name,
      "sku": productObj.sku,
      "productCodes": null,
      "status": productObj.status,
      "productImages": ""
    };
    this.itemsProductVariations.at(index).patchValue(setproductVariants);
    this.allProductsSearchList = [];
    let variationIdentifierId = this.itemsProductVariations.at(index).get("variantIdentifierId").value;

    this.itemsProductVariations_copy.value.forEach((itemCopy: any) => {
      if (variationIdentifierId == itemCopy.variantIdentifierId) {
        itemCopy.productId = productObj.id;
        itemCopy.productName = productObj.name;
        itemCopy.searchName = productObj.searchName;
        itemCopy.sku = productObj.sku;
        itemCopy.status = productObj.status;
      }
    });
  }
  createItemProductVariations(variationProductTitle, variantIdentifierId, productId, productName, searchName, sku): FormGroup {
    return this.formBuilder.group({
      "variantIdentifier": variationProductTitle,
      "variantIdentifierId": variantIdentifierId,
      "productId": productId,
      "productName": productName,
      "searchName": searchName,
      "sku": sku,
      "productCodes": null,
      "status": 1,
      "productImages": []
    });
  };
  cartesianProduct(arr) {
    return arr.reduce(function (a, b) {
      return a.map(function (x) {
        return b.map(function (y) {
          return x.concat(y);
        })
      }).reduce(function (a, b) { return a.concat(b) }, [])
    }, [[]])
  }
  getProductsWithCode(index) {
    this.allProductsSearchList = [];
    let searchKey = (<FormArray>this.productForm.get('productVariants')).at(index);
    this.searchKeyword = searchKey.value.searchName;
    if (!this.searchKeyword) {
      searchKey.get('productName').setValue('')
      searchKey.get('productId').setValue('')
    }
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    let organizationData = JSON.parse(this.jsonOrgData);
    let orgId = organizationData.id;


    if (this.searchKeyword) {
      this.getAllProducts(orgId, this.searchKeyword).subscribe(
        response => { this.allProductsSearchList = []; this.allProducts$.next(response); }
      );

      this.allProducts$.subscribe(value => {
        this.allProductsSearchList = [];
        if (this.searchKeyword.length > 0)
          this.allProductsSearchList = value;

      });
    }
    else {
      this.allProductsSearchList = [];
      setTimeout(() => {
        this.allProductsSearchList = [];
      }, 1000);

    }


  }
  updateDataSourceFromImages() {
    let imagesArray = this.productForm.get('images') as FormArray;
    imagesArray.controls.forEach(imageData => {
      this.addRowData(imageData);
    });
  }
  makeFormObject(productData) {
    //images
    let imagesFormArray = this.productForm.get('images') as FormArray;
    productData.images.forEach((image: ProductImage) => {
      let imageUploadForm = this.initProductImageFormBuilder();
      this.imageControlEditable.push(false);
      imagesFormArray.push(imageUploadForm);
    });
    // variantTypes
    let variantFormArray = this.productForm.get('variantTypes') as FormArray;
    variantFormArray.clear();
    productData.variantTypes.forEach((variant, index) => {
      this.selectedVariantTypes[index] = variant;
      let variantFormBuilder = this.initProductVariantType();
      let optionFormArray = variantFormBuilder.get('options') as FormArray;
      optionFormArray.clear();
      variant.options.forEach((option) => {
        let optionFormBuilder = this.initProductVariantTypeOption();
        optionFormArray.push(optionFormBuilder);
      });
      variantFormArray.push(variantFormBuilder);
    });
    // product Variants
    setTimeout(() => {
      this.generateVariationProducts(productData.variantTypes ? productData.variantTypes : []);
      setTimeout(() => {
        this.productForm.patchValue(productData);
        this.clonedProduct(productData)
        this.channelProductDataTable();
        this.isBasicFormSuccess = true;
        this.itemsProductVariations = this.productForm.get('productVariants') as FormArray;
        this.itemsProductVariations_copy = Object.assign({}, this.itemsProductVariations);

      }, 200);
    }, 400);
  }
  getAllProductTags(searchText: string) {
    let queryString = `?searchText=${searchText}`;
    this.allTags$ = this.productService.getProductTag(queryString).pipe(
      map(res => res.data['data']),
      share()  // add share to stop API call mutiple times on load
    );
    return this.allTags$;
  }
  getAllCategories(searchText: string) {
    // if (searchText.length > 2) {
        let queryString=""    
        if(!searchText){
          searchText=""
        }
        queryString = `?searchText=${searchText}&pageSize=${pageSize}&displayAllCategoriesHierarchy=${"true"}`;
        this.masterCategoryService.getCategoriesForProducts(queryString).subscribe(res => {
        this.isServerSideCategorySearch = res['data'].length == pageSize ? true : false;
        !this.isServerSideCategorySearch && (this.categoriesResponse = res['data']);
        this.allCategoriesValues = res['data'];
        !this.isServerSideCategorySearch && this.categoryControler.setValidators(this.validOptionValidatorForCategory(this.categoriesResponse));
        !this.isServerSideCategorySearch && this.categoryControler.updateValueAndValidity();
      });
    // }
  }
  getAllProducts(organizationId: number, searchText: string) {
    let queryString = `?organizationId=${organizationId}&ExcludeProductGroups=true&FullText=${searchText}`;
    return this.productListService.getData(queryString)
      .pipe(
        map(result => result.data['data'])
      );
  }
  setSelectedCategory(category: ProductCategory, event) {
    if (event && event.isUserInput) {
      this.selectedCategory = category;
      this.categoryControler.setValue(category.fullHierarchicalName);
      this.productForm.get('productCategory').setValue(category);
      this.isServerSideCategorySearch && (this.categoriesResponse = []);
    }
  }
  setSelectedBrand(brand, e) {
    if (e.isUserInput) {
      let brd = JSON.parse(JSON.stringify(brand))
      this.selectedBrand = brd;
      this.isServerSideBrandSearch && (this.brandResponse = []);
      this.productForm.get('productBrand').setValue(brd);
      this.brandControl.setValue(brd.name)
    }

  }
  setSelectedProductWithIndex(prod, index) {
    this.selectedProductByIndex[index] = prod;
    this.productForm.get(['productVariants', index]).patchValue({
      variantIdentifier: prod.variantIdentifier,
      productId: prod.id,
      productName: prod.name,
      sku: prod.sku,
      productCodes: prod.productCodes,
      status: prod.status
    });
  }
  //Function changed for getting the value of default value and making the dropdown as of units in view
  setSelectedShelfLifeUnit(event) {
    let drpVal = event;
    let selectedProductShelfLifeUnitData: any = '';
    if (drpVal.index == 2) {
      selectedProductShelfLifeUnitData = this.productShelfLifeUnitDropdown[0];
    } else if (drpVal.index == 3) {
      selectedProductShelfLifeUnitData = this.productShelfLifeUnitDropdown[1];
    }
    this.productForm.get('productShelfLifeUnit').setValue(selectedProductShelfLifeUnitData);
  }
  setSelectedProductStatus(event) {
    if (event.value) {
      for (let i = 0; i < this.allProductStatusDropdown.length; i++) {
        if (this.allProductStatusDropdown[i].id == event.value) {
          this.selectedProductStatus = this.allProductStatusDropdown[i].value;
          this.statusControler.setValue(this.allProductStatusDropdown[i].value);
        }
      }
    }

  }
  setSelectedVariantType(variant, i) {
    this.selectedVariantTypes[i] = variant;
    this.productForm.get(['variantTypes', i]).patchValue({ id: variant.id, name: variant.name });
  }

  disableVariantSelection(variant){
  let variantData = this.selectedVariantTypes.find(item=> item && (item.id == variant.id));
  return variantData ?  true : false;
  }
  getAllVariantTypes(orgganizationId: number) {
    let queryString = `organizationId=${orgganizationId}&pageSize=-1`;
    return this.variantsService.getAllVariants(queryString)
      .pipe(
        take(1),
        map(result => result.data.data),
        map(results => results.filter(result => result.active))
      );
  }
  getAllBrands(searchText: string) {
    let queryString = `searchText=${searchText}&pageSize=${pageSize}`;
    this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
      this.isServerSideBrandSearch = res.length == pageSize ? true : false;
      !this.isServerSideBrandSearch && (this.brandResponse = res);
      this.allBrandsValues = res;
      !this.isServerSideBrandSearch && this.brandControl.setValidators(this.validOptionValidator(this.brandResponse));
      !this.isServerSideBrandSearch && this.brandControl.updateValueAndValidity();
      let savedBrandObject = this.allBrandsValues.find(item => item.name == this.savedBrand)
      if (savedBrandObject && savedBrandObject.approvers && !this.copyId) {
        for (let i = 0; i < savedBrandObject.approvers.length; i++) {
          if (savedBrandObject.approvers[i] == this.userEmail || this.userPhoneNo) {
            this.approverMatched = true;
          }
        }
        if (this.productResponse && this.productResponse.approvalDetails) {
          if (this.approverMatched) {
            if (this.productResponse.approvalDetails.approvalStatus == ApprovalStatusEnum["Pending For Approval"]) {
              this.showApprovedButton = true;
            }
            else {
              this.showRejectedButton = false;
            }
          }
        }
      }
    });
  }
  getStatuses() {
    return this.commonService.getStatuses();
  }
  selectIsPerishable(event) {
    if (event.checked == true) {
      this.productForm.get('inventoryMethod').setValue(InventoryMethodEnum["First Expired First out"]);
    }
    else {
      this.productForm.get('inventoryMethod').setValue(InventoryMethodEnum["First in First out"]);
    }
  }
  channelMappingFields(): FormGroup {
    return this.formBuilder.group({
      channelId: [''],
      channelName: [''],
      productCode: [''],
      productChannelTitle: [''],
      bulletPoints: this.formBuilder.array([this.getBulletPointFormBuilder()]),
      description: [""],
    })
  }
  createFullForm() {
    this.variantcheck = false;
    this.productForm = this.formBuilder.group({
      id: [''],
      organizationId: [],
      name: ['', [Validators.required, Validators.maxLength(maximumLength.length250)]],
      sku: ['', [Validators.maxLength(50)]],
      productCategory: [null],
      hsnCode: ['', [Validators.maxLength(10), Validators.pattern(validationPattern.hsnCodePattern)]],
      productTaxType: [null],
      description: ['', [Validators.maxLength(2000)]],
      bulletPoints: this.formBuilder.array([this.getBulletPointFormBuilder()]),
      productBrand: [null],
      productCondition: [null],
      productConditionDescription: [null],
      mrp: [0, [Validators.pattern(this.numberPattern),Validators.max(10000000)]],
      brandMRP: [0, [Validators.required, Validators.pattern(this.numberPattern),Validators.max(10000000)]],
      shippingFee: [0, [Validators.pattern(this.numberPattern)]],
      sellingPrice: [0, [Validators.pattern(this.numberPattern),Validators.max(10000000)]],
      minimumSellerAllowedPrice: [0, [Validators.pattern(this.numberPattern)]],
      maximumSellerAllowedPrice: [0, [Validators.pattern(this.numberPattern)]],
      maxCartQty: [0, [Validators.pattern(this.onlyNumberPattern)]],
      minCartQty: [0, [Validators.pattern(this.onlyNumberPattern)]],
      unitWeightInGrams: [''],
      allowFileUpload: [false],
      fileMandatory: [false],
      fractionAllowed: [false],
      asin: [''],
      globalUPCDetails: this.formBuilder.array([this.getGlobalUPCDetailsFormBuilder()]),
      status: [1, [Validators.required]],
      tags: [[], [Validators.maxLength(100)]],
      returnable: [false],
      allowedReturnPeriodInDays: ['', [Validators.pattern(this.onlyNumberPattern)]],
      productReplacementShippingCondition: [],
      productReplacementConditionDescription: [''],
      tat: [0, [Validators.pattern(this.numberPattern)]],
      productDimensions: this.formBuilder.group({
        lengthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductLength)]],
        widthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductWidth)]],
        heightInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductHeight)]],
        weightInGrams: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForProductWeight)]],
      }),
      packageDimensions: this.formBuilder.group({
        lengthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackageLength)]],
        widthInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumuLimitForPackageWidth)]],
        heightInCentimeters: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackagerHeight)]],
        weightInGrams: [0, [Validators.pattern(this.numberPattern), Validators.max(this.maximumLimitForPackageWeight)]]
      }),
      serializable: [''],
      unitOfMeasureId: [''],
      unitOfMeasureName: [''],
      unitOfMeasureShortName: [''],
      unitOfMeasureShortNameDisp: [''],
      isPerishable: [false],
      shelfLife: [null, [Validators.pattern(this.onlyNumberPattern)]],
      productShelfLifeUnit: [null],
      season: [null],
      maintainBatch: [false],
      launchDate: [''],
      inventoryMethod: [InventoryMethodEnum["First in First out"]],
      images: this.formBuilder.array([]),
      isVariantGroup: [false],
      variationGroupId: [''],
      isVariant: [false],
      variantTypes: this.formBuilder.array([this.initProductVariantType()]),
      productVariants: this.formBuilder.array([]),
      createdById: [''],
      createdByName: [''],
      assets: this.formBuilder.array([]),
      aPlusContents: this.formBuilder.array([]),
      approvalDetails: this.formBuilder.group({
        actionByEmail: [''],
        actionByName: [''],
        approvalDateTime: [''],
        approvalStatus: [''],
        approvalStatusName: [''],
        comments: [''],
        requestedDateTime: [''],
      }),
      channelProductCodes: this.formBuilder.array([this.channelMappingFields()]),
      channelVariantPresentations: this.formBuilder.array([]),
      isPublicProduct: [false]
    });
    this.productForm.get('fileMandatory').disable();

    this.allProductFormChangeDetections();
    this.imageUploadForm = this.formBuilder.group({
      title: [''],
      imageBase64: [''],
      name: [''],
      type: [],
      copiedImage: [],
      displayOrder: [0],
      isSwatchOnlyImage: [false],
      fileSizeInBytes: [0],
      originalURL: [''],
      smallThumbnailURL: [''],
      mediumThumbnailUrl: [''],
      largeThumbnailUrl: [''],
      isDeleted: [false]
    });
  }
  getGlobalUPCDetailsFormBuilder(): FormGroup {
    return this.formBuilder.group({
      upcType: [],
      upcValue: ['', [Validators.maxLength(maximumLength.length25)]]
    });
  }
  assetArray(): FormGroup {
    return this.formBuilder.group({
      isSwatchOnlyImage: [],
      channelId: [''],
      channelName: [''],
      isPrimary: [''],
      displayOrder: [''],
      id: [''],
      title: [''],
      description: [''],
      fileDetails: this.formBuilder.group({
        fileName: [''],
        extension: [''],
        fileType: [''],
        fileUrl: [''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      }),
      tags: [],
      status: [''],
      type: [''],
      brandId: [''],
      brandName: [''],
      assetCategoryId: [''],
      assetCategoryName: [''],
      createdByEmail: [''],
      createdByName: [''],
      updatedByEmail: [''],
      updatedByName: [''],
      createdOn: [''],
      updatedOn: [''],
    })
  }
  aPlusContentArray(): FormGroup {
    return this.formBuilder.group({
      title: [''],
      version: [''],
      description: [''],
      channelId: [''],
      channelName: [''],
      fileDetails: this.formBuilder.group({
        fileName: [''],
        extension: [''],
        fileType: [''],
        fileUrl: [''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      })
    })
  }
  allProductFormChangeDetections() {
    this.productForm.get('isPerishable').valueChanges.subscribe(value => {
      this.isPerishable = value;
    });
    this.productForm.get('allowFileUpload').valueChanges.subscribe(val => {
      if (val) {
        !this.viewId && this.productForm.get('fileMandatory').enable();
      } else {
        this.productForm.get('fileMandatory').setValue(false);
        this.productForm.get('fileMandatory').disable();
      }
    })

    this.productForm.get('fractionAllowed').valueChanges.subscribe(val => {
      if (val) {
        !this.viewId && this.productForm.get('unitWeightInGrams').enable();
        this.productForm.get('unitWeightInGrams').setValidators(Validators.required);
        this.productForm.get('unitWeightInGrams').updateValueAndValidity();
      } else {
        this.productForm.get('unitWeightInGrams').clearValidators();
        this.productForm.get('unitWeightInGrams').updateValueAndValidity();
        this.productForm.get('unitWeightInGrams').setValue('');
        this.productForm.get('unitWeightInGrams').disable();
      }
    });
    this.productForm.get('unitWeightInGrams').valueChanges.subscribe(val => {
      if (val == 0 && val !== '') {
        this.productForm.get('unitWeightInGrams').setValue(1, { emitEvent: false });
      }
      else if (val && val > 999) {
        this.productForm.get('unitWeightInGrams').setValue(999, { emitEvent: false });
      } else if ((val && val < 1)) {
        this.productForm.get('unitWeightInGrams').setValue(1, { emitEvent: false });
      }
      this.calculatePricePerUnit();
    });
    this.productForm.get('sellingPrice').valueChanges.subscribe(val => {
      this.calculatePricePerUnit();
    })
    this.productForm.get('unitOfMeasureShortName').valueChanges.subscribe(val => {
      this.showUnitWeightField = val == 'kg' ? true : false;
      val == 'kg' && !this.productForm.get('unitWeightInGrams').value && this.productForm.get('unitWeightInGrams').disable();
      if (val != 'kg' && !this.showUnitWeightField) {
        this.productForm.get('unitWeightInGrams').setValue('')
        this.productForm.get('fractionAllowed').setValue(false);
      }
    });
    this.productForm.get('isVariantGroup').valueChanges.subscribe(val => {
      if (val) {
        this.productForm.get('fileMandatory').setValue(false);
        this.productForm.get('allowFileUpload').setValue(false);
        this.productForm.get('fileMandatory').disable();
        this.productForm.get('allowFileUpload').disable();
      } else {
        !this.viewId && this.productForm.get('allowFileUpload').enable();
      }
    })
  }
  channelVariantDetailsFormArrayControls(): FormGroup {
    return this.formBuilder.group({
      channelId: ['', [Validators.required]],
      channelName: [''],
      variantDetails: this.formBuilder.array([this.variantDetailsFormArrayControls()])
    })
  }
  variantDetailsFormArrayControls(): FormGroup {
    return this.formBuilder.group({
      variantId: [''],
      channelVariantName: [''],
      channelVariantNameForDisplay: [''],
      displayOrder: [0],
      variantOptions: this.formBuilder.array([this.variantOptionsFormArrayControls()])
    })
  }
  variantOptionsFormArrayControls(): FormGroup {
    return this.formBuilder.group({
      channelOptionName: [''],
      channelOptionNameForDisplay: [''],
      optionName: [''],
      isEnabled: [''],
      displayOrder: [0],
    })
  }
  initProductVariantType(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      name: [''],
      options: this.formBuilder.array([this.initProductVariantTypeOption()]),
    });
  }
  initProductVariantTypeOption(): FormGroup {
    return this.formBuilder.group({
      name: [''],

      OptionValueCode: this.generate_UUID().next().value,
      variantSwatchImage: [],
    });
  }
  * generate_UUID() {
    let num = 1;
    while (true) {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      yield uuid;
    }
  }
  initProductVariantsForm(): FormGroup {
    return this.formBuilder.group({
      variantIdentifier: [''],
      productId: [''],
      productName: [''],
      sku: [''],
      productCodes: [''],
      status: [1],
      productImages: [[]],
    });
  }
  initProductImageFormBuilder(): FormGroup {
    return this.formBuilder.group({
      name: [''],
      type: [''],
      description: [''],
      tags: [''],
      originalURL: [''],
      fileSizeInBytes: [0],
      isSwatchOnlyImage: [false],
      displayOrder: [],
      smallThumbnailURL: [''],
      mediumThumbnailURL: [''],
      largeThumbnailURL: ['']
    });
  }
  getBulletPointFormBuilder(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.maxLength(this.bulletPointMaxLength)]]
    });
  }
  openDialog(action, obj, index?: number) {
    obj.action = action;
    if (index !== undefined || index !== null) {
      obj.index = index;
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: index }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (action == 'Add') {
        this.addRowData(obj);
      } else if (action == 'Update') {
        this.updateRowData(obj);
      }
      else if (result === 1) {
        if (obj !== undefined && obj.index !== undefined) {
          let imageFormGroup = this.productForm.get(['images', obj.index]) as FormGroup;
          let originalImageUrl = imageFormGroup.get('originalURL').value;
          originalImageUrl = originalImageUrl.trim();
          if (originalImageUrl.length == 0) {
            let imagesFormArray = this.productForm.get('images') as FormArray;
            imagesFormArray.removeAt(obj.index);
            this.imageControlEditable.splice(obj.index, 1);
            this.deleteRowData(obj);
          }
          else {
            let organizationId = JSON.parse(decodeURI(localStorage.getItem(ORGANIZATION_INFO)))['id'];
            let queryString = `?imageUrl=${originalImageUrl}`;
            if (this.copiedImages.filter(x => x.originalURL == originalImageUrl).length > 0) // Copied
            {
              this.customMessageService.ShowCustomMessage("Image has been deleted successfully", true);
              let imagesFormArray = this.productForm.get('images') as FormArray;
              imagesFormArray.removeAt(obj.index);
              this.imageControlEditable.splice(obj.index, 1);
              this.deleteRowData(obj);
              this.table.renderRows();
            }
            else {
              this.imageService.deleteImage(queryString).subscribe(res => {
                if (res.data === true) {
                  this.customMessageService.ShowCustomMessage(res.statusMessage, true);
                  let imagesFormArray = this.productForm.get('images') as FormArray;
                  imagesFormArray.removeAt(obj.index);
                  this.imageControlEditable.splice(obj.index, 1);
                  this.deleteRowData(obj);
                  this.table.renderRows();
                  this.onSubmit(this.closeStatus);
                }
              });
            }
          }
        }
        this.table.renderRows();
      }
    });
  }
  addRowDataAtIndex(imageData, index) {
    var d = new Date();
    let imageUrl = this.imgURL;
    let isNew = true;
    if (imageUrl == undefined || imageUrl == null || imageData.value.smallThumbnailURL.length > 0) {
      imageUrl = imageData.value.smallThumbnailURL;
      isNew = false;
    }
    this.dataSource.splice(index, 0, {
      imageData: imageUrl,
      title: imageData.value.name,
      displayorder: imageData.value.displayOrder,
      isDeleted: false,
      isNew: isNew
    });
    this.table.renderRows();
  }
  addRowData(imageData) {
    var d = new Date();
    let imageUrl = this.imgURL;
    let isNew = true;
    if (imageUrl == undefined || imageUrl == null || imageData.value.smallThumbnailURL.length > 0) {
      imageUrl = imageData.value.smallThumbnailURL;
      isNew = false;
    }
    this.dataSource.push({
      imageData: imageUrl,
      title: imageData.value.name,
      displayorder: imageData.value.displayOrder,
      isDeleted: false,
      isNew: isNew
    });
    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.displayorder == row_obj.displayorder) {
        value.title = row_obj.title;
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.imageData !== row_obj.imageData;
    });
  }
  removeOptionApiCall(i, j) {
    const control = <FormArray>this.productForm.get(['variantTypes', i, 'options']) as FormArray; // also try this new syntax
    control.removeAt(j);
    this.itemsProductVariations = this.productForm.get('productVariants') as FormArray;
    if (control.length == 0) {
      let variantTypeControl = this.productForm.get('variantTypes') as FormArray;
      variantTypeControl.removeAt(i);
      this.selectedVariantTypes.splice(i,1);
      this.itemsProductVariations.push(this.formBuilder.group({}));
    }
    this.selectedProductByIndex = [];
    let variantsArray = this.productForm.get('variantTypes');
    this.generateVariationProducts(variantsArray.value ? variantsArray.value : []);
  }
  removeOption(i, j) {
    const variantOptions = <FormArray>this.productForm.get(['variantTypes', i, 'options']) as FormArray;
    const variantTypes = <FormArray>this.productForm.get('variantTypes') as FormArray;
    if(variantTypes.length ==1 && variantOptions.length==1){
      this.customMessageService.showValidationMessage(messages.removeVariantValidation,true)
      return;
    }
    
    if (this.productId || this.copyId) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.delete, isError: false, buttonName: 'Ok' }
      });
      // const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //   data: { id: 1 }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          this.removeOptionApiCall(i, j);
        }
      })
    }
    else {
      this.removeOptionApiCall(i, j);
    }
  }

  openScanner(i: number) {
    const dialogRef = this.dialog.open(BarcodeDialogComponent, {
      //disableClose:true
    }
    ).afterClosed().subscribe(result => {
      let globalUpcDetails = this.productForm.get('globalUPCDetails') as FormGroup;
      globalUpcDetails.controls[i].get('upcValue').setValue(result.data);
      //globalUpcDetails.controls[i].get('upcType').setValue(this.globalUPCTypeEnum.EAN);

    })
  }

  addVariantOption(i) {
    const control = <FormArray>this.productForm.get(['variantTypes', i, 'options']);
    this.selectedProductByIndex = [];
    this.productForm.get('productVariants').reset();
    control.push(this.initProductVariantTypeOption());
  }
  disableAddVariantBtn(){
    const control = <FormArray>this.productForm.get('variantTypes');
    return control.length >= this.allVariants.length
  }
  addVariant() {
    const control = <FormArray>this.productForm.get('variantTypes');
    if(control.length >= this.allVariants.length) return;
    this.selectedProductByIndex = [];
    this.productForm.get('productVariants').reset();
    control.push(this.initProductVariantType());
  }
  onSubmit(closeStatus) {
    if(this.selectedTabIndex == 0 && this.productForm.get("isVariantGroup").value === true)
      this.formResetWhenVariantChecked();
    if (this.selectedTabIndex == 2 && this.productId !== null && (this.productForm.get("isVariantGroup").value === false)) {
      if (this.productForm.get('unitOfMeasureName').value == "") {
        this.customMessageService.ShowCustomMessage(UNIT_REQUIRED, false);
        this.productForm.get("unitOfMeasureName").setValidators([Validators.required]);
        this.productForm.get("unitOfMeasureName").updateValueAndValidity();
        return false;
      } else if (this.productForm.invalid) {
        this.redirectToInvalidTab();
        return
      }

    }
    this.unitMandatoryMessage = false;
    let productData = this.productForm.getRawValue();
    productData = this.bulletPointFormArray(productData);
    if (this.productId == null || this.productId == undefined || ((this.copyId !== null && this.copyId !== undefined) && this.copyId.length > 0)) {
      
      if (this.copiedImages.length > 0) {
        for (let i = 0; i < productData.images.length; i++) {
          for (let j = 0; j < this.copiedImages.length; j++) {
            if (productData.images[i].originalURL == this.copiedImages[j].originalURL) {
              productData.images[i].copiedImage = true;
            }
          }
        }
      }
      if (this.brandControl.value) {
        if (this.productForm.get('productBrand').value == null) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { message: messages.invalidBrand, isError: true, buttonName: 'Ok' }
          });
          // const dialogRef = this.dialog.open(DeleteDialogComponent, {
          //   data: { id: 1, invalidBrandStatus: true }
          // });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
              return false;
            }
          })
          return false;
        }
      }
      if (this.categoryControler.value) {
        if (this.productForm.get('productCategory').value == null) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { message: messages.invalidCategory, isError: true, buttonName: 'Ok' }
          });
          // const dialogRef = this.dialog.open(DeleteDialogComponent, {
          //   data: { id: 1, invalidCategoryStatus: true }
          // });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
              return false;
            }
          })
          return false;
        }
      }
      this.disableActionsButtonsWhileAPICall = true;
      this.productService.postProductData(productData).subscribe(data => {
        this.checkVariantType(data);
        this.disableActionsButtonsWhileAPICall = false;
        data.data = this.setBulletPointInFormArray(data.data)
        let message = data.statusMessage || data.StatusMessage;
        this.customMessageService.ShowCustomMessage(message, true);
        this.productForm.patchValue(data.data);
        this.productId = data.data.id;
        this.isBasicFormSuccess = true;
        this.checkRequestForApproval(data.data);
        if (this.copyId) {
          this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH + data.data.id + RouterLinksEnum.ADMIN_EDIT + "?tab=" + 1);
        }
        this.copyId = null;
        this.copiedImages = [];
        if (this.markFormCancel) {
          this.cancel();
        }
        else if (this.variantcheck && this.selectedTabIndex === 2) {
          this.allReset();
          this.selectedTabIndex = 0;
        }
        else {
          this.selectedTabIndex++;
        }

      }, err => {
        this.disableActionsButtonsWhileAPICall = false;
      });
    } else {
      if (this.brandControl.value) {
        this.productForm.get('productBrand').value.name = this.brandControl.value
      } if (this.productForm.invalid) {
        this.redirectToInvalidTab();
        return;
      }
        let variantTypesFormArray = this.productForm.get('variantTypes') as FormArray;
        if(this.variantcheck && this.selectedTabIndex == 2){
          let variantTypesArray = [];
          variantTypesArray=variantTypesFormArray.value.filter(element=>element && element.id)
          if(variantTypesArray.length){
            variantTypesFormArray.clear();
            variantTypesArray.forEach((item,index)=>{
              variantTypesFormArray.push(this.initProductVariantType())
              let variantOptions = variantTypesFormArray.controls[index].get('options') as FormArray
              if(item.options.length){
                variantOptions.clear();
                item.options.forEach(element => {
                  if(element && element.OptionValueCode){
                    variantOptions.push(this.initProductVariantTypeOption());
                  }
                });                
              }
              
            })
            variantTypesFormArray.patchValue(variantTypesArray);
          }
        }
      let productData = this.productForm.getRawValue();
      productData = this.bulletPointFormArray(productData);
      this.disableActionsButtonsWhileAPICall = true;
      this.productService.putProductData(productData).subscribe(data => {
        if (data) {
          this.checkDuplicateChannel = false;
          this.disableActionsButtonsWhileAPICall = false;
          let message = data.statusMessage || data.StatusMessage;
          this.customMessageService.ShowCustomMessage(message, true);
          if (this.selectedTabIndex === 5 && closeStatus == true && this.variantcheck) {
            this.cancel();
            return false;
          }
          if (this.markFormCancel) {
            this.cancel();
          }
          else if (this.variantcheck && this.selectedTabIndex == 3) {
            this.cancel();
          }
          else if (this.variantcheck && this.selectedTabIndex == 2) {
            this.checkVariantType(data);
            this.refreshChannelVariantForm(data.data, "AfterVariantSave");
            this.selectedTabIndex++;
          }
          else if (closeStatus == true) {
            this.router.navigateByUrl(RouterLinksEnum.ADMIN)
          }
          else {
            this.checkVariantType(data)
            this.selectedTabIndex++;
          }
        }
      }, err => {
        this.disableActionsButtonsWhileAPICall = false;
      });
    }
  }
  onRequestForApprovalSubmit() {
    let requestParameter = "?requestForApproval=" + true;
    let productData = this.productForm.getRawValue();
    productData = this.bulletPointFormArray(productData);
    this.disableActionsButtonsWhileAPICall = true;
    this.productService.putProductData(productData, requestParameter).subscribe(data => {
      let message = data.statusMessage || data.StatusMessage;
      this.customMessageService.ShowCustomMessage(message, true);
      this.disableActionsButtonsWhileAPICall = false;
      this.router.navigateByUrl(RouterLinksEnum.ADMIN)
    }, err => {
      this.disableActionsButtonsWhileAPICall = false;
    });
  }
  onAPlusSubmit() {
    this.unitMandatoryMessage = false;
    let productData = this.productForm.getRawValue();
    this.productService.putProductData(productData).subscribe(data => {
      if (data) {
        let message = data.statusMessage || data.StatusMessage;
        this.customMessageService.ShowCustomMessage(message, true);
        this.productForm.patchValue(data.data);
        this.productId = data.data.id;
        this.isBasicFormSuccess = true;
      }
    });
  }
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null || !this.checkValidateImage(files[0].name)) {
      this.customMessageService.ShowCustomMessage(IMAGE_NOT_SUPPORTED, false);
      return;
    }
    this.isButtonVisible = false;
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      let filePathControler = this.imageUploadForm.get('imageBase64') as FormControl;
      filePathControler.setValue(this.imgURL);
      let fileType = this.imageUploadForm.get('type') as FormControl;
      let fileName = this.imageUploadForm.get('name') as FormControl;
      let imageName = files[0].name.split('.');
      fileType.setValue(imageName[1]);
      fileName.setValue(imageName[0]);
    }
  }
  checkValidateImage(fileName): boolean {
    let validImage = false;
    let fileType = fileName.split(".")[1];
    fileType = fileType.toLowerCase();
    if (fileType == "jpeg" || fileType == "jpg" || fileType == "png" || fileType == "tiff") {
      validImage = true;
    }
    return validImage;
  }
  saveAndCloseChannelVariant() {
    let variantOptionCheck = this.variantOptionRequiredCheck();
    if (variantOptionCheck == false) {
      return;
    }
    this.isChannelVariantFormSubmitted = true;
    if (this.productForm.invalid) {
      this.redirectToInvalidTab()
      this.scrollingToErrorsService.scrollToError();
      return;
    }
    else {
      let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
      let index = channelVariantFieldsArray.value.length - 1
      let varintDetailFormArray = channelVariantFieldsArray.controls[index].get('variantDetails') as FormArray
      if (varintDetailFormArray.length) {
        for (let i = 0; i < varintDetailFormArray.length; i++) {
          varintDetailFormArray.controls[i].get('displayOrder').setValue(i)
          let variantOption = varintDetailFormArray.controls[i].get('variantOptions') as FormArray
          if (variantOption.length) {
            for (let j = 0; j < variantOption.length; j++) {
              variantOption.controls[j].get('displayOrder').setValue(j)
            }
          }
        }
      }
    }
    this.onSubmit(true);
  }
  saveBasicForm() {
    if (this.productForm.get('maxCartQty').value < this.productForm.get('minCartQty').value) {
      this.customMessageService.ShowCustomMessage(messages.max_minCartQuantity, false);
      return;
    }
    let isSellingPriceGreaterThanMrp = this.sellingPriceValidation()
    if (isSellingPriceGreaterThanMrp || this.tagLimitError || this.brandControl.invalid) {
      return;
    }
    this.isBasicFormSubmitted = true;
    if (this.productForm.invalid) {
      this.redirectToInvalidTab();
      return;
    }
    else {
      this.isBasicFormSuccess = true;
      this.onSubmit(this.closeStatus);
    }
  }
  nextTab() {
    this.selectedTabIndex++;
  }
  lastTab() {
    this.onRemoveValidationClick();
    this.selectedTabIndex--;
  }
  
  makeVariantsArray() {
    this.variantsArray = [];
    this.variantWithOptionsArray = [];
    let variantsArrayControl = this.productForm.get('variantTypes') as FormArray;
    variantsArrayControl.controls.forEach(element => {
      let variantWithOptions = new VariantWithOptions();
      variantWithOptions.id = element.value.id;
      variantWithOptions.name = this.variantsMapToId[variantWithOptions.id];
      variantWithOptions.options = [];
      let optionsArray = element.get('options') as FormArray;
      if (optionsArray.length > 1) {
        this.hasVariants = true;
      }
      let tempArray = [];
      optionsArray.controls.forEach(element => {
        let optionValue = element.get('name');
        if (optionValue.value !== undefined && optionValue.value !== null && optionValue.value.length > 0) {
          tempArray.push(optionValue.value);
          variantWithOptions.options.push(optionValue.value);
        }
      });
      this.variantsArray.push(tempArray);
      this.variantWithOptionsArray.push(variantWithOptions);
    });
  }
  
  addValueFromArray(currentArrayIndex: number, oneComb: CombinationVariant[]) {
    if (currentArrayIndex < this.variantsArray.length) {
      let combinationVariant = new CombinationVariant();
      combinationVariant.variantTypeId = this.variantWithOptionsArray[currentArrayIndex].id;
      combinationVariant.variantName = this.variantsMapToId[combinationVariant.variantTypeId];
      this.variantWithOptionsArray[currentArrayIndex].options.forEach(entry => {
        combinationVariant.optionValue = entry;
        oneComb.push(combinationVariant);
        this.addValueFromArray(currentArrayIndex + 1, oneComb);
        oneComb.pop();
      });

    } else {
      let str = ''
      oneComb.forEach((value: CombinationVariant) => {
        str += `${value.variantName}:${value.optionValue} `;
      });
      let combVariantControler = this.productForm.get('productVariants') as FormArray;
      combVariantControler.push(this.initProductVariantsForm());
      this.OptionCombinations.push([...oneComb]);
      this.OptionCombinationsStringify.push(JSON.stringify([...oneComb]));
      this.OptionCombinationsString.push(str);
    }
  }
  openImageDialog(i, j): void {
    if (this.viewId || this.productInfo.isClonedProduct) {
      return;
    }
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: this.productForm.get('assets').value
    });
    dialogRef.afterClosed().subscribe(result => {
      const control = this.productForm.get(['variantTypes', i, 'options', j, 'variantSwatchImage']) as FormControl;
      if (result !== null && result !== undefined) {
        control.setValue(result);
      }
    });
  }
  cancel() {
    if(history.length == 1){
      this.router.navigateByUrl(RouterLinksEnum.ADMIN)
    }
    else if(this.checkView){
      history.go(-2); //Using -2 to redirect to product list after clicking on Save button
    }
    else{
      history.back();
    }
    
    // if(this.previousRoute == '/admin/product-dashboard'){
    //   this.router.navigateByUrl(this.previousRoute);
    // }else{
    //   this.router.navigateByUrl('admin/products');
    // }

  }
  moveToProductVariationGroup() {
    window.location.href = RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS + this.productForm.get('variationGroupId').value;
  }
  saveAndContinue() {
    this.onSubmit(this.closeStatus);
  }
  allReset() {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS_NEW]));
  }
  
  saveAndClose() {
    let isSellingPriceGreaterThanMrp = this.sellingPriceValidation()
    if (this.productForm.get('maxCartQty').value < this.productForm.get('minCartQty').value) {
      this.customMessageService.ShowCustomMessage(messages.max_minCartQuantity, false);
      return;
    }
    if (isSellingPriceGreaterThanMrp || this.tagLimitError || this.brandControl.invalid) {
      return;
    }
    this.markFormCancel = true;
    if (this.productForm.invalid) {
      this.redirectToInvalidTab();
      return
    }
    this.onSubmit(this.closeStatus);
  }
  initProductVariants() {
    this.productForm.get('productVariants').patchValue(this.selectedProductByIndex);
  }
  makeAllFieldsDisable() {
    this.productForm.disable();
    this.categoryControler.disable();
    this.brandControl.disable();
    this.productConditionControler.disable();
    this.productReplacementShippingConditionControler.disable();
    this.productShelfLifeUnitControler.disable();
    this.statusControler.disable();
    if (!this.viewId) {
      this.enableFieldForClonedProducts();
    }
    this.isBasicFormSuccess = true;
  }

  enableFieldForClonedProducts() {
    this.productForm.get('unitOfMeasureId').enable();
    this.productForm.get('fractionAllowed').enable();
    !this.productForm.get('isVariantGroup').value && this.productForm.get('allowFileUpload').enable();
    // this.productForm.get('fileMandatory').enable();
    this.productForm.get('minCartQty').enable();
    this.productForm.get('maxCartQty').enable();
  }
  redirectToUpdate() {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH + this.viewId +RouterLinksEnum.ADMIN_EDIT,
      {
        state:
        {
          checkView:true
        }
  });
  }
  redirectToCopy() {
    if(!this.viewId) return;
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH +RouterLinksEnum.MANAGE_PRODUCTS_NEW,
      {
        state:
        {
          copyId: this.viewId
        }
      });
  }
  redirectToDelete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: 1 }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.productListService.removeData(this.viewId).subscribe(res => {
          this.router.navigate([RouterLinksEnum.ADMIN]);
        })
      }
    })
  }
  redirectToProduct(index: any) {
    let itemsProductVariations = this.productForm.get('productVariants') as FormArray;
    let productId = itemsProductVariations.controls[index].get('productId').value
    window.open(RouterLinksEnum.ADMIN + '/' + RouterLinksEnum.MANAGE_PRODUCTS + productId, '_blank')
  }
  productStatusUpdate() {
    // let toggleElement = event;
    let statsuValue = this.statusControler.value;
    if (this.statusControler.value == "Active") {
      this.statusControler.setValue(this.allProductStatusDropdown[3].value);
    }
    else {
      this.statusControler.setValue(this.allProductStatusDropdown[2].value);
    }
    var productStatusDetail = {
      organizationId: this.orgId,
      productId: this.viewId,
      status: this.statusControler.value

    };
    this.productService.postProductStatus(productStatusDetail)
      .subscribe(res => {
        if (res.data) {
          if (productStatusDetail.status == this.allProductStatus[2]) {
            this.productForm.get(this.status).setValue(3);
          }
          else {
            this.productForm.get(this.status).setValue(4);
          }
          this.customMessageService.ShowCustomMessage(res.statusMessage, true);
        }
      }
        , err => {
          if (err) {
            this.statusControler.setValue(statsuValue);
            // toggleElement.source.checked = !toggleElement.source.checked;
          }
        })
  }

  onRemoveValidationClick() {
    this.productForm.get("unitOfMeasureName").clearValidators();
    this.productForm.get("unitOfMeasureName").updateValueAndValidity();
  }
  getChannel() {
    let requestParameter = "?pageSize=-1";
    this.commonService.getChannels(requestParameter).subscribe(res => {
      this.channelData = res['data'].data
    })
  }
  openAddAssetDialogbox() {
    if (this.channelData.length == 0) {
      this.customMessageService.ShowCustomMessage(messages.addChannel, false);
      return
    }
    const dialogRef = this.dialog.open(AddAssetDialogComponent, {
      data: { id: 1 }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.length) {
        this.addAssetApiCall = false;
        if (result.length > 1) {

          let showSwatchImage = this.checkAssetExtension(result)
          const dialogRef = this.dialog.open(ChannelComponent, {
            data: { channelData: this.channelData, showSwatchImage: showSwatchImage }
          });
          dialogRef.afterClosed().subscribe(res => {

            if (res.selectedAssetChannelIds.length) {
              this.selectedAssetChannelIds = [];
              for (let j = 0; j < res.selectedAssetChannelIds.length; j++) {
                this.selectedAssetChannelIds.push(res.selectedAssetChannelIds[j])
              }
              for (let k = 0; k < result.length; k++) {
                if (res.isSwatchOnlyImage) {
                  result[k]['isSwatchOnlyImage'] = true;
                }
                else {
                  result[k]['isSwatchOnlyImage'] = false;
                }
                result[k]['isPrimary'] = false;
              }
              this.addAssetInfo(result);
            }
          })
        }
        else {
          this.showSwatchImageCheckBox = this.checkAssetExtension(result)
          this.assetTitleName = result[0].fileDetails.fileName;
          this.assetImage = result[0].fileDetails.fileUrl;
          this.isButtonVisible = false;
          this.addAssetRowInfo = result
        }
      }
    })
  }
  addAsset() {
    if (!this.assetImage) {
      this.customMessageService.ShowCustomMessage(messages.selectAsset, false);
      return;
    }
    if (this.addAssetApiCall) {
      let data = {
        fileUrl: this.fileUrl,
        fileName: this.assetTitleName
      }
      let url = "Products/" + this.productId + "/asset"
      this.productService.addProductAsset(data, url).subscribe(res => {
        if (res.data) {
          this.addAssetRowInfo = [res.data];
          this.setAssetFormValue()
        }
      })
    }
    else {
      this.setAssetFormValue()
    }
  }
  setAssetFormValue() {
    if (this.assetTitleName) {
      this.addAssetRowInfo[0].fileDetails.fileName = this.assetTitleName;
    }
    this.addAssetRowInfo[0].isPrimary = false;
    if (this.assetSwatchImage) {
      this.addAssetRowInfo[0].isSwatchOnlyImage = this.assetSwatchImage;
    }
    else {
      this.addAssetRowInfo[0].isSwatchOnlyImage = false;
    }
    this.addAssetInfo(this.addAssetRowInfo)
  }
  addAssetInfo(addAssetRowInfo) {
    let tempData = [];
    for (let i = 0; i < addAssetRowInfo.length; i++) {
      if (this.selectedAssetChannelIds.length) {
        let data = this.addAssetToSelectedChannnel(addAssetRowInfo[i])
        for (let i = 0; i < data.length; i++) {
          tempData.push(data[i])
        }
      }
      else {
        tempData.push(addAssetRowInfo[i])
      }
    }
    this.setAssetFormControlValues(this.assetModifiedData, tempData);
    this.assetTitleName = "";
    this.assetTitle = "";
    this.assetImage = "";
    this.assetSwatchImage = false;
    this.selectedAssetChannelIds = []
    this.isButtonVisible = true;
  }
  addAPlusInfo() {
    let temp = 0;
    let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
    if (this.aPlusContentChannel) {
      for (let i = 0; i < this.channelData.length; i++) {
        if (this.channelData[i].id == this.aPlusContentChannel) {
          this.aPlusContentChannelName = this.channelData[i].name;
        }
      }
    }
    if (this.aPlusImage) {
      this.aPlustableInfo.push({
        version: this.aPlusContentVersion,
        aPlusImgUrl: this.aPlusImage,
        title: this.aPlusFileName,
        description: this.aPlusContentDescription,
        channelId: this.aPlusContentChannel,
        channelName: this.aPlusContentChannelName,
        aPlusMediumThumbnailUrl: this.aPlusMediumThumbnailUrl,
        extension: this.aPlusExtension,
        downloadUrl: this.aPlusFileDowbloadUrl
      })
      for (let i = 0; i < this.aPlustableInfo.length; i++) {
        for (let j = i + 1; j < this.aPlustableInfo.length; j++) {
          if (this.aPlustableInfo[i].displayorder > this.aPlustableInfo[j].displayorder) {
            temp = this.aPlustableInfo[i];
            this.aPlustableInfo[i] = this.aPlustableInfo[j];
            this.aPlustableInfo[j] = temp;
          }
        }
      }
      this.aPlusDataSource.data = this.aPlustableInfo;
      if (aPlusFormControls.controls.length == 0) {
        aPlusFormControls.push(this.aPlusContentArray())
        aPlusFormControls.controls[0].get('title').setValue(this.aPlusFileName);
        aPlusFormControls.controls[0].get('version').setValue(this.aPlusContentVersion);
        aPlusFormControls.controls[0].get('description').setValue(this.aPlusContentDescription);
        aPlusFormControls.controls[0].get('channelId').setValue(this.aPlusContentChannel);
        aPlusFormControls.controls[0].get('channelName').setValue(this.aPlusContentChannelName);
        aPlusFormControls.controls[0].get('fileDetails.extension').setValue(this.aPlusExtension)
        aPlusFormControls.controls[0].get('fileDetails.fileName').setValue(this.aPlusFileName)
        aPlusFormControls.controls[0].get('fileDetails.fileSizeInBytes').setValue(this.aPlusFileSizeInBytes)
        aPlusFormControls.controls[0].get('fileDetails.fileType').setValue(this.aPlusFileType)
        aPlusFormControls.controls[0].get('fileDetails.fileUrl').setValue(this.aPlusFileDowbloadUrl)
        aPlusFormControls.controls[0].get('fileDetails.largeThumbnailUrl').setValue(this.aPlusLargeThumbnailUrl)
        aPlusFormControls.controls[0].get('fileDetails.mediumThumbnailUrl').setValue(this.aPlusMediumThumbnailUrl)
        aPlusFormControls.controls[0].get('fileDetails.smallThumbnailUrl').setValue(this.aPlusSmallThumbnailUrl)
      }
      else {
        aPlusFormControls.push(this.aPlusContentArray())
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('title').setValue(this.aPlusFileName);
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('version').setValue(this.aPlusContentVersion);
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('description').setValue(this.aPlusContentDescription);
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('channelId').setValue(this.aPlusContentChannel);
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('channelName').setValue(this.aPlusContentChannelName);
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.extension').setValue(this.aPlusExtension)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.fileName').setValue(this.aPlusFileName)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.fileSizeInBytes').setValue(this.aPlusFileSizeInBytes)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.fileType').setValue(this.aPlusFileType)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.fileUrl').setValue(this.aPlusFileDowbloadUrl)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.largeThumbnailUrl').setValue(this.aPlusLargeThumbnailUrl)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.mediumThumbnailUrl').setValue(this.aPlusMediumThumbnailUrl)
        aPlusFormControls.controls[aPlusFormControls.controls.length - 1].get('fileDetails.smallThumbnailUrl').setValue(this.aPlusSmallThumbnailUrl)
      }
    }
    this.aPlusContentVersion = ""
    this.aPlusContentImage = ""
    this.aPlusImage = ""
    this.aPlusFileName = ""
    this.aPlusContentDescription = ""
    this.aPlusContentChannel = ""
    this.aPlusMediumThumbnailUrl = ""
    this.aPlusExtension = ""
    this.aPlusFileName = ""
    this.aPlusFileSizeInBytes = ""
    this.aPlusFileType = ""
    this.aPlusFileUrl = ""
    this.aPlusFileDowbloadUrl = ""
    this.aPlusLargeThumbnailUrl = ""
    this.aPlusMediumThumbnailUrl = ""
    this.aPlusSmallThumbnailUrl = ""
    this.aPlusContentChannelName = ""
    this.isAPlusImageVisible = true;
  }
  saveAPlus(index, obj) {
    let temp = 0;
    if (this.aPlusListChannel) {
      for (let i = 0; i < this.channelData.length; i++) {
        if (this.channelData[i].id == this.aPlusListChannel) {
          this.aPlusListChannelName = this.channelData[i].name;
        }
      }
    }
    let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
    aPlusFormControls.controls[index].get('title').setValue(this.aPlusListTitle);
    aPlusFormControls.controls[index].get('description').setValue(this.aPlusListDescription);
    aPlusFormControls.controls[index].get('version').setValue(this.aPlusListVersion);
    aPlusFormControls.controls[index].get('channelId').setValue(this.aPlusListChannel);
    aPlusFormControls.controls[index].get('channelName').setValue(this.aPlusListChannelName);


    for (let i = 0; i < this.aPlustableInfo.length; i++) {
      if (i == index) {
        this.aPlustableInfo[i].title = this.aPlusListTitle
        this.aPlustableInfo[i].description = this.aPlusListDescription
        this.aPlustableInfo[i].version = this.aPlusListVersion
        this.aPlustableInfo[i].channelId = this.aPlusListChannel
        this.aPlustableInfo[i].channelName = this.aPlusListChannelName
      }
    }
    this.seletedAPlusIndex = null;
    this.aPlusDataSource.data = this.aPlustableInfo;
  }
  deleteAPlus(index) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: 1 }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
        this.aPlustableInfo.splice(index, 1);
        this.aPlusDataSource.data = this.aPlustableInfo;
        aPlusFormControls.removeAt(index);
      }
    })
  }
  editAPlus(index, obj) {
    this.aPlusListTitle = obj.title;
    this.aPlusListDescription = obj.description;
    this.aPlusListVersion = obj.version;
    this.aPlusListChannel = obj.channelId;
    this.seletedAPlusIndex = index;
  }
  cancelAPlus() {
    this.seletedAPlusIndex = null;
  }
  filesDropped(files: FileHandle[]): void {
    this.files = files;
    this.UploadStarted = true;
    this.UploadProgress = this.blobStorageService.UploadProgress;
    let obj = {
      fileNameWithExtension: this.files[0].file.name
    }
    this.disableActionsButtonsWhileAPICall = true;
    this.sASTokenService.getSASToken(obj).subscribe(res => {
      this.blobName = res.data.blobName;
      this.containerName = res.data.containerName;
      this.fileUrl = res.data.fileUrl;
      this.serviceUrlWithSAS = res.data.serviceUrlWithSAS;
      this.uploadTokenFile(this.files[0].file, 'Aplus');
    }, err => {
      this.disableActionsButtonsWhileAPICall = false;
    })
  }
  addAplusFileDetails(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    this.uploadFile(files);
  }
  uploadFile(formFile: File) {
    let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
    const formData = new FormData;
    formData.append("formFile", formFile[0], formFile[0].name);
    this.assetService.uploadMedia(formData).subscribe(res => {
      if (res) {
        this.imgURL = res.data['fileUrl'];
        this.isAPlusImageVisible = false;
        this.aPlusExtension = res.data['extension'];
        this.aPlusFileName = res.data['fileName'];
        this.aPlusFileSizeInBytes = res.data['fileSizeInBytes'];
        this.aPlusFileType = res.data['fileType'];
        this.aPlusFileUrl = res.data['fileUrl'];
        this.aPlusFileDowbloadUrl = res.data['fileUrl']
        this.aPlusImage = res.data['mediumThumbnailUrl'];
        this.aPlusLargeThumbnailUrl = res.data['largeThumbnailUrl'];
        this.aPlusMediumThumbnailUrl = res.data['mediumThumbnailUrl'];
        this.aPlusSmallThumbnailUrl = res.data['smallThumbnailUrl'];
      }
    })
  }
  onFileChange(event: any, thumbnailGenerateFor: string = ''): void {
    const file = event.target.files[0];
    if (thumbnailGenerateFor == "Aplus") {
      this.UploadStarted = true;
    }
    else {
      this.assetUploadStarted = true;
    }
    this.UploadProgress = this.blobStorageService.UploadProgress;
    let obj = {
      fileNameWithExtension: event.target.files[0].name
    }
    this.disableActionsButtonsWhileAPICall = true;
    this.sASTokenService.getSASToken(obj).subscribe(res => {
      this.blobName = res.data.blobName;
      this.containerName = res.data.containerName;
      this.fileUrl = res.data.fileUrl;
      this.serviceUrlWithSAS = res.data.serviceUrlWithSAS;
      this.uploadTokenFile(file, thumbnailGenerateFor);
    }, err => {
      this.disableActionsButtonsWhileAPICall = false;
    })
  }
  uploadTokenFile(file: File, thumbnailGenerateFor: string = "") {
    let sasInfo = {
      blobName: this.blobName,
      containerName: this.containerName,
      fileUrl: this.fileUrl,
      serviceUrlWithSAS: this.serviceUrlWithSAS
    }
    this.blobStorageService.uploadBlobToStorage(file, sasInfo).then(response => {
      this._response = response;
      this.UploadCompleted = true;
      let fileInfo = file;
      if (this.UploadCompleted == true) {
        let requestParameter = {
          fileUrl: this.fileUrl
        }
        this.sASTokenService.getAssetThumbnail(requestParameter).subscribe(res => {
          if (res) {
            if (thumbnailGenerateFor == "Aplus") {
              this.UploadStarted = false;
              this.imgURL = res.data['fileUrl'];
              this.isAPlusImageVisible = false;
              this.aPlusExtension = res.data['extension'];
              this.aPlusFileName = res.data['fileName'];
              this.aPlusFileSizeInBytes = res.data['fileSizeInBytes'];
              this.aPlusFileType = res.data['fileType'];
              this.aPlusFileUrl = res.data['fileUrl'];
              this.aPlusFileDowbloadUrl = res.data['fileUrl'];
              this.aPlusImage = res.data['mediumThumbnailUrl'];
              this.aPlusLargeThumbnailUrl = res.data['largeThumbnailUrl'];
              this.aPlusMediumThumbnailUrl = res.data['mediumThumbnailUrl'];
              this.aPlusSmallThumbnailUrl = res.data['smallThumbnailUrl'];
              this.disableActionsButtonsWhileAPICall = false;
            }
            else {
              let fileName = fileInfo.name.substring(0, fileInfo.name.lastIndexOf("."));
              this.assetTitleName = fileName ? fileName : fileInfo.name;
              this.assetImage = res.data.fileUrl;
              this.isButtonVisible = false;
              this.addAssetApiCall = true;
              this.assetUploadStarted = false
              this.disableActionsButtonsWhileAPICall = false;
            }
          }
        }, err => {
          this.disableActionsButtonsWhileAPICall = false;
        })
      }
    }).catch(error => {
      this._response = error;
      this.UploadCompleted = true;
      this.UploadStarted = false;
      this.assetUploadStarted = false;
      this.disableActionsButtonsWhileAPICall = false;
    });
  }
  uploadDragFile(formFile: File) {
    let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
    const formData = new FormData;
    formData.append("formFile", formFile, formFile.name);
    this.assetService.uploadMedia(formData).subscribe(res => {
      if (res) {
        this.imgURL = res.data['fileUrl'];
        this.isAPlusImageVisible = false;
        this.aPlusExtension = res.data['extension'];
        this.aPlusFileName = res.data['fileName'];
        this.aPlusFileSizeInBytes = res.data['fileSizeInBytes'];
        this.aPlusFileType = res.data['fileType'];
        this.aPlusFileUrl = res.data['fileUrl'];
        this.aPlusFileDowbloadUrl = res.data['fileUrl']
        this.aPlusImage = res.data['mediumThumbnailUrl'];
        this.aPlusLargeThumbnailUrl = res.data['largeThumbnailUrl'];
        this.aPlusMediumThumbnailUrl = res.data['mediumThumbnailUrl'];
        this.aPlusSmallThumbnailUrl = res.data['smallThumbnailUrl'];
      }
    })
  }
  addAplusListFileDetails(files, index) {
    if (this.seletedAPlusIndex) {
      this.seletedAPlusIndex = null;
    }
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    this.uploadAplusListFile(files, index);
  }
  uploadAplusListFile(formFile: File, index) {
    let aPlusFormControls = <FormArray>this.productForm.get('aPlusContents');
    const formData = new FormData;
    formData.append("formFile", formFile[0], formFile[0].name);
    this.assetService.uploadMedia(formData).subscribe(res => {
      if (res) {
        this.aPlusListExtension = res.data['extension'];
        this.aPlusListFileName = res.data['fileName'];
        this.aPlusListFileSizeInBytes = res.data['fileSizeInBytes'];
        this.aPlusListFileType = res.data['fileType'];
        this.aPlusListFileUrl = res.data['fileUrl'];
        this.aPlusListImage = res.data['mediumThumbnailUrl'];
        this.aPlusListDownloadUrl = res.data['fileUrl'];
        this.aPlusListLargeThumbnailUrl = res.data['largeThumbnailUrl'];
        this.aPlusListMediumThumbnailUrl = res.data['mediumThumbnailUrl'];
        this.aPlusListSmallThumbnailUrl = res.data['smallThumbnailUrl'];
        aPlusFormControls.controls[index].get('fileDetails.extension').setValue(this.aPlusListExtension)
        aPlusFormControls.controls[index].get('fileDetails.fileName').setValue(this.aPlusListFileName)
        aPlusFormControls.controls[index].get('fileDetails.fileSizeInBytes').setValue(this.aPlusListFileSizeInBytes)
        aPlusFormControls.controls[index].get('fileDetails.fileType').setValue(this.aPlusListFileType)
        aPlusFormControls.controls[index].get('fileDetails.fileUrl').setValue(this.aPlusListFileUrl)
        aPlusFormControls.controls[index].get('fileDetails.largeThumbnailUrl').setValue(this.aPlusListLargeThumbnailUrl)
        aPlusFormControls.controls[index].get('fileDetails.mediumThumbnailUrl').setValue(this.aPlusListMediumThumbnailUrl)
        aPlusFormControls.controls[index].get('fileDetails.smallThumbnailUrl').setValue(this.aPlusListSmallThumbnailUrl)
        aPlusFormControls.controls[index].get('title').setValue(this.aPlusListFileName);
        for (let i = 0; i < this.aPlustableInfo.length; i++) {
          if (i == index) {
            this.aPlustableInfo[i].title = this.aPlusListFileName
            this.aPlustableInfo[i].aPlusImgUrl = this.aPlusListImage
            this.aPlustableInfo[i].extension = this.aPlusListExtension
          }
        }
      }
    })
  }
  DownloadProfilePic(url, title, extension, element) {
    this.httpHelper.downloadData(element.downloadUrl).subscribe(res => {
      var downloadURL = window.URL.createObjectURL(res);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = title + "." + extension;
      link.click();
    })
  }
  productApproval(status) {
    if (this.showApprovedButton == true) {
      let approvalStatus;
      if (ApprovalStatusEnum.Approved == status) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: messages.approveProduct, isError: false, buttonName: 'Ok',name: this.productName }
        });
        // const dialogRef = this.dialog.open(DeleteDialogComponent, {
        //   data: { approvalConfirm: true, name: this.productName }
        // });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 1) {
            approvalStatus = ApprovalStatusEnum.Approved;
            let data = {
              approvalStatus: approvalStatus,
              comments: ""
            }
            this.disableActionsButtonsWhileAPICall = true;
            this.productService.productApproval(data, this.productId).subscribe(res => {
              this.customMessageService.ShowCustomMessage(res.statusMessage, true);
              this.disableActionsButtonsWhileAPICall = false;
              this.router.navigateByUrl(RouterLinksEnum.ADMIN)
            }, err => {
              this.disableActionsButtonsWhileAPICall = false;
            })
          }
        })
      }
      else {
        const dialogRef = this.dialog.open(AddApprovalCommnetDialogComponent, {
          data: { id: 1 }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            approvalStatus = ApprovalStatusEnum.Rejected;
            let data = {
              approvalStatus: approvalStatus,
              comments: result
            }
            this.productService.productApproval(data, this.productId).subscribe(res => {
              this.customMessageService.ShowCustomMessage(res.statusMessage, true);
              this.router.navigateByUrl(RouterLinksEnum.ADMIN)
            })
          }
        })
      }
    }
  }
  addChannelRow() {
    let channelFieldsArray = this.productForm.get('channelProductCodes') as FormArray;
    channelFieldsArray.push(this.channelMappingFields());
  }
  createGlobalUPCDetailsRow() {
    let globalUPCDetailFormArray = this.productForm.get('globalUPCDetails') as FormArray;
    globalUPCDetailFormArray.push(this.getGlobalUPCDetailsFormBuilder());
  }
  addChannelVariantRow() {
    this.variantDetailFormArrayValues = [];
    let channelFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    channelFieldsArray.push(this.channelVariantDetailsFormArrayControls());
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    this.variantDetailFormArrayValues.push(channelVariantFieldsArray.value);
    if (channelFieldsArray.value.length > 0) {
      this.addChannelVariantFormArray(channelFieldsArray.value.length - 1)
    }
  }
  deleteChannelRow(index, event) {
    let channelFieldsArray = this.productForm.get('channelProductCodes') as FormArray;
    if(channelFieldsArray.controls[0] && !channelFieldsArray.controls[0].get('channelId').value){channelFieldsArray.removeAt(0)}
    if (this.productId || this.copyId) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.delete, isError: false, buttonName: 'Ok' }
      });
      // const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //   data: { id: 1 }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          event.preventDefault();
          channelFieldsArray.removeAt(index);
          this.productChannelDataSource = new MatTableDataSource((this.productForm.get('channelProductCodes') as FormArray).controls);
         
        }
      })
    }
    else {
      event.preventDefault();
      channelFieldsArray.removeAt(index);
      this.productChannelDataSource = new MatTableDataSource((this.productForm.get('channelProductCodes') as FormArray).controls);
         
    }
  }
  deleteGlobalUPCDetailsRow(index, event) {
    let globalUPCTypes = this.productForm.get('globalUPCDetails') as FormArray;
    if (this.productId || this.copyId) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.delete, isError: false, buttonName: 'Ok' }
      });
      // const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //   data: { id: 1 }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          event.preventDefault();
          globalUPCTypes.removeAt(index);
        }
      })
    }
    else {
      event.preventDefault();
      globalUPCTypes.removeAt(index);
    }
  }
  deleteChannelVariantRow(index, event) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    if (this.productId || this.copyId) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: messages.delete, isError: false, buttonName: 'Ok' }
      });
      // const dialogRef = this.dialog.open(DeleteDialogComponent, {
      //   data: { id: 1 }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          event.preventDefault();
          channelVariantFieldsArray.removeAt(index);
        }
      })
    }
    else {
      event.preventDefault();
      channelVariantFieldsArray.removeAt(index);
    }
  }

  getWrapedText(text, maxLength) {
    if (text) {
      if (text.length <= maxLength) {
        return text;
      }
      else {
        return text.substring(0, maxLength - 3) + "...";
      }
    }
  }
  openChat(id, name) {
    this.chatDialogService.openChatDialog(PIMEntityType.Product, id, name);
  }
  openHelpDialog() {
    if (this.selectedTabIndex == 0) {
      this.helpGuideService.openHelpGuideDialog(this.featureIds.Product_Basic_Information)
    }
    else if (this.selectedTabIndex == 1) {
      this.helpGuideService.openHelpGuideDialog(this.featureIds.Product_Images_Videos)
    }
    else if (this.selectedTabIndex == 2) {
      this.helpGuideService.openHelpGuideDialog(this.featureIds.Product_Volume_And_Weight)
    }
    else if (this.selectedTabIndex == 3) {
      this.helpGuideService.openHelpGuideDialog(this.featureIds.Product_Shelf_Life_And_Batch)
    }
    else {
      this.helpGuideService.openHelpGuideDialog(this.featureIds.Product_Enhanced_Content)
    }
  }
  checkRequestForApproval(data) {
    if (data.productBrand.id !== null) {
      this.brandsService.getBrandbyBrandId(data.productBrand.id + "?organizationId=" + this.orgId).subscribe(res => {
        if (res.data.approvers) {
          if (res.data.approvers.length > 0) {
            if (data.approvalDetails.approvalStatus == ApprovalStatusEnum["Not Initiated"] || data.approvalDetails.approvalStatus == ApprovalStatusEnum.Rejected) {
              this.showRequestForApprovalButton = true;
            }
            else {
              this.showRequestForApprovalButton = false;
            }
          }
        }
        this.brandControl.setValue(res.data.name);
        this.productForm.get('productBrand').patchValue(res.data);
      });
    }
    else {
      this.showRequestForApprovalButton = false;
    }
  }
  openHistory(id, name) {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Product, id, name);
  }
  bulletPointFormArray(productData) {
    let bulletPointArray = []
    if ((productData.bulletPoints.length)) {
      bulletPointArray.push(productData.bulletPoints[0])
      productData.bulletPoints = [];
      if (bulletPointArray[0].name.split("\n").length) {
        let buttetPoints = bulletPointArray[0].name.split("\n")
        for (let i = 0; i < buttetPoints.length; i++) {
          if (buttetPoints[i].substring(2, buttetPoints[i].length).length && buttetPoints[i].substring(2, buttetPoints[i].length)) {
            let obj = this.removeDotFromString(buttetPoints[i])
            productData.bulletPoints.push(obj)
          }
        }
      }
    }
    return productData;
  }
  onChannelClick() {
    this.checkDuplicateChannel = true;
  }
  addChannelVariantFormArray(index) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    let varintDetailFormArray = channelVariantFieldsArray.controls[index].get('variantDetails') as FormArray
    let variantTypes = this.productForm.get('variantTypes') as FormArray
    if (variantTypes.value.length) {
      varintDetailFormArray.clear();
      for (let i = 0; i < variantTypes.length; i++) {
        if (!variantTypes.value[i].name || !variantTypes.value[i].id) {
          return;
        }
        varintDetailFormArray.push(this.variantDetailsFormArrayControls());
        varintDetailFormArray.controls[i].get('variantId').setValue(variantTypes.value[i].id);
        varintDetailFormArray.controls[i].get('channelVariantName').setValue(variantTypes.value[i].name);
        varintDetailFormArray.controls[i].get('channelVariantNameForDisplay').setValue(variantTypes.value[i].name);
        let variantOption = varintDetailFormArray.controls[i].get('variantOptions') as FormArray
        if (variantTypes.value[i].options.length) {
          variantOption.clear();
          for (let j = 0; j < variantTypes.value[i].options.length; j++) {
            variantOption.push(this.variantOptionsFormArrayControls())
            variantOption.controls[j].get('channelOptionName').setValue(variantTypes.value[i].options[j].name);
            variantOption.controls[j].get('channelOptionNameForDisplay').setValue(variantTypes.value[i].options[j].name);
            variantOption.controls[j].get('optionName').setValue(variantTypes.value[i].options[j].name);
            variantOption.controls[j].get('isEnabled').setValue(true);
          }
        }
      }
    }
  }
  setVariantChannelName(channelId, channelVariantFieldsArray, index) {
    if (this.channelData.length) {
      for (let i = 0; i < this.channelData.length; i++) {
        if (this.channelData[i].id == channelId) {
          channelVariantFieldsArray.controls[index].get('channelName').setValue(this.channelData[i].name)
        }
      }
    }
  }
  onChannelSelection(event, index) {
    if (this.checkDuplicateChannel == false) {
      return
    }
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    this.setVariantChannelName(event.value, channelVariantFieldsArray, index);
    if (channelVariantFieldsArray.value.length) {
      for (let m = 0; m < channelVariantFieldsArray.value.length; m++) {
        if (event.value == channelVariantFieldsArray.value[m].channelId) {
          let message = "Cannot select duplicate channel";
          this.customMessageService.ShowCustomMessage(message, false);
          setTimeout(() => {
            channelVariantFieldsArray.controls[index].get('channelId').setValue('');
            channelVariantFieldsArray.controls[index].get('channelId').markAsUntouched()
          }, 1000);
          return;
        }
      }
    }
  }
  variantDisplayOrder(channelVariantIndex, varianttDetailIndex) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    let varintDetailFormArray = channelVariantFieldsArray.controls[channelVariantIndex].get('variantDetails') as FormArray
    return varintDetailFormArray.controls[varianttDetailIndex].get('displayOrder');
  }
  optionDisplayOrder(channelVariantIndex, varianttDetailIndex, variantOptionIndex) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    let varintDetailFormArray = channelVariantFieldsArray.controls[channelVariantIndex].get('variantDetails') as FormArray
    let variantOptionFormArray = varintDetailFormArray.controls[varianttDetailIndex].get('variantOptions') as FormArray
    return variantOptionFormArray.controls[variantOptionIndex].get('displayOrder');
  }
  channelVariant(index) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    return channelVariantFieldsArray.controls[index].get('channelId');
  }
  variantOptionRequiredCheck() {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    if (channelVariantFieldsArray.value.length) {
      for (let i = 0; i < channelVariantFieldsArray.value.length; i++) {
        let varintDetailFormArray = channelVariantFieldsArray.controls[i].get('variantDetails') as FormArray
        if (varintDetailFormArray.value.length) {
          for (let j = 0; j < varintDetailFormArray.value.length; j++) {
            let variantOptionFormArray = varintDetailFormArray.controls[j].get('variantOptions') as FormArray
            if (variantOptionFormArray.value.length) {
              for (let k = 0; k < variantOptionFormArray.value.length; k++) {
                if (variantOptionFormArray.controls[k].get('isEnabled').value == false || variantOptionFormArray.controls[k].get('isEnabled').value == null ||
                  variantOptionFormArray.controls[k].get('isEnabled').value == '') {
                  variantOptionFormArray.controls[k].get('isEnabled').setValue(false);
                  let variantName = varintDetailFormArray.controls[j].get('channelVariantName').value;
                  if (variantOptionFormArray.value.length - 1 == k) {
                    this.customMessageService.ShowCustomMessage("Please select atleast one option for variant '" + variantName + "' ", false)
                    return false;
                  }
                }
                else {
                  break;
                }
              }
            }
          }
        }
      }
    }
  }
  checkVariantType(data) {
    if (data.variantTypes || data.data.variantTypes) {
      let variantType = [];
      if (data.variantTypes) {
        variantType = data.variantTypes;
      }
      else {
        variantType = data.data.variantTypes;
      }
      for (let i = 0; i < variantType.length; i++) {
        if (!variantType[i].id) {
          if (variantType.length - 1 == i) {
            this.variantDetailStatus = false;
          }
        }
        else {
          this.variantDetailStatus = true;
          break;
        }
      }
    }
  }
  refreshChannelVariantFormObject(productData, channelVariantFieldsArray) {
    productData.channelVariantPresentations.forEach(element => {
      channelVariantFieldsArray.push(this.channelVariantDetailsFormArrayControls());
    });
    for (let i = 0; i < productData.channelVariantPresentations.length; i++) {
      if (productData.channelVariantPresentations[i].variantDetails) {
        let varintDetailFormArray = channelVariantFieldsArray.controls[i].get('variantDetails') as FormArray
        varintDetailFormArray.clear();
        productData.channelVariantPresentations[i].variantDetails.forEach(element => {
          varintDetailFormArray.push(this.variantDetailsFormArrayControls());
        });
      }
    }
  }
  showActualVariantAndOptionNameInChannelVariantPlaceholder(productData, varintDetailFormArray, variantOptionFormArray, i, j) {
    if (productData.variantTypes.length) {
      for (let l = 0; l < productData.variantTypes.length; l++) {
        if (productData.variantTypes[l].id == productData.channelVariantPresentations[i].variantDetails[j].variantId) {
          varintDetailFormArray.controls[j].get('channelVariantNameForDisplay').setValue(productData.variantTypes[l].name)
        }
      }
    }
  }
  refreshChannelVariantForm(productData, callFrom) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    if (productData.channelVariantPresentations && productData.channelVariantPresentations.length) {
      channelVariantFieldsArray.clear();
      this.refreshChannelVariantFormObject(productData, channelVariantFieldsArray);
      for (let i = 0; i < productData.channelVariantPresentations.length; i++) {
        if (productData.channelVariantPresentations[i].variantDetails) {
          for (let j = 0; j < productData.channelVariantPresentations[i].variantDetails.length; j++) {
            if (productData.channelVariantPresentations[i].variantDetails[j].variantOptions) {
              let varintDetailFormArray = channelVariantFieldsArray.controls[i].get('variantDetails') as FormArray
              let variantOptionFormArray = varintDetailFormArray.controls[j].get('variantOptions') as FormArray
              variantOptionFormArray.clear();
              productData.channelVariantPresentations[i].variantDetails[j].variantOptions.forEach(element => {
                variantOptionFormArray.push(this.variantOptionsFormArrayControls());
              });
              if (variantOptionFormArray.length) {
                for (let k = 0; k < variantOptionFormArray.length; k++) {
                  let optionName = productData.channelVariantPresentations[i].variantDetails[j].variantOptions[k].optionName;
                  variantOptionFormArray.controls[k].get('channelOptionNameForDisplay').setValue(optionName)
                }
              }
              this.showActualVariantAndOptionNameInChannelVariantPlaceholder(productData, varintDetailFormArray, variantOptionFormArray, i, j)
            }
          }
        }
      }
      if (callFrom == "AfterVariantSave") {
        channelVariantFieldsArray.patchValue(productData.channelVariantPresentations);
      }
    }
    else {
      channelVariantFieldsArray.clear();
    }
  }
  get() {
    return (this.productForm.get('variantTypes') as FormArray).value;
  }
  dropped(event: any, index) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    moveItemInArray(channelVariantFieldsArray.controls[index].get('variantDetails')['controls'], event.previousIndex, event.currentIndex);
    moveItemInArray(this.get(), event.previousIndex, event.currentIndex);
  }
  dropping(indexChannel: number, indexVarientDetail, indexOption, event: any) {
    let channelVariantFieldsArray = this.productForm.get('channelVariantPresentations') as FormArray;
    let varintDetailFormArray = channelVariantFieldsArray.controls[indexChannel].get('variantDetails') as FormArray
    let variantOptionFormArray = varintDetailFormArray.controls[indexVarientDetail].get('variantOptions') as FormArray
    let controlOption = channelVariantFieldsArray.controls[indexChannel].get(['variantDetails', indexVarientDetail, 'variantOptions']) as FormArray;
    moveItemInArray(channelVariantFieldsArray.controls[indexChannel].get(['variantDetails', indexVarientDetail, 'variantOptions'])['controls'], event.previousIndex, event.currentIndex);
    moveItemInArray(controlOption.value, event.previousIndex, event.currentIndex);
  }
  removeDotFromString(point) {
    let obj = {}
    if (point.includes('•')) {
      if (point.trim().charAt(0) == '•') {
        obj = {
          name: point.trim().substring(1, point.length).trim()
        }
      }
      else {
        obj = {
          name: point.trim()
        }
      }
    }
    else {
      obj = {
        name: point.trim()
      }
    }
    return obj
  }
  setBulletPointInFormArray(productData) {
    if (productData.bulletPoints.length) {
      let name = ''
      let length = productData.bulletPoints.length;
      for (let i = 0; i < productData.bulletPoints.length; i++) {
        if (name) {
          if (productData.bulletPoints[i].name.includes('•')) {
            productData.bulletPoints[0].name = productData.bulletPoints[0].name + "\n" + productData.bulletPoints[i].name
          }
          else {
            productData.bulletPoints[0].name = productData.bulletPoints[0].name + "\n" + '• ' + productData.bulletPoints[i].name
          }
        }
        else {
          name = '• ' + productData.bulletPoints[i].name;
          if (productData.bulletPoints[i].name.includes('•')) {
            productData.bulletPoints[0].name = productData.bulletPoints[i].name
          } else {
            productData.bulletPoints[0].name = '• ' + productData.bulletPoints[i].name
          }
        }
      }
      for (let i = 0; i < length; i++) {
        i = 0;
        productData.bulletPoints.splice(i + 1, 1)
        length = productData.bulletPoints.length;
      }
    }
    return productData;
  }
  GetProductImageDisplayData(ChannelData, assets) {
    this.assetModifiedData = []
    for (let i = 0; i < ChannelData.length; i++) {
      let obj = {
        title: '',
        channelName: ChannelData[i].name,
        channelId: ChannelData[i].id,
        displayOrder: '',
        asset_thumbnail: '',
        assets: []
      }
      this.assetModifiedData.push(obj)
    }
    this.checkChannelAssignedImages(this.assetModifiedData, assets)
  }
  checkChannelAssignedImages(assetModifiedData, assets) {
    if (assets.length) {
      for (let j = 0; j < assets.length; j++) {
        for (let i = 0; i < assetModifiedData.length; i++) {
          if (assetModifiedData[i].channelName == assets[j].channelName) {
            assetModifiedData[i].assets.push(assets[j])
            break;
          }
          if (!assets[j].channelName) {
            assetModifiedData = this.setImagesInAllChannels(assets[j], assetModifiedData);
            break;
          }
        }
      }
      this.setDisplayOrderChannelWise(assetModifiedData);
    }
  }
  setDisplayOrderChannelWise(assetModifiedData) {
    let tempDataSource = JSON.parse(JSON.stringify(assetModifiedData))
    for (let i = 0; i < tempDataSource.length; i++) {
      if (tempDataSource[i].assets.length) {
        for (let j = 0; j < tempDataSource[i].assets.length; j++) {
          tempDataSource[i].assets[j].displayOrder = j + 1;
          tempDataSource[i].assets[j].channelName = tempDataSource[i].channelName
          tempDataSource[i].assets[j].channelId = tempDataSource[i].channelId
        }
      }
    }
    tempDataSource = this.removeChannelIfNoAsset(tempDataSource)
    this.assetModifiedData = tempDataSource;
    this.assetDataSource = tempDataSource;
    if (this.element) {
      for (let k = 0; k < this.assetModifiedData.length; k++) {
        if (this.assetModifiedData[k].channelName == this.element.channelName) {
          this.showHideRow(this.assetModifiedData[k], k)
        }
      }
    }
    else {
      this.selectedAssetRow = undefined;
    }
  }
  setImagesInAllChannels(asset, assetModifiedData) {
    for (let i = 0; i < assetModifiedData.length; i++) {
      assetModifiedData[i].assets.push(asset);
    }
    return assetModifiedData
  }
  editAsset(index, element) {
    this.selectedAssetIndex = index;
    this.assetTitle = element.fileDetails.fileName
    this.assetListChannelId = element.channelId
  }
  cancelAsset() {
    this.selectedAssetIndex = null;
  }
  saveAsset(index, element) {
    this.element = element
    for (let i = 0; i < this.assetModifiedData.length; i++) {
      if (this.assetModifiedData[i].channelName == element.channelName) {
        this.assetModifiedData[i].assets[index].fileDetails.fileName = this.assetTitle;
        if (this.assetListChannelId) {
          this.channelUpdate(index, element.channelName, element.channelId);
          return
        }
      }
    }
    this.selectedAssetIndex = null;
  }
  deleteAsset(index, element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: 1 }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        for (let i = 0; i < this.assetModifiedData.length; i++) {
          if (this.assetModifiedData[i].channelName == element.channelName) {
            this.assetModifiedData[i].assets.splice(index, 1)
            if (this.assetModifiedData[i].assets.length == 0) {
              this.selectedAssetRow = undefined;
              this.assetModifiedData.splice(i, 1)
            }
            break;
          }
        }
        this.element = element
        if (this.assetModifiedData.length) {
          this.setAssetFormControlValues(this.assetModifiedData, null)
        }
        else {
          this.assetDataSource = null;
          this.element = null;
        }
      }
    })
  }
  setAssetFormControlValues(assetModifiedData, addRow) {
    let obj = [];
    for (let i = 0; i < assetModifiedData.length; i++) {
      if (assetModifiedData[i].assets) {
        for (let j = 0; j < assetModifiedData[i].assets.length; j++) {
          obj.push(assetModifiedData[i].assets[j])
        }
      }
    }
    if (addRow) {
      if (addRow.length) {
        for (let k = 0; k < addRow.length; k++) {
          obj.push(addRow[k])
        }
      }
    }
    this.GetProductImageDisplayData(this.channelData, obj);
  }
  channelUpdate(index, matchedChannelName, matchedChannelId) {
    if (this.assetListChannelId) {
      this.checkUpdateChannelPresent(this.assetListChannelId)
      for (let i = 0; i < this.assetModifiedData.length; i++) {
        if (this.assetModifiedData[i].channelName == matchedChannelName) {
          for (let j = 0; j < this.assetModifiedData.length; j++) {
            if (this.assetModifiedData[j].channelId == this.assetListChannelId && this.assetListChannelId != matchedChannelId) {
              this.assetModifiedData[i].assets[index].channelName = this.assetModifiedData[j].channelName;
              this.assetModifiedData[i].assets[index].channelId = this.assetModifiedData[j].channelId;
              this.assetModifiedData[j].assets.push(this.assetModifiedData[i].assets[index])
              this.assetModifiedData[i].assets.splice(index, 1)
              break;
            }
          }
        }
      }
      this.setAssetFormControlValues(this.assetModifiedData, null)
    }
  }
  dropAsset(event: CdkDragDrop<string[]>, element) {
    if (this.viewId) {
      return;
    }
    this.element = element
    for (let i = 0; i < this.assetModifiedData.length; i++) {
      if (this.assetModifiedData[i].channelName == element.channelName) {
        moveItemInArray(this.assetModifiedData[i].assets, event.previousIndex, event.currentIndex);
        this.setAssetFormControlValues(this.assetModifiedData, null)
      }
    }
  }
  showHideRow(element, index) {
    this.selectedAssetIndex = null;
    this.selectedAssetRow = index
    this.expandedElement = this.expandedElement === element ? null : element
    if (this.expandedElement == null) {
      this.selectedAssetRow = undefined;
    }
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  addAssetToSelectedChannnel(asset) {
    let obj = [];
    for (let j = 0; j < this.channelData.length; j++) {
      for (let k = 0; k < this.selectedAssetChannelIds.length; k++) {
        if (this.channelData[j].id == this.selectedAssetChannelIds[k]) {
          asset['channelName'] = this.channelData[j].name;
          asset['channelId'] = this.channelData[j].id;
          obj.push(JSON.parse(JSON.stringify(asset)))
        }
      }
    }
    return obj
  }
  removeChannelIfNoAsset(tempDataSource) {
    let length = tempDataSource.length;
    for (let i = 0; i < length; i++) {
      if (tempDataSource[i].assets.length == 0) {
        tempDataSource.splice(i, 1)
        length = tempDataSource.length
        i = -1;
      }

    }
    return tempDataSource;
  }
  checkUpdateChannelPresent(channelId) {
    for (let i = 0; i < this.assetModifiedData.length; i++) {
      if (channelId != this.assetModifiedData[i].channelId) {
        if (i == this.assetModifiedData.length - 1) {
          for (let j = 0; j < this.channelData.length; j++) {
            if (channelId == this.channelData[j].id) {
              let obj = {
                title: '',
                channelName: this.channelData[j].name,
                channelId: this.channelData[j].id,
                displayOrder: '',
                asset_thumbnail: '',
                assets: []
              }
              this.assetModifiedData.push(obj)
            }
          }
        }
      }
    }
  }
  onAssetSaveAndContinue() {
    let assetFormControls = <FormArray>this.productForm.get('assets');
    assetFormControls.clear();
    for (let i = 0; i < this.assetModifiedData.length; i++) {
      if (this.assetModifiedData[i].assets) {
        for (let j = 0; j < this.assetModifiedData[i].assets.length; j++) {
          assetFormControls.push(this.formBuilder.control(this.assetModifiedData[i].assets[j]))
        }
      }
    }
    for (let k = 0; k < assetFormControls.length; k++) {
      assetFormControls.value[k].isPrimary = false;
    }
    this.onSubmit(false)
  }
  checkAssetExtension(result) {
    let showSwatchImage = true;
    if (result.length) {
      for (let i = 0; i < result.length; i++) {
        if (result[i].fileDetails.extension == 'png' || result[i].fileDetails.extension == 'jpg' ||
          result[i].fileDetails.extension == 'PNG' || result[i].fileDetails.extension == 'JPG' || result[i].fileDetails.extension == 'jpeg' ||
          result[i].fileDetails.extension == 'JPEG' || result[i].fileDetails.extension == 'bmp' || result[i].fileDetails.extension == 'BMP' ||
          result[i].fileDetails.extension == 'tiff' || result[i].fileDetails.extension == 'TIFF') {
          showSwatchImage = true;
        }
        else {
          showSwatchImage = false;
          this.assetSwatchImage = false;
          break;
        }
      }
      return showSwatchImage
    }
  }
  sellingPriceValidation() {
    let mrp = Number(this.productForm.get('mrp').value)
    let sellingPrice = Number(this.productForm.get('sellingPrice').value)
    if (mrp < sellingPrice) {
      this.customMessageService.ShowCustomMessage(messages.mrpValidation, false);
      return true
    }
    else {
      return false;
    }
  }
  clonedProduct(productData) {
    if (!this.viewId && productData.isClonedProduct) {
      this.hideFieldsForCloneProduct = false;
      this.makeAllFieldsDisable();
      this.productForm.get('sku').enable();
      this.productForm.get('status').enable();
      this.productForm.get('mrp').enable();
      this.productForm.get('sellingPrice').enable();
      let channelProductCodes = this.productForm.get('channelProductCodes') as FormArray
      channelProductCodes.enable();
      this.productForm.get('isPerishable').enable();
      this.productForm.get('shelfLife').enable();
      this.productForm.get('maintainBatch').enable();
      this.productForm.get('serializable').enable();
      this.productForm.get('inventoryMethod').enable();
      this.productForm.get('returnable').enable();
      this.productShelfLifeUnitControler.enable();
    }

    if (+productData.status == ProductStatusEnum.Draft) {
      // this.productForm.get('isPublicProduct').disable();
      this.productForm.get('isPublicProduct').setValue(false);


    }

  }

  checkUniqueUpc(event) {
    if (event) {
      let queryString = `?upcValue=${event}`
      if (this.productId) {
        queryString += `&productId=${this.productId}`
      }
      this.productService.checkUniqueUpcInBaseOrganization(queryString).subscribe(res => {
        if (res.data && res.data.item2 == true) {
          // let message = `There is already a product available in master catalouge with Global UPC value ${event}. Please either change it or you can use the existing product from the catalouge.`
          this.customMessageService.ShowCustomMessage(res.data.item1, false);
        }
      })
    }
  }
  setApprovalStatusValue(productDetails) {
    if (productDetails["approvalDetails"]["approvalStatus"] == ApprovalStatusEnum["Pending For Approval"]) {
      this.approvalStatus = "(" + productDetails["approvalDetails"]["approvalStatusName"] + ")"
    }

  }

  // get checkToggleDisabled(){
  //   return this.productInfo.status == ProductStatusEnum.Discontinued ?  true :  false;
  // }
  currentTextAreaLength() {
    let bulletPointhtml = document.getElementById(this.bulletPoint);
    if (bulletPointhtml && bulletPointhtml[this.value].length > this.bulletPointMaxLength) {
      return true;
    }
    return false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
    this.currentDevice.emit(this.deviceDetect.currentDevice());
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }

  deleteAssetImage() {
    this.assetTitleName = '';
    this.assetImage = '';
    this.addAssetApiCall = false;
    this.addAssetRowInfo = [];
    this.isButtonVisible = true;
  }

  calculatePricePerUnit() {
    let pricePerUnit = (this.productForm.get('sellingPrice').value / 1000) * this.productForm.get('unitWeightInGrams').value;
    this.pricePerUnit = Number(pricePerUnit.toFixed(2));
  }

  addNewBrand() {
    const dialogRef = this.dialog.open(AddBrandDialogComponent, {
      data: { }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { addBrand: "Brand" }
    // });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        let obj = {
          name: result.data,
          active: true,
          _Paxcom_OrganizationId: this.orgId
        }
        this.brandsService.postBrand(obj).subscribe(res => {
          if (res && res.data) {
            this.brandResponse.push(res.data);
            this.allBrandsValues.push(res.data);
            this.productForm.get('productBrand').setValue(res.data);
            this.brandControl.setValue(res.data.name);
            this.customMessageService.ShowCustomMessage(res['statusMessage'], true);
          }
        })
      } else {
        !this.isServerSideBrandSearch && (this.brandResponse = this.allBrandsValues);
      }
    });
  }
  addNewCategory() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
        data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result.data) {
            let obj = {
                name: result.data,
                description: '',
                id: '',
                parentCategoryName: '',
                parentCategoryId: '',
                fullHierarchicalName: '',
                childCount: 0,
                active: true,
                _Paxcom_OrganizationId: this.orgId,
                header:'',
                footer:''
            }
            this.categoryService.postCategory(obj).subscribe(res => {
                if (res && res.data) {
                    this.categoriesResponse.push(res.data);
                    this.allCategoriesValues.push(res.data);
                    this.productForm.get('productCategory').setValue(res.data);
                    this.categoryControler.setValue(res.data.name);
                    this.customMessageService.ShowCustomMessage(res['statusMessage'], true);
                }
            })
        }else if(result.isOpenAddCategoryBoolean){
          this.getAllCategories('')
       }
        else {
            !this.isServerSideBrandSearch && (this.categoriesResponse = this.allCategoriesValues);
        }
    });
}

  //****custom validator for brand/category autocomplete valid options*****//
  validOptionValidator(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control.value || '').length) return null;
      let item = options && options.length && options.find(item => control.value == item.name);
      return !item ? { validOptions: { value: control.value } } : null;
    };
  }

  validOptionValidatorForCategory(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control.value || '').length) return null;
      let item = options && options.length && options.find(item => control.value == item.fullHierarchicalName);
      return !item ? { validOptions: { value: control.value } } : null;
    };
  }

  checkIsBrandLinked() { // we are using this function while uploading image/video
    if (!this.brandControl.value) {
      this.customMessageService.ShowCustomMessage(messages.brandNotAdded, false);
      return
    }
  }
  redirectToInvalidTab() {
    this.isFormSubmitted = true;
    let formControlKeys = Object.keys(this.productForm.value)
    if (!this.productForm.get('isVariantGroup').value) {
      for (let i = 0; i < formControlKeys.length; i++) {
        if (this.productForm.get(formControlKeys[i]).invalid) {
          for(var tabIndex in this.formInputAndTabMap){
          if (this.formInputAndTabMap[tabIndex].includes(formControlKeys[i])) {
            this.selectedTabIndex = +tabIndex
          }
        } 
        }
      }
    }
    else{
    for (let i = 0; i < formControlKeys.length; i++) {
      if (this.productForm.get(formControlKeys[i]).invalid) {
        for(var tabIndex in this.formInputAndTabMapForVariantGroups){
          if (this.formInputAndTabMapForVariantGroups[tabIndex].includes(formControlKeys[i])) {
            this.selectedTabIndex = +tabIndex
          }
        } 
      }}
    }
    this.scrollingToErrorsService.scrollToError();
  }


  openChannelWiseProductDialog(index=null,element={}){
    let filteredChannels=[]
    for(let i=0;i<this.channelData.length;i++){
      let channelFound=false;
      for(let j=0;j<this.productChannelDataSource.data.length;j++){
        const tempFormGroup=this.productChannelDataSource.data[j];
          if(this.channelData[i]['id']==tempFormGroup.get('channelId').value){
            channelFound=true
          }
      }
      if(!channelFound){filteredChannels.push(this.channelData[i])}
    }
    let dialogOptions={}
    if(index!=null){
      dialogOptions={
        data:{
          channelProductCodes:element
        },
        width:"70%"
      }
    }
    else{
      dialogOptions={
        data:{
          channelData:filteredChannels
        },
        width:"70%"
      }
    }

    let channelFieldsArray = this.productForm.get('channelProductCodes') as FormArray;
    if(channelFieldsArray.controls[0] && !channelFieldsArray.controls[0].get('channelId').value){channelFieldsArray.removeAt(0)}
    const dialogRef = this.dialog.open(AddChannelWiseProductDataDialogComponent, 
     dialogOptions);
     dialogRef.afterClosed().subscribe(res=>{
       if(res){
         if(index!=null){
           channelFieldsArray.removeAt(index);
           channelFieldsArray.insert(index,res.data);
         }else{
           channelFieldsArray.push(res.data);
         }
        this.productChannelDataSource = new MatTableDataSource((this.productForm.get('channelProductCodes') as FormArray).controls);
            
       }
     })
  }
 
  showNewButton(){
    return  !this.viewId && (this.productChannelDataSource.data.length != this.channelData.length)? true:false
  }

  channelProductDataTable(){
    let channelFieldsArray = this.productForm.get('channelProductCodes') as FormArray;
    if(channelFieldsArray.controls[0] && !channelFieldsArray.controls[0].get('channelId').value){channelFieldsArray.removeAt(0)}
    this.productChannelDataSource = new MatTableDataSource((this.productForm.get('channelProductCodes') as FormArray).controls);
            
  }

  formResetWhenVariantChecked(){
    let controlNames = {'status':1,'hsnCode':'','brandMRP':0,'mrp':0,'sellingPrice':0,'returnable': false,'allowedReturnPeriodInDays':'', 'tags':[]};
    Object.keys(controlNames).map(item => this.productForm.get(item).setValue(controlNames[item]));
    let controlsToReset = ['globalUPCDetails', 'images'];
    controlsToReset.map((value) => this.productForm.get(value).reset());
  }
}