export enum RouteUrl{
    HomePage = '',
    Menu='/menu',
    Search = 'search',
    OrderHistoryPage = 'order-history/orders',
    CartPage = 'cart',
    WishlistPage = 'wishlist',
    CategoryPage = 'categories',
    CustomPage = 'page',
    CustomPage_Slash='page/',
    ProductDetailPage = 'product',
    NewReview = '/review/new',
    OrderPlacePage = 'order/place',
    CompactOrderPlacePage = 'order/place',
    TermsAndConditionsPage = 'page/terms-and-conditions',
    AddressPage = 'order-history/addresses',
    OrderDetail='order-history/orders',
    ADMIN_ESTORE_SLASH='/admin/e-store/',
    ADMIN_ESTORE='/admin/e-store',
    ADMIN_ORDERS='/admin/orders',
    EDIT='/edit',
    Payment = 'checkout',
    Razorpay = 'payment/razorpay',
    UnableToProcessPayment = 'unableToProcessPayment',
    Menu_Slash = "menu/",
    Paytm = 'payment/paytm',

}