import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { AppConstants } from '../../constants/global';
import { UrlSessionStorageService } from '../url-session-storage.service';

export const authConfig: AuthConfig = {
  issuer: environment.identityauthorityUrl,
  redirectUri: `${location.origin}/auth-callback`,
  clientId: 'js_pkce',
  responseType: 'code',
  scope: 'openid profile offline_access shop',
  postLogoutRedirectUri: `${location.origin}?postlogoutRedirect=true`,
  // sessionChecksEnabled: true,
  // sessionCheckIntervall: 5000,
  // silentRefreshRedirectUri : `${location.origin}/assetes/silent-renew.html`,
  requireHttps: environment.httpsOnlyAccess,
  showDebugInformation: true,
}

export const AUTH_STORAGE_ITEMS: string[] = [
  'access_token',
  'access_token_stored_at',
  'expires_at',
  'granted_scopes',
  'id_token',
  'id_token_claims_obj',
  'id_token_expires_at',
  'id_token_stored_at',
  'nonce',
  'PKCE_verifier',
  'refresh_token',
  'session_state'
];
@Injectable()
export class AuthService implements OnInit {
  private loginRequestInQueue: boolean = false;

  constructor(private oauthService: OAuthService, public _router: Router, 
    public _urlSessionStorageService: UrlSessionStorageService) {
  }

  ngOnInit() {
  }

  Authorize() {
    this.oauthService.initLoginFlow();
  }

  Register() {
    //Add register parameter to redirectUri to initiate register flow
    this.oauthService.initLoginFlow(null, { register: true });
  }

  Logoff() {
    if (this.isLoggedIn()) {
      this.oauthService.logOut();
    } else {
      this._router.navigate(['']);
    }
  }

  GetToken(): string {
    return this.oauthService.getAccessToken();
  }

  initializeUser(): Promise<any> {
    if (!this.loginRequestInQueue) {
      this.loginRequestInQueue = true;
      return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(res => {
        this.loginRequestInQueue = false;
        return res;
      });
    } else {
      return Promise.resolve(true);
    }
  }

  isLoggedIn(): boolean {
    // let claims = this.oauthService.getIdentityClaims();
    //   if (!claims) return false;
    //   return true;
    return this.oauthService.hasValidAccessToken();
  }

  redirectToOrg() {
    let redirectUrl = environment.organisationUrl + '?InOut_Current_Application=' + sessionStorage.getItem(AppConstants.APPLICATION_SHORT_NAME);
    // let redirectUrl = environment.organisationUrl + '?InOut_Current_Application=Auth&InOut_SelectApp=true';
    location.href = redirectUrl;

  }

  async validateAndRefreshTokenIfRequired() {
    //valid access Token
    if (this.isLoggedIn()) {
      return this.RefreshTokenSuccessResponse();
    }

    //Get Refresh Token and Access Token using refresh token rotation 
    if (this.oauthService.getRefreshToken()) { //Refresh token available
      try {
        let response = await this.oauthService.refreshToken();        
        return response ? this.RefreshTokenSuccessResponse() : this.RefreshTokenFailureResponse();
      } catch (err) {
        return this.RefreshTokenFailureResponse();
      }
    }
  }

  private RefreshTokenSuccessResponse() {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  private RefreshTokenFailureResponse() {
    return new Promise((resolve) => {
      resolve(false);
    });
  }

}
