import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommentService {
  constructor(private httpClient: HttpClient,private httpHelper: HttpHelperService,) { }

  getAllComments(querySyring = ""): Observable<any> {
    let url = 'Comments';
    return this.httpHelper.getData(url + querySyring);
  }
  getCommentsById(id): Observable<any> {
    let url = 'Comments/'+id;
    return this.httpHelper.getData(url);
  }
  saveComment(data:any) : Observable<any>
  {
    let url = 'Comments';
    return this.httpHelper.postData(data,url);
  }
  updateComment(data:any) : Observable<any>
  {
    let url="Comments"
    return this.httpHelper.putData(data,url);
  }
  deletCommentById(id)
  {
    let url="Comments/"+id
     return this.httpHelper.delete(url);
  }
  getSampleReportFile() : Observable<any>{
   // let url="Product/bulkUpload/downloadSample"
   
    //--------Refactored URLs 6/21/2021 ---------------
    let url="Products/import/sample"
    return this.httpHelper.downloadExcel(url);
  }
}
