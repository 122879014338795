import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DataService} from './core/data.service';
import { HighlightModule } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { AuthService } from './core/Authorization/auth.service';
import { StorageService } from './core/storage.service';
import { AuthInterceptor } from './core/Authorization/auth-interceptor';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { LoaderInterceptor } from './core/loader/loading-interceptor';
import { ServerErrorInterceptor } from './core/error-handler-global/server-error.interceptor';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { DateService } from './core/Common/date.service';
import { ScrollingToErrorsService} from './core/Common/scrollingToErrors.service';
import { GlobalErrorHandlerService } from './core/common_services/globalErrorHandler/global-error-handler.service';
import { GetHistoryService} from './core/common_services/hitory/historyService';
import { CustomDateService } from './core/common_services/customDates/customDateService';
import { MaterialModule } from './shared/modules/material.module';
// import { OrderSharedModule } from 'projects/Order/src/app/app.module';
// import { ProductSharedModule } from 'projects/PIM/src/app/app.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { InventorySharedModule } from 'projects/Warehouse/src/app/app.module';
// import { ShopSharedModule } from 'src/app/modules/shop/app.module';
// import { OnboardSharedModule } from 'projects/Onboard/src/app/app.module';
import { NoOrganizationFoundComponent } from './no-organization-found/no-organization-found.component';
import { PIMModule } from './modules/pim/pim.module';
import { HelpGuideComponent } from './modules/pim/shared/help-guide/helpGuide.dialog.component';
import { PipesModule } from './shared/modules/pipes.module';
import { OrderDetailsComponent } from './OrderDetails/order-details/order-details.component';
import { NgxBarcodeModule } from 'ngx-barcode';


export function hljsLanguages() {
  return [
    {name: 'typescript', func: typescript},
    {name: 'scss', func: scss},
    {name: 'xml', func: xml}
  ];
}

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory() : OAuthStorage {
  return environment.authStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NoOrganizationFoundComponent,
    OrderDetailsComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxBarcodeModule,
    BrowserModule,
    PIMModule,
    PipesModule,
    // ProductSharedModule.forRoot(),
    // OrderSharedModule.forRoot(),
    // InventorySharedModule.forRoot(),
    // eStoreAdminSharedModule.forRoot(),
    // OnboardSharedModule.forRoot(),
    // ShopSharedModule.forRoot(),
    HighlightModule.forRoot({ languages: hljsLanguages }),
    OAuthModule.forRoot({resourceServer: {
        allowedUrls: [environment.apiBaseUrl],
        sendAccessToken: true
      }
    })
  ],
  entryComponents:[HelpGuideComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [DateService, DataService, AuthService, 
    StorageService, ScrollingToErrorsService,GetHistoryService,CustomDateService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService}
  ],
  bootstrap: [AppComponent],
  exports:[
  ]
})
export class AppModule { }


