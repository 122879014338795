import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from 'src/app/modules/order/core/Common/http-helper.service';


@Injectable({
  providedIn: 'root'
})
export class UsersListService {

  constructor(private httpHelper:HttpHelperService) { }

  getUsersList(queryString: string): Observable<any> {
    let url = 'users/search?searchText=';
    return this.httpHelper.getData(url + queryString);
  }

  getPickerList(queryString: string): Observable<any> {
    let url = 'pickers?searchText=';
    return this.httpHelper.getData(url + queryString);
  }
}
