import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from '../../../dialogs/warning/warning.dialog.component';
import { AssetStatusEnum } from '../../../ENUM/assetEnum';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { BrandViewModel } from '../../../models/Brands/BrandViewModel';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { AssetService } from '../../../services/Assets/asset.service';
import { AuthAccessService } from '../../../services/Authorization/auth-access.service';
import { BrandsService } from '../../../services/Brands/brands.service';
import { CommonService } from '../../../services/Common/common.service';
import { CustomDateService } from '../../../services/Common/customDateService';
import { HttpHelperService } from '../../../services/Common/http-helper.service';
import { ProductListService } from '../../../services/Product/product-list.service';
import { BulkUpdateComponent } from '../../../shared/bulk-update/bulkUpdate.dialog.component';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-assets-default',
  templateUrl: './assets-default.component.html',
  styleUrls: ['./assets-default.component.scss']
})
export class AssetsDefaultComponent implements OnInit {
  @Input() hideTopPart:boolean  //added to inject product into category
  @Input() injectedSearchText
  @Input() injectedCategoryId
  @Input() hideTableHeader


  // displayedColumns = ['icon','name','updatedOn','updatedBy','history_icon', 'toggle_slide', 'edit_icon', 'delete_icon'];
  displayedColumns = ['select','icon','name','updatedOn','updatedBy','actions'];

  //public dataSource = new MatTableDataSource<Comment>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  public dataSource = new MatTableDataSource<BrandViewModel>();
  public dataSourceEmpty =  [];

  index: number;
  id: number;
  brandName: string;
  active: boolean;
  updateBrandStat: {};
  searchText: string; 
  options: Array<string>;
  bIds: Array<string>;
  response: Array<BrandViewModel>;
  PermissionsEnum: any;
  RouterLinksEnum: any;
  valPer: {};
  hideTable: boolean = true;

  orgId: string;  
  orgName:any;
  appLogoUrl:any;  
  jsonOrgData: string;  
  organizationData:Array<any>;
  jsonAppData: string;
  applicationData: Array<any>;
  titlename: string;
  titleEnum: typeof titlename;
  assetId: any;
  assetTypes: { id: number; name: string; }[];
  assetStatus: { id: number; name: string; }[];
  assetForm: any;
  categorySelected: any;
  brandSelected: any;
  categoriesResponse: any = [];
  brandResponse: any = [];
  routeId: any;
  dataRefresh: BrandViewModel[];
  selection = new SelectionModel<any>(true, []);
  assetsLength: any;
  bulkAssetForm: any;
  displayTotal:number;
  isMobile:boolean = true;
  constructor( public httpClient: HttpClient, public httpHelper:HttpHelperService, public dialog: MatDialog,private route:ActivatedRoute,private formBuilder: FormBuilder,
    public dataService: BrandsService, private productSearchService: ProductListService,private router:Router,private assetService:AssetService,
    public commonService: CommonService, private authAccessService: AuthAccessService,private historyDialogService:HistoryDialogService,private chatDialogService:ChatDialogService,
    private CustomMessage: CustomMessageService,private brandsService: BrandsService,
    private customDateService:CustomDateService,private deviceDetect:DeviceDetect ) {
      
     }

  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Output() callParent = new EventEmitter();

  token: any;
  newData: Array<BrandViewModel>
  noMoreDataMessage: string = "No more data to show";
  hideButton: boolean = true; 

  searchItem(brandName){
    
    this.dataService.searchBrand("Name="+brandName)
          .subscribe(res => {
            let message = res['statusMessage'];
            
            this.CustomMessage.ShowCustomMessage(message, true);
          });
  }
getAllAsset()
{
  this.assetService.getAllAssets().subscribe(res=>{
    if(res.data.data.length>0){
    this.dataSource.data = res['data'].data;
    this.displayTotal = this.getDisplayTotal();
    this.newData=res['data'].data;
    this.token = res.data.paging.continuationToken;
    if (this.token !== null) {
      this.hideButton = false;
    }
    else {
      this.hideButton = true;
    }
  } else {
    this.displayTotal = this.getDisplayTotal();
  }
  })

}
  removeSelectedItem(i, id, name, tStatus) {
    let updateBrandStat: BrandViewModel = {
      id: id,
      name: name,
      active: tStatus,
      _Paxcom_OrganizationId: this.orgId.toString()
    };    
       const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: updateBrandStat,
      };
      this.dataService.deleteBrand(options)
     .subscribe(res => {
         let message = res['statusMessage'];
         
         this.CustomMessage.ShowCustomMessage(message, true);      
          
           this.dataSource.data.splice(i, 1);  
           this.dataSource.paginator = this.paginator;  
           this.displayTotal = this.getDisplayTotal();
       });
   }

  deleteItem(i: number, id: string, name: string, active: boolean) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {id: id, name: name}    
    });
    
    dialogRef.afterClosed().subscribe(result => {
         if (result === 1) {
          this.removeSelectedItem(i, id, name, active);
         }
       });
  }
  updateAssetStatus(id, eStatus) {
    let updateVariantStat = {
      id: id,
      active: eStatus,
    };
    this.assetService.updateAseetStatus(updateVariantStat).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);

      }
    });
  }
  deleteAsset(assetIdId,name,i)
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: 1,name:name }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.assetService.deletAssetById(assetIdId).subscribe(data=> {
          this.CustomMessage.ShowCustomMessage(data.statusMessage, true);
          this.dataRefresh = this.dataSource.data;
          this.dataSource.data.splice(i, 1);
        this.dataSource.data = this.dataRefresh;
        this.displayTotal = this.getDisplayTotal();
        }, err => {
          this.CustomMessage.ShowCustomMessage(err.error.statusMessage, false);
        }
        )
      }
    })
  }

  DownloadProfilePic(url,title,extension) {
    this.httpHelper.downloadData(url).subscribe(res=>{
       var downloadURL = window.URL.createObjectURL(res);
       var link = document.createElement('a');
       link.href = downloadURL;
       link.download = title+"."+extension;
       link.click();})
   } 

  setBrandName(brandInfo)
  {
    //sessionStorage.setItem("brandName",brandInfo.name);
  }
  onToggle(id, name, tStatus, event)
  {
    let toggleElement = event;
      this.bIds = id;

      if (tStatus === false) {
        this.productSearchService.getData("?organizationId=" + this.orgId + "&BrandIds=" + this.bIds)
          .subscribe(res => {
            if (res) {
                  if (res.data.data.length > 0) {
                    let message="There are products created under this brand. Do you still want to deactivate this brand?";
                    const dialogRef = this.dialog.open(WarningDialogComponent, {
                      data: { id: id, name: name,message:message }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      if (result === 1) {
                        this.changeToggleStatus(id, tStatus);
                      }
                      
                  else {
                    toggleElement.source.checked = !toggleElement.source.checked;
                    return false;
                  }
                });  
              }

              else {
                this.changeToggleStatus(id, tStatus);
              }
            }
          })
      }
      
      else {
        this.changeToggleStatus(id, tStatus);
      }
  }

  changeToggleStatus(id, tStatus) {
      if (tStatus === true) {
        this.active = true;
        this.updateSelectedItem(id, this.active);
      }
      else {
        this.active = false;
        this.updateSelectedItem(id, this.active);
      }
  }

  updateSelectedItem(id, eStatus) {
    let updateVariantStat: BrandViewModel = {
      id: id,
      active: eStatus,
      _Paxcom_OrganizationId: this.orgId
    };
    this.dataService.updateBrandStatus(updateVariantStat).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);

      }
    });
  }

  getSearchResults(searchtext) {
    if(searchtext!=="" && searchtext!==undefined && searchtext!==null){
      this.dataSource.data = [];
      this.hideTable = true; 
            this.dataService.getAllBrands("organizationId=" + this.orgId + "&searchText=" + searchtext)
              .subscribe(res => {
                if (res.data.data) {
                  this.hideTable = false;
                  this.dataSource.data = res.data.data as BrandViewModel[];
                  this.displayTotal = this.getDisplayTotal();
                  this.token = res.data.paging.continuationToken;
                  if (this.token !== null) {
                    this.hideButton = false;
                  }
                  else {
                    // this.CustomMessage.ShowCustomMessage("No record found", true);
                    this.hideButton = true;

                  }
                }else {
                  // this.CustomMessage.ShowCustomMessage("No record found", true);
                  this.hideButton = true;
                  this.hideTable = true;
                  this.displayTotal = this.getDisplayTotal();
                }
              });
        this.dataSource.paginator = this.paginator;
  }
}

  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {
    let param;
    if (token !== null || token !== undefined) {
      if(this.searchText){
        param="?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token)+"&FullText="+this.searchText
        this.assetService.getAllAssets(param).subscribe(res=>{
          if(res.data.data.length>0){
            for (let i = 0; i < res.data.data.length; i++) {
              this.newData.push(res.data.data[i]);
            }
            this.token = res.data.paging.continuationToken;
            if (this.token) {
              this.hideButton = false;
            }
            else{
              this.hideButton=true;
            }
            this.dataSource.data = this.newData;
            this.displayTotal = this.getDisplayTotal();
        }
        })
      }
      else{
        if(this.injectedCategoryId){
        param="?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token)+"&AssetCategoryId=" + this.injectedCategoryId;
        this.assetService.getAssetById(param)
        .subscribe(res => {
          if (res.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              this.newData.push(res.data.data[i]);
            }
            this.token = res.data.paging.continuationToken;
            if (this.token) {
              this.hideButton = false;
            }
            else{
              this.hideButton=true;
            }
            this.dataSource.data = this.newData;
            this.displayTotal = this.getDisplayTotal();
          }
        })
      }
      else{
        param="?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token);
        this.assetService.getAllAssets(param).subscribe(res=>{
          if(res.data.data.length>0){
            for (let i = 0; i < res.data.data.length; i++) {
              this.newData.push(res.data.data[i]);
            }
            this.token = res.data.paging.continuationToken;
            if (this.token) {
              this.hideButton = false;
            }
            else{
              this.hideButton=true;
            }
            this.dataSource.data = this.newData;
            this.displayTotal = this.getDisplayTotal();
        }
        })
      }
    }
      
    }
  }
  assestFormControls()
  {
    this.assetForm = this.formBuilder.group({
      id:[''],
      fileDetails : this.formBuilder.group({
        fileName: [''],
        fileType: [''],
        fileUrl: [''],
        extension:[''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      }),
      title:[''],
      description:[''],
      tags:[[]],
      status:[''],
      type:[''],
      brandId:[''],
      brandName:[''],
      assetCategoryId: [''],
      assetCategoryName: [''],
      createdByEmail: [''],
      createdByName: [''],
      updatedByEmail: [''],
      updatedByName: [''],
      createdOn: [''],
      updatedOn: [''],
    })
  }
  selectCategory(id)
  {
    this.assetForm.get("assetCategoryId").setValue(id);
  }
  selectBrand(id)
  {
    this.assetForm.get("brandId").setValue(id);
  }
  ngOnInit() {
    this.isMobile = this.deviceDetect.currentDevice().phoneScreen;
    this.dropdown();
    this.assestFormControls()
    // if(this.hideTopPart){
    //   this.displayedColumns = ['name','updatedOn','updatedBy'];

    // }
    
    this.titleEnum=titlename;
     this.RouterLinksEnum = RouterLinksEnum;
     this.PermissionsEnum = PermissionsEnum;
    this.titlename=this.titleEnum.brands;
     this.organizationData=[];
     this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
     this.organizationData.push(JSON.parse(this.jsonOrgData));
     this.orgId = this.organizationData[0].id;
      //console.log(this.orgId);   
      // if(this.injectedCategoryId){
      //   if(this.hideTopPart){
      //     this.getAssetById(this.injectedCategoryId)
      //   }
      // }
      // else{
      //   if(!this.hideTopPart){
      //     this.getAllAsset();
      //   }
      // }

      if(this.injectedSearchText){
        this.searchText=this.injectedSearchText
        this.getAssetById(this.injectedCategoryId)
      }
      if(this.injectedCategoryId){
        if(this.hideTopPart){
          this.getAssetById(this.injectedCategoryId)
        }
      }
      else{
        if(!this.hideTopPart){
          this.getAllAsset();
        }
      }
      
      //this.dataSource.paginator = this.paginator;     
    this.assetForm.controls['assetCategoryName'].valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.categorySelected = this.categoriesResponse.findIndex(x => x.name == val)
      if (this.categorySelected > -1) {
        return
      }
      if (val !== null && val !== undefined && val !== '') {
        this.getAllCategories(val);
      };
    })
    this.assetForm.controls['brandName'].valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.brandSelected = this.brandResponse.findIndex(x => x.name == val)
      if (this.brandSelected > -1) {
        return
      }
      if (val !== null && val !== undefined && val !== '') {
        this.getAllBrands(val);
      };
    })
  }
  getAllBrands(searchText: string) {

    if (searchText.length > 2) {      
      let queryString = `searchText=${searchText}`;  
      this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
        this.brandResponse = res;        
      });

    }

  }
  msgToParent() {this.callParent.emit(this.assetsLength);} 
  getAssetById(id){
    let requestParameter = "";
    if(this.injectedSearchText){
      requestParameter = "&FullText="+this.injectedSearchText+"&organizationId=" + this.orgId;
    }
    if (id && !this.injectedSearchText) {
      requestParameter = "&AssetCategoryId=" + id+"&organizationId=" + this.orgId;
    }

    requestParameter = requestParameter.substring(1);
    requestParameter = "?" + requestParameter;

    this.assetService.getAllAssets(requestParameter).subscribe(res => {
      this.assetsLength = res['data'].data.length
      this.callParent.emit(this.assetsLength);
      this.dataSource.data = res['data'].data;
      this.displayTotal = this.getDisplayTotal();
      this.newData=res.data.data;
      this.token = res.data.paging.continuationToken;
      if (this.token !== null) {
        this.hideButton = false;
      }
      else {
        // this.CustomMessage.ShowCustomMessage("No record found", true);
        this.hideButton = true;
      }
    })
  }

  getAllCategories(searchText: string) {
    if (searchText.length > 2) {      
      let queryString = `searchText=${searchText}`;  
      this.assetService.getAllCategoriesActive(queryString).subscribe(res => {
        this.categoriesResponse = res;        
      });
    }
  }
  AssetStatusUpdate(event, status, id, index) {
    let updateStatus = status.toString();
    let toggleElement = event;

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: 1,
              showStatusMessage : "true" 
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        
        if (updateStatus == AssetStatusEnum.Inactive) {
          updateStatus = AssetStatusEnum.Active;
        }
        else {
          updateStatus = AssetStatusEnum.Inactive;
        }
        let data = {
          id:id,
          status:updateStatus
        }
        this.assetService.updateAseetStatus(data)
          .subscribe(res => {
            if (res.data) {
              this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
              this.getAssetById(this.injectedCategoryId);
            }
          }
            , err => {          
              this.dataSource.data[index]['status'] = updateStatus;
              toggleElement.source.checked = !toggleElement.source.checked;
              this.table.renderRows();
              this.CustomMessage.ShowCustomMessage(err.error.statusMessage, false);
            })
      }
      else{
        toggleElement.source.checked = !toggleElement.source.checked;
      }
    })



    
  }
  AssetSearch()
  {
    let requestParameter = "";
    if(this.assetForm.get('title').value)
    {
      this.searchText=this.assetForm.get('title').value
      requestParameter = "&FullText="+this.assetForm.get('title').value+"&organizationId=" + this.orgId;
    }
    // if(this.assetForm.get('status').value)
    // {
    //   requestParameter += "&Status="+this.assetForm.get('status').value
    // }
    // if(this.assetForm.get('type').value)
    // {
    //   requestParameter += "&AssetType="+this.assetForm.get('type').value
    // }
    // if(this.assetForm.get('brandId').value)
    // {
    //   requestParameter += "&BrandId="+this.assetForm.get('brandId').value
    // }
    // if(this.assetForm.get('assetCategoryId').value)
    // {
    //   requestParameter += "&AssetCategoryId="+this.assetForm.get('assetCategoryId').value
    // }
    
    
    requestParameter =  requestParameter.substring(1);
    requestParameter = "?"+requestParameter;
    
    // if(this.emloyeelistForm.get('location').value)
    // {
    //   requestParameter = requestParameter + "&Locations="+this.emloyeelistForm.get('location').value
    // }
    
    // if(this.emloyeelistForm.get('department').value)
    // {
    //   requestParameter = requestParameter + "&Departments="+this.emloyeelistForm.get('department').value
    // }
    this.assetService.getAllAssets(requestParameter).subscribe(res=>{
      this.dataSource.data = res['data'].data;
      this.displayTotal = this.getDisplayTotal();
      if(this.dataSource.data.length == 0)
      {
        // this.CustomMessage.ShowCustomMessage("No record found", true);
      }
    })
  }
  dropdown()
  {
    this.assetTypes = [{ id: 1, name: 'Media' },
    { id: 2, name: 'Document' }
    ];
    this.assetStatus = [{ id: 1, name: 'Active' },
    { id: 2, name: 'InActive' },
  ];
  }
  redirectToAsset()
  {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.CATEGORY_ASSET_CREATE)
  }
  redirectToAssetEdit(id)
  {
    let url = this.router.url;
    let paramId = this.route.snapshot.params['id'];
    if (paramId !== null || paramId !== undefined) {
      this.routeId = paramId;
    }
    else {
      this.routeId = id;
    }
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.CATEGORY_ASSET_SLASH+id+RouterLinksEnum.ADMIN_EDIT],{state:{ assetCatId: this.routeId, redirectUrl: url}})
  }
  openHistory(id,name)
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Asset,id,name);  
  }
  openChat(id,name)
  {
    this.chatDialogService.openChatDialog(PIMEntityType.Asset,id,name);  
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  multipleDeleteAsset()
  {
    
    if(this.selection.selected.length == 0)
    {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: 1,
        noAssetSelect : "true" 
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
      }
    })   
    }
    else
    {    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: 1,name:name }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.multipleDeleteAssetUrl().subscribe(res=>{
          if(res)
          {
          this.CustomMessage.ShowCustomMessage(res[0].statusMessage, true);
          this.selection.clear();
          this.getAssetById(this.injectedCategoryId)   
        }
        })
      }
    })
    }
  }
  multipleDeleteAssetUrl() : Observable<any[]>
  {
    let requestArray = [];
    for(let i=0;i<this.selection.selected.length;i++)
    {
      requestArray.push(this.assetService.deletAssetById(this.selection.selected[i].id));  
    }
    return forkJoin(requestArray)
  }
  updateMultipleAssets()
  {
    if(this.selection.selected.length == 0)
    {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { id: 1,
        noAssetSelect : "true" 
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
      }
    })   
    }
    else
    {    const dialogRef = this.dialog.open(BulkUpdateComponent, {
      data: { id: 1,assetInfo:this.selection.selected }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.selection.clear();
        this.getAssetById(this.injectedCategoryId)
      }
    })
    }
  }
  customDateAndTime(date)
  {
    return this.customDateService.customDateAndTimeFormat(date);
  }
  customDate(date)
  {
    return this.customDateService.customDateFormat(date);
  }
  downLoadSelection()
  {
    let assetIds  = []
    if(this.selection.selected.length == 0)
    {
      this.CustomMessage.ShowCustomMessage(messages.selectRecord,false)
      return
    }
    if(this.selection.selected.length)
    {
      for(let j=0;j<this.selection.selected.length;j++)
      {
        assetIds.push(this.selection.selected[j].id)
      }
    }
    this.downLoadAsset(assetIds)
  }
  downLoadAsset(assetIds)
  {
    let requestParameter = ""
    if(assetIds.length)
    {
      for(let i=0;i<assetIds.length;i++)
      {
        requestParameter += "&assetIds="+assetIds[i] 
      }
    }
    requestParameter = "?"+requestParameter.substring(1)
    this.assetService.downloadAssets(requestParameter).subscribe(res => {
      this.CustomMessage.ShowCustomMessage(res.statusMessage,true);
      this.selection.clear();
    })
  }
  selectionClear()
  {
    this.selection.clear();
  }
      copyURL(inputElement) {  
        navigator.clipboard.writeText(inputElement)    
      }  

  getDisplayTotal() {
    return this.dataSource.data.length;
  }

  selectedAssetsList(){
    let selectedAssetIds  = []
    if(this.selection.selected.length)
    {
      for(let j=0;j<this.selection.selected.length;j++)
      {
        selectedAssetIds.push(this.selection.selected[j].id)
      }
    }
    return selectedAssetIds;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
   this.isMobile = this.deviceDetect.currentDevice().phoneScreen;
  }

}
  

