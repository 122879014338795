import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TermsandconditionsComponent } from './termsAndConditions/termsandconditions.component';
import { PrivacypolicyComponent } from './privacyPolicy/privacypolicy.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { DeleteDialogComponent } from './dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from './dialogs/warning/warning.dialog.component';
import { OrdersDialogComponent } from './dialogs/orders/orders.dialog.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { HistoryDialogComponent} from  './dialogs/history/history.dialog.component';
import { ChatComponent} from './dialogs/chat-dialog/chat.dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpGuideComponent} from './dialogs/help-guide/helpGuide.dialog.component';
import { InvoiceDateDialogComponent } from './dialogs/invoice-Date/invoiceDate.dialog.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from './modules/material.module';
import { ConfirmationDialogComponent } from './dialogs/confirmation/confirmation.dialog.component';

@NgModule({
  declarations: [ClickOutsideDirective,HeaderComponent,ChatComponent,TermsandconditionsComponent,PrivacypolicyComponent,
    OrdersDialogComponent,DeleteDialogComponent,WarningDialogComponent,DateFormatPipe,ConfirmationDialogComponent,
    HistoryDialogComponent,HelpGuideComponent,InvoiceDateDialogComponent, FooterComponent,ClickOutsideDirective ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule 
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [OrdersDialogComponent,DeleteDialogComponent,WarningDialogComponent,ConfirmationDialogComponent,
    HistoryDialogComponent,ChatComponent,HelpGuideComponent,InvoiceDateDialogComponent,FooterComponent],
  exports:[ClickOutsideDirective,TermsandconditionsComponent,PrivacypolicyComponent,HeaderComponent,
    OrdersDialogComponent,DeleteDialogComponent,WarningDialogComponent,DateFormatPipe,FooterComponent]
})
export class SharedModule { }
