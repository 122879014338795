import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, NgForm, FormControl, Validators } from '@angular/forms';
; import { ProductService } from '../../services/Product/product.service'
import { Router } from '@angular/router';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { CommentService } from '../../services/comment/comment.service';
@Component({
  templateUrl: './import.dialog.html',
  styleUrls: ['./import.dialog.css']
})
export class ImportDialogComponent implements OnInit {
  fileName: string = '';
  formData = new FormData();
  errorMessage: string;
  detailsUrl: string;
  disabledWhileApiCalling : boolean = false;
  isFileUploaded:boolean=false;
  blob: Blob;
  sampleFileName: string = "ProductSampleFile.xlsx";
  constructor(public dialogRef: MatDialogRef<ImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private productService: ProductService,
    private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog,
    private customMessageService:CustomMessageService,private commentService:CommentService) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  preview(files) {
    this.formData.delete('file')
    if (files.length === 0) {
      return;
    }
    this.formData.append('file', <File>files[0])
  }
  redirectToUrl() {
    window.open(this.detailsUrl, "_blank");
  }
  importProduct() {
    if(!this.formData.get('file')){
      this.customMessageService.ShowCustomMessage("No File has been uploaded. Please upload a valid excel file.",false)
      return;
    }
    let url = this.data.importFrom == 'Kinator' ? "Products/import/kinator" : "Products/import"
    this.disabledWhileApiCalling = true;
    this.productService.postBulkData(this.formData,url).subscribe(res => {
      if (res) {
        if (res.statusMessage) {
          this.customMessageService.ShowCustomMessage(res.statusMessage, true);
          this.errorMessage = null;
          this.productService.sendProductImportUpdate();
          this.detailsUrl = null;
          this.formData.delete('file')
          this.fileName = ''
          this.isFileUploaded=true;
          this.closeDialog();
        }
      }
      this.disabledWhileApiCalling = false;
    }, err => {
      this.errorMessage = err.error.StatusMessage;
      this.detailsUrl = err.error.Data;
      this.formData.delete('file')
      this.fileName = ''
      this.disabledWhileApiCalling = false;
    })
  }
  sampleImportFile(){
    if(this.data.importFrom == 'Kinator'){
      return false ;
    }
    return true ;
  }

  exportToExcel()
  {
    this.commentService.getSampleReportFile().subscribe(res => {
      this.blob = new Blob([res], {type: res.type});
      var downloadURL = window.URL.createObjectURL(res);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.sampleFileName;
      link.click();
    },error => {
      alert("Something went wrong");
    });
  }
}
