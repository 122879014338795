import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { HistoryDialogComponent } from '../history/history.dialog.component';
@Injectable({
    providedIn: 'root'
})
export class HistoryDialogService {
    allWarehouseList: any = [];
    constructor( public dialog: MatDialog,) {
    }
    openHistoryDialog(entityType,entityId,entityName) {
        const dialogRef = this.dialog.open(HistoryDialogComponent, {
            data: { entityType:entityType,entityId:entityId,entityName:entityName }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
            }
          })   
    }

  
}
