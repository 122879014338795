import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { themeJson } from '../../constants/pim-theme-structure';
import { CommonService } from './common.service';

type deviceTypes = 'mobile' | 'desktop'
@Injectable({
  providedIn: 'root'
})
export class DynamicPimThemeLoadService {
  private readonly themeJson ={};
  private deviceType:deviceTypes;
  

  constructor(private deviceDetect: DeviceDetect,
    private commonService:CommonService) { 
      this.themeJson = themeJson.find(item=>item.themeName == 'PIM Default Theme');
    // this.setDeviceType();
  }

  checkDeviceType(){
    this.deviceType = this.deviceDetect.currentDevice().phoneScreen ? 'mobile' : 'desktop';
    this.commonService.sendComponentData(this.themeJson['themeDetails'][this.deviceType]);
  }

}
