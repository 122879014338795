import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../constants/global';
import { TypedRule } from 'tslint/lib/rules';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let orgDataCookie = localStorage.getItem(AppConstants.ORGANIZATION_INFO);
    let getOrgInfoFromSession = sessionStorage.getItem('OrganizationInfo') 
    if (orgDataCookie || getOrgInfoFromSession) {
      let organizationId = 0;
      let newHeader: HttpHeaders;
      let canIgnoreAPIRoute = this.CanIgnoreAPIRoute(req.url);
      
      if(orgDataCookie && canIgnoreAPIRoute == false){ // get orgInfo from local storage
        organizationId = JSON.parse(decodeURIComponent(orgDataCookie)).id; 
      }
      else{ // get orgInfo from session storage
        if(getOrgInfoFromSession && canIgnoreAPIRoute == false){
          let orgInfo = JSON.parse(getOrgInfoFromSession);
          organizationId = orgInfo.id; 
        }
      }

     
      if(sessionStorage.getItem('impersonatee')){
        newHeader = req.headers.set("OrganizationId",organizationId.toString()).set('impersonatee',sessionStorage.getItem('impersonatee'));
      }else{
        newHeader = req.headers.set("OrganizationId",organizationId.toString());
      }

      const authReq = req.clone({
        headers: newHeader 
      });

      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }

  CanIgnoreAPIRoute(url: string):boolean{
    let apisToIgnore = [
      'organizations/api/v1/Onboarding'
    ];
    for(let i=0; i< apisToIgnore.length; i++){
      if(url.toLowerCase().indexOf(apisToIgnore[i].toLowerCase()) > -1){
        return true;
      }
    }

    return false;
  }
}
