export enum RouterLinksEnum {
    ORDERS = '/orders',
    ORDER_DASHBOARD='/order-dashboard',
    ORDER_CHANNELS='/order-channels',
    ORDER_CHANNELS_SLASH='order-channels/',
    ORDER_SETTINGS='/order-settings',
    ORDER_CUSTOMERS='/order-customers',
    ORDER_CUSTOMERS_SLASH='/order-customers/',
    SHIPPING_OPTION='/shipping-option',
    SHIPPING_OPTION_SLASH='/shipping-option/',
    CUSTOMER_INFO='/customer-info',
    CHANNEL='/channel/',
    RETURNS='/returns',
    RETURNS_SLASH='/returns/',
    REPLACE='/returns/replace',
    REPLACE_SLASH='/returns/replace/',
    EDIT='/edit',
    EDIT_SLASH='/edit/',
    NEW='/new',
    IMPORT_CHANNEL_WISE='/import/channel/wise',
    POS='/POS',
    NOP_COMMERCE='/nop-commerce/',
    SHOPIFY='/shopify/',
    MAGENTO='/magento/',
    TATA_CLIQ='/tata-cliq/',
    BIG_BASKET='/big-basket/',
    SUPER_DAILY='/supr-daily/',
    USER_MAPPING='/userMapping/',
    FULFILLMENT_PERFORMANCE='/fulfillment-performance',
    REVENUE='/revenue',
    SALE_AND_RETURNS='/sale-and-returns',
    ZOHO_BOOKS='/zoho-books',
    PAXCOM_INVOICING='/paxcom-invoicing',
    TATA_CLIQ_INVOICING='/tata-cliq-invoicing',
    ABANDON_CART='/abandon-cart',
    ADMIN_PLUGIN='/admin/orders/plugin',
    ADMIN = '/admin/orders',
    ADMIN_SLASH='/admin/orders/',
    ADMIN_REPORTS='/admin/orders/reports',
    ADMIN_ORDERS = '/admin/orders/manage-orders',
    ADMIN_ORDERS_SLASH = '/admin/orders/manage-orders/'

}