import { Component, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-add-new-variant',
  templateUrl: './add-new-variant.component.html',
  styleUrls: ['./add-new-variant.component.scss']
})
export class AddNewVariantComponent implements OnInit {

  themeComponentName: string = '';
  themeSubscription: Subscription;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['addVariant'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
  this.themeSubscription.unsubscribe();
  }
}