export enum UserStatusEnum {
    New = 1,
    Active,
    Deactivated
}

export enum BrandStatusEnum {
    Active = 1,
    Deactivated
}
//enum added for default value in dropdown
export enum DefaultDropDownEnum {
    defaultVal = '--Select--'
}