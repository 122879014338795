import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConstants } from '../../constants/global';

@Injectable({
  providedIn: 'root'
})
export class GetSetSessionStorageService {

  constructor() { }

  setOrgInfoIntoSession(data) {
    sessionStorage.setItem(AppConstants.SHOP_SESSION_INFO, JSON.stringify(data));
  }
  
  getOrgInfoFromSession() {
    let orgInfo = ""
    if (sessionStorage.getItem(AppConstants.SHOP_SESSION_INFO)) {
      orgInfo = JSON.parse(sessionStorage.getItem(AppConstants.SHOP_SESSION_INFO));
    }
    return orgInfo;
  }



}
