import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DataService} from '../../../core/data.service';

@Component({
  selector: 'app-confirmation.dialog',
  templateUrl: '../../dialogs/confirmation/confirmation.dialog.html',
  styleUrls: ['../../dialogs/confirmation/confirmation.dialog.css']
})
export class ConfirmationDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDelete(): void {
  }
    
}
