import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../Common/session-storage.service';
import { AppConstants } from 'src/app/constants/global';
import { CommonService } from '../Common/common.service';
import { UserStatusEnum } from 'src/app/shared/enum/user-status';
import { UserModel } from 'src/app/shared/models/authorization/user-model';
import { ActiveRouteService } from '../Common/active_route.service';
import { UrlSessionStorageService } from '../url-session-storage.service';
import { environment } from 'src/environments/environment';
import { RouterLinksEnum } from 'src/app/modules/glapto/shared/router-links/router-links.enum';


@Injectable({
  providedIn: 'root'
})
export class AuthCallBack {

  public UserName: string = "";
  public Email: string = "";
  public ExtraRegistrationDataNeeded: boolean = false;
  public ClientId: string = "";
  public UserId: string = "";
  public ProviderId: number = 0;
  public Active = true;
  userModel: UserModel;
  options: string[];


  constructor(private router: Router,
    private authService: AuthService,private _sessionStorageService: SessionStorageService,
    public _commonService: CommonService,private activeRouteService:ActiveRouteService, 
    private _urlSessionStorageService: UrlSessionStorageService) {
  }
 existingUserValidation() {
    this.clearUserData();
    this.newUserValidation();
  
      // @ToDo temprary changes 
     
      //this.router.navigate(['InOut/dashboard']);
    }

  //Clear session values on login to remove previously logged in user's data so that new user data is picked from APIs
  clearUserData(){
    sessionStorage.removeItem("SessionStorge_ActMOdel"); 
    this._sessionStorageService.removeloggedinUserinfo();
  }

  newUserValidation() {
    let domain = location.origin || location.href
    // getting organization info using domian
   
    if (location.origin == environment.omnichannelHostname) 
    { //on.paxcom.ai
      // this.router.navigateByUrl('onBoard/storeSelection');
      this.router.navigateByUrl(RouterLinksEnum.GLAPTO_SLASH + RouterLinksEnum.HOME);
      return;
    }
    
      this.getOrgFromUrl(domain)
      this._sessionStorageService.getloggedinUserinfo().then(res => {
        this.userModel = res;

        // if (this.userModel.statusId == UserStatusEnum.New) {
        //   this.router.navigateByUrl('onBoard/storeSelection');
        // }

        // else if (this.userModel.statusId == UserStatusEnum.Active) {
          let orgDataCookie =  this._commonService.getOrganizationData(this.userModel.username); 
          if (orgDataCookie) {

            let organizationData = [];
             organizationData.push(orgDataCookie);
            this._urlSessionStorageService.redirectToStoredOrBaseUrl();
          }
          else {
            this.authService.redirectToOrg();
          }

        // }
        // else {
        //   alert('Your account is currently inactive. Please Contact with Admin');
        // }

      });
  }
  getOrgFromUrl(domain) {
    let requestParameter = "?url=" + encodeURIComponent(domain)
    this._commonService.getOrganizationFromUrl(requestParameter).subscribe(res => {
      let organizationInfo = {
        id: res.data.id,
        logoUri: res.data.logoURI,
        name: res.data.name,
        userName: "",
        canAccessAdmin : res.data.canAccessAdmin
      }
      localStorage.setItem(AppConstants.ORGANIZATION_INFO, JSON.stringify(organizationInfo));
     
    })
  }
}


