export enum AppConstants 
{
     ORGANIZATION_INFO = "InOut_Organization",
     APPLICATION_SHORT_NAME = "App_Short_name",
     Account_Detail = "/Account/UpdateUserDetails",
     SHOP_SESSION_INFO = 'Shop_info',
     SHOP_ACTIVITIES = 'shop_activities',
     PRODUCT_ACTIVITIES = 'pim_activities',
     ORDER_ACTIVITIES = 'orders_activities',
     INVENTORY_ACTIVITIES = 'inventory_activities',
     ESTORE_ADMIN_ACTIVITIES = 'eStoreAdmin_activities',
     CAN_ACCESS_ADMIN = 'canAccessAdmin',
     COMPLETE_INTRO = "complet_glapto_intro",
     FAV_STORE = "fav_store",

}
