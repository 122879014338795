import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocomplete, MatChipInputEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BlobStorageService } from '../../../azure-storage/blob-storage.service';
import { SASTokenService } from '../../../azure-storage/sastoken.service';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { AssetService } from '../../../services/Assets/asset.service';
import { BrandsService } from '../../../services/Brands/brands.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ProductService } from '../../../services/Product/product.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { FileHandle } from '../../../shared/directives/dragDrop.directive';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';


interface IUploadProgress {
  filename: string;
  progress: number;
}
@Component({
  selector: 'app-create-asset-default',
  templateUrl: './create-asset-default.component.html',
  styleUrls: ['./create-asset-default.component.scss']
})
export class CreateAssetDefaultComponent implements OnInit {
  uploadProgress$: Observable<IUploadProgress[]>;
  titleName:string;
  buttonName:string;
  categoryControler: any;
  categoriesResponse: any = [];
  assetForm: FormGroup;
  categorySelected: any;
  isFormSubmit: boolean;
  formData = new FormData();
  organizationData: any[];
  jsonOrgData: string;
  orgId: any;
  isButtonVisible: boolean = true;
  imagePath: any;
  imageUploadForm: any;
  imgURL: string | ArrayBuffer;
  assetTypes: { id: number; name: string; }[];
  assetStatus: { id: number; name: string; }[];
  brandResponse: any = [];
  brandSelected: number;
  assetData: any = [];
  assetId: any;
  addedAllTag: any = [];
  assetCatId: any;
  assetCatName: any;
  redirectUrl: any;
  filesSelected: boolean = false;currentFile: any;
  UploadStarted: boolean;
  UploadProgress: Observable<number>;
   _response: BlobUploadCommonResponse;
  UploadCompleted: boolean;
  blobName: any;
  containerName: any;
  fileUrl: any;
  serviceUrlWithSAS: any;
  assetThumbnailInfo: any;
  featureIds: typeof HelpFeatureIdsEnum;
  disableActionsButtonsWhileAPICall: boolean = false;
  fileNameWithExtension: string;
  showHelpIcon: boolean;
;
  constructor(private formBuilder: FormBuilder,private assetService:AssetService,
    private productService: ProductService,private brandsService: BrandsService,private CustomMessage: CustomMessageService,
    private route:ActivatedRoute,private router:Router,private blobStorageService:BlobStorageService,
    private sASTokenService:SASTokenService,private chatDialogService:ChatDialogService,private helpGuideService:HelpGuideService,
    private historyDialogService:HistoryDialogService,private deviceDetect:DeviceDetect) { 
      this.getCurrentDevice(); 
      const state = history.state;
    if (state) {
    this.assetCatId= state.assetCatId;
    this.assetCatName= state.pName;
    this.redirectUrl= state.redirectUrl;
    }
     
    }
  allTags = [];
  addedAllTags = [];
  allSelectedTags = [];
  allTags$;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  statusControler = new FormControl();
  fruits: string[] = [];
  filteredFruits: Observable<string[]>;
  files: FileHandle[] = [];
  tagLimitError:boolean = false
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagauto', { static: false }) matAutocomplete: MatAutocomplete;
  ngOnInit() {
    this.featureIds = HelpFeatureIdsEnum;
    this.titleName ="New Asset"
    this.buttonName = "Save";
    this.route.paramMap.subscribe(param=>{
      this.assetId =  param.get('assetId');
      
   })
    this.dropdown();
    this.organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = this.organizationData[0].id;
    this.assestFormControls();

    if(this.assetCatName){
      this.assetForm.get('assetCategoryName').setValue(this.assetCatName)
    }
    if(this.assetCatId){
      this.assetForm.get('assetCategoryId').setValue(this.assetCatId)
    }
    this.assetForm.controls['assetCategoryName'].valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.categorySelected = this.categoriesResponse.findIndex(x => x.name == val)
      if (this.categorySelected > -1) {
        return
      }
      if (val !== null && val !== undefined && val !== '') {
        this.getAllCategories(val);
      };
    })
    this.assetForm.controls['brandName'].valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.brandSelected = this.brandResponse.findIndex(x => x.name == val)
      if (this.brandSelected > -1) {
        return
      }
      if (val !== null && val !== undefined && val !== '') {
        this.getAllBrands(val);
      };
    })
    this.tagCtrl.valueChanges.subscribe(val => {
 
      if (val !== null && val !== undefined && val !== '') {
        this.getAllProductTags(val);
      }
    });
    if(this.assetId)
    {
      this.titleName ="Edit Asset";
      this.buttonName = "Update";
      this.getAssetById(this.assetId)
    }
    else{
      this.showHelpIcon = true;
    }
  }
  redirectToAssetList()
  {
    if(this.redirectUrl){
      this.router.navigate([this.redirectUrl])
    }
    else{
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET_LIST])
    }
  }
  getAllCategories(searchText: string) {
    if (searchText.length > 2) {      
      let queryString = `searchText=${searchText}`;  
      this.assetService.getAllCategoriesActive(queryString).subscribe(res => {
        this.categoriesResponse = res;        
      });
    }
  }
  assestFormControls()
  {
    this.assetForm = this.formBuilder.group({
      id:[''],
      fileDetails : this.formBuilder.group({
        fileName: ['',[Validators.required,Validators.maxLength(250)]],
        fileType: [''],
        fileUrl: [''],
        extension:[''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      }),
      title:[''],
      description:['',[Validators.maxLength(2000)]],
      tags:this.formBuilder.array([]),
      status:[1],
      type:[''],
      brandId:[''],
      brandName:[''],
      assetCategoryId: [''],
      assetCategoryName: ['',[Validators.required]],
      createdByEmail: [''],
      createdByName: [''],
      updatedByEmail: [''],
      updatedByName: [''],
      createdOn: [''],
      updatedOn: [''],
    })
  }
  onFormSubmit(formvalue: NgForm | FormGroup) {
    
    this.isFormSubmit = true;
    if (formvalue.invalid || this.tagLimitError) {
      return false;
    }
    if(!this.assetForm.get('fileDetails.fileUrl').value)
    {
      this.CustomMessage.ShowCustomMessage(messages.uploadFile,false);
      return;
    }
    const tagForm = <FormArray>this.assetForm.get('tags'); 
      
    if(this.addedAllTags)
    {
      for(let i=0;i<this.addedAllTags.length;i++)
      {
        tagForm.push(this.formBuilder.control(this.addedAllTags[i]))
      } 
    }

    this.disableActionsButtonsWhileAPICall = true;
    let formValue = this.assetForm.value;
    if(this.assetId)
    {
      this.updateAsset(formValue)
    }
    else
    {
      this.createAsset(formValue)
    } 
  }
  addAllTag(event: MatChipInputEvent): void {
    if(!this.matAutocomplete.isOpen)
    {
      const input = event.input;
      const value = event.value;
      this.tagLimitError = value.length >100 ? true :false;
      if(this.tagLimitError)return;
      if ((value || '').trim()) {
        const tagForm = <FormArray>this.assetForm.get('tags');  
        this.addedAllTags.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }

  removeAllTag(fruit: string) {
    const index = this.addedAllTags.indexOf(fruit);
    this.addedAllTags.splice(index, 1);
  }

  selected(event: any) {
      this.addedAllTags.push(event.name);
      this.tagInput.nativeElement.value = '';
      const tagForm = <FormArray>this.assetForm.get('tags'); 
      let tagArray = []
    this.tagCtrl.setValue(null);
  }
  getAllProductTags(searchText: string) {
    let queryString = `?searchText=${searchText}`;
    this.productService.getProductTag(queryString).subscribe(res=>{
      this.allTags = res.data['data'];
    })
  }

  dropdown()
  {
    this.assetTypes = [{ id: 1, name: 'Media' },
    { id: 2, name: 'Document' }
    ];
    this.assetStatus = [{ id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
  ];
  }
  getAllBrands(searchText: string) {

    if (searchText.length > 2) {      
      let queryString = `searchText=${searchText}`;  
      this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
        this.brandResponse = res;        
      });

    }

  }
  selectCategory(id)
  {
    this.assetForm.get("assetCategoryId").setValue(id);
  }
  selectBrand(id)
  {
    this.assetForm.get("brandId").setValue(id);
  }
  createAsset(formValue)
  {
    this.assetService.createAsset(formValue).subscribe(res=>{
      if(res)
      {
        this.disableActionsButtonsWhileAPICall = false;
        this.CustomMessage.ShowCustomMessage(res.statusMessage,true);
        this.redirectToAssetList()
      }
    },err=>{
      this.disableActionsButtonsWhileAPICall = false;
    })
  }
  updateAsset(formValue)
  {
    
    this.assetService.updateAseet(formValue).subscribe(res=>{
      if(res)
      {
        this.disableActionsButtonsWhileAPICall = false;
        this.CustomMessage.ShowCustomMessage(res.statusMessage,true);
        this.redirectToAssetList();
      }
    },err=>{
        this.disableActionsButtonsWhileAPICall = false;
    })
  }
  getAssetById(id)
  {
    this.assetService.getAssetById(id).subscribe(res=>{
      this.assetData = res.data;
        this.isButtonVisible = false;
        this.imgURL = this.assetData.fileDetails.fileUrl;
        if(this.assetData.tags)
        {
          this.addedAllTags = this.assetData.tags;
        }
      this.assetForm.patchValue(this.assetData);
      
    })
  }
  public onFileChange(event: any): void {
   
    const file = event.target.files[0];
    this.fileNameWithExtension = event.target.files[0].name
    let obj = {
      fileNameWithExtension : event.target.files[0].name
    }
    this.disableActionsButtonsWhileAPICall = true;
    this.sASTokenService.getSASToken(obj).subscribe(res=>{
      this.blobName = res.data.blobName;
      this.containerName = res.data.containerName;
      this.fileUrl = res.data.fileUrl;
      this.serviceUrlWithSAS = res.data.serviceUrlWithSAS;

      this.uploadFile(file);
    },err=>{
      this.disableActionsButtonsWhileAPICall = false;
    })
    
  }

  private uploadFile(file: File) {
    let sasInfo = {
      blobName :this.blobName,
      containerName :this.containerName,
      fileUrl : this.fileUrl,
      serviceUrlWithSAS : this.serviceUrlWithSAS
    }
    this.UploadStarted = true;
    this.UploadProgress = this.blobStorageService.UploadProgress;
    this.blobStorageService.uploadBlobToStorage(file,sasInfo).then(response => {
      
      this._response = response;
      this.UploadCompleted = true;
      if(response)
      {
        let requestParameter = {
          fileUrl:this.fileUrl
        }
        this.sASTokenService.getAssetThumbnail(requestParameter).subscribe(res=>{
          if(res)
          {
          this.UploadStarted = false;      
          this.assetThumbnailInfo = res.data;
          this.imgURL = res.data['fileUrl'];
          this.isButtonVisible = false;
          this.assetForm.get('fileDetails.extension').setValue(res.data['extension'])
          let fileName = this.fileNameWithExtension.substring(0,this.fileNameWithExtension.lastIndexOf('.'))
          this.assetForm.get('fileDetails.fileName').setValue(fileName)
          this.assetForm.get('fileDetails.fileSizeInBytes').setValue(res.data['fileSizeInBytes'])
          this.assetForm.get('fileDetails.fileType').setValue(res.data['fileType'])
          this.assetForm.get('fileDetails.fileUrl').setValue(res.data['fileUrl'])
          this.assetForm.get('fileDetails.largeThumbnailUrl').setValue(res.data['largeThumbnailUrl'])
          this.assetForm.get('fileDetails.mediumThumbnailUrl').setValue(res.data['mediumThumbnailUrl'])
          this.assetForm.get('fileDetails.smallThumbnailUrl').setValue(res.data['smallThumbnailUrl'])    
          this.disableActionsButtonsWhileAPICall = false;
        }
        },err=>{
          this.disableActionsButtonsWhileAPICall = false;
        })
      }
    }).catch(error => {
      this._response = error;
      this.UploadCompleted = true;     
      this.UploadStarted = false; 
      this.disableActionsButtonsWhileAPICall = false;      
    });    
  }  
  filesDropped(files: FileHandle[]): void {
    this.files = files;
    this.UploadStarted = true;
    this.UploadProgress = this.blobStorageService.UploadProgress;
    this.fileNameWithExtension = this.files[0].file.name; 
    let obj = {
      fileNameWithExtension : this.files[0].file.name
    }
    this.disableActionsButtonsWhileAPICall = true;
    this.sASTokenService.getSASToken(obj).subscribe(res=>{
      this.blobName = res.data.blobName;
      this.containerName = res.data.containerName;
      this.fileUrl = res.data.fileUrl;
      this.serviceUrlWithSAS = res.data.serviceUrlWithSAS;

      this.uploadFile(this.files[0].file);
    },err=>{
      this.disableActionsButtonsWhileAPICall = false;
    })
  }
  openChat()
  {
    this.chatDialogService.openChatDialog(PIMEntityType.Asset,this.assetId,this.assetData.fileDetails.fileName);  
  }
  openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }
  openHistory()
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Asset,this.assetId,this.assetData.fileDetails.fileName);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }
}
