import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';
@Component({
  selector: 'app-add-new-brand',
  templateUrl: './add-new-brand.component.html',
  styleUrls: ['./add-new-brand.component.scss']
})
export class AddNewBrandComponent implements OnInit {
  themeComponentName: string = '';
  themeSubscription: Subscription;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['addBrand'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }
}
