import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { CommonService } from '../../Common/common.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
@Injectable({
  providedIn: 'root'
})
export class SignalRConnectionService {
  private hubConnectionBuilder: HubConnection;
  signalRUpdate = new Subject<any>();
  organizationId = JSON.parse(localStorage.getItem('InOut_Organization')).id;
  constructor() { }

  connect(url, methodName) {
    if (!this.hubConnectionBuilder) {
      this.hubConnectionBuilder = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
    this.hubConnectionBuilder
      .start()
      .then(() => console.log('Connection started.......!'))
      .then(() => {
        if (this.hubConnectionBuilder) {
          this.hubConnectionBuilder.invoke('JoinOrganizationGroup',this.organizationId.toString());
          console.log('Joined organization group')
        }
      })
      .catch(err => console.log('Error while connect with server'));
    this.hubConnectionBuilder.on(methodName, (data: any) => {
      // this.offers.push(data);
      this.signalRUpdate.next(data)
    });

      this.hubConnectionBuilder.onreconnected(() => {
        this.hubConnectionBuilder.invoke('JoinOrganizationGroup', this.organizationId.toString());
      })
  
      // this.connection = new signalR.HubConnectionBuilder()
      //   .configureLogging(signalR.LogLevel.Information)
      //   .withUrl(url, {
      //     skipNegotiation: true,
      //     transport: signalR.HttpTransportType.WebSockets
      //   })
      //   .build();

      // this.connection.start().then(() => {
      //   console.log('Hub connection started');
      // })
      // .then(() => {
      //   if (this.connection) {
      //     this.connection.invoke('JoinOrganizationGroup', this.organizationId);
      //     console.log('Joined organization group: '+this.organizationId)
      //   }
      // })
      // .catch(err => console.error(err));

      // this.connection.on(methodName, (data) => {
      //   console.log('Received', data);
      //   this.notification.next(data)
      // });
    }
  }
  
  disconnect() {
    // if (this.connection) {
    //   this.connection.stop();
    //   this.connection = null;
    // }
  }

  get signalRUpdatedData() {
    return this.signalRUpdate.asObservable();
  }
}