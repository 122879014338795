import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CreateVariant } from '../../models/Variants/create-variant.model';
import { VariantViewModel } from '../../models/Variants/VariantViewModel';



@Injectable({
    providedIn: 'root'
  })

export class VariantsService {
    private _getVariantsUrl = "VariantTypes";
    private _createVariantUrl = "VariantTypes";
    private _updateVariantStatusUrl = "VariantTypes/status";
  
    constructor(private httpHelper: HttpHelperService) { }
    /**
     * Get all the List of 
     * Variants, etc associated with it
     */
    getAllVariants(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getVariantsUrl + "?" + queryString);
    }

    searchVariant(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getVariantsUrl + "?" + queryString);
    }

    postVariant(variant: CreateVariant){
      return this.httpHelper.postData(variant, this._createVariantUrl);
    }
    
    editVariant(evariant: CreateVariant){
      return this.httpHelper.putData(evariant, this._createVariantUrl);
    }

    getVariantbyVariantId(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getVariantsUrl + "/" + queryString);
    }

    updateVariantStatus(vvm: VariantViewModel) {
      return this.httpHelper.putData(vvm, this._updateVariantStatusUrl);
    }

    deleteVariant(varintViewModel): Observable<any> {
      
      return this.httpHelper.deleteBody(this._getVariantsUrl,varintViewModel);
    }

}