import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CategoryModel } from '../models/Category/category-model.model';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RouterLinksEnum } from '../shared/router-links/router-links.enum';
import { titlename } from '../ENUM/titlename';
import { AssetCategoryService } from '../services/asset-category.service';
import { ORGANIZATION_INFO } from '../shared/constants/global';
import { ScrollingToErrorsService } from 'src/app/core/Common/scrollingToErrors.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-create-category-asset',
  templateUrl: './create-category-asset.component.html',
  styleUrls: ['./create-category-asset.component.scss']
})
export class CreateCategoryAssetComponent implements OnInit {
  parentCatId: any;
  search: any;
  parentCatName: any;
  categoryId: string;
  isEditMode: boolean = false;
  editRes: any[];
  newCategoryName: any;
  newParentCategoryName: any;
  newDescription: any;
  categoryForm: FormGroup;
  myControl = new FormControl();
  options: Array<string>;
  response: Array<CategoryModel>;
  filteredOptions: Observable<string[]>;
  activeStatus: boolean = true;
  childCount: number = 0;
  orgId: number;
  parentId: string = "";
  getData: any;
  organizationData: any[];
  jsonOrgData: string;
  buttonName: string;
  titlename: string;
  titleEnum: typeof titlename;
  formSubmit: boolean = false;
  redirectUrl: any;
  locationSelected: any;
  categoryResponse: any=[];

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder,
     private router: Router, private _assetCategoryService: AssetCategoryService,
      private CustomMessage: CustomMessageService,private scrollingToErrorsService:ScrollingToErrorsService) {
    this.route.paramMap.subscribe(params => {
      const state = history.state;
      if(state){
      this.parentCatName = state.pName;
      this.parentCatId = state.pId;
      this.redirectUrl = state.url;
      }
    })
  }

  ngOnInit() {

    this.titleEnum = titlename;
    this.titlename = this.titleEnum.addCategoryAsset;

    let organizationData = [];
    let jsonOrgData = localStorage.getItem(ORGANIZATION_INFO);
    if (jsonOrgData) {
      jsonOrgData = decodeURIComponent(jsonOrgData);
      organizationData.push(JSON.parse(jsonOrgData));
      this.orgId = organizationData[0].id;
    }
    this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      id: [''],
      parentCategoryName: [''],
      parentCategoryId: [''],
      fullHierarchicalName: [''],
      childCount: [this.childCount],
      active: [this.activeStatus],
      _Paxcom_OrganizationId: [''],
      header:[],
      footer:[]
    })
    this.getAllCategories().subscribe(res=>{
      this.categoryId = this.route.snapshot.params['id'];
      if (this.categoryId) {
        this.isEditMode = true;
        this.titlename = this.titleEnum.updateCategoryAsset;
  
        if (this.categoryId !== null || this.categoryId !== undefined) {
          this.getCategoryDetailsById();
        };
      }
      else {
        if (this.parentCatName !== null && this.parentCatName !== undefined) {
          this.categoryForm.get('parentCategoryName').setValue(this.parentCatName);
        }
    }
  })
    

      this.categoryForm.get('parentCategoryName').valueChanges
        .pipe(debounceTime(300)
        ).subscribe(val => {
          this.locationSelected = this.categoryResponse.findIndex(x => x.fullHierarchicalName === val)
          if (this.locationSelected > -1) {
            return false;
          }
          if (val !== null && val !== undefined && val !== '') {
            this.getParentCategory(val);
          }
        })

  }

  selectParentLocation(id) {
    this.parentCatId = id;
    this.categoryForm.get('parentCategoryId').setValue(this.parentCatId);
    this.parentCatName = this.categoryForm.get('parentCategoryName').value;
  }

  getParentCategory(searchText) {

    if (searchText.length > 2) {
      this._assetCategoryService.getAllCategories("organizationId=" + this.orgId + "&pageSize=-1&searchText=" + searchText)
        .subscribe(res => {
          if (res.data.data) {
            this.categoryResponse = res.data.data;
          }
        })
    }
  }

  cancel() {
    if (this.redirectUrl !== null && this.redirectUrl !== undefined) {
      this.router.navigate([this.redirectUrl], { state: { pId: this.parentCatId } });
    }
    else {
      this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST], { state: { pId: this.parentCatId } });
    }
  }

  addNewCategory() {
    this.categoryForm.get('_Paxcom_OrganizationId').setValue(this.orgId.toString());
    if(this.parentCatId===null || this.parentCatId===undefined){
      let randomName=this.categoryForm.get('parentCategoryName').value;
        this.getParentId(randomName);        
    }
    if(this.parentCatId){
      this.categoryForm.get('parentCategoryId').setValue(this.parentCatId);
    }

    this._assetCategoryService.postCategory(this.categoryForm.value).subscribe(res => {
      if (res) {
        // let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        if (this.buttonName === "save") {
          if (this.parentCatId !== null && this.parentCatId !== undefined && this.parentCatId !== "") {
            this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST + '/' + this.parentCatId + '/' + RouterLinksEnum.SUB_CATEGORY_ASSET])
          }
          else {
            this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST]);
          }
        }
        else if (this.buttonName === "saveContinue") {
          this.router.navigateByUrl('/admin/products/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['admin/products/' + RouterLinksEnum.CATEGORY_ASSET_CREATE], { state: { pName: this.parentCatName ,pId:this.parentCatId} }));
        }
      }
      //this.router.navigate(['admin/roles']);
    });
  }

  updateCategory() {
    this.categoryForm.get('_Paxcom_OrganizationId').setValue(this.orgId.toString());
    if(this.categoryForm.get('parentCategoryName').value===""){
      this.categoryForm.get('parentCategoryId').setValue("");
    }
    if(this.parentCatId===null || this.parentCatId===undefined || this.parentCatId===""){
      let randomName=this.categoryForm.get('parentCategoryName').value;
        this.getParentId(randomName);
        this.categoryForm.get('parentCategoryId').setValue(this.parentCatId)
    }
    this._assetCategoryService.putCategory(this.categoryForm.value).subscribe(res => {
      if (res) {
        // let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        if (this.parentCatId !== null && this.parentCatId !== undefined && this.parentCatId !== "") {
          this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST + '/' + this.parentCatId + '/' + RouterLinksEnum.SUB_CATEGORY_ASSET]);
          // this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST + '/' + this.parentCatId + '/' + RouterLinksEnum.SUB_CATEGORY], { state: { search: this.search } });
        }
        else{
          this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_ASSET_LIST]);
          // this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST], { state: { search: this.search } });
        }
      }
      //this.router.navigate(['admin/category-list']);
    });
  }

  onFormSubmit(): void {

    this.formSubmit = true;
    if (this.categoryForm.invalid) {
      this.scrollingToErrorsService.scrollToError();
      return;
    }
    this.buttonName = document.activeElement.getAttribute("name");
    if (this.buttonName === "save") {
      this.addNewCategory();
      // this.categoryForm.setValue({ parent: "", category: "", description:""})
    }
    else if (this.buttonName === "saveContinue") {
      this.addNewCategory();
    }
    else if (this.buttonName === "update") {
      this.updateCategory();
    }
  }


  get parent() {
    return this.categoryForm.get('parentCategoryName');
  }

  get category() {
    return this.categoryForm.get('name');
  }
  get description() {
    return this.categoryForm.get('description');
  }

  getParentId(pName: string) {

    for (let i = 0; i < this.response.length; i++) {
      if (this.response[i].name.toLowerCase() === pName.toLowerCase()) {
        this.parentCatId = this.response[i].id;
        break;
      }
      else {
        this.parentCatId = pName;
      }
    }

    return this.parentCatId;
  }

  getParentName(pId: string) {
    this.parentCatName = '';
    for (let i = 0; i < this.response.length; i++) {
      if (this.response[i].id === pId) {
        this.parentCatName = this.response[i].fullHierarchicalName;
        this.categoryForm.get('parentCategoryName').setValue(this.parentCatName)
        break;
      }
    }
    return this.parentCatName;
  }


  getAllCategories():Observable<any> {

   return this._assetCategoryService.getAllCategories("organizationId=" + this.orgId + "&pageSize=-1")
      .pipe(map(res => {
        if (res.data.data) {
          return this.response = res.data.data;
        }
      }));
  }

  getCategoryDetailsById() {
    this._assetCategoryService.getCategoriesById(this.categoryId + "?organizationId=" + this.orgId)
      .subscribe(res => {
        if (res) {
          this.editRes = res.data as CategoryModel[];
          this.parentCatId=res.data.parentCategoryId;
          this.activeStatus = res.data.active;
          this.categoryForm.patchValue(this.editRes);
          if(this.parentCatId!=="" && this.parentCatId!==null){
            this.getParentName(this.parentCatId)
            this.categoryForm.get('parentCategoryName').setValue(this.parentCatName);
          }
        }

      });
  }

}
