import { MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HistoryService } from '../../services/History/history.service';
import { FormBuilder, NgForm, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { debounceTime } from 'rxjs/operators';
import { ORGANIZATION_INFO } from '../../shared/constants/global';
import { CommentService } from '../../services/comment/comment.service';
import { CreateUserService } from '../../services/User/create-user.service';
import { BrandsService } from '../../services/Brands/brands.service';
import { ProductService } from '../../services/Product/product.service';
import { CommonService } from '../../services/Common/common.service';
import { AssetTagOperation } from '../../ENUM/assetTagOperation';
import { CustomMessageService } from 'src/app/core/custommessageservice';
@Component({
  selector: 'app-bulkUpdate.dialog',
  templateUrl: './bulkUpdate.dialog.html',
  styleUrls: ['./bulkUpdate.dialog.css']
})
export class BulkUpdateComponent implements OnInit {
  assetStatus: { id: number; name: string; }[];
  bulkAssetForm: FormGroup;
  brandSelected: any;
  brandResponse: any = [];

  organizationData: any[];
  orgId: any;
  jsonOrgData: string;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // add Tags properties
  allTags = [];
  addedAllTags = [];
  tagCtrl = new FormControl();

  // remove tags properties
  allRemoveTags = [];
  addedRemoveAllTags = [];
  removeTagCtrl = new FormControl();

  // remove tags properties
  allOverrideTags = [];
  addedOverrideAllTags = [];
  overrideTagCtrl = new FormControl();


  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagRemoveInput', { static: false }) tagRemoveInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagOverrideInput', { static: false }) tagOverrideInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagauto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('tagRemoveAuto', { static: false }) matRemoveAutocomplete: MatAutocomplete;
  @ViewChild('tagOverrideAuto', { static: false }) matOverrideAutocomplete: MatAutocomplete;
  onAddTagChecked: boolean = false;
  onRemoveTagChecked: boolean = false;
  onOverwriteTagChecked: boolean = false;
  radioButton: boolean = true;
  disabledAddTag : boolean = true;
  disabledRemoveTag: boolean = true;
  disabledOverwriteTag: boolean = true;
  isFormSubmit: boolean = false;
  assetsIds: any = [];
  brandCheckboxValue: boolean = false;
  removeAllTags: any = [];
  overrideAllTags: any = [];
  constructor(public dialogRef: MatDialogRef<BulkUpdateComponent>,private productService: ProductService,private commonService:CommonService, private customMessageService: CustomMessageService, private brandsService: BrandsService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) {
    dialogRef.disableClose = true;
  }

  closeDialog(): void {
    this.dialogRef.close(1);
  }
  ngOnInit() {
    this.assetStatus = [{ id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
    ];
    this.organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = this.organizationData[0].id;
    this.bulkAssetsForm()
    this.bulkAssetForm.get('assetStatus').disable();
    this.bulkAssetForm.get('brandName').disable();
    this.bulkAssetForm.controls['brandName'].valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.brandSelected = this.brandResponse.findIndex(x => x.name == val)
      if (this.brandSelected > -1) {
        return
      }
      if (val !== null && val !== undefined && val !== '') {
        this.getAllBrands(val);
      };
    })
    this.tagCtrl.valueChanges.subscribe(val => {
      if (val !== null && val !== undefined && val !== '') {
        this.getAllProductTags(val);
      }
    });
    this.removeTagCtrl.valueChanges.subscribe(val => {
      if (val !== null && val !== undefined && val !== '') {
        this.getAllProductTags(val);
      }
    });
    this.overrideTagCtrl.valueChanges.subscribe(val => {
      if (val !== null && val !== undefined && val !== '') {
        this.getAllProductTags(val);
      }
    });
      this.setAssetsIds(this.data)
  }
  setAssetsIds(data){
    if(data)
    {
      if(data.assetInfo.length)
      {
        for(let obj of this.data.assetInfo)
        {
          this.assetsIds.push(obj.id)
        }
      }
    }
  }
  bulkAssetsForm() {
    this.bulkAssetForm = this.formBuilder.group({
      ids: this.formBuilder.array([]),
      brandId: [''],
      brandName: [''],
      tags: this.formBuilder.array([]),
      tagOperation: [],
      assetStatus: [],
      removeBrand: []
    })
  }
  getAllBrands(searchText: string) {

    if (searchText.length > 2) {
      let queryString = `searchText=${searchText}`;
      this.brandsService.getAllActiveBrands(queryString).subscribe(res => {
        this.brandResponse = res;
      });

    }

  }
  onAssetSatusChekbox(event) {
    
    if (event.checked) {
      this.bulkAssetForm.get('assetStatus').enable();
    }
    else {
      this.bulkAssetForm.get('assetStatus').disable();
    }
    this.bulkAssetForm.get('assetStatus').setValue(null);
  }
  onBrandChekbox(event) {
    
    this.brandCheckboxValue = event.checked;
    if (event.checked) {
      this.bulkAssetForm.get('brandName').enable();
    }
    else {
      this.bulkAssetForm.get('brandName').disable()
    }
    this.bulkAssetForm.get('brandName').setValue(null);
    this.bulkAssetForm.get('brandId').setValue(null);
  }
  onTagChekbox(event) {
    
    if(event.checked == false)
    {
      this.onAddTagChecked = false;
      this.onOverwriteTagChecked = false;
      this.onRemoveTagChecked= false;
      this.disabledAddTag = true;
      this.disabledRemoveTag = true;
      this.disabledOverwriteTag = true;
      this.addedAllTags = []
      this.addedRemoveAllTags = []
      this.addedOverrideAllTags = []
    }
    this.radioButton = ! this.radioButton;
    
  }
  // add tags fields functions
  addAllTag(event: MatChipInputEvent): void {
    
    if(!this.matAutocomplete.isOpen){
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.addedAllTags.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }
  removeAddTag(fruit: string) {
    const index = this.addedAllTags.indexOf(fruit);
    this.addedAllTags.splice(index, 1);
  }
  selectedAddTag(event: any) {
    
    this.addedAllTags.push(event.name);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }
  // remove tags fields functions
  addRemoveAllTag(event: MatChipInputEvent): void {
    
    if(!this.matRemoveAutocomplete.isOpen){
      const input = event.input
      const value = event.value;
      if ((value || '').trim()) {
        this.addedRemoveAllTags.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.removeTagCtrl.setValue(null);
    }
  }
  removeAllTag(fruit: string) {
    const index = this.addedRemoveAllTags.indexOf(fruit);
    this.addedRemoveAllTags.splice(index, 1);
  }
  selectedRemoveTag(event: any) {
    
    this.addedRemoveAllTags.push(event.name);
    this.tagRemoveInput.nativeElement.value = '';
    this.removeTagCtrl.setValue(null);
  }

  // override tags fields functions
  addOverrideAllTag(event: MatChipInputEvent): void {
    if(!this.matOverrideAutocomplete.isOpen){
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.addedOverrideAllTags.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.overrideTagCtrl.setValue(null);
    }
  }
  removeOverrideTag(fruit: string) {
    const index = this.addedOverrideAllTags.indexOf(fruit);
    this.addedOverrideAllTags.splice(index, 1);
  }
  selectedOverrideTag(event: any) {
    this.addedOverrideAllTags.push(event.name);
    this.tagOverrideInput.nativeElement.value = '';
    
    this.overrideTagCtrl.setValue(null);
  }


  getAllProductTags(searchText: string) {
    let queryString = `?searchText=${searchText}`;
    this.productService.getProductTag(queryString).subscribe(res => {
      this.allTags = res.data['data'];
    })
  }
  onAddTagRadio(event)
  {
    
    if(event.source._checked == true)
    {
      this.onAddTagChecked = true;
      this.disabledAddTag = ! this.disabledAddTag
      this.addedAllTags = [];
      this.addedRemoveAllTags = [];
      this.addedOverrideAllTags = [];
      this.allTags = [];
    }
    this.disabledRemoveTag = true;
    this.disabledOverwriteTag = true;
    this.onRemoveTagChecked = false;
    this.onOverwriteTagChecked = false;
  }
  onRemoveTagRadio(event)
  {
    if(event.source._checked == true)
    {
      this.onRemoveTagChecked = true;
      this.disabledRemoveTag = ! this.disabledRemoveTag
      this.addedAllTags = [];
      this.addedRemoveAllTags = [];
      this.addedOverrideAllTags = [];
      this.allTags = [];
    }
    this.disabledAddTag = true;
    this.disabledOverwriteTag = true;
    this.onAddTagChecked = false;
    this.onOverwriteTagChecked = false;
  }
  onOverwriteTagRadio(event)
  {
    if(event.source._checked == true)
    {
      this.onOverwriteTagChecked = true;
      this.disabledOverwriteTag = ! this.disabledOverwriteTag
      this.addedAllTags = [];
      this.addedRemoveAllTags = [];
      this.addedOverrideAllTags = [];
      this.allTags = [];
    }
    this.disabledAddTag = true;
    this.disabledRemoveTag = true;
    this.onAddTagChecked = false;
    this.onRemoveTagChecked = false;
  }
  selectBrand(id)
  {
    this.bulkAssetForm.get('brandId').setValue(id);
  }
  onFormSubmit(formvalues: NgForm | FormGroup) {
    
    this.isFormSubmit = true;
    if (formvalues.invalid) {
      return false;
    }
    let assetIds =  this.bulkAssetForm.get('ids')  as FormArray;
    assetIds.clear();
    for(let  obj of this.assetsIds)
    {
      assetIds.push(this.formBuilder.control(obj))
    }
    // brand scenarios
      this.brandScenarios();
    // Tags scenarios
      this.tagsScenarios();
    let formValue =  this.bulkAssetForm.getRawValue();
    console.log(formValue)
    this.updateMultipleAsset(formValue)
  }
  updateMultipleAsset(formValue)
  {
    this.commonService.updateMultipleAsset(formValue).subscribe(res=>{
      this.customMessageService.ShowCustomMessage(res.statusMessage,true);
      setTimeout( () => {this.closeDialog()},1000)
    })
  }
  brandScenarios()
  {
    
    // update removeBrand Value acc to having brandName 
    if(this.brandCheckboxValue == true && (this.bulkAssetForm.get('brandName').value == null || this.bulkAssetForm.get('brandName').value == ''))
    {
      this.bulkAssetForm.get('removeBrand').setValue(true);
    }
    else if(this.brandCheckboxValue == true && this.bulkAssetForm.get('brandId').value && this.bulkAssetForm.get('brandName').value)
    {
      this.bulkAssetForm.get('removeBrand').setValue(false);
    }
    else if(this.brandCheckboxValue == false && (this.bulkAssetForm.get('brandName').value == null || this.bulkAssetForm.get('brandName').value == "" ))
    {
      this.bulkAssetForm.get('removeBrand').setValue(false);
      this.bulkAssetForm.get('brandId').setValue(null);
    }
  }
  tagsScenarios()
  {
    // set 'tagOperation' acc to tags radio buttons
    if(this.onAddTagChecked)
    {
    this.bulkAssetForm.get('tagOperation').setValue(AssetTagOperation.Add)
      if(this.addedAllTags.length)
      {
        let addTagsFormArray =  this.bulkAssetForm.get('tags')  as FormArray;
        addTagsFormArray.clear();
        for(let  obj of this.addedAllTags)
        {
          addTagsFormArray.push(this.formBuilder.control(obj))
        }
      }
    }
    else if(this.onRemoveTagChecked)
    {
    this.bulkAssetForm.get('tagOperation').setValue(AssetTagOperation.Remove)
      if(this.addedRemoveAllTags.length)
      {
        let removeTagsFormArray =  this.bulkAssetForm.get('tags')  as FormArray;
        removeTagsFormArray.clear();
        for(let  obj of this.addedRemoveAllTags)
        {
          removeTagsFormArray.push(this.formBuilder.control(obj))
        }
      }
    }
    else if(this.onOverwriteTagChecked)
    {
    this.bulkAssetForm.get('tagOperation').setValue(AssetTagOperation.Override)
      if(this.addedOverrideAllTags.length)
      {
        let overrideTagsFormArray =  this.bulkAssetForm.get('tags')  as FormArray;
        overrideTagsFormArray.clear();
        for(let  obj of this.addedOverrideAllTags)
        {
          overrideTagsFormArray.push(this.formBuilder.control(obj))
        }
      }
    }
    else
    {
    this.bulkAssetForm.get('tagOperation').setValue(null)
    }
  }
}
