import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { CommonService} from '../Common/common.service';
import { SessionStorageService } from '../Common/session-storage.service';
import { UserModel } from 'src/app/shared/models/authorization/user-model';
import { ActivitiesModel } from 'src/app/shared/models/authorization/activities-model.model';

@Injectable({
  providedIn: 'root'
})
export class AuthAccessService {

    public Email: string = "";
    public OrgId: number;
    userModel: UserModel;
    activitiesData: any;  
    SessionStorage_ActMOdel = "SessionStorge_ActMOdel";

  constructor(
    private authService: AuthService, public commonService: CommonService, 
    private _sessionStorageService: SessionStorageService) {
  }

    async getUserEmailOrgId() {
       
        return await this.authService.initializeUser().then(async () => {
                await this._sessionStorageService.getloggedinUserinfo().then(res => {
                    this.userModel = res;
            });
        });
    }

    async getActivitiesList(): Promise<ActivitiesModel>
    {        
        let pro = await this.getUserEmailOrgId();

        if(sessionStorage[this.SessionStorage_ActMOdel] != null)       
            {
              return Promise.resolve(JSON.parse(sessionStorage[this.SessionStorage_ActMOdel]));
            }
        else {            
            return this.commonService.getAuthActivities("organizationId=" + this.userModel.organizationId + "&userEmail=" + this.userModel.username)
            .toPromise().then(res => {
                    sessionStorage[this.SessionStorage_ActMOdel] = JSON.stringify(res.data);

                        for (var v in JSON.parse(sessionStorage['SessionStorge_ActMOdel'])) {
                            //console.log(JSON.parse(sessionStorage['SessionStorge_ActMOdel'])[0].name);
                            this.activitiesData.push(JSON.parse(sessionStorage['SessionStorge_ActMOdel'])[v].name);
                            console.log(this.activitiesData);

                          return this.activitiesData;
                        }
                });
            } 
    }

    async checkAccessStatus(cons: string) : Promise<boolean> {
        let dfault = false;
        
        let p1 = await this.getActivitiesList(); 
            for (var v in p1)
                {
                    this.activitiesData = p1;
                    //console.log(this.activitiesData[v].name);
                    if (this.activitiesData[v].name == cons){
                        dfault = true;  
                    }
                } 

        console.log(dfault);
        return dfault;
    }


}