import { Injectable } from '@angular/core'
import { HttpHelperService } from './Common/http-helper.service';
import {BaseProductStatus} from '../models/product/product-status';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root'
})
export class ProductstatusService {
  //_productstatus = "Product/statuses";
  
  //--------Refactored URLs 6/21/2021 ---------------
  _productstatus = "Products/statuses";
  constructor(private _httpHelper: HttpHelperService) { }

  getproductStatus() : Observable<BaseProductStatus>
  { 
    return this._httpHelper.getData(this._productstatus) as Observable<BaseProductStatus>;
  }
}
