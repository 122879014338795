import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlSessionStorageService } from '../url-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private _router: Router, private _urlSessionStorageService: UrlSessionStorageService) { }

  isAdminPortal() {
    let routerUrl = this._router['location']._platformLocation.location.pathname;
    if (routerUrl) {
      let route = routerUrl.split('/')
      if (route.length > 0 && route[1]) {
        let routeUrl = route[1].toLowerCase();
        let storedUrl = this._urlSessionStorageService.getUrlFromSession();
        if (routeUrl == 'admin' || routeUrl == 'admin-login' || routeUrl == 'redirect' || (storedUrl && storedUrl.toLowerCase().includes('admin'))) {
          return true;
        }
      }
    }
    return false;
  }

  isAdminRoute(routerUrl) {
    if (!routerUrl)
      return false;

    let route = routerUrl.split('/')
    if (route.length > 0 && route[1] && route[1].toLowerCase() == 'admin')
      return true;
    else
      return false;
  }
}
