export const themeJson = [{
    themeName:"PIM Default Theme",
    themeDetails:{
    desktop: {
        addBrand:'app-add-new-brand-default',
        addVariant:'app-add-new-variant-default',
        asset:'app-assets-default',
        brandList:'app-brand-list-default',
        masterProducts:'app-master-products-default',
        selectedProducts:'app-selected-products-default',
        categoryAsset:'app-category-asset-default',
        categoryList:'app-category-list-default',
        channelList:'app-channel-list-default',
        createChannel:'app-create-channel-default',
        createAsset:'app-create-asset-default',
        createCategoryAsset:'app-create-category-asset',
        createCategory:'app-create-new-category-default',
        createProduct:'app-create-new-product-default',
        dashBoard:'app-dashboard-default',
        imageGuide:'app-imageguidelines',
        sideMenu:'app-inout-menu-default',
        productList:'app-desktop-product-list-default',
        resources:'app-resources-default',
        variantList:'app-variants-list-default',
        pimSettings:'app-settings-default'
    },

    mobile: {
        addBrand:'app-add-new-brand-default',
        addVariant:'app-add-new-variant-default',
        asset:'app-assets-default',
        brandList:'app-brand-list-default',
        masterProducts:'app-master-products-default',
        selectedProducts:'app-selected-products-default',
        categoryAsset:'app-category-asset-default',
        categoryList:'app-category-list-default',
        channelList:'app-channel-list-default',
        createChannel:'app-create-channel-default',
        createAsset:'app-create-asset-default',
        createCategoryAsset:'app-create-category-asset',
        createCategory:'app-create-new-category-default',
        createProduct:'app-create-new-product-mobile-default',
        dashBoard:'app-dashboard-default',
        imageGuide:'app-imageguidelines',
        sideMenu:'app-inout-menu-default',
        productList:'app-mobile-product-list-default',
        resources:'app-resources-default',
        variantList:'app-variants-list-default',
        pimSettings:'app-settings-default'
    }
    }
}]