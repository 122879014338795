import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-delete.dialog',
  templateUrl: '../../dialogs/delete/delete.dialog.html',
  styleUrls: ['../../dialogs/delete/delete.dialog.css']
})
export class DeleteDialogComponent {
brandName:FormControl = new FormControl();
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDelete(): void {
    //this.dataService.deleteIssue(this.data.id);
    this.data && this.data.addBrand && this.dialogRef.close({data:this.brandName.value})
  }
    
}
