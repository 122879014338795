export enum AppConstants 
{
     ORGANIZATION_INFO = "InOut_Organization",
     APPLICATION_SHORT_NAME = "eStore",
     SHOP_ACTIVITIES = "shop_activities",
     SHOP_SESSION_INFO = 'Shop_info',
     ANONYMOUS_CART_SESSION_INFO = 'Inout_AnonymousCartId',
     APP_TITLE = 'eStore'
     
}
