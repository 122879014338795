import { Injectable } from '@angular/core';
import { CommonService} from '../../services/Common/common.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ORGANIZATION_INFO } from '../../shared/constants/global';
import { PIM_ACTIVITIES } from '../../constants/global';


@Injectable({
  providedIn: 'root'
})
export class PermissionsAccessService {

    userEmail:string = "";
    organizationId:string ="";
    permissionsData: any;
  username: any = "";
    
  constructor(private httpClient: HttpClient, public _commonService: CommonService) {
   
  }


  public getPermissionsData(){

      if(sessionStorage[PIM_ACTIVITIES] !== undefined)
      {
        let result = {"data":JSON.parse(sessionStorage[PIM_ACTIVITIES])};
        return of(result);
      }
      else{

        return this.callPermissionsDataService();
      
    }
 }
  
    callPermissionsDataService() {

             // permissions storage
              if(localStorage.getItem(ORGANIZATION_INFO)){
                let jsonOrgData = JSON.parse(decodeURI(localStorage.getItem(ORGANIZATION_INFO)));
                this.organizationId = jsonOrgData.id;
              }
             
              if (sessionStorage.getItem("SessionStorge_UserModel") !== null) {
                let userInfo = JSON.parse(sessionStorage.getItem('SessionStorge_UserModel'));
                this.userEmail = userInfo.email;
                this.username = userInfo.username;
              }
            
  
              if(this.organizationId && this.username){
                      
                    return this._commonService.getAuthActivities("organizationId=" + this.organizationId + "&userEmail=" + this.username);
             
              }
            
    }

    

    


  

}