import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteGuardsService } from '../route-guards.service';
import { UrlSessionStorageService } from '../url-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuardService implements CanActivate {
  url: string;

  constructor(private routeGaurd: RouteGuardsService, private router: Router,private getUrlSession: UrlSessionStorageService) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     return true;
   
  }

}
