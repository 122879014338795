export enum OrderStatusEnum {
    Draft = 1,
    Received,
    Accepted,
    Rejected,
    Shipped,
    'Partially Shipped',
    Processing,
    Closed,
    Cancelled,
    Returned,
    'Fulfillment Processing',
    'Fulfillment Rejected',
    'Fulfillment Accepted',
    'Fulfillment Partially Accepted',
    'Fulfillment Partially Rejected',
    'Partially Accepted',
    Delivered,
    'Partially Delivered',
    'Partially Returned',
    'Fulfillment Requested',
    'Fulfillment Partially Requested'
}
export enum PaymentMethodEnum {
    COD = 1,
    CVS,
    Prepaid,
    Manual
}

export enum ShipmentServiceLevelEnum {
    Expedited = 1,
    FreeEconomy,
    NextDay,
    SameDay,
    SecondDay,
    Scheduled,
    Standard,
    PickFromStore
}

export enum PaymentStatusEnum {
    Unpaid = 1,
    Paid,
    Refunded,
    PartiallyPaid,
    "Paid (Unverified) "
}

export enum OrderTypeEnum {
    Standard = 1,
    Preorder,
    SourcingOnDemandOrder,
    'Credit Note',
    Refund,
    Repair,
    Replacement,
    Wishlist,
    Cart,
    POS
}
export enum ReturnTypeEnum {
    'Credit Note' = 4,
    Refund,
    Repair,
    Replacement
}
export enum OrderType {
    Standard = 1,
    Preorder = 2,
    'Sourcing On Demand Order' = 3,
    Wishlist = 8,
    Cart = 9,
    'POS Cart'=10
}
export enum AllOrderTypesEnum {
    Standard = 1,
    Preorder,
    'Sourcing On Demand Order',
    'Credit Note',
    Refund,
    Repair,
    Replacement,
    Wishlist,
    Cart,
    'POS Cart' 
}
export enum OrderChargeTypeEnum {
    Item = 1,
    Shipping,
    Discount,
    COD,
    GiftWrap,
    ShippingDiscount,
    CODDiscount
}
export enum ImportFileType
    {
        MANUAL = 0,
        "AMAZON PO" = 1,
        "AMAZON NON PO",
        BIGBASKET,
        SUPRDAILY,
    }

    export enum OrderFulfillmentChannel
    {
        Marketplace = 1,
        Merchant
    }
    export enum OrderFilters {
        All = 1,
        Pending,
        Processing,
        Fulfilled
    }
    export enum ReturnStatusEnum {
        Received = 2,
        Accepted = 3,
        Rejected = 4,
        Processing = 7,
        Cancelled = 9,
        Returned = 10
    }

    