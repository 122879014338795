export const validationPattern = {
    mobileNumberValid :"^[0-9_ .+\-\]*$",
    emailPattern : /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
    letterPattern : /^[a-zA-Z ]*$/,
    urlPattern : /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    customerNamePattern :/^[a-zA-Z ]*$/,
    customerTaxNumberPattern :"^[a-zA-Z0-9_-]*$",
    numberPattern :"^[0-9]{0,200}[.]?[0-9]{0,200}$",
    zipCodePattern : "^[0-9]*$",
    vendorNamePattern : "^[a-zA-Z0-9-_. &]*$",
    vendorTaxNumberPattern : "^[a-zA-Z0-9_-]*$",
    numberWithDecimal : "^[0-9]{1,11}(?:\.[0-9]{1,3})?$",
    normalNumberPattern:'[0-9]*',
    numberPlusPercentagePattern : "^[0-9.%()-]+$",
    normalNumberPatternWithMaxLength: '^[0-9]{1,9}$',
    hsnCodePattern: '^[^\,^\ ]+$',
    noEmptySpacePattern: /^(\s+\S+\s*)*(?!\s).*$/,
    maxRangeWithDecimal:"^[0-9]{1,5}(?:\.[0-9]{1,3})?$"
}