import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../services/Common/http-helper.service';
import {ConstantAPIUrls} from '../constant-apiurls';
import {CategoryModel} from '../models/Category/category-model.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesServiceService {

  private _getChildrenUrl="Categories/children";
  private _putCategoryUrl="Categories"
  private _getCategoryUrl="Categories";
  private _updateStatusUrl="Categories/status";
  private _deleteCategoryUrl="Categories";
  private _postCategoryUrl="Categories";

  constructor(private httpHelper: HttpHelperService) {}

  getAllCategories(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getCategoryUrl+ "?" + queryString);
  }

  getAllCategoriesActive(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getCategoryUrl+ "?" + queryString).pipe(
      map(res => res.data.data),
      map(results => results.filter(result => result.active))
    )
  }

  getCategoriesById(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getCategoryUrl+ "/" + queryString);
  }

  getChildCategories(queryString = ""): Observable<any> {
    return this.httpHelper.getData(this._getChildrenUrl + "?" + queryString); 
  }

  deleteCategory(payload): Observable<any> {
    return this.httpHelper.deleteBody(this._deleteCategoryUrl,payload);
  }

  postCategory(category:CategoryModel) {
    return this.httpHelper.postData(category, this._postCategoryUrl);
  }

  putCategory(eCategory:CategoryModel) {
    return this.httpHelper.putData(eCategory, this._putCategoryUrl);
  }

  updateStatus(category:CategoryModel)  {
   return this.httpHelper.putData(category,this._updateStatusUrl);
  }
}
