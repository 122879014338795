import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { HelpGuideComponent} from '../../shared/help-guide/helpGuide.dialog.component';
@Injectable({
    providedIn: 'root'
})
export class HelpGuideService {
    allWarehouseList: any = [];
    constructor( public dialog: MatDialog,) {
    }
    openHelpGuideDialog(featureId) {
        const dialogRef = this.dialog.open(HelpGuideComponent, {
            data: { featureId:featureId }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
            }
          })   
    }

  
}
