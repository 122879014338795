import { Component, Input, OnInit, Output,EventEmitter, ViewChildren, HostListener, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsEnum } from 'src/app/modules/pim/constants/permissions';
import { ProductStatusEnum } from 'src/app/modules/pim/ENUM/productstatusenum';
import { titlename } from 'src/app/modules/pim/ENUM/titlename';
import { CategoryModel } from 'src/app/modules/pim/models/Category/category-model.model';
import { ProductStatus } from 'src/app/modules/pim/models/product/product-status';
import { SearchproductModel } from 'src/app/modules/pim/models/product/searchproductmodel';
import { CategoriesServiceService } from 'src/app/modules/pim/services/categories-service.service';
import { CommonService } from 'src/app/modules/pim/services/Common/common.service';
import { ProductListService } from 'src/app/modules/pim/services/Product/product-list.service';
import { ProductService } from 'src/app/modules/pim/services/Product/product.service';
import { ProductbrandService } from 'src/app/modules/pim/services/Product/productbrand.service';
import { ProductstatusService } from 'src/app/modules/pim/services/productstatus.service';
import { ORGANIZATION_INFO } from 'src/app/modules/pim/shared/constants/global';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { StickySearchDirective } from 'src/app/modules/pim/shared/directives/sticky-search.directive';
import { environment } from 'src/environments/environment';
import { RouterLinksEnum } from 'src/app/modules/pim/shared/router-links/router-links.enum';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';
@Component({
  selector: 'app-mobile-product-list-default',
  templateUrl: './mobile-product-list-default.component.html',
  styleUrls: ['./mobile-product-list-default.component.scss']
})
export class MobileProductListDefaultComponent implements OnInit {
  @Output() currentDevice:EventEmitter<object>= new EventEmitter(); 
  @Input() hideTopPart:boolean  //added to inject product into category
  @Input() injectedCategoryId: any; //added to inject product into category
  @Input() injectedSearchText: any;
  productstatusinfo: ProductStatus[] = [];
  brandList: any;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  searchForm: any;
  jsonOrgData: string;
  orgId: string;
  brandId: string;
  PermissionsEnum;
  token: string;
  showLoadMoreButton: boolean = false;
  titlename: string;
  titleEnum: typeof titlename;
  categoryId: string;
  approvalTypeEnum: { id: number; name: string; }[];
  maxDate = new Date();
  baseOrganizationId:any;
  displayTotal:number;
  statusId:string;
  productList: Array<SearchproductModel> = [] 
  ProductStatusEnum: typeof ProductStatusEnum;
  showMenuFilterBar: boolean = false;
  categoryList: Array<CategoryModel> = [];
  filterCategoryList: Array<CategoryModel> = [];
  variantId: string;
  @ViewChild (StickySearchDirective,{static:true}) stickySearchDirective : StickySearchDirective;
  queryString: any = {};
  apiInProgress:boolean = false;
  noDataFound:boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  checkRequestParameter:String;
  constructor(private productListService: ProductListService,private productstatusService: ProductstatusService,
    private productbrandService: ProductbrandService,private formbulider: FormBuilder,private route: ActivatedRoute,
    private router: Router,private categoryService: CategoriesServiceService,private customMessageService: CustomMessageService,
    public dialog: MatDialog,public productService: ProductService,private deviceDetect:DeviceDetect,
    private commonService: CommonService) {
    
      const navigation = this.router.getCurrentNavigation();
      if(navigation){
        this.variantId = navigation.extras.state ? navigation.extras.state.id : null;
      }
      this.getAllproductbrand();
  }
  subscriptionRef: Subscription;
  ngOnInit() {
    this.ProductStatusEnum = ProductStatusEnum
    this.subscriptionRef = this.productService.productImportNotification.subscribe(() => {
      this.getAllProducts();
    });
    this.route.queryParamMap.subscribe((param)=>{
      this.statusId=param.get('statuses');
    })
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.productList;
    this.approvalTypeEnum = [{ id: 1, name: 'Not Initiated' },
    { id: 2, name: 'Pending For Approval' },
    { id: 3, name: 'Approved' },
    { id: 4, name: 'Rejected' },
    ];
    this.PermissionsEnum = PermissionsEnum;
    this.serachForm()
    this.searchForm.get('productCategory').valueChanges.pipe(debounceTime(300)).subscribe(val => {
      if (val !== null && val !== undefined && val !== '') {
        this.filterCategoryList = this.filteredCategories(val)
      }
      else{
        this.filterCategoryList = this.categoryList;
        this.searchForm.get('categoryId').setValue('')
      }
    })
    let organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = organizationData[0].id;
    this.baseOrganizationId=environment.baseOrganizationId
    this.getProductStatus();
    this.getCategories(this.orgId);
    this.categoryId = this.route.snapshot.params['categoryId'] ? this.route.snapshot.params['categoryId'] : null;
    this.variantId = this.route.snapshot.params['variantId'] ? this.route.snapshot.params['variantId'] : null;
    this.brandId = this.route.snapshot.params['brandId'] ? this.route.snapshot.params['brandId'] : null;

    setTimeout(()=>{
      this.setSearchValues()
    })
    this.setDefaultNoItemsFound();
   
  }
  serachForm(){
    this.searchForm = this.formbulider.group({
      sku: [],
      productCategory: [],
      createdOn: [],
      updatedOn: [],
      maximumSellerAllowedPrice: [],
      minimumSellerAllowedPrice: [],
      status: [],
      productStatus: [],
      productBrand: [],
      createdByName: [],
      updatedByName: [],
      sellingChannels: [],
      includeSubcategories: [],
      ShowOnlyVariationGroups:[],
      CreateDateRangeStart: [],
      CreateDateRangeEnd: [],
      UpdateDateRangeStart: [],
      UpdateDateRangeEnd: [],
      keyword: [],
      approvalStatus:[],
      categoryId:[],
      FullText:[]
    })
  }
  updateProductStatus(event,product,index,variantIndex = null) { // update product status
    let status = product.status == ProductStatusEnum.Active ? ProductStatusEnum.Inactive : ProductStatusEnum.Active
    var data = {
      organizationId: this.orgId,
      productId: product.id,
      status: status.toString()
    };
    this.productService.postProductStatus(data).subscribe(res => {
      if (res.data) {
        if(variantIndex || variantIndex == 0)
        {
          if(this.productList[index].productVariants.length){
            this.productList[index].productVariants[variantIndex].status = status.toString();
          }
        }
        else{
          this.productList[index].status = status;
        }
        this.customMessageService.ShowCustomMessage(res.statusMessage, true);
      }
    }, err => {
        if(variantIndex || variantIndex == 0)
        {
          if(this.productList[index].productVariants.length){
            this.productList[index].productVariants[variantIndex].status = product.status;
          }
        }
        else{
          this.productList[index]['status'] = product.status;
        }
      
      event.source.checked = !event.source.checked;
    })
  }
  setSelectedCategory(category,event) {
    if (event && event.isUserInput){
    this.searchForm.get("categoryId").setValue(category.id);
    this.searchForm.get("productCategory").setValue(category.fullHierarchicalName);
    }
  }
  getCategories(orgId) { // all categories
    let queryString = "organizationId=" + orgId+"&pageSize=500";
    this.categoryService.getAllCategories(queryString).subscribe(res=> {
      this.categoryList = res.data.data;
      this.filterCategoryList = this.categoryList;
    })
  }
  onFormSubmit() {
    let requestparameter = this.getSearchParameter();
    this.getProductList(requestparameter)
  }
  getSearchParameter() { // all serach parameters
    var requestparameter = "?organizationId=" + this.orgId;
    this.checkRequestParameter=requestparameter;
    
    if (this.searchForm.get('sku').value != null && this.searchForm.get('sku').value) {
      requestparameter += "&NameOrSKU=" + this.searchForm.get('sku').value;
    }
    if (this.searchForm.get('CreateDateRangeStart').value != null && this.searchForm.get('CreateDateRangeStart').value) {
      if(this.isIsoDate(this.searchForm.get('CreateDateRangeStart').value)){
        requestparameter += "&CreateDateRangeStart=" + this.searchForm.get('CreateDateRangeStart').value;
      }else{
        requestparameter += "&CreateDateRangeStart=" + new Date(this.searchForm.get('CreateDateRangeStart').value).toISOString();
      } 

      // requestparameter += "&CreateDateRangeStart=" + new Date(this.searchForm.get('CreateDateRangeStart').value).toISOString();
    }
    if (this.searchForm.get('CreateDateRangeEnd').value != null && this.searchForm.get('CreateDateRangeEnd').value) {
      if(this.isIsoDate(this.searchForm.get('CreateDateRangeEnd').value)){
        requestparameter += "&CreateDateRangeEnd=" + this.searchForm.get('CreateDateRangeEnd').value;
      }else{
        requestparameter += "&CreateDateRangeEnd=" + new Date(this.searchForm.get('CreateDateRangeEnd').value).toISOString();
      } 

      // requestparameter += "&CreateDateRangeEnd=" + new Date(this.searchForm.get('CreateDateRangeEnd').value).toISOString();
    }
    if (this.searchForm.get('UpdateDateRangeStart').value != null && this.searchForm.get('UpdateDateRangeStart').value) {
      if(this.isIsoDate(this.searchForm.get('UpdateDateRangeStart').value)){
        requestparameter += "&UpdateDateRangeStart=" + this.searchForm.get('UpdateDateRangeStart').value;
      }else{
        requestparameter += "&UpdateDateRangeStart=" + new Date(this.searchForm.get('UpdateDateRangeStart').value).toISOString();
      } 

      // requestparameter += "&UpdateDateRangeStart=" + new Date(this.searchForm.get('UpdateDateRangeStart').value).toISOString();
    }
    if (this.searchForm.get('UpdateDateRangeEnd').value != null && this.searchForm.get('UpdateDateRangeEnd').value) {
      if(this.isIsoDate(this.searchForm.get('UpdateDateRangeEnd').value)){
        requestparameter += "&UpdateDateRangeEnd=" + this.searchForm.get('UpdateDateRangeEnd').value;
      }else{
        requestparameter += "&UpdateDateRangeEnd=" + new Date(this.searchForm.get('UpdateDateRangeEnd').value).toISOString();
      } 

      // requestparameter += "&UpdateDateRangeEnd=" + new Date(this.searchForm.get('UpdateDateRangeEnd').value).toISOString();
    }
    if (this.searchForm.get('minimumSellerAllowedPrice').value != null && this.searchForm.get('minimumSellerAllowedPrice').value) {
      requestparameter += "&MinimumPrice=" + this.searchForm.get('minimumSellerAllowedPrice').value.toString();
    }
    if (this.searchForm.get('maximumSellerAllowedPrice').value != null && this.searchForm.get('maximumSellerAllowedPrice').value) {
      requestparameter += "&MaximumPrice=" + this.searchForm.get('maximumSellerAllowedPrice').value.toString();
    }
    if (this.searchForm.get('createdByName').value != null && this.searchForm.get('createdByName').value) {
      requestparameter += "&CreatedBy=" + this.searchForm.get('createdByName').value.toString();
    }
    if (this.searchForm.get('updatedByName').value != null && this.searchForm.get('updatedByName').value) {
      requestparameter += "&UpdatedBy=" + this.searchForm.get('updatedByName').value.toString();
    }
    if (this.searchForm.get('includeSubcategories').value == true) {
      requestparameter += "&includeSubcategories=" + this.searchForm.get('includeSubcategories').value.toString();
    }
    if (this.searchForm.get('ShowOnlyVariationGroups').value == true) {
      requestparameter += "&ShowOnlyVariationGroups=" + this.searchForm.get('ShowOnlyVariationGroups').value.toString();
    }
    if (this.searchForm.get('approvalStatus').value && this.searchForm.get('approvalStatus').value != "-1") {
      requestparameter += "&ApprovalStatus=" + this.searchForm.get('approvalStatus').value.toString();
    }
    if (this.searchForm.get('productBrand').value != null && this.searchForm.get('productBrand').value != "" &&
      this.searchForm.get('productBrand').value != "") {
      requestparameter += "&brandIds=" + this.searchForm.get('productBrand').value.toString().replace(/,/g, '&brandIds=');
    }
    if (this.searchForm.get('productStatus').value != null) {
      if(this.searchForm.get('productStatus').value.length){
        requestparameter += "&Statuses=" + this.searchForm.get('productStatus').value.toString().replace(/,/g, '&Statuses=');
      }
    }
    let category = this.searchForm.get("productCategory").value;
    if (category) {
      let categoryId = this.searchForm.get('categoryId').value;
      if (categoryId) {
        requestparameter +=  "&CategoryId=" + categoryId;
      }
    }
    if (this.searchForm.get('keyword').value != null && this.searchForm.get('keyword').value) {
      requestparameter += "&FullText=" + this.commonService.filterSpecialCharacters(this.searchForm.get('keyword').value.toString());
    }
    if(this.searchForm.get('FullText').value)
    {
      requestparameter += "&FullText=" + this.commonService.filterSpecialCharacters(this.searchForm.get('FullText').value.toString());
    }
    
    return requestparameter;
  }
  resetForm() {
    this.categoryId = "";
    this.variantId = "";
    this.statusId = "";
    this.brandId = "";
    this.searchForm.reset();
    this.onFormSubmit();
    this.setDefaultNoItemsFound();
  }
  public getAllproductbrand() { // all brands
    let requestParameter = "?pageSize=-1";
    this.productbrandService.getproductBrandsActive(requestParameter).subscribe(res => {
      this.brandList = res;
      this.sortBrandData();
    })
  }
  sortBrandData(){
    this.brandList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
  public getProductStatus() { // product status
    this.productstatusService.getproductStatus().subscribe(data => {
      this.productstatusinfo = data.data;
    })
  }
  getAllProducts() {
    
      var requestparameter = "?organizationId=" + this.orgId;
      if(this.categoryId) { requestparameter+="&CategoryId="+this.categoryId }
      if(this.variantId) { requestparameter+="&VariantTypeId="+this.variantId }
      if(this.brandId) { requestparameter+="&brandIds="+this.brandId }
      if(this.statusId && this.statusId!="0"){
        requestparameter+="&statuses="+this.statusId
      }
      this.getProductList(requestparameter)
  }
  getProductList(requestparameter){ // mai product list calling function
    this.setSearchNotFoundVariables();
    if(requestparameter===this.checkRequestParameter||this.checkRequestParameter===undefined){
      this.setDefaultNoItemsFound();
    }
    this.productListService.getData(requestparameter)
    .subscribe(res => {

      if(res.data.data.length){
      this.noDataFound = false;
      this.productList = this.modifyProductList(res.data["data"])
      this.displayTotal = this.getDisplayTotal()
      this.token = res.data["paging"].continuationToken;
      this.showLoadMoreButton = this.token ? true : false;
      this.apiInProgress = false;
      this.patchFormValue();
      !this.router.url.includes('variants') && this.setQueryString();}
      else{
        this.customMessageService.ShowCustomMessage(messages.noRecordFound,true);
        this.apiInProgress = false;
        this.noDataFound = true;
        this.productList = [];
      }
    })
  }
  modifyProductList(productList){ // add additional property for show hide actions buttons, variation collapse etc..
    productList.forEach(res=>{
      res['expandVariants'] = false; // in case of variations
      res['showActionButtons'] = false;
      res['originalMrp'] = res['mrp']; // properties like originalMrp and originalSellingPrice is for camparing 
      res['originalSellingPrice'] = res['sellingPrice']; // with user input.. based on we show hide actions buttons
      if(res.isVariantGroup && res.productVariants.length){
        res.productVariants.forEach(varinatRes=>{
          varinatRes['originalMrp'] = varinatRes['mrp'];
          varinatRes['originalSellingPrice'] = varinatRes['sellingPrice'];
          varinatRes['showActionButtons'] = false;
        })
      }
    })
    return productList
  }
  getCommaSeparated(arrayObjects: string[]): string {
    return Array.prototype.map.call(arrayObjects, s => s).toString();
  }
  getMoreData() { // on load more button 
    if (this.token) {
      this.showLoadMoreButton = true;
      this.callApiAgain(this.token)
    }
  }
  callApiAgain(token) { // get token data
    this.apiInProgress = true;
    let requestParameter = this.getSearchParameter();
    requestParameter = requestParameter ? requestParameter + "&continuationToken=" + encodeURIComponent(token) :
    "?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token)
    this.productListService.getData(requestParameter).subscribe(res => {
        if (res.data) {
          this.apiInProgress = false;
          let tokenData = this.modifyProductList(res.data.data)
          tokenData.forEach(res=>{
            res['expandVariants'] = false;
            this.productList.push(res)
          })
          this.token = res.data["paging"].continuationToken;
          this.showLoadMoreButton = this.token ? true : false;
          this.displayTotal = this.getDisplayTotal();
        }
        this.apiInProgress = false;
      });
  }
  addProductsFromCatalogue(){ // redirect to catalogue
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS+RouterLinksEnum.CATALOUGE)
  }
  redirectToAddProduct(){ // redirect to create products
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS_NO_SLASH+RouterLinksEnum.ADMIN_NEW)
  }
  redirecttoReadOnly(id, name) {
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.MANAGE_PRODUCTS+ id], { state: { productName: name } });
  }
  ngOnDestroy() {
    this.subscriptionRef.unsubscribe();
  }
  getDisplayTotal() { // not used
    return this.productList.length;
  } 
  checkToggleDisabled(status){ // not used
    return status == ProductStatusEnum.Discontinued ?  true :  false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentDevice.emit(this.deviceDetect.currentDevice()); 
  }
  expandVariantProducts(product){ // show hide variant products based on 'expandVariants' property
    this.productList.forEach(res=>{
      if(res.id == product.id && res.isVariantGroup){
        res['expandVariants'] = ! res['expandVariants']
      }else{
        res['expandVariants'] = false;
      }
    })
  }
  showAdvanceSearch(){ // used for show hide advance filter 
    this.showMenuFilterBar = this.showMenuFilterBar ? false : true
  }
  filteredCategories(value: string) {  // function will return filtered Categories baesd upon user input
    let filter = value.toLowerCase();
    return this.categoryList.filter(x => x.fullHierarchicalName.toLowerCase().includes(filter));
  }
  patchFormValue(){ // this function patch value for other component filters
    if(this.statusId || this.brandId || this.categoryId){
      if(this.statusId){
        this.searchForm.value.productStatus = []
        this.searchForm.value.productStatus.push(this.statusId)
      }
      if(this.brandId) { 
        this.searchForm.value.productBrand = []
        this.searchForm.value.productBrand.push(this.brandId)
      }
      if(this.categoryId){
        let category = this.categoryList.filter(category => category.id == this.categoryId)
        if(category.length){
          this.searchForm.value.productCategory = category[0].fullHierarchicalName
          this.searchForm.value.CategoryId = category[0].id
        }
      }
    this.searchForm.patchValue(this.searchForm.value)
    }
  }
  saveProduct(productData,index,variantproductIndex = null){
    this.productService.putProductData(productData).subscribe(data => {
      let message = data.statusMessage || data.StatusMessage;
      this.customMessageService.ShowCustomMessage(message, true);
      if(variantproductIndex == 0 || variantproductIndex){
        this.productList[index].productVariants[variantproductIndex]['showActionButtons'] = false
        this.productList[index].productVariants[variantproductIndex]['originalMrp'] = this.productList[index].productVariants[variantproductIndex]['mrp']
        this.productList[index].productVariants[variantproductIndex]['originalSellingPrice'] = this.productList[index].productVariants[variantproductIndex]['sellingPrice']
      }
      else{
        this.productList[index]['showActionButtons'] = false 
        this.productList[index]['originalMrp'] =  this.productList[index]['mrp']
        this.productList[index]['originalSellingPrice'] =  this.productList[index]['sellingPrice']
      }
    })
  }
  
  outerPriceKeyUp(list,index){ // used for show hide non variantion action buttons
    list['mrp'] = list['mrp'] ? list['mrp'] : 0
    list['sellingPrice'] = list['sellingPrice'] ? list['sellingPrice'] : 0
    if(list['mrp'] != list['originalMrp']){
      this.productList[index]['showActionButtons'] = true 
    }
    if(list['sellingPrice'] != list['originalSellingPrice']){
      this.productList[index]['showActionButtons'] = true 
    }
    if(list['sellingPrice'] == list['originalSellingPrice'] && list['mrp'] == list['originalMrp']){
      this.productList[index]['showActionButtons'] = false 
    }
  }
  cancelProduct(list,index){ // hide non variantion action buttons
    this.productList[index]['mrp'] = list['originalMrp'] 
    this.productList[index]['sellingPrice'] = list['originalSellingPrice'] 
    this.productList[index]['showActionButtons'] = false
  }
  varinatPriceKeyUp(variant,index,variantIndex){ // used for show hide variantion action buttons
    variant['mrp'] = variant['mrp'] ? variant['mrp'] : 0
    variant['sellingPrice'] = variant['sellingPrice'] ? variant['sellingPrice'] : 0
    if(variant['mrp'] != variant['originalMrp']){ 
      this.productList[index].productVariants[variantIndex]['showActionButtons'] = true 
    }
    if(variant['sellingPrice'] != variant['originalSellingPrice']){
      this.productList[index].productVariants[variantIndex]['showActionButtons'] = true 
    }
    if(variant['sellingPrice'] == variant['originalSellingPrice'] && variant['mrp'] == variant['originalMrp']){
      this.productList[index].productVariants[variantIndex]['showActionButtons'] = false
    }
  }
  cancelVariant(variant,index,variantIndex){ // hide variantion action buttons
    this.productList[index].productVariants[variantIndex]['mrp'] = this.productList[index].productVariants[variantIndex]['originalMrp']
    this.productList[index].productVariants[variantIndex]['sellingPrice'] = this.productList[index].productVariants[variantIndex]['originalSellingPrice']
    this.productList[index].productVariants[variantIndex]['showActionButtons'] = false
  }


  setQueryString(){
    let formControlKeys = Object.keys(this.searchForm.value)

    for(let i=0;i<formControlKeys.length;i++)
    {

      if(this.searchForm.get(formControlKeys[i]).value || JSON.stringify(this.queryString).includes(formControlKeys[i]))
      {
        let stickySearch = "&"+formControlKeys[i]
        this.queryString = this.stickySearchDirective.setRouteParamenter(stickySearch,this.searchForm);
        Object.keys(this.queryString).forEach(key => {
          if (!this.queryString[key]) {
            delete this.queryString[key];
          }
        });
      }  
    }
    this.router.navigate( [RouterLinksEnum.ADMIN],  { queryParams: { query: decodeURI(JSON.stringify(this.queryString)) } })

  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
  }

  setSearchValues(){
    this.apiInProgress = true;
    this.stickySearchDirective.getRouteParamenter(this.searchForm);
    let formControlKeys = Object.keys(this.searchForm.value)
   
    for(let i=0;i<formControlKeys.length;i++){
      if(this.searchForm.get(formControlKeys[i]).value)
      {

        this.onFormSubmit();
        return;
      }  
    }
    this.getAllProducts()
  }
  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle="Products";
    this.noItemsFoundDesc=messages.noResultFoundDesc;
  }
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle="Products";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }
}

