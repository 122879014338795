import {
    Directive,
    HostBinding,
    HostListener,
    Output,
    EventEmitter
  } from "@angular/core";
  import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
  
  export interface FileHandle {
    file: File,
    url: SafeUrl
    updateStatus:number
    fileUrl:string
    fileUrlWithToken:string
  }
  
  @Directive({
    selector: "[appDrag]"
  })
  export class DragDirective {
    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
    @Output() onDrag: EventEmitter<string> = new EventEmitter<string>();
    @Output() onDragleave: EventEmitter<string> = new EventEmitter<string>();
    @HostBinding("style.background") private background = "#eee";
    //@HostBinding("style.border") private border = "none";
    @HostBinding("style.display") private display = "block";
  
    constructor(private sanitizer: DomSanitizer) { }
  
    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      //this.background = "#999";
      //this.border = "dashed 2px #aaa";
      this.display = "block";
      this.onDrag.emit("Drag");
    }
  
    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      //this.background = "#eee";
      //this.border = "none";
      this.display = "block";
      this.onDragleave.emit("Leave");
    }
  
    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      //this.background = '#eee';
      //this.border = "none";
      this.display = "block";
    
      let files: FileHandle[] = [];
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        const file = evt.dataTransfer.files[i];
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
        const updateStatus = 1;
        const fileUrl = "";
        const fileUrlWithToken = "";
        files.push({ file, url ,updateStatus,fileUrl,fileUrlWithToken});
      }
      //if (files.length > 0) {
        this.files.emit(files);
      //}
    }
  }
  