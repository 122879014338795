import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import { HistoryService} from '../../services/History/history.service';
import { CustomDateService } from '../../services/Common/customDateService';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';

@Component({
  selector: 'app-history.dialog',
  templateUrl: './history.dialog.html',
  styleUrls: ['./history.dialog.css']
})
export class HistoryDialogComponent implements OnInit {
  historyData: any = [];
  PIMEntityTypeEnum: { id: number; name: string; }[];
  onloadShowHistory:boolean = true;
  onSelectionShowHistory: boolean = false;
  selectedIndex: number = 0;
  showHistory: boolean = false;
  PIMActivityTypeEnum: { id: number; name: string; }[];
  noHistory: boolean = false;
  PIMActivityTypeValue: { id: number; name: string; }[];
  continuation: string;
  showLoadMore: boolean = false;
  continuationDatasource = [];
  entityIdQuery : string = "?entityId="; 
  entityTypeQuery : string = "&entityType=";
  continuationQuery: string = "&continuation=";
  paging: string = "paging";
  selected: string= "selected";
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor(public dialogRef: MatDialogRef<HistoryDialogComponent>,private historyService:HistoryService,
              private customDateService:CustomDateService,
              private deviceDetectService: DeviceDetect,
              @Inject(MAT_DIALOG_DATA) public data: any,) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
    this.checkScreenSize();
    this.WMSEntityType()
      if(this.data)
      {
        setTimeout(()=>{this.getHistory(this.data.entityId,this.data.entityType)},1000);
        
      }
      
  }
  WMSEntityType()
  {
    this.PIMActivityTypeEnum = 
    [{ id: 1, name: 'is created' },
    { id: 2, name: 'is updated' },
    { id: 3, name: 'is deleted' },
  ],
  this.PIMActivityTypeValue = 
  [{ id: 1, name: 'Created' },
  { id: 2, name: 'Updated' },
  { id: 3, name: 'Deleted' },
],
    this.PIMEntityTypeEnum = 
    [{ id: 1, name: 'Product' },
    { id: 2, name: 'Category' },
    { id: 3, name: 'Brand' },
    { id: 4, name: 'NotificationEventSettings' },
    { id: 5, name: 'ProductTag' },
    { id: 6, name: 'Variant' },
    { id: 7, name: 'User' },
    { id: 8, name: 'Asset' },
    { id: 9, name: 'Folder' },
    { id: 10, name: 'Channel' }
    ];
  }
  getHistory(entityId,entityType)
  {
      let requestParameter = this.entityIdQuery + entityId + this.entityTypeQuery+entityType
      this.historyService.getHistory(requestParameter).subscribe(res=>{
        this.historyData = res.data.data;
        if(this.historyData.length > 0)
        {
          for(let i=0;i<this.historyData.length;i++){
            this.historyData[i][this.selected] = 'false'
          }
          this.historyData[0][this.selected] = 'true'
          this.showHistory = true;
          this.continuationDatasource = this.historyData ;
          this.continuation = res.data[this.paging].continuationToken;
          this.showLoadMoreButton();
        }
        else
        {
          this.noHistory = true; 
        }
    })
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  getShortText(text) {
    var myStr =text;
    var matches = myStr.match(/\b(\w)/g);
    return matches.join('');
  }
  showActivityType(entityType,activityType) {
    var text;
    text =  this.PIMEntityTypeEnum[entityType - 1].name +" "+ this.PIMActivityTypeEnum[activityType - 1].name
    return text
  }
  onHistoryListSelection(ind)
  {
    this.historyData.forEach(element => {
      element['selected'] = 'false';
    });
    this.historyData[ind]['selected'] = 'true';
    this.selectedIndex = ind;
    this.onSelectionShowHistory = true;
    this.onloadShowHistory = false;
  }
  getWrapedText(text,maxLength)
  {
    if(text.length <= maxLength)
    {
      return text;
    }
    else
    {
      return text.substring(0,maxLength-3) + "...";
    }
  }
  removeLastComma(strng){        
    var n=strng.lastIndexOf(",");
    var a=strng.substring(0,n) 
    return a;
  }
  customDateAndTime(date)
    {
      return this.customDateService.customDateAndTimeFormat(date);
    }
    customDate(date)
    {
      return this.customDateService.customDateFormat(date);
    }
    showLoadMoreButton(){

      if (this.continuation) {
        this.showLoadMore = true;
      }
      else {
        this.showLoadMore = false;
      }
    }
    getMoreData(){
      if (this.continuation !== null || this.continuation !== undefined) {
        this.showLoadMore = false
        this.continuationData(this.continuation)
      }
    }
    continuationData(continuation){
      if (continuation !== null || continuation !== undefined) {
  
        let requestParameter = this.entityIdQuery + this.data.entityId + this.entityTypeQuery + this.data.entityType + this.continuationQuery + encodeURIComponent(continuation)
        this.historyService.getHistory(requestParameter).subscribe(res=>{
        if(res.data.data.length > 0)
          {
            for(let i=0;i<res.data.data.length;i++){

              res.data.data[i][this.selected] = 'false';
              this.continuationDatasource.push(res.data.data[i])
            }
  
            this.continuationDatasource[0][this.selected] = 'true'
            this.showHistory = true;
            this.historyData = this.continuationDatasource ;
            this.continuation = res.data[this.paging].continuationToken;
            this.showLoadMoreButton();
  
          }
  
  
      })
  
      }
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetectService.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetectService.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetectService.currentDevice().desktopScreen;
  }
}
