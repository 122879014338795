import { Injectable } from '@angular/core';
import { HttpHelperService } from '../Common/http-helper.service';
import { Observable } from 'rxjs';
import {BaseSearchproductModel} from '../../models/product/searchproductmodel';
@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  // _urlProducts = "Product/search/product"
  // _urldelete = "Product/product/";

  //-----------------Refactored Urls - 6/18/2021 ---------------------------
  _urlProducts = "Products";
  _urldelete = "Products/";
  constructor(private httpHelper: HttpHelperService) { }

  getData(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._urlProducts + queryString) as Observable<any>;
  }

  removeData(queryString): Observable<BaseSearchproductModel> {
    return this.httpHelper.delete(this._urldelete + queryString) as Observable<BaseSearchproductModel>;
}

}
