import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {DashBoardModel,BaseDashBoardModel} from '../../models/dashboard/dashboardmodel';
import {BaseDashboardActivitiesModel} from '../../models/dashboard/dashboardactivitiesmodel';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
_urlDashboard = "dashboard"
_urlDashboardActivities = "dashboard/activities";
  constructor(
    private httpHelper: HttpHelperService,
  ) { }

  getDashboardData(quertString = "") : Observable<BaseDashBoardModel>
  {
    return this.httpHelper.getData(this._urlDashboard + quertString) as Observable<BaseDashBoardModel>;
  }

  getDashboardActivities(queryString = "") : Observable<BaseDashboardActivitiesModel>
  {
    return this.httpHelper.getData(this._urlDashboardActivities + queryString) as Observable<BaseDashboardActivitiesModel>;
  }
}
