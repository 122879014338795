export enum titlename {
   productList="Products",
   addProduct = "Add New Product",
   editProduct ="Edit Product",
   viewProduct ="View Product",
   product = "Product",
   addCategory ="Add New Category",
   updateCategory= "Update Category",
   categoryList="Categories",
   addCategoryAsset="Add New Asset Category",
   updateCategoryAsset= "Update Asset Category",
   categoryAssetList="Assets",
   brands= "Brands",
   addBrand="Add New Brand",
   updateBrand="Update Brand",
   addVariant="Add New Variant",
   updateVariant="Update Variant",
   variant="Variants",
   dashboard="Dashboard",
   createChannel = "Add Channel",
   editChannel = "Edit Channel",
   viewChannel = "View Channel",
   channelList = "Channels",
   settings = "Settings",
   resources = "Resources"
}
