import { 
  Directive, 
  ElementRef,  
  HostListener, 
  Input,
  OnInit, 
  OnDestroy,
  Output, 
  EventEmitter 
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appStickySearch]'
})
export class StickySearchDirective implements OnInit, OnDestroy {
  
  @Input('appStickySearch') stickySearch: string;
  @Output() preserveClick = new EventEmitter();
  
  private clicks = new Subject();
  private subscription: Subscription;
  queryParam: string;
  queryString: any = {}

  constructor(private el: ElementRef, private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscription = this.clicks.subscribe(e => this.preserveClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public setRouteParamenter(stickySearch: string, searchForm: any) {
    let tempKey = "";
    let tempValue = "";
    tempKey = stickySearch.substring(1).split('=')[0];
    tempValue = searchForm.get(tempKey).value;
    if(tempValue != 'undefined' && tempValue != null) {
      this.queryString[tempKey] = tempValue;
      return this.queryString;
    } else {
      this.queryString[tempKey] = null;
       return this.queryString;
    }
  }

  public getRouteParamenter(searchForm) {
    this.route.queryParams.subscribe(params => {
      this.queryParam = params['query'] || null;
      this.queryParam = JSON.parse(this.queryParam);
      if(this.queryParam) {
        Object.keys(searchForm.controls).forEach(key => {
          searchForm.controls[key].setValue('');
        });
        if(searchForm.get('DateRangeValue') !== null) {
          searchForm.get('DateRangeValue').setValue(null);
        }
        Object.entries(this.queryParam).map(param => {
            searchForm.get(param[0]).setValue(param[1])
        })
        this.queryString = this.queryParam;
      }
      else {
        Object.keys(searchForm.controls).forEach(key => {
          searchForm.controls[key].setValue('');
        });
        if(searchForm.get('DateRangeValue') !== null) {
          searchForm.get('DateRangeValue').setValue(null);
        }
        if(searchForm.get('OrdersTabToDisplay') !== null) {
          searchForm.get('OrdersTabToDisplay').setValue('0');
        }
      }
    });
  }
  @HostListener('focusout', ['$event'])
    onFocusout(event) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks.next(event);
    }
}
