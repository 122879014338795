import {Component,Input, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';
import { AssetsDefaultComponent } from '../themes/assets/assets-default/assets-default.component';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit,AfterViewInit {
  @ViewChild(AssetsDefaultComponent, { static: false }) childComponent: AssetsDefaultComponent;
  @Input() hideTopPart:boolean  //added to inject product into category
  @Input() injectedSearchText
  @Input() injectedCategoryId
  @Input() hideTableHeader
  @Output() callParent = new EventEmitter();
  themeComponentName: string = '';
  themeSubscription: Subscription;
  assetComponent:any;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['asset'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
  this.themeSubscription.unsubscribe();
  }

  getAssetLength(event){
    this.callParent.emit(event);
  }

  ngAfterViewInit(): void {
    this.assetComponent = this.childComponent;
  }
}


  
