import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  themeSubscription:Subscription;
  themeComponentName:string;
    constructor(private commonService:CommonService) {
      this.themeSubscription = this.commonService.getComponentData.subscribe(res=>{
        this.themeComponentName = res['pimSettings'];
      })
     }
  
    ngOnInit() {
    }
  
    ngOnDestroy(): void {
      this.themeSubscription.unsubscribe();
    }
}
