import { Injectable } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { CommonService } from 'src/app/core/Common/common.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CurrencyPipeService {
 defaultCurrencyCode:string = environment.currencySymbol;
  constructor( private commonService:CommonService) {
    // this.getCurrencySign();
   }

  // private getCurrencySign(){
  //   const organizationData = this.commonService.getOrganizationData('') || {};
  //   this.defaultCurrencyCode = JSON.parse(organizationData).currencyKey;
  // }
  
  fetchCurrencySymbol(value,format,code){
    //  if(code){
    //      let currencySymbol = getCurrencySymbol(code, format);
    //      return formatCurrency(value,'en',currencySymbol,'','1.0-2');
    //  } else {
        let currencySymbol = getCurrencySymbol(this.defaultCurrencyCode, format);
        return formatCurrency(value,'en',currencySymbol,'','1.0-2');
    //  }
  }
} 