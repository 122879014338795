import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../../Common/http-helper.service';


@Injectable({
  providedIn: 'root'
})
export class CommentService {
  // _baseUrl = environment.orderApiBaseUrl;
  constructor(private httpHelper:HttpHelperService) { }
  getAllComments(querySyring = ""): Observable<any> {
    let url = 'Comments';
    return this.httpHelper.getData(url + querySyring);
  }
  getCommentsById(id): Observable<any> {
    let url = 'Comments/'+id;
    return this.httpHelper.getData(url);
  }
  saveComment(data:any) : Observable<any>
  {
    let url = 'Comments';
    return this.httpHelper.postData(data,url);
  }
  updateComment(data:any) : Observable<any>
  {
    let url="Comments"
    return this.httpHelper.putData(data,url);
  }
  deletCommentById(id)
  {
    let url="Comments/"+id
     return this.httpHelper.delete(url);
  }
  
  getAllWMSComments(querySyring = ""): Observable<any> {
    let url = 'Comments/WMS';
    return this.httpHelper.getData(url + querySyring);
  }
  getWMSCommentsById(id): Observable<any> {
    let url = 'Comments/WMS/'+id;
    return this.httpHelper.getData(url);
  }
  saveWMSComment(data:any) : Observable<any>
  { 
    let url = 'Comments/WMS';
    return this.httpHelper.postData(data,url);
  }
  updateWMSComment(data:any) : Observable<any>
  {
    let url="Comments/WMS"
    return this.httpHelper.putData(data,url);
  }
  deleteWMSCommentById(id)
  {
    let url="Comments/WMS/"+id
     return this.httpHelper.delete(url);
  }
}
