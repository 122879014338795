import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class OMSEntityService {

    OMSActivityTypeEnum =
    [{ id: 1, name: 'is created' },
    { id: 2, name: 'is updated' },
    { id: 3, name: 'is deleted' },
  ];
    OMSEntityTypeEnum =
    [ { id: 1, name: 'Channel' },
      { id: 2, name: 'Order' },
      { id: 3, name: 'Order Fulfillment' },
      { id: 4, name: 'Notification Event Settings' },
      { id: 5, name: 'User' },
      { id: 6, name: 'Return Order' },
      { id: 7, name: 'Shipping Option' },
    ];
  OMSActivityTypeValue =
    [
      { id: 1, name: 'Created' },
      { id: 2, name: 'Updated' },
      { id: 3, name: 'Deleted' },
    ]
}