import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { titlename } from '../../../ENUM/titlename';
import { SettingService } from '../../../services/Settings/setting.service';
import { OrganizationCategoriesOnly } from '../../../constants/messages';

@Component({
  selector: 'app-settings-default',
  templateUrl: './settings-default.component.html',
  styleUrls: ['./settings-default.component.scss']
})
export class SettingsDefaultComponent implements OnInit {
  titlename: string;
  titleEnum: typeof titlename;
  organizationSettingsForm: FormGroup;
  organizationCategoriesOnly: string;

  constructor(private formbuilder: FormBuilder,
    private settingService: SettingService,
    private customMessageService: CustomMessageService) {
    this.organizationSettingsForm = this.formbuilder.group({
      searchOrganizationCategoriesOnly:[false]
    });
  }

  ngOnInit() {
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.settings;
    this.organizationCategoriesOnly= OrganizationCategoriesOnly;
    this.getOrgSettings();
  }
  getOrgSettings() {
    this.settingService.getOrganizationSettings().subscribe(res => {
      if (res.data) {
        this.organizationSettingsForm.patchValue({ 
      'searchOrganizationCategoriesOnly':res.data.searchOrganizationCategoriesOnly
      });
      }
    });
  }

  onSubmitOrganization() {
    let formData = this.organizationSettingsForm.value;
    if (formData) {
      this.settingService.updateOrganizationSettings(formData).subscribe(
        response => {
          this.customMessageService.ShowCustomMessage(response.statusMessage, true);
        });
    }
  }
}

