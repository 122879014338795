import {Injectable} from '@angular/core';

@Injectable()
export class ScrollingToErrorsService {
    scrollTo(el: Element): void {
        if (el) {
          return el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    
      scrollToError(): void {
        const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
        this.scrollTo(firstElementWithError);
      }
}
