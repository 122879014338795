import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';


@Component({
  selector: 'app-create-asset',
  templateUrl: './create-asset.component.html',
  styleUrls: ['./create-asset.component.scss']
})
export class CreateAssetComponent implements OnInit {
  themeSubscription: Subscription;
  themeComponentName: string;
  assetCatId:any;
  assetCatName:any;
  redirectUrl:any;

  constructor(private commonService: CommonService,private route:ActivatedRoute,private router:Router) {
     this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['createAsset'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }
}
