import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timeStamp } from 'console';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CommonService } from 'src/app/modules/shop/core/Common/common.service';
import { GetSetSessionStorageService } from 'src/app/modules/shop/core/Common/get-set-session-storage.service';
import { PaymentMethodsEnum } from 'src/app/modules/shop/ENUM/paymentMethods';
import { BaseOrderItemModel } from 'src/app/modules/shop/models/order/orderModel';
import { OrganizationAddress } from 'src/app/modules/shop/models/organizationAddressModel';
import { messages } from 'src/app/shared/Constants/messageResource';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  orderId: string;
  orderInfo: BaseOrderItemModel;
  shopLogo: string;
  shopName: string;
  currencyCode: string;
  paymentMethod = PaymentMethodsEnum;
  organizationAddress: OrganizationAddress;
  contactNo: string;
  fullAddress: string;
  totalQuantity: number;
  barcodeObj: object;
  elementType = 'svg';
  value = 'someVal';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  barcodePlaceholder: string;
  isPhone: boolean;
  message = messages.publicInvoiceMsg
  constructor(private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private getSessionStorageService: GetSetSessionStorageService,
    private deviceDetect: DeviceDetect) {
    this.orderId = this.activatedRoute.snapshot.params['id'];
  }

  ngOnInit() {
    this.shopName = this.getOrgName();
    this.shopLogo = this.getOrgLogo();
    this.getOrderDetails(this.orderId);
    this.getOrganizationAddress();
    this.checkDeviceType()
  }

  getOrderDetails(id) {
    this.commonService.getOrderDetails(id).subscribe(res => {
      this.orderInfo = res['data'];
      this.barcodeObj = { 'Order-id': this.orderInfo.orderId }
      this.currencyCode = this.orderInfo['orderTotalAmount']['currencyCode'];
      this.barcodePlaceholder = `Order No:${this.orderInfo.orderId}`
      this.totalQuantity = this.orderInfo.orderItems.reduce(
        (previousValue, currentValue) => previousValue + currentValue.presentationQty,
        0,
      );
    })
  }

  getOrgLogo() {
    let shopOrgInfo = this.getSessionStorageService.getOrgInfoFromSession();
    return shopOrgInfo['logoURI'] && shopOrgInfo['logoURI'] || null;
  }
  getOrgName() {
    let shopOrgInfo = this.getSessionStorageService.getOrgInfoFromSession();
    return shopOrgInfo['name'] && shopOrgInfo['name'] || null;
  }

  getOrganizationAddress() {
    this.commonService.getOrganizationAddress().subscribe(res => {
      this.organizationAddress = res.data;
      this.contactNo = res.data && res.data.contact && res.data.contact.phone1;
      this.fullAddress = this.getFullAddress(res.data.address)
    });
  }

  getFullAddress(address) { // return full address
    let address1 = address.addressLine1 ? ', ' + address.addressLine1 : ''
    let address2 = address.addressLine2 ? ', ' + address.addressLine2 : ''
    let address3 = address.addressLine3 ? ', ' + address.addressLine3 : ''
    let cityName = address.cityName ? ', ' + address.cityName : ''
    let stateName = address.stateName ? ', ' + address.stateName : ''
    let countryName = address.countryName ? ', ' + address.countryName : ''
    let postalCode = address.postalCode ? ', ' + address.postalCode : ''
    let fullAddress = address1 + address2 + address3 + cityName + stateName + countryName + postalCode
    return fullAddress = fullAddress.substr(1);
  }

  checkDeviceType() {
    this.isPhone = this.deviceDetect.currentDevice().phoneScreen ? true : false;
  }
}
