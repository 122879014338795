import { Injectable } from '@angular/core';
import { UserStatusService } from '../User/user-status.service';
import { UserModel } from 'src/app/shared/models/authorization/user-model';
import { AppConstants } from 'src/app/constants/global';
import { AuthService } from '../Authorization/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  SessionStorage_UserModel = "SessionStorge_UserModel";

  constructor(private userstatusService: UserStatusService, private _authService: AuthService) {

  }
  getloggedinUserinfo(): Promise<UserModel> {
    let isLoggedIn = this._authService.isLoggedIn();
    if (sessionStorage[this.SessionStorage_UserModel] != null) {
      return Promise.resolve(JSON.parse(sessionStorage[this.SessionStorage_UserModel]));
    }
    else {
      if (isLoggedIn) {
        return this.userstatusService.getUserStatus().then(res => {
          sessionStorage[this.SessionStorage_UserModel] = JSON.stringify(res.data);
          return res.data;
        })
      } else {
        return Promise.resolve(null);
      }

    }
  }

  removeloggedinUserinfo() {
    sessionStorage.removeItem(AppConstants.SHOP_ACTIVITIES);
    sessionStorage.removeItem(AppConstants.PRODUCT_ACTIVITIES);
    sessionStorage.removeItem(AppConstants.ORDER_ACTIVITIES);
    sessionStorage.removeItem(AppConstants.INVENTORY_ACTIVITIES);
    sessionStorage.removeItem(AppConstants.ESTORE_ADMIN_ACTIVITIES);
    sessionStorage.removeItem(this.SessionStorage_UserModel);
    sessionStorage.removeItem(AppConstants.APPLICATION_SHORT_NAME);
  }

  setShopOrgInfoIntoSession(data) {
    sessionStorage.setItem(AppConstants.SHOP_SESSION_INFO, JSON.stringify(data));
  }
  setAppInfoIntoSession(data) {
    sessionStorage.setItem(AppConstants.APPLICATION_SHORT_NAME, data);
  }

  getCanAccessAdminValue():boolean {
    let getCanAccessAdminValue = localStorage.getItem(AppConstants.CAN_ACCESS_ADMIN);
    if (getCanAccessAdminValue) {
      return getCanAccessAdminValue == "true";
    } else {
      return false;
    }
  }

  removeOrganizationInfo(){
    localStorage.removeItem(AppConstants.ORGANIZATION_INFO);
  }
}
