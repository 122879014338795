export enum RouterLinksEnum {
	INOUT="InOut",

	WAREHOUSE = "warehouse",
	WAREHOUSE_CREATE = "warehouse/new",
	WAREHOUSE_PIN_CODE_MAPPING = "warehousePinCodesMapping/",
	WAREHOUSE_USER_MAPPING = "userMapping/",

	CUSTOMER = "customers",
	CUSTOMER_SLASH = "customers/",

	LOCATION = "locations",
	LOCATION_SLASH = "locations/",
	CREATE_LOCATION = "locations/new",


	GRN = "grn",
	GRN_SLASH = "grn/",
	CREATE_GRN = "grn/new",

	GDN = "gdn",
	GDN_SLASH = "gdn/",
	CREATE_GDN = "gdn/new",

	ZSH = "zsh",
	ZSH_SLASH = "zsh/",
	CREATE_ZSH = "zsh/new",

	STA = "sta",
	STA_SLASH = "sta/",
	CREATE_STA = "sta/new",

	ALC = "alc",
	ALC_SLASH = "alc/",
	CREATE_ALC = "alc/new/",

	RFC = "rfc",
	RFC_SLASH = "rfc/",
	CREATE_RFC = "rfc/new",

	RCO = "rco",
	RCO_SLASH = "rco/",
	CREATE_RCO = "rco/new",

	STR = "str",
	STR_SLASH = "str/",
	CREATE_STR = "str/new",

	IFC = "ifc",
	IFC_SLASH = "ifc/",
	CREATE_IFC = "ifc/new",

	RTV = "rtv",
	RTV_SLASH = "rtv/",
	CREATE_RTV = "rtv/new",

	GOS = "gos",
	GOS_SLASH = "gos/",
	CREATE_GOS = "gos/new",

	STD = "std",
	STD_SLASH = "std/",
	CREATE_STD = "std/new",

	ITEM_WISE_LEDGER = "ledger/item-wise-ledger",
	LOCATION_WISE_LEDGER = "ledger/location-wise-ledger",

	ORDER_FULFILLMENT = "order-fulfillment",
	ORDER_FULFILLMENT_SLASH = "order-fulfillment/",
	CREATE_ORDER_FULFILLMENT_SLASH = "order-fulfillment/new",
	REVERSE_ORDER_FULFILLMENT = "reverse-order-fulfillment",
	REVERSE_ORDER_FULFILLMENT_SLASH = "reverse-order-fulfillment/",
	CREATE_REVERSE_ORDER_FULFILLMENT_SLASH = "reverse-order-fulfillment/new",

	SHIPPING_SERVICE_PROVIDER = "shipping-service-provider",
	SHIPPING_SERVICE_PROVIDER_SLASH = "shipping-service-provider/",
	CREATE_SHIPPING_SERVICE_PROVIDER = "shipping-service-provider/new",

	OVER_SHORT_REPORT = "reports/overshort-report",
	STOCK_REPORT = "reports/stock-report",
	AGED_INVENTORY_REPORT = "reports/aged-inventory-report",
	EXPIRED_PRODUCT_REPORT = "reports/expired-product-report",
	EXPIRING_PRODUCT_REPORT = "reports/expiring-products-report",
	STOCK_AVAILABILTY_REPORT = "reports/stock-availabilty-report/",	

	MANIFEST = "manifest",
	MANIFEST_SLASH = "manifest/",
	REVERSE_MANIFEST = "reverse-manifest",
	REVERSE_MANIFEST_SLASH = "reverse-manifest/",

	PICKLIST = "picklist",
	ORDER_WISE_PICKLIST = "picklist/orderWisePicklist/",
	LOCATION_WISE_PICKLIST = "picklist/locationWisePicklist/",

	VENDOR = "vendors",
	VENDOR_SLASH = "vendors/",
	CREATE_VENDOR = "vendors/new",


	DASHBOARD = "dashboard",

	DEPARTMENT = "departments",
	CREATE_DEPARTMENT = "departments/new",
	SETTING = "settings",

	BASE_ROUTE = '/admin/warehouse',
	BASE_ROUTE_SLASH = '/admin/warehouse/',
	EDIT = '/edit',
	COPY = '/copy',
	VIEW = '/view',
	NEW = "/new",
	SUB_LOCATION = "/sub-locations",
	MANAGE_WAREHOUSES = "/manage-warehouses",

	PLUGIN = "plugin",
	PLUGIN_SLASH = "plugin/",
    
}




