import { Injectable } from "@angular/core";
import { HttpHelperService } from '../Common/http-helper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostImage } from '../../models/images/image';

@Injectable({
    providedIn:'root'
})
export class ImageService {

    //private _postImageURL = 'Product/image';
    //private _deleteImageURL = 'Product/image/';

    //Refactored urls 21/6/2021--------------
    private _postImageURL = 'Products/image';
    private _deleteImageURL = 'Products/image';

    constructor(
        private httpHelperService: HttpHelperService
    ) {

    }

    postImage(image: PostImage):Observable<any> {
        return this.httpHelperService.postData(image,this._postImageURL).
            pipe(
                map(res => res['data'])
            );
    }

    postImageWithMetaData(image: PostImage):Observable<any> {
        return this.httpHelperService.postData(image,this._postImageURL);
    }

    deleteImage(queryString: string) {
        return this.httpHelperService.delete(this._deleteImageURL+queryString);
    }
}
