import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/shared/models/category/category';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  _baseUrl = environment.apiBaseUrl + environment.pimUrlIdentifier;
  constructor(private httpClient: HttpClient) { }
  getMasterCategories(queryString = ""): Observable<CategoryModel> {
    let url = this._baseUrl + 'Onboarding/masterCategories';
    return this.httpClient.get(url + queryString) as Observable<CategoryModel>;
  }
  getCategoriesForProducts(queryString = ""): Observable<any> {
    let url = this._baseUrl + 'Onboarding/categories';
    return this.httpClient.get(url + queryString) as Observable<any>;
  }

  cloneMasterCategories(data): Observable<any> {
    let url = this._baseUrl + 'Onboarding/masterCategories/clone';
    return this.httpClient.put(url, data) as Observable<any>;
  }
}
