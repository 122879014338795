import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  themeSubscription: Subscription;
  themeComponentName: string;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['dashBoard'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }

}