import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import { FormControl, Validators } from '@angular/forms';
import { RouterLinksEnum } from '../../shared/router-links/router-links.enum';
import {NEWCATEGORYNOTE} from '../../shared/constants/global'

@Component({
  selector: 'app-add-category.dialog',
  templateUrl: '../../dialogs/add-category/add-category.dialog.html',
  styleUrls: ['../../dialogs/add-category/add-category.dialog.css']
})
export class AddCategoryDialogComponent {
formSubmit = false;
isOpenAddCategoryBoolean = false;
newCategoryNote=NEWCATEGORYNOTE;
categoryName:FormControl = new FormControl();
  constructor(public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) { 
                dialogRef.disableClose = true;
                this.categoryName.setValidators([Validators.required,Validators.maxLength(50)])
              }

  cancel(): void {
    this.dialogRef.close({isOpenAddCategoryBoolean:this.isOpenAddCategoryBoolean});
  }

  addCategory(): void {
    this.formSubmit = true;
    if(this.categoryName.invalid){ return }
    this.dialogRef.close({data:this.categoryName.value})
  }

  openAddCategoryPage():void{
    let url = RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_CREATE ;
    this.isOpenAddCategoryBoolean = true;
    window.open(url, "_blank");
  }
}
