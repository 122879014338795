import { Injectable } from '@angular/core'
import { HttpHelperService } from '../Common/http-helper.service';
import { BaseProductBrand} from '../../models/product/productbrands';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductbrandService {
  _productbrand = "brands";
  _productBrandById = "Brands/"
  constructor(private _httpHelper: HttpHelperService) { }

  getproductBrand(queryString = "") : Observable<BaseProductBrand>
  { 
    return this._httpHelper.getData(this._productbrand + queryString) as Observable<BaseProductBrand>;
  }

  getproductBrandsActive(queryString = "") : Observable<BaseProductBrand>
  { 
    return this._httpHelper.getData(this._productbrand + queryString).pipe(
      map(res => res.data.data),
      map(results => results.filter(result => result.active))
    ) as Observable<BaseProductBrand>;
  }

  getproductBrandById(queryString = "") : Observable<BaseProductBrand>
  { 
    return this._httpHelper.getData(this._productBrandById + queryString) as Observable<BaseProductBrand>;
  }
}
