import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  _baseUrl = environment.apiBaseUrl + environment.organizationUrlIdentifier +"organization/";
  _orderBaseUrl= environment.apiBaseUrl+environment.ordersUrlIdentifier ;
  _commonUrl = environment.apiBaseUrl + environment.organizationUrlIdentifier +"common/";
  _shopBaseUrl =  environment.apiBaseUrl + environment.shopApiBaseUrl;
  constructor(private httpClient: HttpClient) { }

  getData(url: string) : Observable<any> {
    return this.httpClient.get<any>(this._baseUrl + url);
  }

  postData(body, url) : Observable<any>  {
    return this.httpClient.post((this._baseUrl + url), body);
  }

  putData(body, url) : Observable<any> {
    return this.httpClient.put((this._baseUrl + url), body);
  }
  putInvoiceData(body, url) : Observable<any> {
    return this.httpClient.put((this._orderBaseUrl + url), body);
  }

  // putBodyUrl(body, url) : Observable<any> {
  //   return this.httpClient.put((this._baseUrl + url), body);
  // }

  delete(url): Observable<any> {
    return this.httpClient.delete<any>(this._baseUrl + url);
  }

  deleteBody(url,body): Observable<any> {
    return this.httpClient.delete<any>(this._baseUrl + url,body);
  }

  downloadData(url: string) : Observable<Blob> {
    return this.httpClient.get<Blob>(this._baseUrl + url,{ responseType: 'blob' as 'json' });
  }

  orgFromUrlData(url: string) : Observable<any> {
    return this.httpClient.get<any>(this._baseUrl  + url);
  }

  orgFromId(url:string) : Observable<any>{
    return this.httpClient.get<any>(environment.apiBaseUrl + environment.shopUrlIdentifier + url);
  }

  getCommonData(url: string):Observable<any>{
    return this.httpClient.get<any>(this._commonUrl + url);
  }
  getEstoreOrg(url: string) : Observable<any> {
    return this.httpClient.get<any>(this._shopBaseUrl + url);
  }
  
}
