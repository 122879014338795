import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  //--------6/21/2021 Below URLS are Refactored-------------
  constructor(      private httpHelper: HttpHelperService,
    private httpClient: HttpClient) { }
  createAsset(assetData:any) : Observable<any>
  {
  
    let url = 'Assets';
    return this.httpHelper.postData(assetData,url);
  }
  getAllCategoriesActive(queryString = ""): Observable<any> {
    let url = "AssetCategories"
    return this.httpHelper.getData(url+ "?" + queryString).pipe(
      map(res => res.data.data),
      map(results => results.filter(result => result.active))
    )
  }
  updateAseet(assetData:any) : Observable<any>
  {

    let url="Assets"
    return this.httpHelper.putData(assetData,url);
  }
  updateAseetStatus(assetData:any) : Observable<any>
  {
 
    let url="Assets/Status"

    return this.httpHelper.putData(assetData,url);
  }
  uploadMedia(data) :Observable<any> {

    let url="Assets/File/upload";
    return this.httpHelper.postData(data,url);

  }
  getAssetById(id) : Observable<any>{
 
    let url="Assets/"+id

    return this.httpHelper.getData(url);
  }
  deletAssetById(id)
  {

    let url="Assets/"+id

     return this.httpHelper.delete(url);
  }
  getAllAssets(queryString = "") : Observable<any> {
 
    let url="Assets"

    return this.httpHelper.getData(url + queryString);
  }
  downloadAssets(queryString = "") : Observable<any> {
    let url="Assets/DownloadAssets"
    return this.httpHelper.getData(url + queryString);
  }
  getResources(queryString = "") : Observable<any> {
    let url="UserResources"
    return this.httpHelper.getData(url + queryString);
  }
}
