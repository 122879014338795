import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { forkJoin, Observable } from 'rxjs';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { messages } from 'src/app/shared/Constants/messageResource';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { environment } from 'src/environments/environment';
import { AssetsComponent } from '../../../assets/assets.component';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { BlobStorageService } from '../../../azure-storage/blob-storage.service';
import { SASTokenService } from '../../../azure-storage/sastoken.service';
import { AddFolderDialogComponent } from '../../../dialogs/add-folder/add-folder.dialog.component';
import { AddAssetDialogComponent } from '../../../dialogs/addAsset/add-asset.dialog.component';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from '../../../dialogs/warning/warning.dialog.component';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { CategoryModel } from '../../../models/Category/category-model.model';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { AssetCategoryService } from '../../../services/asset-category.service';
import { AssetService } from '../../../services/Assets/asset.service';
import { CustomDateService } from '../../../services/Common/customDateService';
import { ProductListService } from '../../../services/Product/product-list.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { FileHandle } from '../../../shared/directives/dragDrop.directive';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';



interface IUploadProgress {
  filename: string;
  progress: number;
}

@Component({
  selector: 'app-category-asset-default',
  templateUrl: './category-asset-default.component.html',
  styleUrls: ['./category-asset-default.component.scss']
})
export class CategoryAssetDefaultComponent implements OnInit {
  @ViewChild(AssetsComponent, { static: false }) childComponent: AssetsComponent;
  @Input() hideTopPart: boolean;
  isViewChildCategory: boolean = false;
  isViewItems: boolean = false;
  orgId: number;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  //organizationData: any[];
  jsonOrgData: string;
  myControl = new FormControl();
  options: Array<string>;
  response: Array<CategoryModel>;
  filteredOptions: Observable<string[]>;
  active: boolean;
  selection = new SelectionModel<any>(true, []);
  //displayedColumns = ['category', 'actions'];
  displayedColumns = ['select','icon', 'category', 'updatedOn', 'updatedBy','download','Actions'];
  displayTotal:number = 0;
  public dataSource = new MatTableDataSource<CategoryModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  tableSourceData: any;
  parentCategoryName: any;
  parentDescription: any;
  categoryId: number;
  token: any;
  newData: Array<CategoryModel>
  noMoreDataMessage: string = "";
  hideButton: boolean = true;
  searchText: string;
  showfullHierarchicalName: boolean = false;
  childData: any;
  childCount: any;
  dataRefresh: CategoryModel[];
  RouterLinksEnum: any;
  PermissionsEnum: typeof PermissionsEnum;
  activeParent: boolean;
  activeChild: boolean;
  buttonName: string;
  hideTable: boolean = true;
  routeId: any;
  titlename: string;
  titleEnum: typeof titlename;
  redirectUrl: any;
  childCategoryName: any;
  topCardParentId: any;
  element: any;
  hideAssetHeader = true;
  UploadStarted: boolean;
  uploadProgress$: Observable<IUploadProgress[]>;
  UploadProgress: Observable<number>;
  blobName: any;
  containerName: any;
  fileUrl: any;
  serviceUrlWithSAS: any;
  files: FileHandle[] = [];
  UploadCompleted: boolean;
  _response: BlobUploadCommonResponse;
  imgURL: any;
  assetForm: any;
  fileUrlArray: any = [];
  dialogRef: any;
  showProgress: boolean = false;
  progressStatus: { id: number; name: string; }[];
  fileInfo: { id: number; name: string; }[];
  fileName: any;
  valueStatus: number;
  index: any = 0;
  finalResponceArray: any = []
  onDragOver: boolean = true;
  thumbnailInfoArray: any = [];
  parentCategory: any;
  createAssetInfo: any = [];
  assetListLength: number;
  assetInfo: any = [];
  parentNodes: any = [];
  phoneScreen:boolean;
  tabletScreen:boolean;
  desktopScreen:boolean;
  noDataFound:boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  //isSearch: boolean = false;
  constructor( private router: Router, private historyDialogService: HistoryDialogService,
    private productSearchService: ProductListService, private route: ActivatedRoute, public httpClient: HttpClient,
    private _assetCategoryService: AssetCategoryService, private CustomMessage: CustomMessageService, private assetService: AssetService,
    public dialog: MatDialog, private chatDialogService: ChatDialogService, private formBuilder: FormBuilder,
    private blobStorageService: BlobStorageService, private sASTokenService: SASTokenService,
    private customDateService:CustomDateService,
    private deviceDetect:DeviceDetect) {
    this.route.paramMap.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().trigger === "popstate") {
        let url = this.router.url;
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() => {
          this.router.navigate([url])
        })
      }
    });
  }

  ngOnInit() {
    if(history.state){
      this.searchText = history.state.searchText ? history.state.searchText : ''
      this.redirectUrl = history.state.redirectUrl ? history.state.redirectUrl : ''
    }
    this.valueStatus = 1;
    this.getCurrentDevice();
    this.progressStatus = [{ id: 1, name: 'Pending' },
    { id: 2, name: 'Uploading' },
    { id: 3, name: 'Processing' },
    { id: 4, name: 'Completed' }
    ];
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.categoryAssetList;
    this.assestFormControls();
    this.RouterLinksEnum = RouterLinksEnum;
    this.PermissionsEnum = PermissionsEnum;
    let organizationData = [];

    this.setDefaultNoItemsFound();

    let jsonOrgData = localStorage.getItem(ORGANIZATION_INFO);
    if (jsonOrgData) {
      jsonOrgData = decodeURIComponent(jsonOrgData);
      organizationData.push(JSON.parse(jsonOrgData));
      this.orgId = organizationData[0].id;
    }
    if (this.hideTopPart) {
      this.displayedColumns = ['icon', 'category', 'updatedOn', 'updatedBy'];
    }
    this.options = [];
    this.route
      .params
      .subscribe(v => {
        this.categoryId = v['id'];
        if (this.searchText) {
          if (this.categoryId !== null && this.categoryId !== undefined) {
            this.isViewChildCategory = true;
          }
          this.getSearchResults(this.searchText);
        }
        if (this.categoryId !== null && this.categoryId !== undefined && !this.searchText) {
          this.isViewChildCategory = true;
          this.showfullHierarchicalName = false;
          this.searchText = null;
          this.getCategoryById();
        }
        else {
          if (!this.searchText) {
            this.isViewChildCategory = false;
            this.loadBaseCategories();
          }
        }
      });
     
  }

  loadBaseCategories() {
    this.setDefaultNoItemsFound();
    this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&pageSize=-1")
      .subscribe(res => {
        if (res.data.data.length > 0) {
          this.hideTable = false;
          this.dataSource.data = res.data.data as CategoryModel[];
          this.noDataFound = false;
          this.displayTotal = this.getDisplayTotal();
          this.response = res.data.data;
          this.newData = res.data.data;
          this.token = res.data.paging.continuationToken;
          if (this.token !== null) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }
        }
        else {
          this.hideAssetHeader = false;
          this.hideTable = true;
          this.noDataFound = true;
          this.displayTotal = this.getDisplayTotal();
        }
      });
  }
  viewProduct(id: string) {
    this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH + id]);
  }


  goToSearch() {
    let url = this.router.url;
    if (!this.isViewChildCategory) {
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([url], { state: { searchText: this.searchText } }))
    }
    else {
      let url = this.router.url;
      let routeId = this.route.snapshot.params['id'];
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([url], { state: { url: url, pId: routeId, searchText: this.searchText } }))
    }
  }

  redirectToAsset() {
    let url = this.router.url;
    let routeId = this.route.snapshot.params['id'];
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.CATEGORY_ASSET_CREATE], { state: { assetCatId: routeId, pName: this.parentCategoryName, redirectUrl: url } })
  }
  getSearchResults(searchText: string) {
    this.hideButton = true;
    this.setSearchNotFoundVariables();
    if(this.searchText===""||this.searchText===undefined){
      this.setDefaultNoItemsFound();
    }
    if (searchText !== "" && searchText !== undefined && searchText !== null) {
      this.showfullHierarchicalName = true;
      this.dataSource.data = [];
      this.noDataFound = false;
      this.hideTable = false;
      this._assetCategoryService.getAllCategories("organizationId=" + this.orgId + "&searchText=" + searchText)
        .subscribe(res => {
          if (res.data.data.length > 0) {
            this.hideTable = false;
            this.dataSource.data = res.data.data as CategoryModel[];
            this.noDataFound = false;
            this.displayTotal = this.getDisplayTotal();
            this.token = res.data.paging.continuationToken;
            if (this.token !== null) {
              this.hideButton = false;
            }
            else {
              this.hideButton = true;
            }
          }
          else {
            this.hideButton = true;
            this.hideTable = true;
            this.hideAssetHeader = false;
            this.noDataFound = true;
            this.displayTotal = this.getDisplayTotal();
          }
        });
    }
  }

  routeToEditCategory(id, name, pId) {
    let url = this.router.url;
    let pname = this.parentCategoryName;
    const dialogRef = this.dialog.open(AddFolderDialogComponent,
      { data: { pid: pId, folderId: id, folderName: name, pname: pname, action: 'update', url: url } }
    );

  }

  openAddAssetDialogbox(element) {

    let url = this.router.url;
    const dialogRef = this.dialog.open(AddAssetDialogComponent, {
      data: { action: 'moveFolder', element: element }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "success") {
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() => {
          if (url) {
            this.router.navigate([url])
          }
        })
      }
    })
  }

  routeToEditTopCategory() {
    let url = this.router.url;
    let paramId = this.route.snapshot.params['id'];
    let pname = this.parentCategoryName;
    const dialogRef = this.dialog.open(AddFolderDialogComponent,
      { data: { pid: this.topCardParentId, folderId: paramId, folderName: this.childCategoryName, pname: pname, action: 'update', url: url } }
    );

  }

  viewChildCategory(id) {
    let url = this.router.url;
    let routeId = this.route.snapshot.params['id'];
    this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET_SLASH + id + RouterLinksEnum.SUB_CATEGORY_ASSET_SLASH], { state: { url: url, pId: routeId } }))
  }

  viewItems(id: number) {
    this.isViewItems = true;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  removeSelectedItem(i, id, type?: string) {
    this.categoryId = this.route.snapshot.params['id'];
    let deleteCategory: CategoryModel = {
      id: id,
      _Paxcom_OrganizationId: this.orgId.toString()
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteCategory,
    };
    this._assetCategoryService.deleteCategory(options).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);
        this.dataRefresh = this.dataSource.data;
        this.dataSource.data.splice(i, 1);
        this.dataSource.data = this.dataRefresh;
        this.noDataFound = false;
        this.displayTotal = this.getDisplayTotal();
        this.hideTable = false;
        if (this.dataSource.data.length === 0) {
          this.hideTable = true;
          this.noDataFound = true;
        }
        if (type === "main" && this.redirectUrl !== null) {
          this.router.navigate([this.redirectUrl]);
        }

        if (type === "main" && this.redirectUrl === null) {
          this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET_LIST]);
        }
      }
    });
  }

  deleteItem(i: any, id: number, name: string, type?: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok', name: name }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: id, name: name }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        if (type === "main") {
          this.removeSelectedItem(i, id, type);
        }
        else {
          this.removeSelectedItem(i, id);
        }
      }
    });
  }

  goToCreateNew() {
    let id = this.route.snapshot.params['id'];
    let pname = this.parentCategoryName;
    let url = this.router.url;
    const dialogRef = this.dialog.open(AddFolderDialogComponent,
      { data: { pid: id, pname: pname, action: 'add', url: url } }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
          this.router.navigate([url]))
      }
    })
  }

  onToggle(id, tStatus, categoryType, event) {
    let toggleElement = event;
    if (tStatus === false) {
      this.productSearchService.getData("?organizationId=" + this.orgId + "&CategoryId=" + id)
        .subscribe(res => {
          if (res) {
            if (res.data.data.length > 0) {
              let message = "There are products created under this category. Do you still want to deactivate this category?";
              const dialogRef = this.dialog.open(WarningDialogComponent, {
                data: { id: id, name: name, message: message }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result === 1) {
                  this.changeToggleStatus(id, tStatus, categoryType, event);
                }
                else {
                  toggleElement.source.checked = !toggleElement.source.checked;
                  return false;
                }
              });

            }
            else {
              this.changeToggleStatus(id, tStatus, categoryType, event);
            }
          }
        })
    }
    else {
      this.changeToggleStatus(id, tStatus, categoryType, event);
    }
  }

  changeToggleStatus(id, tStatus, categoryType, event) {
    if (categoryType === 1) {
      if (tStatus === true) {
        this.activeParent = true;
        this.updateSelectedItem(id, this.activeParent, 1, event);
      }
      else {
        this.activeParent = false;
        this.updateSelectedItem(id, this.activeParent, 1, event);
      }

    }
    else if (categoryType === 2) {
      if (tStatus === true) {
        this.active = true;
        this.updateSelectedItem(id, this.active, 2, event);
      }
      else {
        this.active = false;
        this.updateSelectedItem(id, this.active, 2, event);
      }

    }
  }

  updateSelectedItem(id, eStatus, type, event) {
    let updateCategoryStatus: CategoryModel = {
      id: id,
      active: eStatus,
      _Paxcom_OrganizationId: this.orgId.toString()
    };
    this._assetCategoryService.updateStatus(updateCategoryStatus).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);
        if (type === 1 && this.isViewChildCategory === true) {
          this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&parentCategoryId=" + this.categoryId)
            .subscribe(res => {
              if (res.data.data.length > 0) {
                this.hideTable = false;
                this.dataSource.data = res.data.data as CategoryModel[];
                this.displayTotal = this.getDisplayTotal();
                this.noDataFound = false;
                this.childData = res.data.data;
                this.token = res.data.paging.continuationToken;
                if (this.token !== null) {
                  this.hideButton = false;
                }
                else {
                  this.hideButton = true;
                }
              }
              else {
                this.hideTable = true;
                this.hideAssetHeader = false;
                this.displayTotal = this.getDisplayTotal();
                this.noDataFound = true;
              }
            });
        }
      }
    }, err => { event.source.checked = !event.source.checked });
  }

  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {
    if (token !== null || token !== undefined) {
      if (this.isViewChildCategory === false) {
        this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token))
          .subscribe(res => {
            if (res.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                this.newData.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token === null) {
                this.hideButton = true;
              }
              this.dataSource.data = this.newData;
              this.displayTotal = this.getDisplayTotal();
            }
          });
      }

      else {
        this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&parentCategoryId=" + this.categoryId + "&continuationToken=" + encodeURIComponent(token))
          .subscribe(res => {
            if (res.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                this.childData.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token === null) {
                this.hideButton = true;
              }
              this.dataSource.data = this.childData;
              this.displayTotal = this.getDisplayTotal();
            }
          });
      }
    }
  }
  openHistory(id, name) {
    this.historyDialogService.openHistoryDialog(PIMEntityType.AssetCategory, id, name);
  }


  goBack() {
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH  + RouterLinksEnum.CATEGORY_ASSET_LIST])
  }
  openChat(id, name) {
    this.chatDialogService.openChatDialog(PIMEntityType.AssetCategory, id, name);
  }
  uploadFile(file: File): Observable<any[]> {
    let sasInfo = {
      blobName: this.blobName,
      containerName: this.containerName,
      fileUrl: this.fileUrl,
      serviceUrlWithSAS: this.serviceUrlWithSAS
    }
    let requestArray = [];
    requestArray.push(this.blobStorageService.uploadBlobToStorage(file, sasInfo));
    return forkJoin(requestArray)
  }
  filesDropped(files: FileHandle[]): void {
    this.onDragOver = true;
    if (this.categoryId && files.length) {
      for (let j = 0; j < files.length; j++) {
        this.files.push(files[j])
      }
      this.showProgress = true;
      this.generateSASToken(files).subscribe(res => {
        this.uploadToBlob(res).subscribe(blobRes => {
          this.generateThumbnail(blobRes).subscribe(thumbnailRes => {
            for (let i = 0; i < thumbnailRes.length; i++) {
              this.UploadStarted = false;
              this.setAssetFormControlsValues(thumbnailRes[i],this.files[i])
              let formValue = this.assetForm.value;
              this.createAsset(formValue);
            }
          })
        })
      },err =>{
        this.removeNotAuthExtensions(this.files)
        if(this.files.length == 0)
        {
          this.showProgress = false;
        }
        else
        {
          let pendingFiles = [];
          let files = this.files
          let abc = JSON.parse(JSON.stringify(this.files))
          let fileLength = files.length;
          for(let j =0;j<fileLength;j++)
          {
            if(files[j].updateStatus == 1)
            {
              pendingFiles.push(files[j])
            }
          }
          for(let k=0;k<this.files.length;k++)
          {
            if(this.files[k].updateStatus == 1)
            {
              this.files.splice(k,1);
              k = -1;
            }
          }
          if(pendingFiles.length)
          {
            this.filesDropped(pendingFiles);
          }
        }
        
      });
    }
  }
  setAssetFormControlsValues(data,files)
  {
    this.imgURL = data.data['mediumThumbnailUrl'];
    this.assetForm.get('fileDetails.extension').setValue(data.data['extension'])
    let fileName = files.file.name.substring(0,files.file.name.lastIndexOf('.'))
    this.assetForm.get('fileDetails.fileName').setValue(fileName)
    this.assetForm.get('fileDetails.fileSizeInBytes').setValue(data.data['fileSizeInBytes'])
    this.assetForm.get('fileDetails.fileType').setValue(data.data['fileType'])
    this.assetForm.get('fileDetails.fileUrl').setValue(data.data['fileUrl'])
    this.assetForm.get('fileDetails.largeThumbnailUrl').setValue(data.data['largeThumbnailUrl'])
    this.assetForm.get('fileDetails.mediumThumbnailUrl').setValue(data.data['mediumThumbnailUrl'])
    this.assetForm.get('fileDetails.smallThumbnailUrl').setValue(data.data['smallThumbnailUrl'])
    this.assetForm.get('assetCategoryId').setValue(this.categoryId)
  }
  assestFormControls() {
    this.assetForm = this.formBuilder.group({
      id: [''],
      fileDetails: this.formBuilder.group({
        fileName: [''],
        fileType: [''],
        fileUrl: [''],
        extension: [''],
        fileSizeInBytes: [''],
        smallThumbnailUrl: [''],
        mediumThumbnailUrl: [''],
        largeThumbnailUrl: [''],
      }),
      title: [''],
      description: [''],
      tags: this.formBuilder.array([]),
      status: [1],
      type: [1],
      brandId: [''],
      brandName: [''],
      assetCategoryId: [''],
      assetCategoryName: [''],
      createdByEmail: [''],
      createdByName: [''],
      updatedByEmail: [''],
      updatedByName: [''],
      createdOn: [''],
      updatedOn: [''],
    })
  }
  uploadToBlob(res): Observable<any[]> {
    let requestArray = [];
    for (let i = 0; i < this.files.length; i++) {
      for (let j = 0; j < res.length; j++) {
        if (this.files[i].file.name == res[j].data.fileName+"."+res[j].data.extension) {
          if(this.files[i].updateStatus == 1)
          {
          this.files[i].updateStatus = 2
          this.files[i].fileUrl = res[j].data.fileUrl;
          this.files[i].fileUrlWithToken =  res[j].data.fileUrl + res[j].data.sasToken
          let sasInfo = {
            blobName: res[j].data.blobName,
            containerName: res[j].data.containerName,
            fileUrl: res[j].data.fileUrl,
            serviceUrlWithSAS: res[j].data.serviceUrlWithSAS
          }
          requestArray.push(this.blobStorageService.uploadBlobToStorage(this.files[i].file, sasInfo));
        }
        }
      }
    }
    return forkJoin(requestArray)
  }
  generateThumbnail(blobRes): Observable<any[]> {
    let requestArray = [];
    for (let i = 0; i < this.files.length; i++) {
      for (let j = 0; j < blobRes.length; j++) {
        if (unescape(this.files[i].fileUrlWithToken) == unescape(blobRes[j]._response.request.url)) {
          if (this.files[i].updateStatus != 4) {
            this.files[i].updateStatus = 3;
          }
          if (this.files[i].updateStatus != 4 && this.files[i].updateStatus == 3) {
            let requestParameter = {
              fileUrl: this.files[i].fileUrl
            }
            requestArray.push(this.sASTokenService.getAssetThumbnail(requestParameter))
          }
        }
      }
    }
    return forkJoin(requestArray);
  }
  generateSASToken(files): Observable<any[]> {
    let requestArray = [];
    for (let i = 0; i < files.length; i++) {
      let obj = {
        fileNameWithExtension : files[i].file.name
      }
      requestArray.push(this.sASTokenService.getSASToken(obj))
    }
    return forkJoin(requestArray);
  }
  createAsset(formValue) {
    this.assetService.createAsset(formValue).subscribe(res => {
      if (res) {
        this.assetInfo.push(res.data);
        for(let i=0;i<this.files.length;i++)
        {
          for(let j=0;j<this.assetInfo.length;j++)
          {
            if(unescape(this.files[i].fileUrl) == unescape(this.assetInfo[j].fileDetails.fileUrl))
            {
              this.files[i].updateStatus = 4
            }
          }
        }
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        this.childComponent.assetComponent.getAssetById(this.categoryId);
      }
    })
  }
  closeProgress() {
    this.showProgress = false;
    if (this.files.length) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].updateStatus == 4) {
          if (i == this.files.length - 1) {
            this.files = []
            this.thumbnailInfoArray = [];
            this.finalResponceArray = [];
            this.fileUrlArray = [];
            this.fileInfo = [];
            this.assetInfo = [];
          }
          continue

        }
        else {
          break;
        }
      }
    }

  }
  getWrapedText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    else {
      return text.substring(0, maxLength - 3) + "...";
    }
  }
  onDrag() {
    this.onDragOver = false;
  }
  onDragLeave() {
    this.onDragOver = true;
  }
  getCategoryById() {
    this._assetCategoryService.getCategoriesById(this.categoryId + "?organizationId=" + this.orgId + "&pageSize=-1")
      .subscribe(res => {
        if (res.data !== null) {
          this.parentNodes = res.data.parentNodes;
          this.parentCategoryName = res.data.fullHierarchicalName;
          this.topCardParentId = res.data.parentCategoryId;
          this.parentDescription = res.data.description;
          this.childCategoryName = res.data.name;
          this.parentCategory = res.data.parentCategoryName;
          this.element = res.data;
          this.activeParent = res.data.active;
          this.childCount = res.data.childCount;
          this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&parentCategoryId=" + this.categoryId + "&pageSize=-1")
            .subscribe(res => {
              if (res.data.data.length > 0) {
                this.hideTable = false;
                this.dataSource.data = res.data.data as CategoryModel[];
                this.displayTotal = this.getDisplayTotal();
                this.noDataFound = false;
                this.childData = res.data.data;
                this.token = res.data.paging.continuationToken;
                if (this.token !== null) {
                  this.hideButton = false;
                }
                else {
                  this.hideButton = true;
                }
              }
              else {
                this.hideButton = true;
                this.hideTable = true;
                this.hideAssetHeader = false;
                this.noDataFound = true;
                this.displayTotal = this.getDisplayTotal();
              }
            });
        }
        else {
          this.hideButton = true;
          this.hideTable = true;
          this.router.navigate([RouterLinksEnum.ADMIN_SLASH  + RouterLinksEnum.CATEGORY_ASSET_LIST]);
        }
      });
  }
  deleteMultipleAssets() {
    this.childComponent.assetComponent.multipleDeleteAsset();
  }
  getAssetLength(event) {
    this.assetListLength = event;
  }
  updateMultipleAssets() {
    this.childComponent.assetComponent.updateMultipleAssets();
  }
  customDate(date)
  {
    return this.customDateService.customDateFormat(date);
  }
  redirectToParent(id)
  {
    this.topCardParentId = id;
    if (this.topCardParentId !== "" && this.topCardParentId !== null && this.topCardParentId !== undefined) {
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_ASSET_SLASH + this.topCardParentId + RouterLinksEnum.SUB_CATEGORY_ASSET_SLASH])
      )
    }
  }
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  downLoadSelection()
  {
    let assetsIds = [];
    let categoryIds = [];
    let assetSelection = this.childComponent.assetComponent.selectedAssetsList();
    if(this.selection.selected.length == 0 && assetSelection.length == 0)
    {
      this.CustomMessage.ShowCustomMessage("Please select atleast one record",false)
      return
    }
    if(this.selection.selected.length)
    {
      for(let i=0;i<this.selection.selected.length;i++)
      {
        categoryIds.push(this.selection.selected[i].id)
      }
    }
    if(assetSelection.length)
    {
      for(let j=0;j<assetSelection.length;j++)
      {
        assetsIds.push(assetSelection[j])
      }
    }
    this.downLoadAsset(assetsIds,categoryIds)
  }
  removeNotAuthExtensions(files)
  {
    if(files.length)
    {
      let length = files.length;
      for(let i=0;i<length;i++)
      {
        let fileName = files[i].file.name
        let extension  = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
        // png,  jpeg,  bmp,  tiff,  xls,  xlsx,  xlsm,  xlsxm,  csv,  tsv,  .zip,  .7z,  rar,  txt, pdf, doc & doc
        // .mp4, .avi, .wmv, .mpeg and .flv
        if(extension == 'png' || extension == 'jpeg' || extension == 'tiff' || extension == 'xls' || extension == 'xlsx' ||
        extension == 'xlsm' || extension == 'csv' || extension == 'zip' || extension == '7z' || extension == 'rar' ||
        extension == 'txt' || extension == 'pdf' || extension == 'doc' || extension == 'mp4' || extension == 'avi' ||
        extension == 'wmv' || extension == 'mpeg' || extension == 'flv' || extension == 'jpg' )
        {
          continue;
        }
        else
        {
          this.files.splice(i,1);
          length = this.files.length;
          i=-1;
        }
      }
    }
  }
  downLoadAsset(assetIds,categoryIds)
  {
    let requestParameter = ""
    if(assetIds.length)
    {
      for(let i=0;i<assetIds.length;i++)
      {
        requestParameter += "&assetIds="+assetIds[i] 
      }
    }
    if(categoryIds.length)
    {
      for(let i=0;i<categoryIds.length;i++)
      {
        requestParameter += "&assetCategoryIds="+categoryIds[i] 
      }
    }
    requestParameter = "?"+requestParameter.substring(1)
    this.assetService.downloadAssets(requestParameter).subscribe(res => {
      this.CustomMessage.ShowCustomMessage(res.statusMessage,true);
      this.selection.clear();
      this.childComponent.assetComponent.selectionClear();
    })
  }

  getDisplayTotal() {

    return this.dataSource.data.length;
  }

  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle="Assets";
    this.noItemsFoundDesc=messages.noResultFoundDesc;
  }
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle="Assets";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }

}

