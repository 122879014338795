import { Injectable } from '@angular/core';
import { HttpHelperService } from '../Common/http-helper.service';
import { Observable } from 'rxjs';
import {CreateUserModel} from '../../models/CreateUserModel';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
     //--------------------------Refactored Urls - 6/22/2021 ------------------------------------
  _url = "users";
  // _url = "Product/user";
  //_url = "Authorization/user";
  constructor(private httpHelper: HttpHelperService) { }

  createUser(user: CreateUserModel) {
    // user.id=0;
    // user.profileImageUri="";
    // user.statusId=2;
    // user.statusName="";
    // user.organizationId=0;
    // //user.lastLogin="2019-08-13T08:18:50.517Z";
    return this.httpHelper.postData(user, this._url);
  }
  getUsersList(queryString: string): Observable<any> {
     //--------------------------Refactored Urls - 6/22/2021 ------------------------------------
    let url = 'users?searchText=';
    return this.httpHelper.getData(url + queryString);
  }
}
