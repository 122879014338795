import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  themeComponentName:string ='';
  categoriesThemeSubscription: Subscription;
  constructor(private commonService:CommonService) {
    this.categoriesThemeSubscription = this.commonService.getComponentData.subscribe(res=>{
      this.themeComponentName = res['categoryList'];
    })
  }

ngOnInit(): void {
  
}

ngOnDestroy(){
  this.categoriesThemeSubscription.unsubscribe();
}
}
