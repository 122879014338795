import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-create-new-category',
  templateUrl: './create-new-category.component.html',
  styleUrls: ['./create-new-category.component.scss']
})
export class CreateNewCategoryComponent implements OnInit {
  themeSubscription: Subscription;
  themeComponentName: string;
  constructor(private commonService: CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res => {
      this.themeComponentName = res['createCategory'];
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }
}