import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DateService {
 
  constructor() { }

  convertDateFormat(mode, dateInput)
  {    
	let timeZoneOffsetMinutes = (new Date()).getTimezoneOffset()*60;

	let dateObj = new Date(dateInput);
	let yy = dateObj.getFullYear();
	let mm = dateObj.getMonth()+1;
	let dd = dateObj.getDate();
	var dateFormat = yy+'-'+mm+'-'+dd; 	
	
	if(mode==='startDate'){
		let sDate = dateFormat+' 00:00:00';
		let isoDate = new Date(sDate).toISOString();
		return isoDate;
		//return new Date(new Date(dateInput).setSeconds(0 - timeZoneOffsetMinutes)).toISOString();
	}
	else if(mode==='endDate'){
		let sDate = dateFormat+' 23:59:59';
		let isoDate = new Date(sDate).toISOString();
		return isoDate;		
		//return new Date(new Date(dateInput).setSeconds(86399 - timeZoneOffsetMinutes)).toISOString();
	}		
	
  }
}
