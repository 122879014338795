import { MdcSnackbar } from '@angular-mdc/web';
import { Injectable } from '@angular/core';
import { DeleteDialogComponent } from 'src/app/shared/dialogs/delete/delete.dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { WarningDialogComponent } from 'src/app/shared/dialogs/warning/warning.dialog.component';
import { ConfirmationDialogComponent } from '../shared/dialogs/confirmation/confirmation.dialog.component';

@Injectable({
    providedIn: 'root'
})
export class CustomMessageService {
    constructor(
        private snackbar: MatSnackBar, public dialog: MatDialog
    ) {

    }
    ShowCustomMessage(message, success,isError=false) {
        if (!message) {
            return;
        }
        if (success === true && isError===false) {
            const snackbarRef = this.snackbar.open(message, 'X', {
                // leading: true,
                // dismiss: true,
                // classes: 'custom-snackbar--label-ink-color',
                // timeoutMs: 5000 // 5 sec
                horizontalPosition:'left',
                verticalPosition:'bottom',
                duration:5000
            });
            // snackbarRef.afterDismiss().subscribe(reason => {
            //     //console.log(reason);
            // });
        }
        else{
            isError=true;
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: { name: message,isError:isError}
            });
        }

    }

    showValidationMessage(message,isValidation=true){
        const dialogRef = this.dialog.open(WarningDialogComponent, {
            data: { name: message,isValidation:isValidation}
        });
    }
}
