import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelperService } from '../Common/http-helper.service';


@Injectable({
  providedIn: 'root'
})
export class SettingService {
  
  constructor(private httpHelper: HttpHelperService) { }

  getOrganizationSettings(): Observable<any> {
    let url = 'Settings/organization';
    return this.httpHelper.getData(url);
  }

  updateOrganizationSettings(data): Observable<any> {
    let url = 'Settings/organization';
    return this.httpHelper.putData(data,url);
  }
}
