import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { messages } from 'src/app/shared/Constants/messageResource';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { environment } from 'src/environments/environment';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { CloneMatserCategoriesDialogComponent } from '../../../dialogs/clone-master-categories/clone-master-categories.dialog.component';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from '../../../dialogs/warning/warning.dialog.component';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { CategoryModel } from '../../../models/Category/category-model.model';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { CategoriesServiceService } from '../../../services/categories-service.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ProductListService } from '../../../services/Product/product-list.service';
import { SearchPreserveService } from '../../../services/search-preserve.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CommonService } from 'src/app/core/Common/common.service';

@Component({
  selector: 'app-category-list-default',
  templateUrl: './category-list-default.component.html',
  styleUrls: ['./category-list-default.component.scss']
})
export class CategoryListDefaultComponent implements OnInit {
  isViewChildCategory: boolean = false;
  isViewItems: boolean = false;
  orgId: number;
  //organizationData: any[];
  jsonOrgData: string;
  myControl = new FormControl();
  options: Array<string>;
  response: Array<CategoryModel>;
  filteredOptions: Observable<string[]>;
  active: boolean;

  //displayedColumns = ['category', 'actions'];
  displayedColumns = ['category','status','type','actions'];

  public dataSource = new MatTableDataSource<CategoryModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  tableSourceData: any;
  parentCategoryName: any;
  parentDescription: any;
  categoryId: string;
  token: any;
  newData: Array<CategoryModel> = [];
  noMoreDataMessage: string = "";
  hideButton: boolean = true;
  searchText: string;
  showfullHierarchicalName: boolean = false;
  childData: any;
  childCount: any;
  dataRefresh: CategoryModel[];
  RouterLinksEnum: any;
  PermissionsEnum: typeof PermissionsEnum;
  activeParent: boolean;
  activeChild: boolean;
  buttonName: string;
  hideTable: boolean = true;
  routeId: any;
  titlename: string;
  titleEnum: typeof titlename;
  redirectUrl: any;
  topCardParentId: any;
  featureIds: typeof HelpFeatureIdsEnum;
  isBaseOrganization: boolean = true;
  baseOrganizationId: any;
  displayTotal: number = 0;
  displayNoItemsTemplate: boolean = false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  parentNodes: any = [];
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor(private searchService: SearchPreserveService, private router: Router, private historyDialogService: HistoryDialogService, private chatDialogService: ChatDialogService,
    private productSearchService: ProductListService, private route: ActivatedRoute, public httpClient: HttpClient, private _categoryService: CategoriesServiceService,
    private CustomMessage: CustomMessageService, public dialog: MatDialog, private helpGuideService: HelpGuideService,private commonServices:CommonService,
    private deviceDetect : DeviceDetect) {
    this.route.paramMap.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().trigger === "popstate") {
        let url = this.router.url;
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() => {
          this.router.navigate([url])
        }) // this.dontCallNgOnIt = true;  
      }
      const state = history.state;
      if (state) {
        this.redirectUrl = state.url;
        this.searchText = state.searchText;
        this.parentCategoryName = state.pName;
        this.parentDescription = state.parentDescription;
        this.activeParent = state.activeParent;

      }
      this.setDefaultNoItemsFound();

    });
  }

  ngOnInit() {
    this.getCurrentDevice()
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.categoryList;
    this.RouterLinksEnum = RouterLinksEnum;
    this.PermissionsEnum = PermissionsEnum;
    let organizationData = [];
    let jsonOrgData = localStorage.getItem(ORGANIZATION_INFO);
    if (jsonOrgData) {
      jsonOrgData = decodeURIComponent(jsonOrgData);
      organizationData.push(JSON.parse(jsonOrgData));
      this.orgId = organizationData[0].id;
    }
    this.baseOrganizationId = environment.baseOrganizationId
    if (this.orgId == this.baseOrganizationId) {
      this.isBaseOrganization = false;
    }
    this.options = [];
    this.route
      .params
      .subscribe(v => {
        this.categoryId = v['id'];
        if (this.searchText) {
          if (this.categoryId !== null && this.categoryId !== undefined) {
            this.isViewChildCategory = true;
          }
          this.getSearchResults(this.searchText);
        }
        this.getCategories();

      });
      this.setDefaultNoItemsFound();
    // let search = document.getElementById("search");
    // search.addEventListener("keydown", function (e) {
    //   if (e.keyCode === 13) {
    //     event.preventDefault();
    //     document.getElementById("searchBtn").click();
    //   }
    // });
  }

  getCategories() {
    if (this.categoryId !== null && this.categoryId !== undefined && !this.searchText) {
      this.isViewChildCategory = true;
      this.showfullHierarchicalName = false;
      this.searchText = null;
      this._categoryService.getCategoriesById(this.categoryId + "?organizationId=" + this.orgId)
        .subscribe(res => {
          if (res.data !== null) {
            this.parentCategoryName = res.data.fullHierarchicalName;
            this.parentNodes = res.data.parentNodes;
            this.topCardParentId = res.data.parentCategoryId;
            this.parentDescription = res.data.description;
            this.activeParent = res.data.active;
            this.childCount = res.data.childCount;
            let requestParameter = this.getRequestParameters()
            this._categoryService.getChildCategories(requestParameter)
              .subscribe(res => {
                if (res.data.data.length > 0) {
                  this.hideTable = false;
                  this.dataSource.data = res.data.data as CategoryModel[];
                  this.displayTotal = this.getDisplayTotal();
                  this.childData = res.data.data;
                  this.token = res.data.paging.continuationToken;
                  if (this.token !== null) {
                    this.hideButton = false;
                  }
                  else {
                    this.hideButton = true;
                  }
                }
                else {
                  this.hideButton = true;
                  this.hideTable = true;
                  this.CustomMessage.ShowCustomMessage("No Record Found.", true);
                  this.displayNoItemsTemplate = true;

                }
              });
          }
          else {
            this.hideButton = true;
            this.hideTable = true;
            this.CustomMessage.ShowCustomMessage("No record found", true);
            this.displayNoItemsTemplate = true;
            this.router.navigate([RouterLinksEnum.ADMIN+ '/' + RouterLinksEnum.CATEGORY_LIST]);
          }
        });
    }
    else {
      if (!this.searchText) {
        this.isViewChildCategory = false;
        this.loadBaseCategories();
      }
    }
  }

  loadBaseCategories() {
    this._categoryService.getChildCategories("organizationId=" + this.orgId)
      .subscribe(res => {
        if (res.data.data.length > 0) {
          this.hideTable = false;
          this.dataSource.data = res.data.data as CategoryModel[];
          this.displayTotal = this.getDisplayTotal();
          this.response = res.data.data;
          // this.newData = res.data.data;
          this.token = res.data.paging.continuationToken;
          if (this.token !== null) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }
        }
        else {
          this.hideTable = true;
          this.displayTotal = this.getDisplayTotal();
          this.CustomMessage.ShowCustomMessage("No record found", true);
          this.displayNoItemsTemplate = true;
        }
      });
  }
  viewProduct(category) {
    let searchParameters = {
      productCategory : category.name,
      categoryId : category.id
    }

    this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS_NO_SLASH],  { queryParams: { query: decodeURI(JSON.stringify(searchParameters)) } })
  }

  goToSearch() {
    this.displayNoItemsTemplate = false;
    let url = this.router.url;
    if (!this.isViewChildCategory) {
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([url], { state: { searchText: this.searchText } }))
    }
    else {
      let url = this.router.url;
      let routeId = this.route.snapshot.params['id'];
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([url], {
          state: {
            url: url, pName: this.parentCategoryName,
            pId: routeId, searchText: this.searchText, activeParent: this.activeParent, parentDescription: this.parentDescription
          }
        }))
    }
  }

  getSearchResults(searchText: string) {
    this.setSearchNotFoundVariables();
    if(searchText === "" || searchText === undefined || searchText === null){
      this.setDefaultNoItemsFound();
    }
    this.hideButton = true;
    if (searchText !== "" && searchText !== undefined && searchText !== null) {
      this.showfullHierarchicalName = true;
      this.dataSource.data = [];
      this.hideTable = true;
      let requestParameter = this.getRequestParameters();
      this._categoryService.getAllCategories(requestParameter)
        .subscribe(res => {
          if (res.data.data.length > 0) {
            this.hideTable = false;
            this.dataSource.data = res.data.data as CategoryModel[];
            this.displayTotal = this.getDisplayTotal();
            this.token = res.data.paging.continuationToken;
            if (this.token !== null) {
              this.hideButton = false;
            }
            else {
              // this.CustomMessage.ShowCustomMessage("No record found", true);
              this.hideButton = true;
            }
            //this.searchService.storeSearchData(searchText, this.dataSource.data)
          }
          else {
            this.CustomMessage.ShowCustomMessage("No record found", true);
            this.displayNoItemsTemplate = true;
            this.hideButton = true;
            this.hideTable = true;
            this.hideButton = true;
            this.displayTotal = this.getDisplayTotal();
          }
        });
    }
  }

  routeToEditCategory(id) {
    let url = this.router.url;
    let paramId = this.route.snapshot.params['id'];
    if (paramId !== null || paramId !== undefined) {
      this.routeId = paramId;
    }
    else {
      this.routeId = id;
    }
    this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH + id + RouterLinksEnum.ADMIN_EDIT], { state: { pId: this.routeId, url: url } });

  }

  viewChildCategory(id) {
    let url = this.router.url;
    let routeId = this.route.snapshot.params['id'];
    this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() => {
      this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH  + id + RouterLinksEnum.SUB_CATEGORY_SLASH], { state: { url: url, pId: routeId } });
    })
  }

  viewItems(id: number) {
    this.isViewItems = true;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  removeSelectedItem(i, id, type?: string) {
    this.categoryId = this.route.snapshot.params['id'];
    let deleteCategory: CategoryModel = {
      id: id,
      _Paxcom_OrganizationId: this.orgId.toString()
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteCategory,
    };
    this._categoryService.deleteCategory(options).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);
        this.dataRefresh = this.dataSource.data;
        this.dataSource.data.splice(i, 1);
        this.dataSource.data = this.dataRefresh;
        this.displayTotal = this.getDisplayTotal();
        this.hideTable = false;
        // if (type === "main" && this.dataSource.data.length === 0) {
        //   this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST]);
        //   this.hideTable = true;
        // }
        if (this.dataSource.data.length === 0) {
          this.hideTable = true;
          this.displayTotal = this.getDisplayTotal();
        }
        if (type === "main" && this.redirectUrl !== null) {
          this.router.navigate([this.redirectUrl]);
        }

        if (type === "main" && this.redirectUrl === null) {
          this.router.navigate([RouterLinksEnum.ADMIN+ RouterLinksEnum.CATEGORY_LIST_SLASH]);
        }
      }
    });
  }

  deleteItem(i: any, id: number, name: string, type?: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: { id: id, name: name }
    // });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        if (type === "main") {
          this.removeSelectedItem(i, id, type);
        }
        else {
          this.removeSelectedItem(i, id);
        }
      }
    });
  }

  goBack() {
    if (this.topCardParentId !== "" && this.topCardParentId !== null && this.topCardParentId !== undefined) {
      this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>

        this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH + this.topCardParentId+ RouterLinksEnum.SUB_CATEGORY_SLASH])
      )
    }
    else {
      this.router.navigate([RouterLinksEnum.ADMIN  + RouterLinksEnum.CATEGORY_LIST_SLASH])
    }
  }

  goToCreateNew() {
    this.buttonName = document.activeElement.getAttribute("name");
    if (this.buttonName === "category") {
      this.router.navigate([RouterLinksEnum.ADMIN + '/' + RouterLinksEnum.CATEGORY_CREATE]);
    }
    else if (this.buttonName === "subcategory") {
      let id = this.route.snapshot.params['id'];
      this.router.navigate([RouterLinksEnum.ADMIN + '/' + RouterLinksEnum.CATEGORY_CREATE], { state: { pName: this.parentCategoryName, pId: id } });
    }

  }

  onToggle(id, tStatus, categoryType, event,index) {
    
    // let toggleElement = event;
    if (tStatus === false) {
      this.productSearchService.getData("?organizationId=" + this.orgId + "&CategoryId=" + id)
        .subscribe(res => {
          if (res) {
            if (res.data.data.length > 0) {
              let message = "There are products created under this category. Do you still want to deactivate this category?";
              const dialogRef = this.dialog.open(WarningDialogComponent, {
                data: { id: id, name: name, message: message }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result === 1) {
                  this.changeToggleStatus(id, tStatus, categoryType, event,index);
                }
                else {
                  // toggleElement.source.checked = !toggleElement.source.checked;
                  return false;
                }
              });

            }
            else {
              this.changeToggleStatus(id, tStatus, categoryType, event,index);
            }
          }
        })
    }
    else {
      this.changeToggleStatus(id, tStatus, categoryType, event,index);
    }
  }

  changeToggleStatus(id, tStatus, categoryType, event,index) {
    if (categoryType === 1) {
      if (tStatus === true) {
        this.activeParent = true;
        this.updateSelectedItem(id, this.activeParent, 1, event,index);
      }
      else {
        this.activeParent = false;
        this.updateSelectedItem(id, this.activeParent, 1, event,index);
      }

    }
    else if (categoryType === 2) {
      if (tStatus === true) {
        this.active = true;
        this.updateSelectedItem(id, this.active, 2, event,index);
      }
      else {
        this.active = false;
        this.updateSelectedItem(id, this.active, 2, event,index);
      }

    }
  }
  updateSelectedItem(id, eStatus, type, event,index) {
    //let toggleElement=event;
    let updateCategoryStatus: CategoryModel = {
      id: id,
      active: eStatus,
      _Paxcom_OrganizationId: this.orgId.toString()
    };
    this._categoryService.updateStatus(updateCategoryStatus).subscribe(res => {
      if (res) {
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);
        this.dataSource.data[index].active =  this.dataSource.data[index].active ? false : true;
        if (type === 1 && this.isViewChildCategory === true) {
          let requestParameter = this.getRequestParameters()
          this._categoryService.getChildCategories(requestParameter)
            .subscribe(res => {
              if (res.data.data.length > 0) {
                this.hideTable = false;
                this.dataSource.data = res.data.data as CategoryModel[];
                this.displayTotal = this.getDisplayTotal();
                this.childData = res.data.data;
                this.token = res.data.paging.continuationToken;
                if (this.token !== null) {
                  this.hideButton = false;
                }
                else {
                  this.hideButton = true;
                }
              }
              else {
                this.hideTable = true;
                this.displayTotal = this.getDisplayTotal();
              }
            });
        }
      }
    }, err => { 
      // event.source.checked = !event.source.checked
     });
  }

  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {


    if (token !== null || token !== undefined) {
      let requestParameter = this.getRequestParameters();
      if (this.isViewChildCategory === false) {
        requestParameter += token ? "&continuationToken=" + encodeURIComponent(token) : ''
        this._categoryService.getChildCategories(requestParameter)
          .subscribe(res => {
            if (res.data) {
              this.newData = [];
              this.newData = this.dataSource.data;

              for (let i = 0; i < res.data.data.length; i++) {
                this.dataSource.data.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token === null) {
                this.hideButton = true;
              }
              this.dataSource.data = this.dataSource.data;
              this.displayTotal = this.getDisplayTotal();
            }
          });
      }

      else {
        requestParameter += token ? "&continuationToken=" + encodeURIComponent(token) : ''
        this._categoryService.getChildCategories(requestParameter)
          .subscribe(res => {

            if (res.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                this.dataSource.data.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token === null) {
                this.hideButton = true;
              }
              this.dataSource.data = this.dataSource.data;
              this.displayTotal = this.getDisplayTotal();
            }
          });
      }
    }
  }
  openHistory(id, name) {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Category, id, name);
  }
  openChat(id, name) {
    this.chatDialogService.openChatDialog(PIMEntityType.Category, id, name);
  }
  openHelpDialog(featureId) {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }

  addCategoriesFromCatalogue() {
    const dialogRef = this.dialog.open(CloneMatserCategoriesDialogComponent, {
      data: { entityType: PIMEntityType.Category }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.goToSearch();
      }
    });
  }

  getDisplayTotal() {
    return this.dataSource.data.length;
  }
  getRequestParameters() {
    let requestParameter = "organizationId=" + this.orgId;
    requestParameter += this.searchText ? "&searchText=" + this.searchText : ''
    requestParameter += this.categoryId ? "&parentCategoryId=" + this.categoryId : '';
    return requestParameter ? requestParameter : ''
  }
  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle =  "Categories";
    this.noItemsFoundDesc=messages.noResultFoundDesc;
  }
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle= "Categories";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }
  getWrapedText(text, length){   //shrink the string to length size.
    return this.commonServices.getWrapedText(text, length);
  }
  goToCategoryPage(){
    this.topCardParentId = '';
    this.goBack();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }
}
