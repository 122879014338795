import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';

@Component({
  selector: 'app-barcode-dialog',
  templateUrl: './barcode-dialog.component.html',
  styleUrls: ['./barcode-dialog.component.scss']
})
export class BarcodeDialogComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  showCamerasOptions : boolean = false;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;


  constructor(private dialogRef: MatDialogRef<BarcodeDialogComponent>,
    private deviceDetect:DeviceDetect) { }

  ngOnInit() {
    this.getCurrentDevice()
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  acceptResult(qrResultString: string): void {
    this.dialogRef.close({ data: qrResultString });
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.dialogRef.close({ data: resultString });
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }
  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }

}
