import { Component, HostListener } from '@angular/core';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig, AuthService, AUTH_STORAGE_ITEMS } from './core/Authorization/auth.service';
import { Router } from '@angular/router';
import { CommonService } from './core/Common/common.service';
import { ApplicationModel } from './shared/models/authorization/application-model';
import { environment } from 'src/environments/environment';
import { CurrentApplicationIndexService } from './core/Common/current-application-index.service';
import { CurrentApplication } from './constants/CurrentApplicationEnum';
import { AppConstants } from './constants/global';
import { SessionStorageService } from './core/Common/session-storage.service';
import { AdminService } from './core/Common/admin.service';
import { LoaderService } from './core/loader/loader.service';
import { Title } from '@angular/platform-browser';
import { DeviceDetect } from './core/common_services/device-detect/device-detect.service';
import { fromEvent } from 'rxjs';
import { RouterLinksEnum as WarehouseRouterLinks } from './modules/warehouse/shared/router-links/router-links.enum';
import { RouterLinksEnum  as OrderRouterLinks } from './modules/order/shared/router-links/router-links.enum';
import { RouterLinksEnum as PIMRouterLinks } from './modules/pim/shared/router-links/router-links.enum';
import { RouterLinksEnum as EStoreRouterLinks } from './modules/e-store-admin/shared/router-links/router-links.enum';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  selectedTabIndex = 0;
  title = 'Paxcom.Authorization.Web';
  applications: Array<ApplicationModel> = [];
  showSidebar: boolean = false;
  isLoggedIn: boolean;
  showHeader: boolean = false;
  applicationsToDisplayInHeader = environment.applicationsToDisplayInHeader;
  WMSAppToDisplayInHeader =  environment.WMSAppToDisplayInHeader
  isLoader: boolean = false;
  jsonOrgData: string;
  showRouterOutlet: boolean = false;
  userInfoExists: boolean = false;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  appIsIdle = false;
  private idleAfterSeconds = 3600;
  private countDown;


  constructor(private authService: AuthService, private oauthService: OAuthService, private loaderService: LoaderService, private titleService: Title,
    private _router: Router, private router: Router, private commonService: CommonService,
    private currentIndexService: CurrentApplicationIndexService, private sessionStorageService: SessionStorageService,
    private adminService: AdminService, private deviceDetectService: DeviceDetect) {
    this.startIdleTimer();

    this.configure();

    this.oauthService.setupAutomaticSilentRefresh();

    this.handleOauthEventsResponse();

    loaderService.isLoading.subscribe(value => {
      this.isLoader = value;
    });

    this.currentIndexService.getCurrentIndex().subscribe(res => {
      if (res) {
        this.setAdminTitle()
      }
      this.selectedTabIndex = res.text;
      this.setAppInfo(this.selectedTabIndex);
    });
  }

  ngOnInit() {
    this.checkScreenSize();
    //If origin is omniChannelHostName
    //Don't get organization info, guard is added in shop application which will redirect user to login(Public Component)
    // based on origin.
    if (location.origin == environment.omnichannelHostname) { //on.paxcom.ai
      this.sessionStorageService.removeOrganizationInfo();
      this.showRouterOutlet = true;
      return false;
    }

    let domain = location.origin || location.href
    // getting organization information using domain 
    this.getOrgFromUrl(domain)

    //get all applications only if admin portal is accessed
    if (this.checkIfUrlIncludesAdmin())
      this.getAllApplication();

  }

  setAppInfo(index) {
    if (index == CurrentApplication.Products) {
      this.sessionStorageService.setAppInfoIntoSession('Products')
    }
    if (index == CurrentApplication.Orders) {
      this.sessionStorageService.setAppInfoIntoSession('Orders')
    }
    if (index == CurrentApplication.Warehouse) {
      this.sessionStorageService.setAppInfoIntoSession('Inventory')
    }
    if (index == CurrentApplication.eStoreAdmin) {
      this.sessionStorageService.setAppInfoIntoSession('eStore')
    }
  }

  setAdminTitle() {
    let route = JSON.parse(sessionStorage.getItem('Shop_info')).name
    this.titleService.setTitle(`Admin - ${route}`);
  }

  private configure() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.authService.initializeUser();
  }

  startIdleTimer() {
    fromEvent(document, 'mousemove').subscribe(() => this.interaction())
    fromEvent(document, 'touchstart').subscribe(() => this.interaction())
    fromEvent(document, 'keydown').subscribe(() => this.interaction())
  }

  interaction() {
    //If app is idle
    //Furthur delayed by 10 secs so that oauth internal refresh token implementation 
    //and manual refresh token implementation does not happen at same time.
    if (this.appIsIdle) {
      this.appIsIdle = false;
      setTimeout(() => {
        this.authService.validateAndRefreshTokenIfRequired();
      }, 10000)
    }

    //Clear previous count down time
    clearTimeout(this.countDown);

    //Start countdown again
    this.countDown = setInterval(() => {
      this.appIsIdle = true;
    },
      this.idleAfterSeconds * 1000);
  }

  getAllApplication() {
    this.commonService.getApplication().subscribe(res => {
      this.applications =  res.data ? res.data.filter(application => this.WMSAppToDisplayInHeader.includes(application.id)) : [];
      res.data && this.commonService.sendApplicationsApiRes(res.data);
    })
  }

  getUserInfo() {
    this.sessionStorageService.getloggedinUserinfo().then(res => {
      if (res && res.id) {
        this.userInfoExists = true;
      }
    });
  }

  tabSelect(index) {
    this.selectedTabIndex = index;
    this.setAppInfo(this.selectedTabIndex);
  }
  newTab(index){
    if (index == CurrentApplication.Products) {
      return PIMRouterLinks.ADMIN
    }
    if (index == CurrentApplication.Orders) {
      return OrderRouterLinks.ADMIN
    }
    if (index == CurrentApplication.Warehouse) {
      return `${WarehouseRouterLinks.BASE_ROUTE_SLASH}${WarehouseRouterLinks.ORDER_FULFILLMENT}`
    }
    if (index == CurrentApplication.eStoreAdmin) {
      return EStoreRouterLinks.ADMIN_SETTINGS
    }
  }

  showhideHeader() {
    let canAccessAdmin = this.sessionStorageService.getCanAccessAdminValue();
    return this.checkIfUrlIncludesAdmin() && canAccessAdmin;
  }

  getOrgFromUrl(domain) {
    let requestParameter = "?url=" + encodeURIComponent(domain)
    this.commonService.getOrganizationFromUrl(requestParameter).subscribe(async res => {
      this.getUserInfo();
      if (!res.data) {
        this.router.navigateByUrl("/no-organization-found")
          .then(() => {
            if (!localStorage.getItem('isReloaded')) {
              localStorage.setItem('isReloaded', 'true')
              location.reload()
            } else {
              localStorage.removeItem('isReloaded')
            }
          });
        this.showRouterOutlet = true;
      }
      else {
        let obj = {
          id: res.data.id,
          logoUri: res.data.logoURI,
          name: res.data.name,
          userName: "",
        }
        localStorage.setItem(AppConstants.ORGANIZATION_INFO, JSON.stringify(obj));
        this.getOrgById();
        if(!this.router.url.includes('glapto')){
          this.checkVisitedStore(res.data)
        }
        await this.commonService.canAccessAdmin();
      }
    })
  }

  getOrgById() {
    this.commonService.getOrganizationById().subscribe(res => {
      if (res.data) {
        this.sessionStorageService.setShopOrgInfoIntoSession(res.data);
      }
      this.showRouterOutlet = true;
    })
  }

  checkIfUrlIncludesAdmin() {
    let routerUrl = this.router['location']._platformLocation.location.pathname;
    return this.adminService.isAdminRoute(routerUrl);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetectService.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetectService.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetectService.currentDevice().desktopScreen;
  }

  handleOauthEventsResponse() {
    this.oauthService.events.subscribe(function (e) {
      if (e.type == "session_terminated" || e.type == "session_error" || e.type === 'token_refresh_error') {
        AUTH_STORAGE_ITEMS.map((item: string) => localStorage.removeItem(item))
        location.reload();
      }
    });
  }
 checkVisitedStore(store){
  let favStoreList = []
  let favStores = localStorage.getItem(AppConstants.FAV_STORE)
  if(favStores) { favStoreList = JSON.parse(favStores)}
    let existingStoreIndex = favStoreList.findIndex(fav =>{  return fav.name == store.name}); 
    if((!existingStoreIndex || existingStoreIndex == -1 ) && existingStoreIndex != 0){
      store['favourite'] = false;
      // var d = new Date("Sun Apr 27 2014 16:47:53 GMT+0100");
      // d.setHours(d.getHours() + 5);
      // d.setMinutes(d.getMinutes() + 30);
      store['visitedTime'] = new Date();
      favStoreList.push(store)
    }
    else if(existingStoreIndex > -1){
      favStoreList[existingStoreIndex]['visitedTime'] = new Date(); 
    }
    localStorage.setItem(AppConstants.FAV_STORE,JSON.stringify(favStoreList))
 }
}
