import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppConstants } from 'src/app/constants/global';
import { AuthService } from '../Authorization/auth.service';
import { CommonService } from '../Common/common.service';
@Injectable({
  providedIn: 'root'
})
export class CanAccessAdmin implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router, private commonService: CommonService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let result = await this.commonService.canAccessAdmin();
    if (result) {
      return true;
    } else {
      this.router.navigate(['not-authorized-page']);
      return false;
    }
  }
}
