import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../Authorization/auth.service';
import { RouteGuardsService } from '../route-guards.service';
import { UrlSessionStorageService } from '../url-session-storage.service';
import { SessionStorageService } from '../Common/session-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  router: Router = null;
  url: string;
  constructor(private authService: AuthService, 
              private getUrlSession: UrlSessionStorageService,
              private _router: Router, 
              private sessionStorageService: SessionStorageService,
              private routeGuard: RouteGuardsService) {
    this.router = _router;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    this.url=state.url;
    if (this.routeGuard.checkIfAuthenticated()) {
      return true;
    }
    else {
      const token = this.authService.GetToken();
      this.getUrlSession.storeUrlBeforeRedirect(this.url);
      this.sessionStorageService.removeloggedinUserinfo();

      if (token !== null && token !== undefined && token !== '') {
        this.authService.Logoff();
        return false;
      } else {
        this.authService.Authorize();
        return false;
      }
    }
    
  }
}