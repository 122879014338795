import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../Common/http-helper.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CreateBrand } from '../../models/Brands/create-brand.model';
import { BrandViewModel } from '../../models/Brands/BrandViewModel';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

export class BrandsService {
    private _getBrandsUrl = "Brands";
    private _createBrandUrl = "Brands";
    private _updateBrandStatusUrl = "Brands/status";
  
    constructor(
      private httpHelper: HttpHelperService,
      private httpClient: HttpClient
      ) { }
    /**
     * Get all the List of 
     * Brands, etc associated with it
     */
    getAllBrands(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getBrandsUrl + "?" + queryString);
    }

    getAllActiveBrands(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getBrandsUrl + "?" + queryString).pipe(
        map(res => res.data.data),
        map(results => results.filter(result => result.active))
      );
    }

    searchBrand(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getBrandsUrl + "?" + queryString);
    }

    postBrand(brand: CreateBrand) : Observable<any>{
      return this.httpHelper.postData(brand, this._createBrandUrl);
    }
    
    editBrand(ebrand: CreateBrand) :Observable<any>{
      return this.httpHelper.putData(ebrand, this._createBrandUrl);
    }

    getBrandbyBrandId(queryString = ""): Observable<any> {
      return this.httpHelper.getData(this._getBrandsUrl + "/" + queryString);
    }

    updateBrandStatus(bvm: BrandViewModel) : Observable<any>  {
      return this.httpHelper.putData(bvm, this._updateBrandStatusUrl);
    }
   
    deleteBrand(brandViewwModel): Observable<any> {      
      return this.httpHelper.deleteBody(this._getBrandsUrl,brandViewwModel);
    }
    getCustomersList(querySyring = ""): Observable<any> {
      let url = 'Customer';
      return this.httpHelper.getData(url + querySyring);
    }
    getBrandApprovers(queryString: string): Observable<any> {
      let url = 'Brands/approvers?searchText=';
      return this.httpHelper.getData(url + queryString);
    }
} 