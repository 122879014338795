import { Directive, OnInit, AfterViewInit, OnDestroy, ElementRef, Input, Renderer2, TemplateRef, ViewContainerRef, HostBinding } from '@angular/core';
import { PermissionsAccessService } from "../authorization/services/permissions-access.service";
import { PIM_ACTIVITIES } from '../constants/global';

@Directive({
  selector: '[appCanAccess]',

})

export class CanAccessDirective implements AfterViewInit {

  @Input() appCanAccess: string | string[];
  @HostBinding('disabled') disabled: boolean;
  permissionGranted: boolean = false;
  permissionsData:any;

  constructor(public elRef: ElementRef, public renderer: Renderer2, private _permissionsAccessService: PermissionsAccessService) {

   }

   ngAfterViewInit() {

    setTimeout(() => {

      this._permissionsAccessService.getPermissionsData().subscribe(
        result => {
          
          this.permissionsData = result['data'];

          sessionStorage.setItem(PIM_ACTIVITIES, JSON.stringify(result['data']));
         
           //====================================
           if (this.appCanAccess) {

            var permissonList = this.appCanAccess;
      
            if (typeof permissonList == 'string' && permissonList.length > 0) {
      
              let permisson = permissonList;
              this.permissionGranted = this.singlePermissionCheck(permisson, this.permissionsData);
            }
            else if (typeof permissonList == 'object' && permissonList.length > 0) {
      
              this.permissionGranted = this.multiPermissionsCheck(permissonList, this.permissionsData);
            }
      
            if (this.permissionGranted) {
      
                    this.renderer.setAttribute(this.elRef.nativeElement, "title", "You don't have permisson on this feature");
      
                    var innerValue = this.elRef.nativeElement.innerHTML;
      
                   if( innerValue.indexOf('mat-slide-toggle') >-1 ){
      
                      this.renderer.addClass(this.elRef.nativeElement,'mat-disabled');
                      this.renderer.setAttribute(this.elRef.nativeElement.firstElementChild.childNodes[0].firstElementChild,'disabled','true');
                    }     
      
                    if (innerValue.indexOf('mdc-text-field') > -1 || innerValue.indexOf('mdc-button') > -1) {
                      
                      this.renderer.setAttribute(this.elRef.nativeElement.parentElement, "title", "You don't have permisson on this feature");
                      
                       this.renderer.addClass(this.elRef.nativeElement,'mdc-text-field--disabled');
                       
                    }              
      
                    this.disabled = true;
              
            }
      
          }
           //==================================
         }
      );

    

  },100); // end of setTimeout

  } // end of method ngAfterViewInit


  multiPermissionsCheck(permissions: Array<any>, permissionsData): boolean {

    var permissonAllowFlag = false;
    var permissonFlag = false;
    var that = this;
    permissions.forEach(function (item) {

      permissonFlag = that.singlePermissionCheck(item, permissionsData);

      if (permissonFlag === true) {

        permissonAllowFlag = true;

      }

    });

    return permissonAllowFlag;

  };



  singlePermissionCheck(permissonValue: any, permissionsData: any): boolean {

    var permissionsList = permissionsData;
    
    let obj = permissionsList.find(o => o.name === permissonValue);

    if (obj == undefined) {
      return true;
    }
    else {
      return false;
    }

  };



  getPermissions(): any {

   // this.access.getActivitiesList();
  
   this.permissionsData = sessionStorage.getItem(PIM_ACTIVITIES);
 
        if (sessionStorage.getItem(PIM_ACTIVITIES) !== null ) {
          this.permissionsData = sessionStorage.getItem(PIM_ACTIVITIES);
          return this.permissionsData = this.getJsonPermissionsData(this.permissionsData);

        }
        else{
          this._permissionsAccessService.getPermissionsData();
          this.permissionsData = sessionStorage.getItem(PIM_ACTIVITIES);
          return this.permissionsData = this.getJsonPermissionsData(this.permissionsData);
        }   
          

}

      getJsonPermissionsData(data){
             if(data){
                  return JSON.parse(data);
              }
              else{
                  return [{
                    "id": 0,
                    "name": "",
                    "applicationId": 0,
                    "applicationName": null,
                    "status": 0,
                    "statusName": ""
                    }];
                };
            }


}