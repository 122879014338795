import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { messages } from 'src/app/shared/Constants/messageResource';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from '../../../dialogs/warning/warning.dialog.component';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { VariantViewModel } from '../../../models/Variants/VariantViewModel';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { HttpHelperService } from '../../../services/Common/http-helper.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ProductListService } from '../../../services/Product/product-list.service';
import { VariantsService } from '../../../services/Variants/variants.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-variants-list-default',
  templateUrl: './variants-list-default.component.html',
  styleUrls: ['./variants-list-default.component.scss']
})
export class VariantsListDefaultComponent implements OnInit {
  displayedColumns = ['variants', 'description', 'status','actions'];
  // displayedColumns = ['variants', 'description', 'view_icon', 'toggle_slide', 'edit_icon', 'delete_icon','history_icon'];
  //public dataSource = new MatTableDataSource<Comment>();
  public dataSource = new MatTableDataSource<VariantViewModel>();

  index: number;
  id: number;
  active: boolean;
  variantName: string;
  description: string;
  _Paxcom_DocumentType: string;
  updateVariantStat: {};
  searchText: string;
  PermissionsEnum: any;
  RouterLinksEnum: any;

  orgId:string;  
  orgName:any;
  appLogoUrl:any;  
  jsonOrgData: string;  
  organizationData:Array<any>;
  titlename: string;
  titleEnum: typeof titlename;
  newData: any = [];
  token: any;
  hideButton: boolean = true;
  featureIds: typeof HelpFeatureIdsEnum;
  displayTotal:number=0;
  displayNoItemsTemplate:boolean=false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  /*COMMENT: Comment[] = [
    {
      id: 1,
      name: 'Color',
      description: 'Bright Red Color'
  },
  {
      id: 2,
      name: 'Size',
      description: 'Medium'
  },
  {
      id: 3,
      name: 'Pattern',
      description: 'Stripped'
  }
];*/


constructor( public httpClient: HttpClient, public httpHelper:HttpHelperService, public dialog: MatDialog, private productSearchService: ProductListService, public dataService: VariantsService,
  private CustomMessage: CustomMessageService,private historyDialogService:HistoryDialogService,
  private chatDialogService:ChatDialogService,private helpGuideService:HelpGuideService,
  private router:Router,private deviceDetect:DeviceDetect ) {
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort; 

  searchItem(variantName){
    this.dataService.searchVariant("Name="+variantName)
    .subscribe(res => {
     let message = res['statusMessage'];
     this.CustomMessage.ShowCustomMessage(message, true);
   });
  }

  removeSelectedItem(i, id, name, tStatus) {

    let updateBrandStat: VariantViewModel = {
      id: id,
      name: name,
      active: tStatus,
      _Paxcom_OrganizationId: this.orgId.toString()
    }; 
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: updateBrandStat,
    };

    this.dataService.deleteVariant(options)
    .subscribe(res => {
        let message = res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);      
          
          this.dataSource.data.splice(i, 1);  
          this.dataSource.paginator = this.paginator;
          this.displayTotal = this.getDisplayTotal();
          if(this.displayTotal==0){
            if(!this.searchText) {this.searchText="";}
            this.getSearchResults(this.searchText);
           }
      });
  }

  deleteItem(i: number, id: number, name: string, active: boolean) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok',name: name}
    });
    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   data: {id: id, name: name}    
    // });
    
    dialogRef.afterClosed().subscribe(result => {
         if (result === 1) {
          this.removeSelectedItem(i, id, name, active);
         }
       });
  }

  onToggle(id, name, tStatus,index)
  {
    
    tStatus = tStatus ? false : true;
      if (tStatus === false) {
        this.productSearchService.getData("?organizationId=" + this.orgId + "&VariantTypeId=" + id)
          .subscribe(res => {
            if (res) {
                  if (res.data.data.length > 0) {
                    let message="There are products created under this variant. Do you still want to deactivate this variant?";
                    const dialogRef = this.dialog.open(WarningDialogComponent, {
                      data: { id: id, name: name,message:message }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      if (result === 1) {
                        this.changeToggleStatus(id, tStatus,index);
                      }
                      
                  else {
                    // toggleElement.source.checked = !toggleElement.source.checked;
                    return false;
                  }
                });  
              }

              else {
                this.changeToggleStatus(id, tStatus,index);
              }
            }
          })
      }
      
      else {
        this.changeToggleStatus(id, tStatus,index);
      }
  }

  changeToggleStatus(id, tStatus,index) {
      if (tStatus === true) {
        this.active = true;
        this.updateSelectedItem(id, this.active,index);
      }
      else {
        this.active = false;
        this.updateSelectedItem(id, this.active,index);
      }
  }

  updateSelectedItem(id, eStatus,index) {
    let updateVariantStat: VariantViewModel = {
      id: id,
      active: eStatus,
      description: this.description,
      _Paxcom_OrganizationId: this.orgId
    };
    this.dataService.updateVariantStatus(updateVariantStat).subscribe(res => {
      if (res) {
        this.dataSource.data[index].active =  this.dataSource.data[index].active ? false : true;
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);

      }
    });
  }


  getSearchResults(searchtext) {
    this.setSearchNotFoundVariables();
    if(this.searchText===""||this.searchText===undefined){
      this.setDefaultNoItemsFound();
    }
    this.displayNoItemsTemplate=false;
      this.dataSource.data = [];
      this.hideButton = true;
      let requestParam= `organizationId=${this.orgId}${this.searchText ? `&searchText=${searchtext}`:''}`
    this.dataService.getAllVariants(requestParam)
      .subscribe(res => {
        if (res.data.data.length>0) {
          this.dataSource.data = res.data.data as VariantViewModel[];
          this.displayTotal = this.getDisplayTotal();
          this.token = res.data.paging.continuationToken;
          if (this.token !== null) {
            this.hideButton = false;
          }
          else {
            this.displayTotal = this.getDisplayTotal();
            // this.CustomMessage.ShowCustomMessage("No record found", true);
            this.hideButton = true;
          }
        }else {
          this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
          this.displayNoItemsTemplate=true;
          this.hideButton = true;
          this.displayTotal=0;
        }
      });
    this.dataSource.paginator = this.paginator;
  
}

  ngOnInit() {
    this.getCurrentDevice()
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum=titlename;
    this.titlename=this.titleEnum.variant;
    this.RouterLinksEnum = RouterLinksEnum;    
    this.PermissionsEnum = PermissionsEnum;

    this.organizationData=[];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId=this.organizationData[0].id;
    
    //this.dataSource.data = this.COMMENT;
    this.dataService.getAllVariants("organizationId=" +this.orgId)
    .subscribe(res => 
      { 
        this.setDefaultNoItemsFound();
        if (res.data.data.length > 0) {
          this.dataSource.data = res.data.data as VariantViewModel[];  
          this.newData = res.data.data;
          this.displayTotal = this.getDisplayTotal();
          this.token = res.data.paging.continuationToken;
          if (this.token !== null) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }   
        }
        else {
          this.displayTotal = this.getDisplayTotal();
          this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
          this.displayNoItemsTemplate=true;
        }
      });

      this.dataSource.paginator = this.paginator;
        
      // let search = document.getElementById("search");
      //   search.addEventListener("keydown", function (e) {
      //       if (e.keyCode === 13) {
      //         event.preventDefault();
      //         document.getElementById("searchBtn").click();
      //       }
      // });
  }
  getMoreData() {
    if (this.token) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {
    if (token) {
        this.dataService.getAllVariants("organizationId=" +this.orgId + "&continuationToken=" + encodeURIComponent(token))
          .subscribe(res => {
            if (res.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                this.newData.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token === null) {
                this.hideButton = true;
              }
              this.dataSource.data = this.newData;
              this.displayTotal = this.getDisplayTotal();
            }
          });
    }
  }

  naviagateToVariantItemList(id){
   this.router.navigate([`${RouterLinksEnum.ADMIN_SLASH}${RouterLinksEnum.VARIANTS_SLASH}${id}`]);
  }

  navigateToVariantEdit(id){
    // ['../'+RouterLinksEnum.VARIANTS+'/'+element.id+'/'+RouterLinksEnum.VARIANTS_EDIT]
    this.router.navigate([`${RouterLinksEnum.ADMIN_SLASH}${RouterLinksEnum.VARIANTS_SLASH}${id}${RouterLinksEnum.ADMIN_EDIT}`]);
  }

  openHistory(id,name)
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.VariantType,id,name);  
  }
  openChat(id,name)
  {
    this.chatDialogService.openChatDialog(PIMEntityType.VariantType,id,name);  
  }
  openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }

  getDisplayTotal() {
    return this.dataSource.data.length;
  }

  getWrapedText(text, maxLength) {
    if(text){
    if (text.length <= maxLength) {
      return text;
    }
    else {
      return text.substring(0, maxLength - 3) + "...";
    }
  }
}
setDefaultNoItemsFound(){
  this.noItemsFoundDescType=messages.noResultFoundDescType;
  this.noItemsFoundTitle="Variants";
  this.noItemsFoundDesc=messages.noResultFoundDesc;
}
setSearchNotFoundVariables(){
  this.noItemsFoundDescType=messages.searchNotFoundDescType;
  this.noItemsFoundTitle="Variants";
  this.noItemsFoundDesc=messages.searchNotFoundDesc;
}
@HostListener('window:resize', ['$event'])
onResize(event) {
  this.getCurrentDevice();
}
  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }
}