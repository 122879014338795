import { Component, OnInit} from '@angular/core';
import { CommonService } from '../services/Common/common.service';
import { Subscription } from 'rxjs';
import { DynamicPimThemeLoadService } from '../services/Common/dynamic-pim-theme-load.service';


@Component({
  selector: 'app-inout-menu',
  templateUrl: './inout-menu.component.html',
  styleUrls: ['./inout-menu.component.scss']
})
export class InoutMenuComponent implements OnInit {
  themeSubscription:Subscription;
  themeComponentName:string;
    constructor(private commonService:CommonService,private themeService:DynamicPimThemeLoadService) {
      this.themeService.checkDeviceType();
      this.themeSubscription = this.commonService.getComponentData.subscribe(res=>{
        this.themeComponentName = res['sideMenu'];
      })
     }
  
    ngOnInit() {
    }
  
    ngOnDestroy(): void {
      this.themeSubscription.unsubscribe();
    }
}
