import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  _baseUrl = environment.apiBaseUrl + environment.pimApiBaseUrl;

  constructor(private httpClient: HttpClient) { }

  getData(url: string) : Observable<any> {
    return this.httpClient.get<any>(this._baseUrl + url);
  }

  postData(body, url) : Observable<any>  {
    return this.httpClient.post((this._baseUrl + url), body);
  }

  putData(body, url) : Observable<any> {
    return this.httpClient.put((this._baseUrl + url), body);
  }

  delete(url): Observable<any> {
    return this.httpClient.delete<any>(this._baseUrl + url);
  }

  deleteBody(url,body): Observable<any> {
    return this.httpClient.delete<any>(this._baseUrl + url,body);
  }

  downloadData(url: string) : Observable<Blob> {
    return this.httpClient.get<Blob>(url,{ responseType: 'blob' as 'json' });
  }
  downloadExcel(url: string) : Observable<Blob> {
    return this.httpClient.get<Blob>(this._baseUrl+url,{ responseType: 'blob' as 'json' });
  }

}
