// errors-handler.ts
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../Authorization/auth.service';
// import { SESSION_EXPIRED } from 'src/app/constants/applications';
@Injectable()
export class ErrorsHandler implements ErrorHandler {

    constructor (
        private authService : AuthService
    ) {}

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            
            if (!navigator.onLine) {
                // Handle offline error
                
            } else {
                // Handle Http Error (error.status === 403, 404...)
                if (error.status === 401) {
                    // const token = this.authService.GetToken();
                    // if (token !== null && token !== undefined && token !== '') {

                        // this.customMessageService.ShowCustomMessage(SESSION_EXPIRED, false);
                        this.authService.Logoff();
                    // }
                }
                
            }
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)     
            
        }
        // Log the error anyway
        // console.error('It happens: ', error);
    }
}