import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, retryWhen, delay, take, timeout } from 'rxjs/operators';
import { AuthService } from '../Authorization/auth.service';
import { Router } from '@angular/router';
import { CustomMessageService } from '../custommessageservice';
import { NOT_AUTHORISED } from '../../constants/messages';


@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private customMessageService: CustomMessageService
  ) {

  }

  intercept(
    request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe( timeout(240000),
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorCodes = [0];  
        if (errorCodes.includes(error.status)){
          const newReqOne = request.clone();      
          return next.handle(newReqOne).pipe( retryWhen(errors => errors.pipe(delay(1000), take(2)) ) ); 
        }

        if (error.error !== null && error.error !== undefined) {

          let message = error.error['StatusMessage'] || error.error['statusMessage'];
          if (message !== null && message !== undefined && message !== '') {
            let msg = '', errMsg; let msgArr = [];
            if (message.indexOf('\n') >= 0) {
              let count = 1;
              msgArr = (message.split('\n')).filter(item=> item!='');
              while (count <= msgArr.length) {
                msg = `${msg} \n ${count}. ${msgArr[count - 1]}`;
                count++;
              }
              // message = `${count++}. ` + message; 
              // message = message.replaceAll('\n',`\n${count++}. `)
              // message = "Fix below error's: \n \n " + message; 
              errMsg = `Exception(s) Occurred: \n ${msg ? msg : message }`;
            }
            this.customMessageService.ShowCustomMessage(errMsg || message, false);
          }
        }
        if (error.status === 401) {

          if (this.authService.isLoggedIn()) {
            this.authService.Logoff;
          }
          else {
            this.router.navigate(['']);
          }
        }
        else if (error.status === 403) // this is handled differency because client is not getting status message in case  of 403
        {
          this.customMessageService.ShowCustomMessage(NOT_AUTHORISED, true);
          return throwError(error);
        }
        // else if (error.status === 400) { 
        //   let message = error.error['StatusMessage'] || error.error['statusMessage'];
        //   this.customMessageService.showValidationMessage(message, true);
        //   return throwError(error);
        // } 
        else {
          //return throwError(error);
          return throwError(error);
        }
      })
    );
  }
}
