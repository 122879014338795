export enum PermissionsEnum {
    VIEW_DASHBOARD = "View Dashboard",
    CREATE_PRODUC = "Create Product",
    VIEW_PRODUCT = "View Product",
    EDIT_PRODUCT = "Edit Product",
    DELETE_PRODUCT = "Delete Product",
    SEARCH_PRODUCTS = "Search Products",
    CREATE_CATEGORY = "Create Category",
    VIEW_CATEGORY = "View Category",
    EDIT_CATEGORY = "Edit Category",
    DELETE_CATEGORY = "Delete Category",
    SEARCH_CATEGORIES = "Search Categories",
    CREATE_BRAND = "Create Brand",
    VIEW_BRAND = "View Brand",
    EDIT_BRAND = "Edit Brand",
    DELETE_BRAND = "Delete Brand",
    SEARCH_BRANDS = "Search Brands",
    CREATE_VARIANT_TYPE = "Create Variant Type",
    VIEW_VARIANT_TYPE = "View Variant Type",
    EDIT_VARIANT_TYPE = "Edit Variant Type",
    DELETE_VARIANT_TYPE = "Delete Variant Type",
    SEARCH_VARIANT_TYPE = "Search Variant Type",
    SETTINGS = "Settings",
    CREATE_VARIANT_GROUP = "Create Variant Group",
    EDIT_VARIANT_GROUP = "Edit Variant Group",
    DELETE_VARIANT_GROUP = "Delete Variant Group",
    COPY_PRODUCT = "Copy Product",
    ENABLE_DISABLE_PRODUCT = "Enable/Disable Product",
    ENABLE_DISABLE_CATEGORY = "Enable/Disable Category",
    ENABLE_DISABLE_BRAND = "Enable/Disable Brand",
    ENABLE_DISABLE_VARIANT_TYPE = "Enable/Disable Variant Type",
    ENABLE_DISABLE_VARIANT_GROUP = "Enable/Disable Variant Group",
    CREATE_CHANNEL = "Create Channel",
    VIEW_CHANNEL = "View Channel",
    EDIT_CHANNEL = "Edit Channel",
    DELETE_CHANNEL = "Delete Channel",
}

