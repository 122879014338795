import { Injectable } from '@angular/core';
import { CanActivate, Router,ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../Authorization/auth.service';
import { RouteGuardsService } from '../route-guards.service';
import { UrlSessionStorageService } from '../url-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CookieCheckGuardService implements CanActivate {
  url: string;

      constructor(private routeGaurd: RouteGuardsService, private getUrlSession: UrlSessionStorageService,private router: Router,private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.url=state.url;
    if(this.routeGaurd.checkOrgCookieExists()){
      return true;
    }
    else{
      this.getUrlSession.storeUrlBeforeRedirect(this.url);
      this.authService.redirectToOrg();
      return false;
    }
   
  }
}
