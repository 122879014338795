import { MdcSnackbar } from '@angular-mdc/web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router  } from '@angular/router';
import { messages } from 'src/app/shared/Constants/messageResource';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { PermissionsEnum } from '../../../authorization/enum/permissions.enum';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { WarningDialogComponent } from '../../../dialogs/warning/warning.dialog.component';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { BrandViewModel } from '../../../models/Brands/BrandViewModel';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { AuthAccessService } from '../../../services/Authorization/auth-access.service';
import { BrandsService } from '../../../services/Brands/brands.service';
import { CommonService } from '../../../services/Common/common.service';
import { HttpHelperService } from '../../../services/Common/http-helper.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ProductListService } from '../../../services/Product/product-list.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-brand-list-default',
  templateUrl: './brand-list-default.component.html',
  styleUrls: ['./brand-list-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrandListDefaultComponent implements OnInit {
  displayedColumns = ['brands','status','actions'];
  //public dataSource = new MatTableDataSource<Comment>();

  public dataSource = new MatTableDataSource<BrandViewModel>();
  public dataSourceEmpty =  [];

  index: number;
  id: number;
  brandName: string;
  active: boolean;
  updateBrandStat: {};
  searchText: string; 
  options: Array<string>;
  bIds: Array<string>;
  response: Array<BrandViewModel>;
  PermissionsEnum: any;
  RouterLinksEnum: any;
  valPer: {};
  hideTable: boolean = true;
  apiInProgress:boolean = false;
  orgId: string;  
  orgName:any;
  appLogoUrl:any;  
  jsonOrgData: string;  
  organizationData:Array<any>;
  jsonAppData: string;
  applicationData: Array<any>;
  titlename: string;
  titleEnum: typeof titlename;
  featureIds: typeof HelpFeatureIdsEnum;
  searchTextQuery:string;
  displayNoItemsTemplate:boolean=false;
  noItemsFoundDescType:string;
  noItemsFoundTitle:string;
  noItemsFoundDesc:string;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor( public httpClient: HttpClient, public httpHelper:HttpHelperService, public dialog: MatDialog,
    public dataService: BrandsService, private productSearchService: ProductListService,private chatDialogService:ChatDialogService,
    public commonService: CommonService, private authAccessService: AuthAccessService,private historyDialogService:HistoryDialogService,
    private router: Router,private CustomMessage: CustomMessageService,private route: ActivatedRoute,
    private helpGuideService:HelpGuideService,private deviceDetect:DeviceDetect ) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  token: any;
  newData: Array<BrandViewModel>
  noMoreDataMessage: string = "No more data to show";
  hideButton: boolean = true; 
  displayTotal:number=0;
  searchItem(brandName){
    
    this.dataService.searchBrand("Name="+brandName)
          .subscribe(res => {
            let message = res['statusMessage'];
            this.CustomMessage.ShowCustomMessage(message, true);
          });
  }

  removeSelectedItem(i, id, name, tStatus) {
    let updateBrandStat: BrandViewModel = {
      id: id,
      name: name,
      active: tStatus,
      _Paxcom_OrganizationId: this.orgId.toString()
    };    
       const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: updateBrandStat,
      };
      this.dataService.deleteBrand(options).subscribe(res => {
         let message = res['statusMessage'];
         this.CustomMessage.ShowCustomMessage(message, true);      
          
           this.dataSource.data.splice(i, 1);  
           this.dataSource.paginator = this.paginator; 
           this.displayTotal = this.getDisplayTotal(); 
           if(this.displayTotal==0){
            if(!this.searchText) {this.searchText="";}
            this.getSearchResults(this.searchText);
           }
       });        
   }

  deleteItem(i: number, id: string, name: string, active: boolean) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok', name: name }
    });
    dialogRef.afterClosed().subscribe(result => {
         if (result === 1) {
          this.removeSelectedItem(i, id, name, active);
         }
       });
  }
  onToggle(id, name, tStatus, index)
  {
    // let toggleElement = event;
      // this.bIds = id;
      tStatus = tStatus ? false : true;
      if (tStatus === false) {
        this.productSearchService.getData("?organizationId=" + this.orgId + "&BrandIds=" + id)
          .subscribe(res => {
            if (res) {
                  if (res.data.data.length > 0) {
                    let message="There are products created under this brand. Do you still want to deactivate this brand?";
                    const dialogRef = this.dialog.open(WarningDialogComponent, {
                      data: { id: id,message:message }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      if (result === 1) {
                        this.changeToggleStatus(id, tStatus,index);
                      }
                      
                  else {
                    // toggleElement.source.checked = !toggleElement.source.checked;
                    return false;
                  }
                });  
              }

              else {
                this.changeToggleStatus(id, tStatus,index);
              }
            }
          })
      }
      
      else {
        this.changeToggleStatus(id, tStatus,index);
      }
  }

  changeToggleStatus(id, tStatus,index) {
      if (tStatus === true) {
        this.active = true;
        this.updateSelectedItem(id, this.active,index);
      }
      else {
        this.active = false;
        this.updateSelectedItem(id, this.active,index);
      }
  }

  updateSelectedItem(id, eStatus,index) {
    let updateVariantStat: BrandViewModel = {
      id: id,
      active: eStatus,
      _Paxcom_OrganizationId: this.orgId
    };
    this.dataService.updateBrandStatus(updateVariantStat).subscribe(res => {
      if (res) {
        this.dataSource.data[index].active =  this.dataSource.data[index].active ? false : true;
        let message = res['StatusMessage'] || res['statusMessage'];
        this.CustomMessage.ShowCustomMessage(message, true);

      }
    });
  }

  getSearchResults(searchtext) {
    this.displayNoItemsTemplate=false;
      this.setSearchNotFoundVariables();
      if(this.searchText===""||this.searchText===undefined){
        this.setDefaultNoItemsFound();
      }
      this.dataSource.data = [];
      this.hideTable = true; 
      this.apiInProgress = true;
      let requestParam= `organizationId=${this.orgId}${this.searchText ? `&searchText=${searchtext}`:''}`
            this.dataService.getAllBrands(requestParam)
              .subscribe(res => {
                if (res.data.data.length>0) {
                  this.hideTable = false;
                  this.apiInProgress = false;
                  this.dataSource.data = res.data.data as BrandViewModel[];
                  this.displayTotal = this.getDisplayTotal();
                  this.token = res.data.paging.continuationToken;
                  if (this.token !== null) {
                    this.hideButton = false;
                  }
                  else {
                    // this.CustomMessage.ShowCustomMessage("No record found", true);
                    this.hideButton = true;
                    this.displayTotal = this.getDisplayTotal();
                  }
                }else {
                  this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
                  this.displayNoItemsTemplate=true;
                  this.hideButton = true;
                  this.apiInProgress = false;
                  this.displayTotal= 0;
                  this.hideTable = true;
                }
              });
        this.dataSource.paginator = this.paginator;
  
}

  getMoreData() {
    this.apiInProgress = true;
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {
    if (token !== null || token !== undefined) {
      this.dataService.getAllBrands("organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token))
        .subscribe(res => {
          this.apiInProgress = false;
          if (res.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              this.newData.push(res.data.data[i]);
            }
            this.token = res.data.paging.continuationToken;
            if (this.token === null) {
              this.hideButton = true;
            }
            this.dataSource.data = this.newData;
            this.displayTotal = this.getDisplayTotal();
          }
        })
    }
  }

  ngOnInit() {
    this.getCurrentDevice()
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum=titlename;
    this.RouterLinksEnum = RouterLinksEnum;
    this.PermissionsEnum = PermissionsEnum;
    this.titlename=this.titleEnum.brands;
    this.organizationData=[];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = this.organizationData[0].id;
      //console.log(this.orgId);
    this.loadBrands();
    this.setDefaultNoItemsFound();
 
  }

  loadBrands(){

      this.route.queryParamMap.subscribe((param)=>{
        this.searchTextQuery=param.get('searchtext');
      })
      var queryString=""
      if(this.searchTextQuery!=undefined){
        queryString="&searchText="+this.searchTextQuery
        this.searchText=this.searchTextQuery;
      }
     this.dataService.getAllBrands("organizationId=" + this.orgId+queryString)    
        .subscribe(res => 
        { 
          if (res.data.data.length > 0) {
            this.dataSource.data = res.data.data as BrandViewModel[];
            this.displayTotal = this.getDisplayTotal();
            this.response = res.data.data;
            this.newData = res.data.data;
            this.token = res.data.paging.continuationToken;
              if (this.token !== null) {
                    this.hideButton = false;
                }
              else {
                this.hideButton = true;
              }            
          }
          else {
            this.hideButton = true;
            this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
            this.displayNoItemsTemplate=true;
          }
        });
        
        this.dataSource.paginator = this.paginator;     

      //   let search = document.getElementById("search");
      //   search.addEventListener("keydown", function (e) {
      //           if (e.keyCode === 13) {
      //             event.preventDefault();
      //             document.getElementById("searchBtn").click();
      //           }
      // });
  }
  openHistory(id,name)
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Brand,id,name);  
  }
  openChat(id,name)
  {
    this.chatDialogService.openChatDialog(PIMEntityType.Brand,id,name);  
  }
  openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }

  getDisplayTotal() {
    return this.dataSource.data.length;
  }
  redirectToProductList(brand){
    let searchParameters = {
      productBrand : [brand.id]
    }
    this.router.navigate( [RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.MANAGE_PRODUCTS_NO_SLASH],  { queryParams: { query: decodeURI(JSON.stringify(searchParameters)) } })
  }
  setDefaultNoItemsFound(){
    this.noItemsFoundDescType=messages.noResultFoundDescType;
    this.noItemsFoundTitle="Brands";
    this.noItemsFoundDesc=messages.noResultFoundDesc;
  }
  setSearchNotFoundVariables(){
    this.noItemsFoundDescType=messages.searchNotFoundDescType;
    this.noItemsFoundTitle="Brands";
    this.noItemsFoundDesc=messages.searchNotFoundDesc;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }
    getCurrentDevice(){
      this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
      this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
      this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
    }
}

