import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import { FormControl, Validators } from '@angular/forms';
import {NEWBRANDNOTE} from '../../shared/constants/global'

@Component({
  selector: 'app-add-brand.dialog',
  templateUrl: '../../dialogs/add-brand/add-brand.dialog.html',
  styleUrls: ['../../dialogs/add-brand/add-brand.dialog.css']
})
export class AddBrandDialogComponent {
formSubmit = false;
newBrandNote= NEWBRANDNOTE;
brandName:FormControl = new FormControl();
  constructor(public dialogRef: MatDialogRef<AddBrandDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService) { 
                dialogRef.disableClose = true;
                this.brandName.setValidators([Validators.required,Validators.maxLength(50)])
              }

  cancel(): void {
    this.dialogRef.close();
  }

  addBrand(): void {
    this.formSubmit = true;
    if(this.brandName.invalid){ return }
    this.dialogRef.close({data:this.brandName.value})
  }
    
}
