import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickySearchDirective } from './sticky-search.directive';
@NgModule({
  declarations: [StickySearchDirective],
  imports: [
    CommonModule
  ],
  exports: [
    StickySearchDirective
  ]
})
export class StickySearchDirectiveModule { }