export class ProductCategory {
    id: string;
    name: string;
    description: string;
    parentCategoryId: string;
    parentCategoryName: string;
    fullHierarchicalName: string;
    childCount: number;
    active: boolean;
    _Paxcom_OrganizationId: string;
}

export class ProductTaxType {
    id: string;
    name: string;
    percentValue?: number;
}

export class ProductBrand {
    name: string;
    active: boolean;
    id: string;
    _Paxcom_OrganizationId: string;
    _Paxcom_DocumentType: string;
}

export class ProductCondition {
    id: string;
    name: string;
}

export class Tag {
    id: string;
    name: string;
}

export class ProductReplacementShippingCondition {
    id: string;
    name: string;
}

export class ProductDimensions {
    lengthInCentimeters: number;
    widthInCentimeters: number;
    heightInCentimeters: number;
    weightInGrams: number;
}

export class PackageDimensions {
    lengthInCentimeters: number;
    widthInCentimeters: number;
    heightInCentimeters: number;
    weightInGrams: number;
}

export class ProductShelfLifeUnit {
    id: string;
    name: string;
}

export class Season {
    id: number;
    name: string;
}

export class Image {
    name: string;
    type: string;
    description: string;
    tags: string;
    originalURL: string;
    fileSizeInBytes: number;
    isSwatchOnlyImage: boolean;
    displayOrder: number;
    smallThumbnailURL: string;
    mediumThumbnailURL: string;
    largeThumbnailURL: string;
    copiedImage : boolean;
}

export class SwatchImage {
    name: string;
    type: string;
    description: string;
    tags: string;
    originalURL: string;
    fileSizeInBytes: number;
    isSwatchOnlyImage: boolean;
    displayOrder: number;
    smallThumbnailURL: string;
    mediumThumbnailURL: string;
    largeThumbnailURL: string;
}

export class Option {
    name: string;
    swatchImage: SwatchImage;
}

export class VariantType {
    id: string;
    name: string;
    options: Option[];
}

export class ProductImage {
    name: string;
    type: string;
    description: string;
    tags: string;
    originalURL: string;
    fileSizeInBytes: number;
    isSwatchOnlyImage: boolean;
    displayOrder: number;
    smallThumbnailURL: string;
    mediumThumbnailURL: string;
    largeThumbnailURL: string;
}

export class ProductVariant {
    variantIdentifier: string;
    productId: string;
    productName: string;
    sku: string;
    productCodes: string;
    status: string;
    productImages: ProductImage[];
}

export class bulletPoint {
    name : string;
}

export class ProductPostDTO{
    id: string;
    organizationId: string;
    name: string;
    sku: string;
    productCategory: ProductCategory;
    hsnCode: string;
    productTaxType: ProductTaxType; // remaining to add
    description: string;
    bulletPoints: bulletPoint[];
    productBrand: ProductBrand;
    productCondition: ProductCondition;
    productConditionDescription: string;
    mrp: number;
    shippingFee: number;
    minimumSellerAllowedPrice: number;
    maximumSellerAllowedPrice: number;
    asin: string;
    isbn: string;
    upc: string;
    eanNumber: string;
    gtin: string;
    gcid: string;
    status: string;
    tags: Tag[];
    returnable: boolean; // did not find
    productReplacementShippingCondition: ProductReplacementShippingCondition;
    productReplacementConditionDescription: string;
    tat: number;
    productDimensions: ProductDimensions;
    packageDimensions: PackageDimensions;
    isPerishable: boolean;
    shelfLife: number;
    productShelfLifeUnit: ProductShelfLifeUnit; // api dependent
    season: Season; // did not find
    maintainBatch: boolean;
    launchDate: Date;
    images: Image[];
    isVariantGroup: boolean;
    variationGroupId: string;
    isVariant: boolean;
    variantTypes: VariantType[];
    productVariants: ProductVariant[];
    createdById: string;
    createdByName: string;
    createdOn: Date;
}
