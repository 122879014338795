import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../Common/http-helper.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpGuideService {

  _baseUrl = environment.apiBaseUrl;
  constructor(private httpClient: HttpClient,private httpHelper:HttpHelperService) { }

  getHelpGuide(querySyring = ""): Observable<any> {
    let url = 'HelpGuide';
    return this.httpHelper.getData(url + querySyring);
  }
  getHelpGuideById(id,querySyring = ""): Observable<any> {
    let url = 'HelpGuide/'+id;
    return this.httpHelper.getData(url + querySyring);
  }
  saveHelpGuide(data:any) : Observable<any>
  {
    let url="HelpGuide"
    return this.httpHelper.putData(data,url);
  }
  updateHelpGuide(data) :Observable<any> {
    let url="HelpGuide";
    return this.httpHelper.postData(data,url);

  }
  deletHelpGuideById(id)
  {
    let url="HelpGuide/"+id
     return this.httpHelper.delete(url);
  }
}
