import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InoutMenuComponent } from './inout-menu/inout-menu.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { AddNewBrandComponent } from './add-new-brand/add-new-brand.component';
// import { ImageguidelinesComponent } from '../app/imageguidelines/imageguidelines.component';
import { CreateNewProductDefaultComponent } from './themes/create-product/create-product-desktop/create-new-product-default/create-new-product-default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateNewCategoryComponent } from './create-new-category/create-new-category.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { VariantsListComponent } from './variants-list/variants-list.component';
import { AddNewVariantComponent } from './add-new-variant/add-new-variant.component';
import { CategoryAssetComponent } from './category-asset/category-asset.component';
import { AssetsComponent } from './assets/assets.component';
import { CreateAssetComponent } from './create-asset/create-asset.component';
import { SettingsComponent } from './settings/settings.component';
import { ResourcesComponent } from './resources/resources.component';
import { AuthGuardService } from 'src/app/core/guards/auth-guard.service';
import { CanAccessAdmin } from 'src/app/core/guards/can-access-admin';
import { MasterGuard } from 'src/app/core/guards/mainGuard/masterGuard';
import { ProductListWrapperComponent } from './product-list/product-list-wrapper.component';
// import { ProductListContainerComponent } from './devices/injector/product-list-container/product-list-container.component';
import { AddNewProductComponent } from './add-new-product/add-new-product.component';
import { ImageguidelinesComponent } from './imageguidelines/imageguidelines.component';


const routes: Routes = [
  {
    path: "", component: InoutMenuComponent,  data:  {guards: [AuthGuardService,CanAccessAdmin]}, canActivate: [MasterGuard],
    children: [
      
      {
        path: "manage-products", component: ProductListWrapperComponent,
        pathMatch:'full', data: { titlename: "Product List" },
      },
      {
        path: "product-dashboard", component: DashboardComponent,
        pathMatch:'full', data: { titlename: "Dashboard" },
      },
      {
        path: "manage-products/new", component: AddNewProductComponent,
        pathMatch:'full', data: { titlename: "Add Product" },
      },
      {
        path: "manage-products/:id/edit", component: AddNewProductComponent,
        pathMatch:'full', data: { titlename: "Edit Product" },
      },
      {
        path: "manage-products/copy/:copyId",
        pathMatch:'full', component: AddNewProductComponent, data: { titlename: "Edit Product" },
      },
      {
        path: "category/:categoryId",
        pathMatch:'full', component: ProductListWrapperComponent, data: { titlename: "View Product" },
      },
      {
        path: "brand/:brandId", component: ProductListWrapperComponent,
        pathMatch:'full', data: { titlename: "View Product" },
      },
      {
        path: "variants/new", component: AddNewVariantComponent,
        pathMatch:'full', data: { titlename: "Add New Variant" },
      },
      {
        path: "variants/:variantId", component: ProductListWrapperComponent,
        pathMatch:'full', data: { titlename: "View Product" },
      },
      {
        path: 'manage-products/catalogue',
        loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule)
      },
      {
        path: "manage-products/:viewId", component: AddNewProductComponent,
        pathMatch:'full', data: { titlename: "View Product" },
      },
      {
        path: "products/:id", component: ProductListWrapperComponent,
        pathMatch:'full', data: { titlename: "Product List" },
      },
      {
        path: "categories", component: CategoryListComponent,
        pathMatch:'full', data: { titlename: "Categories" },
      },
      {
        path: "categories/new", component: CreateNewCategoryComponent,
        pathMatch:'full', data: { titlename: "Add New Category" },
      },
      {
        path: "categories/:id/edit", component: CreateNewCategoryComponent,
        pathMatch:'full', data: { titlename: "Update Category" },
      },
      {
        path: "categories/:id/sub-categories", component: CategoryListComponent,
        pathMatch:'full', data: { titlename: "Categories" },
      },
      {
        path: "image-guideline", component: ImageguidelinesComponent,
        pathMatch:'full', data: { titlename: "Add Product" },
      },
      {
        path: "brands", component: BrandListComponent,
        pathMatch:'full', data: { titlename: "Brands" },
      },
      {
        path: "brands/new", component: AddNewBrandComponent,
        pathMatch:'full', data: { titlename: "Add New Brand" },
      },
      {
        path: "brands/:id/edit", component: AddNewBrandComponent,
        pathMatch:'full', data: { titlename: "Update Brand" },
      },
      {
        path: "variants", component: VariantsListComponent,
        pathMatch:'full', data: { titlename: "Variants" },
      },
      {
        path: "variants/:id/edit", component: AddNewVariantComponent,
        pathMatch:'full', data: { titlename: "Update Variant" },
      },
      {
        path: "asset", component: CategoryAssetComponent,
        pathMatch:'full', data: { titlename: "Asset Categories" },
      },
      {
        path: "resources", component: ResourcesComponent,
        pathMatch:'full', data: { titlename: "Resources" },
      },
      {
        path: "asset/:id/sub-asset", component: CategoryAssetComponent,
        pathMatch:'full', data: { titlename: "Sub Asset Categories" },
      },
      {
        path: "asset-list", component: AssetsComponent,
        pathMatch:'full', data: { titlename: "Assests" },

      },
      {
        path: "asset/new", component: CreateAssetComponent,
        pathMatch:'full', data: { titlename: "Assests" },
      },
      {
        path: "asset/:assetId/edit", component: CreateAssetComponent,
        pathMatch:'full', data: { titlename: "Assests" },
      },
      {
        path: 'product-channels',
        loadChildren: () => import('./channels/channels.module').then(m => m.ChannelsModule)
      },
      {
        path: "product-settings", component: SettingsComponent,
        pathMatch:'full', data: { titlename: "Settings" },
      },
      {
        path: "",
        redirectTo:"manage-products",
        pathMatch:'prefix'
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PIMRoutingModule { }
