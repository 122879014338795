import { Injectable } from '@angular/core';
import { SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlSessionStorageService {

  constructor(private _router: Router) { }

  storeUrlBeforeRedirect(url: string) {
    let returnUrl = this.getUrlFromSession();
    if (returnUrl === null) {
      sessionStorage.setItem("returnUrl", url);
    }
  }

  deleteUrl() {
    sessionStorage.removeItem("returnUrl");
  }

  redirectToStoredUrl() {
    let returnUrl = this.getUrlFromSession();
    this.deleteUrl();
    this._router.navigate([returnUrl]);
  }


  redirectToStoredOrBaseUrl() {
    let returnUrl = this.getUrlFromSession();
    this.deleteUrl();
    if (returnUrl != null)
    {
      location.href = location.protocol + "//" + location.host + returnUrl;
      // this._router.navigate([returnUrl]);
    }
    else
      this._router.navigate(['']);
  }

  getUrlFromSession(): any {
    return sessionStorage.getItem("returnUrl");
  }

  // setGuardStatus() {
  //   if (sessionStorage.getItem("guardStatus") === null) {
  //     sessionStorage.setItem("guardStatus", "false");
  //   }
  // }
}
