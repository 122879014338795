import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent} from '@angular/material';
import {Component, Inject,OnInit, ViewChild, ElementRef} from '@angular/core';
import {DataService} from '../../../core/data.service';
import { CustomMessageService } from 'src/app/core/custommessageservice';
@Component({
  selector: 'app-invoiceDate.dialog',
  templateUrl: '../../dialogs/invoice-Date/invoiceDate.dialog.html',
  styleUrls: ['../../dialogs/invoice-Date/invoiceDate.dialog.css']
})

export class InvoiceDateDialogComponent implements OnInit {
  
  invoiceDate:any;
  titleOrButtonName: string = "Generate";
  invoiceNumber: any;
    
  constructor(public dialogRef: MatDialogRef<InvoiceDateDialogComponent>,public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService,
              private customMessageService: CustomMessageService,
             ) { 
                dialogRef.disableClose = true;
              }

  closeDialog(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if(this.data.invoiceDate || this.data.invoiceNumber)
    {
      this.titleOrButtonName = "Update";
      if(this.data.invoiceDate)
      {
        this.invoiceDate = this.data.invoiceDate;  
      }
      if( this.data.invoiceNumber)
      {
        this.invoiceNumber = this.data.invoiceNumber 
      }
    }
    else
    {
      this.invoiceDate = new Date(Date.now());
      this.invoiceDate = this.invoiceDate.toISOString();
    }
    }
    generateInvoice()
    {
      if(!this.invoiceDate)
      {
        this.customMessageService.ShowCustomMessage("Please enter date",false)
        return;
      }
      let obj = {
        invoiceDate : this.invoiceDate,
        invoiceNumber : this.invoiceNumber
      }
      this.dialogRef.close(obj);
    }
  }
