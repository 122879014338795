import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ScrollingToErrorsService } from 'src/app/core/Common/scrollingToErrors.service';
import { messages } from 'src/app/shared/Constants/messageResource';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation/confirmation.dialog.component';
import { DeleteDialogComponent } from '../../../dialogs/delete/delete.dialog.component';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { CategoryModel } from '../../../models/Category/category-model.model';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { AssetService } from '../../../services/Assets/asset.service';
import { CategoriesServiceService } from '../../../services/categories-service.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { maximumLength } from 'src/app/shared/Constants/maximumLength';

@Component({
  selector: 'app-create-new-category-default',
  templateUrl: './create-new-category-default.component.html',
  styleUrls: ['./create-new-category-default.component.scss']
})
export class CreateNewCategoryDefaultComponent implements OnInit {
  parentCatId: any;
  search: any;
  parentCatName: any;
  categoryId: string;
  isEditMode: boolean = false;
  editRes: any[];
  newCategoryName: any;
  newParentCategoryName: any;
  newDescription: any;
  categoryForm: FormGroup;
  myControl = new FormControl();
  options: Array<string>;
  response: Array<CategoryModel>;
  filteredOptions: Observable<string[]>;
  activeStatus: boolean = true;
  childCount: number = 0;
  orgId: number;
  parentId: string = "";
  getData: any;
  organizationData: any[];
  jsonOrgData: string;
  buttonName: string;
  titlename: string;
  titleEnum: typeof titlename;
  formSubmit: boolean = false;
  redirectUrl: any;
  locationSelected: any;
  categoryResponse: any = [];
  featureIds: typeof HelpFeatureIdsEnum;
  disableActionsButtonsWhileAPICall: boolean = false;
  buttonActionName: string = "Save";
  noRedirect: boolean;
  showSetting: boolean = false;
  imgURL: any;
  isButtonVisible: boolean = true;
  deleteImageStatus: boolean;
  deleteImageUrl: any;
  viewMode: boolean = false;
  showHelpIcon: boolean = false;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  maxLengthCategoryName = maximumLength.category;
  constructor(private route: ActivatedRoute, private assetService: AssetService, private dialog: MatDialog, private formBuilder: FormBuilder,
    private router: Router, private _categoryService: CategoriesServiceService, private chatDialogService: ChatDialogService,
    private CustomMessage: CustomMessageService, private scrollingToErrorsService: ScrollingToErrorsService,
    private helpGuideService: HelpGuideService, 
    private deviceDetect : DeviceDetect,
    private historyDialogService: HistoryDialogService) {
    this.route.paramMap.subscribe(params => {
      const state = history.state;
      this.parentCatName = state.pName;
      this.parentCatId = state.pId;
      this.redirectUrl = state.url;
    })
  }

  ngOnInit() {
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum = titlename;
    this.titlename = this.titleEnum.addCategory;
    this.getCurrentDevice();
    let organizationData = [];
    let jsonOrgData = localStorage.getItem(ORGANIZATION_INFO);
    if (jsonOrgData) {
      jsonOrgData = decodeURIComponent(jsonOrgData);
      organizationData.push(JSON.parse(jsonOrgData));
      this.orgId = organizationData[0].id;
    }
    this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(this.maxLengthCategoryName)]],
      description: [''],
      id: [''],
      imageURI: [''],
      smallImageURI: [''],
      mediumImageURI: [''],
      bigImageURI: [''],
      parentCategoryName: [''],
      parentCategoryId: [''],
      fullHierarchicalName: [''],
      childCount: [this.childCount],
      active: [this.activeStatus],
      _Paxcom_OrganizationId: ['']
    })
    this.getAllCategories().subscribe(res => {
      this.categoryId = this.route.snapshot.params['id'];
      if (this.categoryId) {
        this.buttonActionName = "Update";
        this.isEditMode = true;
        this.titlename = this.titleEnum.updateCategory;

        if (this.categoryId !== null || this.categoryId !== undefined) {
          this.getCategoryDetailsById();
        };
      }
      else {
        if (this.parentCatName !== null && this.parentCatName !== undefined) {
          this.categoryForm.get('parentCategoryName').setValue(this.parentCatName);
        }
        this.showHelpIcon = true;
      }
    })


    this.categoryForm.get('parentCategoryName').valueChanges
      .pipe(debounceTime(300)
      ).subscribe(val => {
        this.locationSelected = this.categoryResponse.findIndex(x => x.fullHierarchicalName === val)
        if (this.locationSelected > -1) {
          return false;
        }
        if (val !== null && val !== undefined && val !== '') {
          this.getParentCategory(val);
        }
      })

  }

  selectParentLocation(id) {
    this.parentCatId = id;
    this.categoryForm.get('parentCategoryId').setValue(this.parentCatId);
    this.parentCatName = this.categoryForm.get('parentCategoryName').value;
  }

  getParentCategory(searchText) {

    if (searchText.length > 2) {
      this._categoryService.getAllCategories("organizationId=" + this.orgId + "&pageSize=-1&searchText=" + searchText)
        .subscribe(res => {
          if (res.data.data) {
            this.categoryResponse = res.data.data;
          }
        })
    }
  }

  cancel() {
    if (this.redirectUrl !== null && this.redirectUrl !== undefined) {
      this.router.navigate([this.redirectUrl], { state: { pId: this.parentCatId } });
    }
    else {
      this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_SLASH], { state: { pId: this.parentCatId } });
    }
  }

  addNewCategory() {
    this.categoryForm.get('_Paxcom_OrganizationId').setValue(this.orgId.toString());


    if (this.parentCatId === null || this.parentCatId === undefined) {
      let randomName = this.categoryForm.get('parentCategoryName').value;
      this.getParentId(randomName);
    }
    if (this.parentCatId) {
      this.categoryForm.get('parentCategoryId').setValue(this.parentCatId);
    }

    if (this.categoryForm.get('parentCategoryName').value === "") { //if parentCategoryName gets erased, reset ID too.
      this.categoryForm.get('parentCategoryId').setValue("");
    }

    this._categoryService.postCategory(this.categoryForm.value).subscribe(res => {
      if (res) {
        this.disableActionsButtonsWhileAPICall = false;
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        if (this.noRedirect == false) {
          if (this.parentCatId !== null && this.parentCatId !== undefined && this.parentCatId !== "") {
            this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH + this.parentCatId + RouterLinksEnum.SUB_CATEGORY_SLASH])
          }
          else {
            this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_SLASH]);
          }
        }
        else {
          this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
            this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_CREATE_SLASH], { state: { pName: this.parentCatName, pId: this.parentCatId } }));
        }

        // if (this.buttonName === "save") {
        //   if (this.parentCatId !== null && this.parentCatId !== undefined && this.parentCatId !== "") {
        //     this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST + '/' + this.parentCatId + '/' + RouterLinksEnum.SUB_CATEGORY])
        //   }
        //   else {
        //     this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST]);
        //   }
        // }
        // else if (this.buttonName === "saveContinue") {
        //   this.router.navigateByUrl('/admin', { skipLocationChange: true }).then(() =>
        //     this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_CREATE], { state: { pName: this.parentCatName ,pId:this.parentCatId} }));
        // }
      }
      //this.router.navigate(['admin/roles']);
    }, err => {
      this.disableActionsButtonsWhileAPICall = false;
    });
  }

  updateCategory() {
    this.categoryForm.get('_Paxcom_OrganizationId').setValue(this.orgId.toString());
    if (this.categoryForm.get('parentCategoryName').value === "") {
      this.categoryForm.get('parentCategoryId').setValue("");
    }
    if (this.parentCatId === null || this.parentCatId === undefined || this.parentCatId === "") {
      let randomName = this.categoryForm.get('parentCategoryName').value;
      if (randomName) {
        this.getParentId(randomName);
        this.categoryForm.get('parentCategoryId').setValue(this.parentCatId)
      }
    }
    this._categoryService.putCategory(this.categoryForm.value).subscribe(res => {
      if (res) {
        this.disableActionsButtonsWhileAPICall = false;
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        if (this.parentCatId !== null && this.parentCatId !== undefined && this.parentCatId !== "") {
          this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_DOUBLE_SLASH + this.parentCatId + RouterLinksEnum.SUB_CATEGORY_SLASH]);
          // this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST + '/' + this.parentCatId + '/' + RouterLinksEnum.SUB_CATEGORY], { state: { search: this.search } });
        }
        else {
          this.router.navigate([RouterLinksEnum.ADMIN + RouterLinksEnum.CATEGORY_LIST_SLASH]);
          // this.router.navigate(['admin/' + RouterLinksEnum.CATEGORY_LIST], { state: { search: this.search } });
        }
      }
      //this.router.navigate(['admin/category-list']);
    }, err => {
      this.disableActionsButtonsWhileAPICall = false;
    });
  }

  onFormSubmit(): void {

    this.formSubmit = true;
    if (this.categoryForm.invalid) {
      this.scrollingToErrorsService.scrollToError();
      return;
    }
    this.disableActionsButtonsWhileAPICall = true;
    this.buttonName = document.activeElement.getAttribute("name");
    if (this.editRes) {
      if (this.editRes['id']) {
        this.updateCategory();
      }
      else {
        this.addNewCategory();
      }
    }
    else {
      this.addNewCategory();
    }
    // if (this.buttonName === "save") {
    //   this.addNewCategory();
    //   // this.categoryForm.setValue({ parent: "", category: "", description:""})
    // }
    // else if (this.buttonName === "saveContinue") {
    //   this.addNewCategory();
    // }
    // else if (this.buttonName === "update") {
    //   this.updateCategory();
    // }
  }


  get parent() {
    return this.categoryForm.get('parentCategoryName');
  }

  get category() {
    return this.categoryForm.get('name');
  }
  get description() {
    return this.categoryForm.get('description');
  }

  getParentId(pName: string) {

    for (let i = 0; i < this.response.length; i++) {
      if (this.response[i].name.toLowerCase() === pName.toLowerCase()) {
        this.parentCatId = this.response[i].id;
        break;
      }
      else {
        this.parentCatId = pName;
      }
    }

    return this.parentCatId;
  }

  getParentName(pId: string) {
    this.parentCatName = '';
    for (let i = 0; i < this.response.length; i++) {
      if (this.response[i].id === pId) {
        this.parentCatName = this.response[i].fullHierarchicalName;
        this.categoryForm.get('parentCategoryName').setValue(this.parentCatName)
        break;
      }
    }
    return this.parentCatName;
  }


  getAllCategories(): Observable<any> {

    return this._categoryService.getAllCategories("organizationId=" + this.orgId + "&pageSize=-1")
      .pipe(map(res => {
        if (res.data.data) {
          return this.response = res.data.data;
        }
      }));
  }

  getCategoryDetailsById() {
    this._categoryService.getCategoriesById(this.categoryId + "?organizationId=" + this.orgId)
      .subscribe(res => {
        if (res) {
          this.editRes = res.data as CategoryModel[];
          this.parentCatId = res.data.parentCategoryId;
          this.activeStatus = res.data.active;
          this.categoryForm.patchValue(this.editRes);
          if (res.data['imageURI'] !== null || res.data['imageURI'] === "") {
            this.isButtonVisible = false;
            this.imgURL = res.data['imageURI'];

          }
          else {
            this.isButtonVisible = true;
          }
          if (this.parentCatId !== "" && this.parentCatId !== null) {
            this.getParentName(this.parentCatId)
            this.categoryForm.get('parentCategoryName').setValue(this.parentCatName);
          }
        }

      });
  }
  preview(files) {
    this.deleteImageStatus = false;
    this.deleteImageUrl = null;
    if (files.length === 0)
      return;
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      let message = "Only images are supported.";
      this.CustomMessage.ShowCustomMessage(message, false);
      this.isButtonVisible = true;
      return;
    }

    this.uploadFile(files);
  }
  uploadFile(formFile: File) {
    const formData = new FormData;
    formData.append("formFile", formFile[0], formFile[0].name);
    this.assetService.uploadMedia(formData).subscribe(res => {
      if (res) {
        this.imgURL = res.data['fileUrl'];
        this.isButtonVisible = false;
        this.categoryForm.controls['imageURI'].setValue(res.data['fileUrl']);
        this.categoryForm.controls['smallImageURI'].setValue(res.data['smallThumbnailUrl']);
        this.categoryForm.controls['mediumImageURI'].setValue(res.data['mediumThumbnailUrl']);
        this.categoryForm.controls['bigImageURI'].setValue(res.data['largeThumbnailUrl']);
      }
    })

  }
  deleteImage(url, $event) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: messages.delete, isError: false, buttonName: 'Ok' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.deleteImageStatus = true;
        this.deleteImageUrl = url;
        this.imgURL = null;
        this.isButtonVisible = true;
        this.categoryForm.controls['imageURI'].setValue(null);
        this.categoryForm.controls['smallImageURI'].setValue(null);
        this.categoryForm.controls['mediumImageURI'].setValue(null);
        this.categoryForm.controls['bigImageURI'].setValue(null);
      }
    })
    return false;
  }
  openChat() {
    this.chatDialogService.openChatDialog(PIMEntityType.Category, this.categoryId, this.editRes['name']);
  }
  openHelpDialog(featureId) {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }
  openHistory() {
    this.historyDialogService.openHistoryDialog(PIMEntityType.Category, this.categoryId, this.editRes['name']);
  }
  onSaveAndNoRedirect() {
    this.noRedirect = true;
  }
  onSaveAndRedirect() {
    this.noRedirect = false;
  }
  showHideSetting() {
    this.showSetting = !this.showSetting;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }
}
