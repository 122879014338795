import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { messages } from '../Constants/messageResource';

@Component({
  selector: 'app-no-items-found',
  templateUrl: './no-items-found.component.html',
  styleUrls: ['./no-items-found.component.scss']
})
export class NoItemsFoundComponent implements OnInit {
  @Input() pageType:string;
  @Input() searchOrNoResult:string="notFound"||"searchNotFound"||"noResultFound";
  @Input() desc:string;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  notFoundTitle:string=messages.notFoundTitle;
  title:string;
  image:string;
  constructor() { }

  ngOnInit() {
  }
  ngDoCheck(){
    this.generateText();

  }
  generateText(){
    if(this.searchOrNoResult==="searchNotFound"){
      this.title = "";
      this.image="404%20Page%20Not%20Found%20_Monochromatic%201.png"
    }
    else if(this.searchOrNoResult==="noResultFound"){
      this.title = this.replaceText(this.notFoundTitle,this.pageType);
      this.desc = this.replaceText(this.desc,this.pageType.toLowerCase());
      this.image="Group%2077.png";
    }
    else{
      this.title="Sorry! No result found";
      this.desc="";
      this.image="no-result-found.png";
    }
  }
  replaceText(lineReplaced,word){ return lineReplaced.replaceAll("{}",word); }
}
