export enum ApplicationIdEnum{
    Organization = 1,
    Authorization,
    PIM,
    WMS,
    OMS,
    Estore = 9

}

export enum SignalREntityType
    {
        Order = 1
    }


   export enum SignalROperationType
    {
        Created = 1,
        Updated,
        Deleted,
        DownloadStarted,
        DownloadFinished,
        Received,
        Cancelled,
        StatusUpdated
    }