import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  themeSubscription:Subscription;
  themeComponentName:string;
    constructor(private commonService:CommonService) {
      this.themeSubscription = this.commonService.getComponentData.subscribe(res=>{
        this.themeComponentName = res['resources'];
      })
     }
  
    ngOnInit() {
    }
  
    ngOnDestroy(): void {
      this.themeSubscription.unsubscribe();
    }
}
