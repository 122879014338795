import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomMessageService } from "src/app/core/custommessageservice";
import { ConfirmationDialogComponent } from "src/app/shared/dialogs/confirmation/confirmation.dialog.component";
import { CommonService } from "../../services/Common/common.service";

@Component({
    selector: 'app-add-channelwise-product-data.dialog',
    templateUrl: './add-channelwise-product-data.dialog.html',
    styleUrls: ['./add-channelwise-product-data.dialog.css']
  })
  export class AddChannelWiseProductDataDialogComponent implements OnInit {
    title:string="Add";
    formSubmit = false;
    disableChannelDropDown:boolean=false;
    productForm:FormGroup;
    bulletPointMaxLength: number = 2000;
    channelData=[];
      constructor(public dialogRef: MatDialogRef<AddChannelWiseProductDataDialogComponent>,public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder,private commonService: CommonService,
        private customMessageService: CustomMessageService
        ){
            this.dialogRef.disableClose=true;

      }
      ngOnInit(){
        this.productForm = this.formBuilder.group({
            channelId: ['',[Validators.required]],
            channelName: [''],
            productCode: [''],
            productChannelTitle: [''],
            bulletPoints: this.formBuilder.array([this.getBulletPointFormBuilder()]),
            description: [''],
        })
        if(this.data && this.data.channelData){
          this.channelData = this.data.channelData
        }else{
          this.title="Edit"
          let requestParameter = "?pageSize=-1";
          this.commonService.getChannels(requestParameter).subscribe(res => {
            this.channelData = res['data'].data
            if(this.data && this.data.channelProductCodes){
              this.patchingFormValues();
              if(this.productForm.get("channelId").value){
                  this.disableChannelDropDown=true;
              }
          }
          })
        }
        
      }
      getBulletPointFormBuilder(): FormGroup {
        return this.formBuilder.group({
          name: ['', [Validators.maxLength(this.bulletPointMaxLength)]]
        });
      }
      cancel(){
          this.dialogRef.close();
      }
      addChannelWiseProductData(){
        this.formSubmit=true;
        if(this.productForm.invalid){return false}
        let checkForValue = false;
        if(this.productForm.get('productCode').value || this.productForm.get('productChannelTitle').value
        || this.productForm.get('description').value || this.checkForBulletPoints()
        ){checkForValue=true}
        if(checkForValue){
          this.dialogRef.close({data:this.productForm})
        }else{
          this.customMessageService.showValidationMessage("Please provide any of the above information",true);
        }
      }

      checkForBulletPoints(){
        if(this.productForm.get('bulletPoints').value.length>=1){
          return this.productForm.get('bulletPoints').value[0]['name']!='' ? true : false 
        }
        return false
      }
      onChannelChange(e){
          this.channelData.forEach(channel=>{
              if(channel.id == e.value){
                  this.productForm.get('channelName').setValue(channel.name);
              }
          })
      }
      currentTextAreaLength() {
        let bulletPointhtml = document.getElementById('bulletPoints');
        if (bulletPointhtml && bulletPointhtml['value'].length > this.bulletPointMaxLength) {
          return true;
        }
        return false;
      }
      patchingFormValues(){
        const channelId=this.data.channelProductCodes.get('channelId').value;
        const channelName=this.data.channelProductCodes.get('channelName').value;
        const productCode=this.data.channelProductCodes.get('productCode').value;
        const productChannelTitle=this.data.channelProductCodes.get('productChannelTitle').value;
        const bulletPoints = this.data.channelProductCodes.get('bulletPoints') as FormArray;
        const description=this.data.channelProductCodes.get('description').value;
        this.productForm.get("channelId").setValue(channelId);
        this.productForm.get("channelName").setValue(channelName);
        this.productForm.get("productCode").setValue(productCode);
        this.productForm.get("productChannelTitle").setValue(productChannelTitle);
        this.productForm.get('description').setValue(description);
        let productFormBulletArray=this.productForm.get('bulletPoints') as FormArray;
        productFormBulletArray.patchValue(this.data.channelProductCodes.value.bulletPoints);
        
      }
 
   
  }