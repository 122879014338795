import { Component, OnInit, Input, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MdcDrawer } from '@angular-mdc/web';
import { AuthService } from '../../core/Authorization/auth.service';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../../core/Common/session-storage.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../core/loader/loader.service';
import { CommonService } from '../../core/Common/common.service';
import { ApplicationModel } from '../models/authorization/application-model';
import { AppConstants } from '../../constants/global';
import { Subject, Subscription, timer } from 'rxjs';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { SignalRConnectionService } from 'src/app/core/common_services/signalR_service/signal-rconnection.service';
import { notificationModel } from '../models/notification/notifcation';
import { RouterLinksEnum } from 'src/app/modules/order/shared/router-links/router-links.enum';
import { ApplicationIdEnum, SignalREntityType, SignalROperationType } from '../enum/applicationEnum';

import { GetSetSessionStorageService } from 'src/app/modules/shop/core/Common/get-set-session-storage.service';
import { RouteUrl } from 'src/app/modules/shop/constants/RouteUrls';
import { takeUntil } from 'rxjs/operators';
import { messages } from '../Constants/messageResource';
import { CommonService as OrderCommonService } from 'src/app/modules/order/core/Common/common.service';
import { RouterLinksEnum as GlaptoRouterLinksEnum } from '../../modules/glapto/shared/router-links/router-links.enum'
import { OrderStatusEnum } from 'src/app/modules/shop/ENUM/orderStatus';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  messages = messages;
  matcher: MediaQueryList;
  applicationdetail = [];
  appTitle: string = environment.APP_TITLE;
  public buttonName: any = 'Show';
  public show: boolean = false;
  isLoader: boolean = false;
  userName: any;
  appId: number;
  orgId: number;
  appName: any;
  orgName: any;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  appLogoUrl: any;
  orgLogoUrl: any;
  jsonAppData: string;
  jsonOrgData: string;
  applicationData: Array<any>;
  organizationData: Array<any>;
  showHoverText: boolean;
  applications: ApplicationModel;
  public showapp: boolean = false;
  showButtonsOnHeader: boolean = false;
  applicationsToDisplayInHeader = environment.applicationsToDisplayInHeader;
  applicationsToDisplayInApplicationsMenuList = environment.applicationsToDisplayInApplicationsMenuList;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  applicationSubscription:Subscription;
  showHideNotification:boolean = false;
  signalRSubscription:Subscription;
  notificationsArray:Array<notificationModel> = [];
  newNotifications:boolean = false;
  bellTimer = timer(0,4000);
  destroy = new Subject();
  stopOrderBellSubscription:Subscription;
  timerInstance:any;
  token:string = (localStorage.getItem('access_token'));
  omnichannelHost = environment.omnichannelHostname;
    constructor(private _router: Router,
    private _authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private orderCommonService:OrderCommonService,
    private deviceDetectService: DeviceDetect,
    private signalRconnection:SignalRConnectionService,private getSetSessionStorageService: GetSetSessionStorageService,
    private cdRef:ChangeDetectorRef) {
      this.notificationsArray = JSON.parse(localStorage.getItem('notificationArray') || '[]');
      this.applicationSubscription = this.commonService.applicationsApiRes.subscribe((res: any) => {
        this.applications = res;
      })
      loaderService.isLoading.subscribe(value => {
        this.isLoader = value;
      });

      // this.stopOrderBellSubscription = this.commonService.orderBellPlayStatus.subscribe(()=>{
      //   this.stopBell();
      // })

  }

  toggleApp() {
    this.showapp = !this.showapp;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.showapp)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

  toggleAppHide() {
    this.showapp = false;
  }

  @Input() appDrawer: MdcDrawer;
  @Input() showMenuIcon: boolean = false;

  ngOnInit() {
    this.signalRconnection.connect(`${environment.signalRNotificationUrl}signalrHub/connect?token=${this.token}`,"OrderUpdates");
    
    this.signalRSubscription = this.signalRconnection.signalRUpdatedData.subscribe((data)=>{ 
      let orderStatus = data.serializedInformation ? JSON.parse(decodeURIComponent(data.serializedInformation)).OrderStatus : null;
      if(data.operation == SignalROperationType.Created && orderStatus == OrderStatusEnum.Received){
      data.currentTimeStamp = this.formatCurrentTime(new Date());
      this.notificationsArray.unshift(data);
      this.newNotifications = this.showHideNotification ? false : true;
      this.checkUpdateNotificationArrayLocalStorage();
      this.startBell();
      }
      this.cdRef.detectChanges();
      this.sendOrderUpdatetoOrderList(data);
    })
    
    this.checkScreenSize();
    // this.getAllApllication();
    this.matcher = matchMedia(`(max-width: ${environment.SMALL_WIDTH_BREAKPOINT}px)`);
    if (this.isScreenSmall()) {
      this.appDrawer && (this.appDrawer.open = false);
    }

    this.getLoggedInUserInfo();

    this.organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(AppConstants.ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgName = this.organizationData[0].name;

    if (this.organizationData[0].logoUri) {
      this.orgLogoUrl = decodeURIComponent(this.organizationData[0].logoUri);
    }

    if (this._router.url === "/registration") {
      this.showButtonsOnHeader = false;
    }
    else if (this._router.url !== "/registration") {
      if (this.orgName) {
        this.showButtonsOnHeader = true;
      }
      else {
        this.showButtonsOnHeader = false;
      }
    }
    if (this.orgName.length > 15) {
      this.showHoverText = true;
    }
    else {
      this.showHoverText = false;
    }
  }

  getLoggedInUserInfo() {
    let isLoggedIn = this._authService.isLoggedIn();
    if (sessionStorage.getItem("SessionStorge_UserModel")) {
      let userInfo = JSON.parse(sessionStorage.getItem('SessionStorge_UserModel'));
      this.userName = userInfo.name;
    }
    else {
      if (isLoggedIn) {
        this.sessionStorageService.getloggedinUserinfo().then(res => {
          this.userName = res.name;
        });
      }

    }
  }

  isScreenSmall(): boolean {
    return this.matcher.matches;
  }


  signout() {
    this.sessionStorageService.removeloggedinUserinfo();
    //Clearing local storage value at the time of sign out for some reason was initializing login flow.
    //So clearing this value only after complete logout.    
    // localStorage.removeItem(AppConstants.ORGANIZATION_COOKIE);
    this._authService.Logoff();

  }

  getAllApllication() {
    this.commonService.getApplication().subscribe(res => {
      this.applications = res.data;
    })
  }

  redirectBackToOrg() {
    // this.commonService.removeLocalStorageInOutItems();
    this._authService.redirectToOrg();

  }
  redirectToAccountDetails() {
    let url = environment.identityauthorityUrl + AppConstants.Account_Detail
    window.open(url, '_blank')
  }

  getRedirectQuery() {
    let jsonOrgData = decodeURI(localStorage.getItem(AppConstants.ORGANIZATION_INFO));
    let originalData = JSON.parse(jsonOrgData);
    let organizationDetail =
    {
      id: originalData.id,
      name: originalData.name,
      logoUri: originalData.logoUri,
      userName: originalData.userName,
    }
    let query = encodeURI(JSON.stringify(organizationDetail));
    return query;
  }

  redirectToApp(application) {
    if (application.shortName == 'eStore') {
      let query = this.getRedirectQuery();
      let link = application.applicationUrl + "/?" + environment.shopOrganizationInfo + "=" + query
      window.open(link, '_blank')
    }
    else {
      window.open(application.applicationUrl, "_blank")
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetectService.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetectService.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetectService.currentDevice().desktopScreen;
  }


  redirectToHeaderApp(application) {
    if (application.shortName == "Products") {
      this._router.navigateByUrl('admin/products')
    }
    if (application.shortName == "Orders") {
      this._router.navigateByUrl('admin/orders')
    }
  }


  goToPublicStore(){
    let shopOrgInfo = this.getSetSessionStorageService.getOrgInfoFromSession();
    let enableMenuCardView = shopOrgInfo['enableMenuCardView'] ? shopOrgInfo['enableMenuCardView'] : false;
    if(enableMenuCardView){
      window.open(RouteUrl.Menu,'_blank')
    }
    else{
      window.open(location.origin,'_blank');
    }
    
  }
  
  
  getWrapedText(text){
    return this.commonService.getWrapedText(text,17)
  }

  checkUpdateNotificationArrayLocalStorage(){   
      localStorage.setItem('notificationArray',JSON.stringify(this.notificationsArray));
  }

  redirectToApplicationEntity(notification){
    if (notification.application == ApplicationIdEnum.OMS) {
      this.showHideNotification = false;
      // location.href = RouterLinksEnum.ADMIN;
      if (location.pathname.endsWith('manage-orders')) {
        this.resetOrderTab();
      } else {
        this._router.navigateByUrl(RouterLinksEnum.ADMIN)
      }
    } else {
      return;
    }
  }

  removeAllNotification(){
    this.notificationsArray = [];
    localStorage.removeItem('notificationArray');
  }

  removeSingleNotification(index){
    this.notificationsArray.splice(index,1);
    localStorage.setItem('notificationArray',JSON.stringify(this.notificationsArray));
  }

  sendOrderUpdatetoOrderList(data) {
    if(data && data.entityId && data.entityType ==  SignalREntityType.Order){
      this.commonService.sendNewOrderId(data.entityId);
    //  !this.timerInstance && this.startBell()
    }
  }

  startBell() {
    // let audioPlayer = <HTMLAudioElement>document.getElementById('order_bell');
    // audioPlayer.muted = true;
    let audio = new Audio();
    audio.src = `${this.assetsBaseUrl}assets/Shared/sounds/alarm_bell.mp3`;
    audio.load();
    audio.play();
    // this.timerInstance = this.bellTimer.pipe(takeUntil(this.destroy)).subscribe(val => {
    //   audio.load();
    //   audio.play();
    // });
  }
  
  stopBell(){
    this.timerInstance = null;
    this.destroy.next();
    this.destroy.complete();
    this.destroy = new Subject();
  }

  ngOnDestroy(): void {
    this.applicationSubscription.unsubscribe();
    this.signalRSubscription && this.signalRSubscription.unsubscribe();
    // this.stopOrderBellSubscription &&  this.stopOrderBellSubscription.unsubscribe();
    // this.stopBell();
}

formatCurrentTime(time){
  let date,month;
  date = time.getDate();
  month  = time.toLocaleString('default', { month: 'short' });;
 return `${date} ${month},${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}

resetOrderTab(){
  this.orderCommonService.resetOrderTabonNavigate();
}

outsideClicked(event){
this.showHideNotification = false;
}

redirectToGlapto(){
  let omnichannelHostName = this.omnichannelHost.split('/')[2]
  let url = location.protocol + '//' + omnichannelHostName + GlaptoRouterLinksEnum.GLAPTO_SLASH + GlaptoRouterLinksEnum.HOME ;
  window.open(url,'_self')
}

}

