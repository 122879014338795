import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { CommonService } from '../services/Common/common.service';

@Component({
  selector: 'app-product-list-wrapper',
  templateUrl: './product-list-wrapper.component.html',
  styleUrls: ['./product-list-wrapper.component.scss']
})
export class ProductListWrapperComponent implements OnInit,OnDestroy {
themeSubscription:Subscription;
themeComponentName:string;
  constructor(private commonService:CommonService) {
    this.themeSubscription = this.commonService.getComponentData.subscribe(res=>{
      this.themeComponentName = res['productList'];
    })
   }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }
}
