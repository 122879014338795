export class VariantViewModel {

    id: number;
    name?: string; description?: string;
    active: boolean;
    _Paxcom_OrganizationId: string;
    _Paxcom_DocumentType?: string;
}

export class VariantDTO {
    name: string;
    options: string[];
    description: string;
    active: boolean;
    id: number;
    _Paxcom_OrganizationId : number;
    _Paxcom_DocumentType: number
} 

export class CombinationVariant {
    variantTypeId: number|string;
    variantName: string;
    optionValue: string;
}

export class VariantWithOptions {
    id: string;
    name: string;
    options: string[];
}
