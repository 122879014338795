import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderTotalCalculationService {

  constructor() { }

  //Calculates shopping Cart SubTotal inclTax
  getCartSubTotal(cartInfo) {
    //As per current implementation we are showing orderTotal as order subTotal
    //return this.getCartSubTotalNetAmount(cartInfo) + this.getCartItemsTotalDiscount(cartInfo);
    return this.getCartTotal(cartInfo);
  }

  //The net amount recv from API includes discount also.
  //As a result discount is added to show subtotal excluding discount
  getCartsubTotalExcludingDiscount(cartInfo) {
    return this.getCartSubTotalNetAmount(cartInfo) + this.getCartItemsTotalDiscount(cartInfo);
  }
  //Calculate Net Amount
  private getCartSubTotalNetAmount(cartInfo) {
    if (cartInfo && cartInfo['orderTotals'] && cartInfo['orderTotals'].length > 0) {
      let orderItemsTotal = cartInfo['orderTotals'][0];
      if (orderItemsTotal.netAmount) {
        return orderItemsTotal.netAmount.amount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  //Calculate shopping Cart Total inclTax
  getCartTotal(cartInfo) {
    if (cartInfo && cartInfo['orderTotalAmount']) {
      return cartInfo['orderTotalAmount'].amount;
    } else {
      return 0;
    }
  }

  //Calculate Total Discount
  getCartItemsTotalDiscount(cartInfo) {
    if (cartInfo && cartInfo['orderTotals'] && cartInfo['orderTotals'].length > 0) {
      let orderItemsTotal = cartInfo['orderTotals'][0];
      if (orderItemsTotal.discount) {
        return Math.abs(orderItemsTotal.discount.amount);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  //Calculates Net Shipping Amount
  getNetShippingAmount(cartInfo) {
    if (cartInfo && cartInfo['orderTotals'] && cartInfo['orderTotals'].length > 1) {
      let shippingDetails = cartInfo['orderTotals'][1];
      if (shippingDetails.netAmount) {
        return shippingDetails.netAmount.amount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  //get Shipping Total Excluding Discount
  //We will add discount in net amount
  getShippingTotalExcludingDiscount(cartInfo) {
    return this.getNetShippingAmount(cartInfo) + this.getShippingDiscount(cartInfo);
  }

  getShippingDiscount(cartInfo) {
    if (cartInfo && cartInfo['orderTotals'] && cartInfo['orderTotals'].length > 1) {
      let shippingDetails = cartInfo['orderTotals'][1];
      if (shippingDetails.discount) {
        return Math.abs(shippingDetails.discount.amount)
      } else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }

}
