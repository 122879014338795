import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { Component, Inject, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { ORGANIZATION_INFO } from '../../shared/constants/global';
import { DataService } from '../../services/data.service';
import { AssetService } from '../../services/Assets/asset.service';
import { AssetCategoryService } from '../../services/asset-category.service';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';


@Component({
  selector: 'app-add-asset.dialog',
  templateUrl: '../../dialogs/addAsset/add-asset.dialog.html',
  styleUrls: ['../../dialogs/addAsset/add-asset.dialog.css']
})
export class AddAssetDialogComponent {
  searchText
  public assetCategory = new MatTableDataSource();
  public assetList = new MatTableDataSource();

  assetListColumns = ['checkbox','icon', 'name', 'updatedOn', 'updatedBy', 'size'];
  assetCategoryColumn = ['icon', 'category', 'updatedOn', 'updatedBy', 'size']
  //public dataSource = new MatTableDataSource<Comment>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  organizationData: any[];
  jsonOrgData: string;
  orgId: any;
  isViewChildCategory: boolean;
  hideTable: boolean;
  response: any;
  newData: any;
  token: any;
  hideButton: boolean = true;
  categoryId: any;
  showfullHierarchicalName: boolean;
  parentCategoryName: any;
  parentDescription: any;
  activeParent: any;
  childCount: any;
  dataSource: any;
  childData: any;
  disableInsert: boolean = true
  selectedAssetObject: any;
  selectedIndex: any;
  selectedFolder: any;
  selectedFolderId: any;
  selected: boolean = false;
  prList = {}
  selectedFolderName = 'Root';
  selection = new SelectionModel<any>(true, []);
  parentNodes: any = [];
  constructor(public dialogRef: MatDialogRef<AddAssetDialogComponent>, private _assetCategoryService: AssetCategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dataService: DataService, private assetService: AssetService, private CustomMessage: CustomMessageService) {

    this.rootView();

  }

  rootView() {
    this.selectedFolder = null;
    this.selectedIndex = null;
    this.dialogRef.disableClose = true;
    this.organizationData = [];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId = this.organizationData[0].id;
    // this.getAllAsset();
    this.isViewChildCategory = false;
    this.loadBaseCategories();
  }

  // getAllAsset()
  // {
  //   this.assetService.getAllAssets().subscribe(res=>{
  //     this.assetList.data = res['data'].data;
  //   })
  // }
  selectedParentFolder(id, name) {
    this.prList = {};
    this.prList[id] = true;
    this.selectedFolderName = name;
    this.selectedFolderId = id;
  }


  move() {
    let parentCategoryId;
    let folderId = this.data.element['id'];
    if (Object.keys(this.prList).length < 1) {
      parentCategoryId = this.categoryId;
      this.data.element['parentCategoryId'] = parentCategoryId;
    }
    else {
      parentCategoryId = this.selectedFolderId;
      this.data.element['parentCategoryId'] = parentCategoryId;
    }
    if (parentCategoryId === undefined || parentCategoryId === null) {
      this.data.element['parentCategoryId'] = "";
    }
    if (parentCategoryId === folderId) {
      this.CustomMessage.ShowCustomMessage(messages.folderNotSameDestination, false);
      return;
    }
    this._assetCategoryService.putCategory(this.data.element).subscribe(res => {
      if (res) {
        this.CustomMessage.ShowCustomMessage(res.statusMessage, true);
        this.dialogRef.close("success");
      }
    });
  }


  loadBaseCategories() {
    this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&pageSize=-1")
      .subscribe(res => {
        if (res.data.data.length > 0) {
          // this.hideTable = false;
          if(this.data.action==='moveFolder'){
            let data: [] = res.data.data;
            this.assetCategory.data = data.filter(d => {
              return d['id'] !== this.data.element['id']
            })
            this.response = this.assetCategory.data;
          }
          else{
            this.assetCategory.data= res.data.data;
            this.response = this.assetCategory.data;
          }
          
          // this.newData = res.data.data;
          // this.token = res.data.paging.continuationToken;
          // if (this.token !== null) {
          //   this.hideButton = false;
          // }
          // else {
          //   this.hideButton = true;
          // }
        }
        else {
          // this.hideTable = true;
          this.CustomMessage.ShowCustomMessage(messages.noRecordFound, true);
        }
      });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDelete(): void {
  }

  goToSearch() {
    if (this.searchText) {
      this.assetCategorySearch(this.searchText);
      if (this.data.action !== 'moveFolder') {
        this.assetSearch(this.searchText);
      }
    }
    else {
      return
    }
  }

  insert(assetObject?) {
    if (assetObject) {
      this.selectedAssetObject = assetObject;

    }
    let selectAsset = []

    for(let i=0;i<this.selection.selected.length;i++)
    {
      selectAsset.push(this.selection.selected[i])
    }
    // return;
    this.dialogRef.close(selectAsset);
  }

  selectedAsset(assetObject, i) {
    this.selectedIndex = i;
    this.disableInsert = false;
    this.selectedAssetObject = assetObject;
  }
  getAssetById(id) {
    let requestParameter = "";
    if (id) {
      requestParameter = "&AssetCategoryId=" + id + "&organizationId=" + this.orgId;
    }


    requestParameter = requestParameter.substring(1);
    requestParameter = "?" + requestParameter;

    this.assetService.getAllAssets(requestParameter).subscribe(res => {
      this.assetList.data = res['data'].data;
      this.newData = res.data.data;
      this.token = res.data.paging.continuationToken;
      if (this.token !== null) {
        this.hideButton = false;
      }
      else {
        this.hideButton = true;
      }
    })
  }

  goToRootLevel() {
    this.parentNodes = [];
    this.assetList.data = [];
    this.searchText = null;
    this.hideButton = true;
    this.disableInsert = true;
    this.selection.clear();
    this.rootView();
  }

  viewChildCategory(element, i) {
    this.selection.clear();
    this.prList = {};
    this.selectedIndex = null;
    this.selectedFolderName = element.name;
    this.categoryId = element.id;
    this.selectedFolder = i;
    this.disableInsert = true;
    if (this.data.action !== "moveFolder") {
      this.getAssetById(element.id)
    }
    this.isViewChildCategory = true;
    this.showfullHierarchicalName = false;
    this.searchText = null;
    this._assetCategoryService.getCategoriesById(this.categoryId + "?organizationId=" + this.orgId + "&pageSize=-1").subscribe(res => {
      if (res.data !== null) {
        this.parentNodes = res.data.parentNodes;
        this.parentCategoryName = res.data.fullHierarchicalName;
        this.parentDescription = res.data.description;
        this.activeParent = res.data.active;
        this.childCount = res.data.childCount;
        this._assetCategoryService.getChildCategories("organizationId=" + this.orgId + "&parentCategoryId=" + this.categoryId + "&pageSize=-1")
          .subscribe(res => {
            this.selectedFolder = null;
            this.selectedIndex = null;
            if (res.data.data.length > 0) {
              // this.hideTable = false;
              if(this.data.action==='moveFolder'){
                let data: [] = res.data.data;
                this.assetCategory.data = data.filter(d => {
                  return d['id'] !== this.data.element['id']
                })
                this.childData = this.assetCategory.data;
              }
              else{
                this.assetCategory.data=res.data.data;
                this.childData = this.assetCategory.data;
              }
              // this.token = res.data.paging.continuationToken;
              // if (this.token !== null) {
              //   this.hideButton = false;
              // }
              // else {
              //   this.hideButton = true;
              // }
            }
            else {
              // this.hideButton = true;
              this.assetCategory.data = [];
            }
          })
      }
    })
  }

  assetCategorySearch(searchText) {
    this.hideButton = true;
    if (searchText !== "" && searchText !== undefined && searchText !== null) {
      this.showfullHierarchicalName = true;
      this.assetCategory.data = [];
      this._assetCategoryService.getAllCategories("organizationId=" + this.orgId + "&searchText=" + searchText + "&pageSize=-1")
        .subscribe(res => {
          this.selectedFolder = null;
          this.selectedIndex = null;
          if (res.data.data.length > 0) {
            if(this.data.action==='moveFolder'){
              let data: [] = res.data.data;
              this.assetCategory.data = data.filter(d => {
                return d['id'] !== this.data.element['id']
              })
            }
            else{
              this.assetCategory.data=res.data.data
            }
            // this.token = res.data.paging.continuationToken;
            // if (this.token !== null) {
            //   this.hideButton = false;
            // }
            // else {
            //   // this.CustomMessage.ShowCustomMessage("No record found", true);
            //   this.hideButton = true;
            // }
            //this.searchService.storeSearchData(searchText, this.dataSource.data)
          }
          else {
            // this.hideButton = true;
            this.assetCategory.data = [];
          }
        });
    }
  }


  getMoreData() {
    if (this.token !== null) {
      this.hideButton = false;
      this.callApiAgain(this.token)
    }
  }

  callApiAgain(token) {
    let param;
    if (token !== null || token !== undefined) {
      if (this.searchText) {
        param = "?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token) + "&FullText=" + this.searchText;
        this.assetService.getAllAssets(param).subscribe(res => {
          this.assetList.data = res['data'].data;
          for (let i = 0; i < res.data.data.length; i++) {
            this.newData.push(res.data.data[i]);
          }
          this.token = res.data.paging.continuationToken;
          if (this.token) {
            this.hideButton = false;
          }
          else {
            this.hideButton = true;
          }
          this.assetList.data = this.newData;

        })
      }
      else {
        param = "?organizationId=" + this.orgId + "&continuationToken=" + encodeURIComponent(token) + "&AssetCategoryId=" + this.categoryId;
        this.assetService.getAssetById(param)
          .subscribe(res => {
            if (res.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                this.newData.push(res.data.data[i]);
              }
              this.token = res.data.paging.continuationToken;
              if (this.token) {
                this.hideButton = false;
              }
              else {
                this.hideButton = true;
              }
              this.assetList.data = this.newData;
            }
          })
      }

    }
  }



  assetSearch(searchText) {
    let requestParameter = "";
    if (searchText) {
      requestParameter = "&FullText=" + searchText + "&organizationId=" + this.orgId;
    }


    requestParameter = requestParameter.substring(1);
    requestParameter = "?" + requestParameter;
    this.assetService.getAllAssets(requestParameter).subscribe(res => {
      this.assetList.data = res['data'].data;
      this.newData = res.data.data;
      this.token = res.data.paging.continuationToken;
      if (this.token !== null) {
        this.hideButton = false;
      }
      else {
        this.hideButton = true;
      }
    })
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRowsMinusExcluded = this.assetList.data.length;
    return numSelected === numRowsMinusExcluded;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));


  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
redirectToParent(id,name)
{
  let obj =  {
    name : name,
    id : id
  }
  this.viewChildCategory(obj,0)
}
}