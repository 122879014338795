import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    // dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
}
export default MY_FORMATS
@Injectable({
    providedIn: 'root'
})

export class CustomDateService {
    constructor(private datePipe: DatePipe) {
    }
      calculatedCustomDate(dateType) {
      let orderFromDate;
      if(dateType == 1)
      {
        return orderFromDate = new Date(new Date().setDate(new Date().getDate() - 7))
      }
      if(dateType == 2)
      {
        return dateType = this.addMonths(new Date(),-1);
      }
      if(dateType == 3)
      {
        return orderFromDate = this.addMonths(new Date(),-6);
      }  
    }
    addMonths(date, months) {
      date.setMonth(date.getMonth() + months);
      return date;
    }
    customDateFormat(date)
    {
       return this.datePipe.transform(date, 'dd-MM-yyyy')
      //  return this.datePipe.transform(date, 'MM/dd/yyyy')
    }
    customDateAndTimeFormat(date)
    {
       return this.datePipe.transform(date, 'dd-MM-yyyy,h:mm:ss a')
      //  return this.datePipe.transform(date, 'MM/dd/yyyy,h:mm:ss a')
    }
}
