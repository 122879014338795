
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from './services/data.service';
import { BrandsService } from './services/Brands/brands.service';
import { HighlightModule } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { InoutMenuComponent } from './inout-menu/inout-menu.component';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { CreateNewProductDefaultComponent } from './themes/create-product/create-product-desktop/create-new-product-default/create-new-product-default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateNewCategoryComponent } from './create-new-category/create-new-category.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { DeleteDialogComponent } from './dialogs/delete/delete.dialog.component';
import { ChatComponent } from './shared/chat-dialog/chat.dialog.component';
import { HelpGuideComponent } from './shared/help-guide/helpGuide.dialog.component';
import { SuccessMessageComponent } from './shared/Modals/successmessage.component';
import { ChartsModule } from 'ng2-charts';
import { BrandListComponent } from './brand-list/brand-list.component';
import { AddNewBrandComponent } from './add-new-brand/add-new-brand.component';
import { VariantsListComponent } from './variants-list/variants-list.component';
import { AddNewVariantComponent } from './add-new-variant/add-new-variant.component';
import { ImageguidelinesComponent } from './imageguidelines/imageguidelines.component';
import { ImageDialogComponent } from './dialogs/image-dialog/image-dialog.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { WarningDialogComponent } from './dialogs/warning/warning.dialog.component';
import { ReplaceWithNameDirective } from './replace-with-name.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ImportDialogComponent } from './dialogs/import-files/import.dialog.component';
import { HistoryService } from './services/History/history.service';
import { HistoryDialogComponent } from './shared/history/history.dialog.component';
import { CategoryAssetComponent } from './category-asset/category-asset.component';
import { CreateCategoryAssetComponent } from './create-category-asset/create-category-asset.component';
import { AddAssetDialogComponent } from './dialogs/addAsset/add-asset.dialog.component';
import { AddApprovalCommnetDialogComponent } from './dialogs/approval-comment/add-approval-commnent.dialog.component';
import { AssetsComponent } from './assets/assets.component';
import { CreateAssetComponent } from './create-asset/create-asset.component';
import { AddFolderDialogComponent } from './dialogs/add-folder/add-folder.dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BlobStorageService } from './azure-storage/blob-storage.service';
import { DragDirective } from './shared/directives/dragDrop.directive';

import { ChannelComponent } from './shared/selectChannel/channel.dialog.component';
import { BulkUpdateComponent } from './shared/bulk-update/bulkUpdate.dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { CustomDateService } from './services/Common/customDateService';
import { DatePipe } from '@angular/common';
import { ResourcesComponent } from './resources/resources.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { environment } from '../environments/environment';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { CloneMatserCategoriesDialogComponent } from './dialogs/clone-master-categories/clone-master-categories.dialog.component';
import { PipesModule } from 'src/app/shared/modules/pipes.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeDialogComponent } from './dialogs/barcode-dialog/barcode-dialog.component';
import { StickySearchDirectiveModule } from './shared/directives/sticky-search.module';
import { NoItemsModule } from 'src/app/shared/modules/No-Item.module';
import { DynamicPimThemeLoadService } from './services/Common/dynamic-pim-theme-load.service';
import { ProductListWrapperComponent } from './product-list/product-list-wrapper.component';
import { DesktopProductListDefaultComponent } from './themes/product-list/product-list-desktop/desktop-product-list-default/desktop-product-list-default.component';
import { MobileProductListDefaultComponent } from './themes/product-list/product-list-mobile/mobile-product-list-default/mobile-product-list-default.component';
import { CategoryListDefaultComponent } from './themes/category-list/category-list-default/category-list-default.component';
import { CreateNewCategoryDefaultComponent } from './themes/add-category/create-new-category-default/create-new-category-default.component';
import { SettingsDefaultComponent } from './themes/settings/settings-default/settings-default.component';
import { ResourcesDefaultComponent } from './themes/resources/resources-default/resources-default.component';
import { AddNewBrandDefaultComponent } from './themes/add-new-brand/add-new-brand-default/add-new-brand-default.component';
import { BrandListDefaultComponent } from './themes/brand-list/brand-list-default/brand-list-default.component';
import { VariantsListDefaultComponent } from './themes/variants-list/variants-list-default/variants-list-default.component';
import { AddNewVariantDefaultComponent } from './themes/add-new-variant/add-new-variant-default/add-new-variant-default.component';
import { AddNewProductComponent } from './add-new-product/add-new-product.component';
// import { CreateNewProductMobileDefaultComponent } from './create-new-product-mobile/create-new-product-mobile.component';
import { DashboardDefaultComponent } from './themes/dashboard/dashboard-default/dashboard-default.component';
import { CreateAssetDefaultComponent } from './themes/create_asset/create-asset-default/create-asset-default.component';
import { CategoryAssetDefaultComponent } from './themes/category-asset/category-asset-default/category-asset-default.component';
import { AssetsDefaultComponent } from './themes/assets/assets-default/assets-default.component';
import { InoutMenuDefaultComponent } from './themes/inout-menu/inout-menu-default/inout-menu-default.component';
import { CreateNewProductMobileDefaultComponent } from './themes/create-product/create-product-mobile/create-new-product-mobile-default/create-new-product-mobile-default.component';
import { environment } from 'src/environments/environment';
import { PIMRoutingModule } from './pim-routing.module';
import { CommonModule } from '@angular/common';
import { AddBrandDialogComponent } from './dialogs/add-brand/add-brand.dialog.component';
import { HistoryDialogService } from './shared/histotyDialogOpenService/historyDialog';
import { ChatDialogService } from './shared/chatDialogOpenService/chatDialog';
import { AddProductDialogComponent } from './dialogs/add-product/add-product.dialog.component';
import { ProductListCardviewComponent } from './themes/product-list/product-list-desktop/product-list-cardview/product-list-cardview.component';
import { AddCategoryDialogComponent } from './dialogs/add-category/add-category.dialog.component';
import { AddChannelWiseProductDataDialogComponent } from './dialogs/add-channelwise-product-data/add-channelwise-product-data.dialog.component';
// import { ProductListCardviewComponent } from './themes/product-list/product-list-cardview/product-list-cardview.component';


export function hljsLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml }
  ];
}
// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return environment.authStorage;
}
const providers = [DynamicPimThemeLoadService,DataService, BrandsService, BlobStorageService, HistoryService, CustomDateService,
   DatePipe,HistoryDialogService,ChatDialogService,
  { provide: OAuthStorage, useFactory: storageFactory }
]
@NgModule({
  declarations: [
    InoutMenuComponent,
    ImportDialogComponent,
    DeleteDialogComponent,
    ChatComponent,
    ChannelComponent,
    BulkUpdateComponent,
    HelpGuideComponent,
    SuccessMessageComponent,
    CreateNewProductDefaultComponent,
    DashboardComponent,
    BrandListComponent,
    AddNewBrandComponent,
    DashboardComponent,
    CreateNewCategoryComponent,
    CategoryListComponent,
    VariantsListComponent,
    AddNewVariantComponent,
    ImageguidelinesComponent,
    ImageDialogComponent,
    ClickOutsideDirective,
    WarningDialogComponent,
    ReplaceWithNameDirective,
    HistoryDialogComponent,
    CategoryAssetComponent,
    CreateCategoryAssetComponent,
    AddAssetDialogComponent,
    AddApprovalCommnetDialogComponent,
    AssetsComponent,
    CreateAssetComponent,
    AddFolderDialogComponent,
    AddBrandDialogComponent,
    AddCategoryDialogComponent,
    DragDirective,
    SettingsComponent,
    ResourcesComponent,
    CloneMatserCategoriesDialogComponent,
    BarcodeDialogComponent,
    ProductListWrapperComponent,
    DesktopProductListDefaultComponent,
    MobileProductListDefaultComponent,
    CategoryListDefaultComponent,
    CreateNewCategoryDefaultComponent,
    SettingsDefaultComponent,
    ResourcesDefaultComponent,
    AddNewBrandDefaultComponent,
    BrandListDefaultComponent,
    VariantsListDefaultComponent,
    AddNewVariantDefaultComponent,
    ProductListCardviewComponent,
    // MobileProductListComponent,
    // ProductListContainerComponent,
    // DesktopProductListComponent,
    AddNewProductComponent,
    CreateNewProductMobileDefaultComponent,
    DashboardDefaultComponent,
    CreateAssetDefaultComponent,
    CategoryAssetDefaultComponent,
    AssetsDefaultComponent,
    InoutMenuDefaultComponent,
    AddProductDialogComponent,
    AddChannelWiseProductDataDialogComponent
  ],
  imports: [
    PIMRoutingModule,
    HttpClientModule,
    ZXingScannerModule,
    ScrollingModule,
    MaterialModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    DirectivesModule,
    AngularEditorModule,
    PipesModule,
    HighlightModule.forRoot({ languages: hljsLanguages }),
    StickySearchDirectiveModule,
    NoItemsModule,
    CommonModule
  ],
  entryComponents: [
    SuccessMessageComponent,
    DeleteDialogComponent,
    BarcodeDialogComponent,
    ChatComponent,
    ChannelComponent,
    BulkUpdateComponent,
    HelpGuideComponent,
    ImageDialogComponent,
    WarningDialogComponent,
    ImportDialogComponent,
    HistoryDialogComponent,
    AddAssetDialogComponent,
    AddApprovalCommnetDialogComponent,
    AddFolderDialogComponent,
    AddBrandDialogComponent,
    AddCategoryDialogComponent,
    CloneMatserCategoriesDialogComponent,
    AddProductDialogComponent,
    AddChannelWiseProductDataDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: providers,
  exports: []
})
export class PIMModule { }
