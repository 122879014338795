import { Injectable } from '@angular/core';
import { HttpHelperService } from '../Common/http-helper.service';
import { BaseUserModel } from 'src/app/shared/models/authorization/user-model';

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {

  _url = "whoami";
  constructor(private httpHelper: HttpHelperService) { }

  getUserStatus(): Promise<BaseUserModel> {
      return this.httpHelper.getData(this._url).toPromise();
  }
}
