import { NgModule } from '@angular/core';
import { NoItemsFoundComponent } from '../no-items-found/no-items-found.component';
import { CommonModule } from '@angular/common';



@NgModule({
    declarations: [NoItemsFoundComponent],
    exports: [NoItemsFoundComponent],
    imports: [
       CommonModule
    ]
})
export class NoItemsModule { }