import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../Authorization/auth.service";
import { CommonService } from '../Common/common.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterLinksEnum } from "src/app/modules/glapto/shared/router-links/router-links.enum";

@Injectable({
    providedIn: 'root'
})
export class AdminLoginGuardService implements CanActivate {
    isLoggedIn: boolean;
    constructor(private _authService: AuthService, private commonService: CommonService,
        private router: Router) { }
    canActivate(): Observable<boolean> | boolean {
        

        if (location.origin == environment.omnichannelHostname) 
        { //on.paxcom.ai
            if (this._authService.isLoggedIn()) {
                this.router.navigateByUrl(RouterLinksEnum.GLAPTO_SLASH + RouterLinksEnum.HOME);
                //   this.router.navigateByUrl('onBoard/storeSelection')
                  return false;
            }
            else {
                // this._authService.Authorize();
                return true;              
            }
        } 
        else 
        {
            return true
        }
    }
}