import { Injectable } from '@angular/core';
import { HttpHelperService } from '../services/Common/http-helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SASTokenService {

  //--------6/21/2021 Below URLS are Refactored-------------
  constructor(      private httpHelper: HttpHelperService,
    private httpClient: HttpClient) { }

  getSASToken(data) : Observable<any>{

    let url="Assets/accessToken/generate"

    return this.httpHelper.postData(data,url);
  }
  getAssetThumbnail(data) : Observable<any>{

    let url="Assets/thumbnail/generate"

    // let url="Asset/thumbnail/generate"+queryString
    return this.httpHelper.postData(data,url);
  }
}
