import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
    
    @Injectable({ providedIn: 'root' })
    export class UpdateCartService {
        private subjectName = new Subject<any>(); //need to create a subject
    
        updateCart() { //the component that wants to update something, calls this fn
            this.subjectName.next(); //next() will feed the value in Subject
        }
    
        getCart(): Observable<any> { //the receiver component calls this function 
            return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
        }

        getCartTotalQuantity(orderItemArray){
            let itemcount, total = 0;
            itemcount = orderItemArray.reduce((acc, curr) => {
              total = total + curr.quantity;
              return total
            }, 0);
            return itemcount;
        }
    }
    @Injectable({ providedIn: 'root' })
    export class UpdateMiniCartService {
        private subjectName = new Subject<any>(); //need to create a subject
        private productAddedSubject$: Subject<void> = new Subject<void>();
        private productAddedObjectSubject$: Subject<any> = new Subject<any>();
        private cartTotalSubject = new Subject<any>();
        sendUpdate(message: any) { //the component that wants to update something, calls this fn
            this.subjectName.next({ text: message }); //next() will feed the value in Subject
        }
    
        getUpdate(): Observable<any> { //the receiver component calls this function 
            return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
        }

        sendProductAddedUpdate() {
            this.productAddedSubject$.next();
        }

        get productAddedNotification() {
            return this.productAddedSubject$.asObservable()
        }

        sendProductAddedData(data){
            this.productAddedObjectSubject$.next(data);
        }

        get addedProductData(){
            return this.productAddedObjectSubject$.asObservable();
        }

        sendCartSubTotal(recvSubTotal: number){
            this.cartTotalSubject.next({ subTotal: recvSubTotal });
        }

        getCartSubTotal(): Observable<any> {
            return this.cartTotalSubject.asObservable();
        }

        getCartTotalQuantity(orderItemArray) {
            let itemcount, total = 0;
            itemcount = orderItemArray.reduce((acc, curr) => {
                total = total + curr.presentationQty;
                return total
            }, 0);
            return itemcount;
        }

    }