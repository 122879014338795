import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatDialog, MatPaginator, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import MY_FORMATS, { CustomDateService } from '../../../services/Common/customDateService';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts'
import { environment } from 'src/environments/environment';
import { DashBoardModel } from '../../../models/dashboard/dashboardmodel';
import { DashboardActivitiesModel } from '../../../models/dashboard/dashboardactivitiesmodel';
import { titlename } from '../../../ENUM/titlename';
import { ProductListService } from '../../../services/Product/product-list.service';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { DOCUMENT } from '@angular/common';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { CustomMessageService } from 'src/app/core/custommessageservice';


@Component({
  selector: 'app-dashboard-default',
  templateUrl: './dashboard-default.component.html',
  styleUrls: ['./dashboard-default.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue:MY_FORMATS },
  ],
})
export class DashboardDefaultComponent implements OnInit {
  jsonOrgData: string;
  orgId: any;
  organizationData: Array<any>;
  assetsBaseUrl:string=environment.assetsBaseUrl;
  statusValues;
  getDashboard : DashBoardModel;
  allDashboardDataArray = [];
  getDashboardActivities : DashboardActivitiesModel[] = [];
  dataArr = []
  activitiesDataRangeForm : any;
  isFormSubmit: boolean = false;
  hideChart : boolean = true;
  hideCategoryChart : boolean = true;
  hideBrandChart : boolean = true;
  setColor: { 1: string; 2: string; 3: string; 4: string; 5: string; 6: string; };
  titlename: string;
  titleEnum: typeof titlename;
  featureIds : any;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor(private productListService: ProductListService,
    private router: Router,
    private formbulider: FormBuilder,
    private dashboardService : DashboardService,
    private CustomMessage: CustomMessageService,
    public dialog: MatDialog,
    private helpGuideService:HelpGuideService,private customDateService:CustomDateService,
    private route:ActivatedRoute,private deviceDetect : DeviceDetect,
    @Inject(DOCUMENT) private document: Document) { }
  public displayedColumns = [ 'image','name', 'status', 'createdOn', 'updatedByName', 'actions'];//,'sku', 'name', 'productBrand', 'variants', 'status', 'sellingChannels', 'tat', 'actions'
  public dataSource = new MatTableDataSource<any>();


  // Top Products Chart Function
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks:{
        beginAtZero:true
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [],label:''},
   
  ];
  public chartColors: Array<any> = [
    { // first color
      backgroundColor: '#1A947D',
      pointBackgroundColor: '#92E0D1',
      pointHoverBackgroundColor: '#92E0D1',
    }];
// End Top Product

// Top Number Categories Chart Function
public TopNumberCategoriesChartOptions: ChartOptions = {
  responsive: true,
  // We use these empty structures as placeholders for dynamic theming.
  scales: { xAxes: [{}], yAxes: [{
    ticks:{
      beginAtZero:true
    }
  }] },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    }
  },
  onClick:(event,item)=>{
    let itemValues = item[0];
      if(itemValues['_index'] != null || itemValues['_index'] != undefined || itemValues['_index'] != '')
      {  
        this.onTopCategoriesSelection(this.getDashboard.topEntities.categories[itemValues['_index']].title)     
      }
  }
};
public TopNumberCategoriesChartLabels: Label[] = [];
public TopNumberCategoriesChartType: ChartType = 'bar';
public TopNumberCategoriesChartLegend = false;
public TopNumberCategoriesChartPlugins = [pluginDataLabels];
public TopNumberCategoriesChartData: ChartDataSets[] = [
  
    // { data: [190,260,49],label:''},
    { data: [],label:''},

];

public TopNumberCategoriesColors: Array<any> = [
  { // first color
    backgroundColor: '#914791',
    pointBackgroundColor: '#F504F5',
    pointHoverBackgroundColor: '#F504F5',
  }];
// End Top Number Categories Chart Function

// Top Number Brands Charts 
public TopNumberbrandsChartOptions: ChartOptions = {
  responsive: true,
  // We use these empty structures as placeholders for dynamic theming.
  scales: { xAxes: [{}], yAxes: [{
    ticks:{
      beginAtZero:true
    }
  }] },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    }
  },
  onClick:(event,item)=>{
    let itemValues = item[0];
      if(itemValues['_index'] != null || itemValues['_index'] != undefined || itemValues['_index'] != '')
      {  
        this.onTopBrandSelection(this.getDashboard.topEntities.brands[itemValues['_index']].title)     
      }
  }
};
public TopNumberbrandsChartLabels: Label[] = [];
public TopNumberbrandsChartType: ChartType = 'bar';
public TopNumberbrandsChartLegend = false;
public TopNumberbrandsChartPlugins = [pluginDataLabels];
public TopNumberbrandsChartData: ChartDataSets[] = [
  { data: [],label:''},
];
public TopNumberbrandsColors: Array<any> = [
  { // first color
    backgroundColor: '#4659C9',
    pointBackgroundColor: '#0E2EEC',
    pointHoverBackgroundColor: '#0E2EEC',
}];
// End Top Number Brands Charts 

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  frDate= [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000  )];
  tote= [new Date(Date.now())];
  SetRoute(title)
    {
      
      // TODO:002 refactoring required, also in admin Menu also in admin Menu component
      if(title == "Brands")
      {
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.BRANDS]);
      }
      else if(title == "Categories")
      {
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.CATEGORY_LIST]);
      }
      else if(title == "Variant Types")
      {
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.VARIANTS]);
      }
    }

  ngOnInit() {
    this.getCurrentDevice();
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum=titlename;
    this.titlename=this.titleEnum.dashboard;
    this.document.body.scrollTop = 0;  
    let fromDateInitialValue = new Date(this.addMonths(new Date(),-1));
    let toDateInitialValue = new Date(Date.now());
    toDateInitialValue = new Date(toDateInitialValue.toDateString());
    this.activitiesDataRangeForm = this.formbulider.group({
      fromDate: [fromDateInitialValue],
      toDate: [toDateInitialValue],
    })
    this.setColor = { 1:"Red",2:"Indigo",3:"Blue",4:"Cyan",5:"Teal",6:"Green"};
    let serchDateFilter = this.activitiesDataRangeForm.value;
    this.onEnterdashboardActivitiesFilter(serchDateFilter)
    this.statusValues = {1:"Draft", 2:"Completed", 3:"Active",4:"Inactive",5:"Discontinued"};
    this.organizationData=[];
     this.jsonOrgData =decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId=this.organizationData[0].id;
    this.getAllProducts();
    let requestParameter = "?OrganizationId=" + this.orgId; 
    this.dashboardService.getDashboardData(requestParameter).subscribe(res=>{
      this.getDashboard = res.data;
      this.allDashboardDataArray.push(res.data);
        for(let obj of this.getDashboard.topEntities.categories)
        {
          this.TopNumberCategoriesChartData[0].data.push(obj.count); 
          this.TopNumberCategoriesChartLabels.push(obj.title);
        }
        for( let obj of this.getDashboard.topEntities.brands)
        {
          this.TopNumberbrandsChartData[0].data.push(obj.count); 
          this.TopNumberbrandsChartLabels.push(obj.title);
        }
    },
    err => {
      this.CustomMessage.ShowCustomMessage(err.error.StatusMessage || "Something went wrong", false);
    })
    this.dataSource.paginator = this.paginator;
    this.changeColumnPosition();
  }
  public getAllProducts() { 
    var requestparameter = "?organizationId=" + this.orgId + "&sortBy=3&sortOrder=2&ExcludeProductGroups=true";
    this.productListService.getData(requestparameter)
      .subscribe(res => {
        if (res.data) {
          this.dataSource.data = res.data["data"];
        }
      },
    err => {
      this.CustomMessage.ShowCustomMessage(err.error.StatusMessage || "Something went wrong", false);
    })
  }
  public redirectToUpdate = (id) => {
    let navigation:any = RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.PRODUCT_DASHBOARD;
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH+id+RouterLinksEnum.ADMIN_EDIT,navigation);
  }

  getCommaSeparated(arrayObjects: string[]): string {
    return Array.prototype.map.call(arrayObjects, s => s).toString();
  }
  onFormSubmit(f: NgForm | FormGroup) {
    if (f.invalid) {
      return false;
    }
    this.isFormSubmit = true;
    const searchdata = this.activitiesDataRangeForm.value;
    this.onEnterdashboardActivitiesFilter(searchdata);
  }
  redirecttoReadOnly(id)
  {
    this.router.navigateByUrl(RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.MANAGE_PRODUCTS+id);
  }

  onEnterdashboardActivitiesFilter(event)
  {
    let timeZoneOffsetMinutes = (new Date()).getTimezoneOffset();
    let requestParameter = "?OrganizationId=" + this.orgId; 
    if (this.activitiesDataRangeForm.get('fromDate').value != null) {
      requestParameter += "&fromDate=" + new Date(this.activitiesDataRangeForm.get('fromDate').value).toISOString();
    }
    if (this.activitiesDataRangeForm.get('toDate').value != null) {
      requestParameter += "&toDate=" + new Date(this.activitiesDataRangeForm.get('toDate').value).toISOString();
    }
    this.barChartData[0].data = [];
    this.barChartLabels =[];
    this.dashboardService.getDashboardActivities(requestParameter).subscribe(res=>{
      this.getDashboardActivities = res.data;
      for(let obj of this.getDashboardActivities)
      {
        this.barChartData[0].data.push(obj.count); 
        this.barChartLabels.push(obj.title);
      }
    })
  }
  getWrapedText(text,maxLength)
  {
    if(text.length <= maxLength)
    {
      return text;
    }
    else
    {
      return text.substring(0,maxLength-3) + "...";
    }
  }
  openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }
  customDate(date)
  {
    return this.customDateService.customDateFormat(date);
  }
  customDateAndTime(date)
    {
      return this.customDateService.customDateAndTimeFormat(date);
    }

    addMonths(date, months) {
      date.setMonth(date.getMonth() + months);
      return date.setHours(0,0,0,0);
    }  
    onQuickNumbersSelection(entityId,count){
      if(!count)return;
      if(entityId=="0"){
        this.router.navigate([RouterLinksEnum.ADMIN]);
       return ;
      }
      if(this.phoneScreen){
        this.router.navigate([RouterLinksEnum.ADMIN],{relativeTo:this.route,queryParams:{statuses:entityId}});
      }
      if(this.tabletScreen || this.desktopScreen){
      let productStatus = JSON.stringify({"productStatus":entityId});
      this.router.navigate([RouterLinksEnum.ADMIN],{relativeTo:this.route,queryParams:{query:productStatus}});
      }
    }
    onTopBrandSelection(title){
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.BRANDS],{relativeTo:this.route,queryParams:{searchtext:title}})
    }
    onTopCategoriesSelection(title){
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.CATEGORY_LIST],{relativeTo:this.route,state:{searchText:title}})
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.getCurrentDevice();
      this.changeColumnPosition();
    }
    getCurrentDevice(){
      this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
      this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
      this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
    }
    changeColumnPosition(){
      if(this.desktopScreen || this.tabletScreen){
        this.displayedColumns = ['image','name','status', 'createdOn', 'updatedByName','actions'];
      }
      if(this.phoneScreen){
        this.displayedColumns = [ 'actions','image','name','status', 'createdOn', 'updatedByName',];
      }
    }
}
