import { MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete } from '@angular/material';
import { Component, Inject, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { CommonService } from '../../services/Common/common.service';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { environment } from 'src/environments/environment';
import { CustomMessageService } from 'src/app/core/custommessageservice';

@Component({
  selector: 'app-helpGuide.dialog',
  templateUrl: './helpGuide.dialog.html',
  styleUrls: ['./helpGuide.dialog.css']
})
export class HelpGuideComponent implements OnInit {
  historyData: any = [];
  PIMEntityTypeEnum: { id: number; name: string; }[];
  onloadShowHistory: boolean = true;
  onSelectionShowHistory: boolean = false;
  selectedIndex: any;
  showHistory: boolean = false;
  PIMActivityTypeEnum: { id: number; name: string; }[];
  noHistory: boolean = false;
  PIMActivityTypeValue: { id: number; name: string; }[];
  allCommentInfo: any = [];
  commentForm: any;
  isFormSubmit: boolean = false;
  addedAllUser = [];
  allSelectedUser = [];
  allUser: { id: number; name: string; }[];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allUserList: { id: number; name: string; }[];
  userCSVsCtrl = new FormControl();
  // filteredUser: Observable<string[]>;
  filteredUser: any = [];
  selectedUserList: string[] = [];
  allFruits: string[] = [];
  @ViewChild('editor', { static: false }) editor;
  @ViewChild('fruitInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  helpGuideInfo: any;
  innerHtml: any;
  isEditable: boolean = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  userEmail: any;
  showActionButtons: boolean = false;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor(public dialogRef: MatDialogRef<HelpGuideComponent>,
    private customMessageService: CustomMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private deviceDetectService: DeviceDetect) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.checkScreenSize();
    this.getUserInfo();
    if (this.data.featureId) {
      let requestParameter = "?featureId=" + this.data.featureId
      setTimeout(() => { this.getHelpGuide(requestParameter) }, 1000);
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  getHelpGuide(requestParameter) {
    this.commonService.getHelpGuide(requestParameter).subscribe(res => {
      this.helpGuideInfo = res.data.data;
      this.innerHtml = this.helpGuideInfo[0].html;
    })
  }
  editHelp() {
    this.isEditable = true;
  }
  setStyle(style: string) {
    let bool = document.execCommand(style, false, null);
  }
  updateHelpGuide() {

    this.helpGuideInfo[0].html = this.innerHtml;
    this.commonService.saveHelpGuide(this.helpGuideInfo[0]).subscribe(res => {
      this.customMessageService.ShowCustomMessage(res.statusMessage, true);
      this.closeDialog();
    })
  }

  cancelUpdate() {
    this.isEditable = false
  }
  getUserInfo() {
    if (sessionStorage.getItem("SessionStorge_UserModel") !== null) {
      let userInfo = JSON.parse(sessionStorage.getItem('SessionStorge_UserModel'));
      this.userEmail = userInfo.email;
      if (this.userEmail) {
        let splitInfo = this.userEmail.split("@");
        if (environment.admin_domain.indexOf(splitInfo[1]) > -1) {
          this.showActionButtons = true;
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetectService.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetectService.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetectService.currentDevice().desktopScreen;
  }
}
