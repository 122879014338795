import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollingToErrorsService } from '../../../../../../../../OmniChannel/src/app/core/Common/scrollingToErrors.service';
import { HelpGuideService } from '../../../services/helpGuide/helpGuideService';
import { HelpFeatureIdsEnum } from '../../../ENUM/helpFeatureIds';
import { PIMEntityType } from '../../../ENUM/PIMEntityTypeEnum';
import { titlename } from '../../../ENUM/titlename';
import { CreateVariant } from '../../../models/Variants/create-variant.model';
import { RouterLinksEnum } from '../../../shared/router-links/router-links.enum';
import { HttpHelperService } from '../../../services/Common/http-helper.service';
import { VariantsService } from '../../../services/Variants/variants.service';
import { ChatDialogService } from '../../../shared/chatDialogOpenService/chatDialog';
import { ORGANIZATION_INFO } from '../../../shared/constants/global';
import { HistoryDialogService } from '../../../shared/histotyDialogOpenService/historyDialog';
import { CustomMessageService } from 'src/app/core/custommessageservice';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';

@Component({
  selector: 'app-add-new-variant-default',
  templateUrl: './add-new-variant-default.component.html',
  styleUrls: ['./add-new-variant-default.component.scss']
})
export class AddNewVariantDefaultComponent implements OnInit {
  newVariant: string;
  editVariant: {};
  variantId: string;
  description: string;
  _Paxcom_DocumentType: string = "";
  isEditMode: boolean = false;
  
  orgId:string;  
  orgName:any;
  appLogoUrl:any;  
  jsonOrgData: string;  
  organizationData:Array<any>;
  RouterLinksEnum: any;  
  variantForm: any;
  buttonName: string;
  titlename: string;
  titleEnum: typeof titlename;
  isFormSubmit: boolean=false;
  variantName: any;
  featureIds: typeof HelpFeatureIdsEnum;
  disableActionsButtonsWhileAPICall: boolean = false;
  saveAndContinue: boolean = false;
  actionName: string = "Save";
  showHelpIcon : boolean = false;
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  constructor(public httpClient: HttpClient, public httpHelper:HttpHelperService, private route: ActivatedRoute,
     private router: Router, public dialog: MatDialog, public dataService: VariantsService,private helpGuideService:HelpGuideService,
    private chatDialogService:ChatDialogService,
    private deviceDetect : DeviceDetect,
     private CustomMessage: CustomMessageService,
     private scrollingToErrorsService:ScrollingToErrorsService,
     private historyDialogService:HistoryDialogService) { 
      this.getCurrentDevice();
    }
  
  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  addNewVariant() {
    let createVariant: CreateVariant = {
       name: this.variantForm.get('variant').value,
       description: this.variantForm.get('description').value,
       active: true,
      _Paxcom_OrganizationId: this.orgId,
      _Paxcom_DocumentType: this._Paxcom_DocumentType
    };

    this.dataService.postVariant(createVariant).subscribe(res => {
      let message = res['statusMessage'];
      this.CustomMessage.ShowCustomMessage(message, true);      
      this.disableActionsButtonsWhileAPICall = false;
      if (this.saveAndContinue == false) {
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.VARIANTS_LIST]);
      }
      else {
        this.router.navigateByUrl(RouterLinksEnum.ADMIN, { skipLocationChange: true }).then(() =>
        this.router.navigate([RouterLinksEnum.ADMIN_SLASH + RouterLinksEnum.VARIANTS_CREATE]));
      }
    },err=>{
      this.disableActionsButtonsWhileAPICall = false;
    })

  }  

  updateVariant() {

    let editVariant: CreateVariant = {
       id: this.variantId.toString(),
       name: this.variantForm.get('variant').value,
       description: this.variantForm.get('description').value,
       active: true,
      _Paxcom_OrganizationId: this.orgId.toString(),
      _Paxcom_DocumentType: this._Paxcom_DocumentType
    };
    this.dataService.editVariant(editVariant).subscribe(res => {
      let message = res['statusMessage'];
      this.CustomMessage.ShowCustomMessage(message, true);
      this.disableActionsButtonsWhileAPICall = false;
      this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.VARIANTS_LIST]);
    },err=>{
      this.disableActionsButtonsWhileAPICall = false;
    })
  }

  cancell() {
    this.router.navigate([RouterLinksEnum.ADMIN_SLASH+RouterLinksEnum.VARIANTS_LIST]);
  }

  onFormSubmit(f: NgForm | FormGroup): void {
    this.isFormSubmit=true;
    if(f.invalid){
      this.scrollingToErrorsService.scrollToError();
      return;
    }
    this.buttonName = document.activeElement.getAttribute("name");
    let variantForm = this.variantForm.value;
    this.disableActionsButtonsWhileAPICall = true;
    if(this.variantId)
    {
      variantForm.id = this.variantId;
      this.updateVariant(); 
    }
    else
    {
      variantForm.id = "";
      this.addNewVariant();
    }
    // if (this.buttonName === "save") {
    //   this.addNewVariant();
    // }
    // else if (this.buttonName === "saveContinue") {
    //   this.addNewVariant();
    // }
    // else if (this.buttonName === "update") {
    //   this.updateVariant();
    // }
  }

  get variant(){
    return this.variantForm.get('variant');
  }

  ngOnInit() {
    this.featureIds = HelpFeatureIdsEnum;
    this.titleEnum=titlename;
    this.RouterLinksEnum = RouterLinksEnum;
    this.titlename=this.titleEnum.addVariant;
    this.organizationData=[];
    this.jsonOrgData = decodeURI(localStorage.getItem(ORGANIZATION_INFO));
    this.organizationData.push(JSON.parse(this.jsonOrgData));
    this.orgId=this.organizationData[0].id;

    this.variantForm = new FormGroup({
      variant: new FormControl('', [Validators.required,Validators.maxLength(250)]),
      description: new FormControl('',[Validators.maxLength(2000)])
    })
           
    this.variantId = this.route.snapshot.params['id'];

    if(this.variantId){
      this.actionName = "Update";
      this.isEditMode = true; 
      this.titlename=this.titleEnum.updateVariant;     
        if (this.variantId !== undefined) {
              this.dataService.getVariantbyVariantId(this.variantId+"?organizationId="+this.orgId).subscribe(res => 
              {
                this.variantName = res.data.name;
                this.variantForm.setValue({ variant: res.data.name, description: res.data.description });
              }, 
              err => { this.CustomMessage.ShowCustomMessage(err.error.message, false) });  
        }
      }
      else {
          this.isEditMode = false;
          this.showHelpIcon = true;

        }
    }
  openChat()
  {
    this.chatDialogService.openChatDialog(PIMEntityType.VariantType,this.variantId,this.variantName);  
  }
  openHelpDialog(featureId)
  {
    this.helpGuideService.openHelpGuideDialog(featureId)
  }
  openHistory()
  {
    this.historyDialogService.openHistoryDialog(PIMEntityType.VariantType,this.variantId,this.variantName);  
  }
  onSaveAndRedirect()
  {
      this.saveAndContinue = false;   
  }
  onSaveAndContinue()
  {
      this.saveAndContinue = true;   
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCurrentDevice();
  }

  getCurrentDevice(){
    this.phoneScreen = this.deviceDetect.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetect.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetect.currentDevice().desktopScreen;
  }
}

