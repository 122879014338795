import {MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {Component, Inject, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import { HistoryService} from '../../services/History/history.service';
import { FormBuilder, NgForm, FormGroup, Validators, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CommentService } from '../../services/comment/comment.service';
import { CreateUserService } from '../../services/User/create-user.service';
import { CustomDateService } from '../../services/Common/customDateService';
import { DeviceDetect } from 'src/app/core/common_services/device-detect/device-detect.service';
import { messages } from 'src/app/shared/Constants/messageResource';
import { CustomMessageService } from 'src/app/core/custommessageservice';
@Component({
  selector: 'app-chat.dialog',
  templateUrl: './chat.dialog.html',
  styleUrls: ['./chat.dialog.css']
})
export class ChatComponent implements OnInit {
  historyData: any = [];
  PIMEntityTypeEnum: { id: number; name: string; }[];
  onloadShowHistory:boolean = true;
  onSelectionShowHistory: boolean = false;
  selectedIndex: any;
  showHistory: boolean = false;
  PIMActivityTypeEnum: { id: number; name: string; }[];
  noHistory: boolean = false;
  PIMActivityTypeValue: { id: number; name: string; }[];
  allCommentInfo: any = [];
  commentForm: any;
  isFormSubmit: boolean = false;
  addedAllUser = [];
  allSelectedUser = [];
  allUser: { id: number; name: string; }[];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allUserList: { id: number; name: string; }[];
  userCSVsCtrl = new FormControl();
  // filteredUser: Observable<string[]>;
  filteredUser: any = [];
  selectedUserList: string[] = [];
  allFruits: string[] = [];
  phoneScreen: boolean;
  tabletScreen: boolean;
  desktopScreen: boolean;
  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  constructor(public dialogRef: MatDialogRef<ChatComponent>,
    private customDateService:CustomDateService, private customMessageService: CustomMessageService,
              @Inject(MAT_DIALOG_DATA) public data: any,private commentService:CommentService,
              private formBuilder: FormBuilder,private createUserService:CreateUserService,
              private deviceDetectService: DeviceDetect) { 
                dialogRef.disableClose = true;
                this.userCSVsCtrl.valueChanges.subscribe(val => {
 
                  if (val !== null && val !== undefined && val !== '') {
                    this.getAllUser(val);
                  }
                });
              }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit()
  {
    this.checkScreenSize();
    this.WMSEntityType()
    this.commentFormControls();
    if(this.data)
    {
      setTimeout(()=>{this.getComment(this.data.entityId,this.data.entityType)},1000);  
    }
  }
  WMSEntityType()
  {
    this.PIMActivityTypeEnum = 
    [{ id: 1, name: 'is created' },
    { id: 2, name: 'is updated' },
    { id: 3, name: 'is deleted' },
  ],
  this.PIMActivityTypeValue = 
  [{ id: 1, name: 'Created' },
  { id: 2, name: 'Updated' },
  { id: 3, name: 'Deleted' },
],
    this.PIMEntityTypeEnum = 
    [{ id: 1, name: 'Product' },
    { id: 2, name: 'Category' },
    { id: 3, name: 'Brand' },
    { id: 4, name: 'NotificationEventSettings' },
    { id: 5, name: 'ProductTag' },
    { id: 6, name: 'Variant' },
    { id: 7, name: 'User' },
    { id: 8, name: 'Asset' },
    { id: 9, name: 'Folder' },
    { id: 10, name: 'Channel' }
    ];
  }
  confirmDelete(): void {
    //this.dataService.deleteIssue(this.data.id);
  }
  getComment(entityId,entityType)
  {
      let requestParameter = "?entityId="+entityId+"&pIMEntityType="+entityType
      this.commentService.getAllComments(requestParameter).subscribe(res=>{
        this.allCommentInfo = res.data.data;
    })
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  commentFormControls()
  {
    this.commentForm = this.formBuilder.group({
      id : [''],
      commentTitle: [''],
      commentText: [''],
      entityId: [''],
      entityType: [''],
      applicationId: [''],
      commentedAt: [''],
      commentedByName: [''],
      commentedById: [''],
      commentedByEmail: [''],
      updatedById: [''],
      updatedOn: [''],
      updatedByName: [''],
      updatedByEmail: [''],
    })
  }
  getShortText(text) {

    var myStr =text;
    var matches = myStr.match(/\b(\w)/g);
    return matches.join('');
    //console.log(matches.join(''));
  }
  showActivityType(entityType,activityType) {
    var text;
    text =  this.PIMEntityTypeEnum[entityType - 1].name +" "+ this.PIMActivityTypeEnum[activityType - 1].name
    return text
    //console.log(matches.join(''));
  }
  onHistoryListSelection(ind)
  {
    this.selectedIndex = ind;
    this.onSelectionShowHistory = true;
    this.onloadShowHistory = false;
  }
  getWrapedText(text,maxLength)
  {
    if(text.length <= maxLength)
    {
      return text;
    }
    else
    {
      return text.substring(0,maxLength-3) + "...";
    }
  }
  removeLastComma(strng){        
    var n=strng.lastIndexOf(",");
    var a=strng.substring(0,n) 
    return a;
  }
  onFormSubmit(formvalue: NgForm | FormGroup) {
    this.isFormSubmit = true;
    let commentText = "";
    if(this.commentForm.get('commentText').value == null || this.commentForm.get('commentText').value == "")
    {
      this.customMessageService.ShowCustomMessage(messages.addComment,false);
      return false;
    }
    if (formvalue.invalid) {
      return false;
    }
    
    this.commentForm.get('entityId').setValue(this.data.entityId)
    this.commentForm.get('entityType').setValue(this.data.entityType)
    if(this.selectedUserList.length > 0 )
    {
      commentText = this.commentForm.get('commentText').value;
      commentText = commentText + "\n" + JSON.stringify(this.selectedUserList).replace(/['"]+/g, '').replace(/[\[\]']+/g,'');
      //this.commentForm.get('commentText').setValue(commentText)
    }
    let formValue = this.commentForm.getRawValue();
    if(commentText)
    {
      formValue.commentText = commentText;
    }
    this.commentService.saveComment(formValue).subscribe(res=>{
      let message=res.statusMessage||res.StatusMessage;
      this.customMessageService.ShowCustomMessage(message, true);
      this.selectedUserList = [];
      this.commentForm.get('commentText').setValue(null);
      this.getComment(this.data.entityId,this.data.entityType)
    })
  }
  add(event: MatChipInputEvent): void {

    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        //this.selectedUserList.push(value.trim());
        //this.userMappingForm.get('usersCSVs').setValue(JSON.stringify(this.selectedUserList).replace(/[\[\]']+/g,'').replace(/['"]+/g, ''));
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
      
      this.userCSVsCtrl.setValue(null);
    }
  }

  remove(fruit: string): void {
    const index = this.selectedUserList.indexOf(fruit);

    if (index >= 0) {
      this.selectedUserList.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedUserList.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    //this.userMappingForm.get('usersCSVs').setValue(JSON.stringify(this.selectedUserList).replace(/[\[\]']+/g,'').replace(/['"]+/g, ''));
     this.userCSVsCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  getAllUser(val) {
     this.createUserService.getUsersList(val).subscribe(res=>{
      this.filteredUser = res.data
     })


    // this.filteredUser = this.usersListService.getUsersList(val).pipe(
    //   map(res => res.data),
    //   share()  // add share to stop API call mutiple times on load
    // );
    // return this.filteredUser;
  }
  customDate(date)
  {
    return this.customDateService.customDateFormat(date);
  }
  customDateAndTime(date)
    {
      return this.customDateService.customDateAndTimeFormat(date);
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.phoneScreen = this.deviceDetectService.currentDevice().phoneScreen;
    this.tabletScreen = this.deviceDetectService.currentDevice().tabletScreen;
    this.desktopScreen = this.deviceDetectService.currentDevice().desktopScreen;
  }
}
